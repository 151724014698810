import { Navigate, Route, Routes } from 'react-router-dom';
import { PrivacyPages } from '../pages';

export function PrivacyRoutes() {
  return (
    <Routes>
      <Route path="" element={<PrivacyPages />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
}
