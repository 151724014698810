import { Navigate, Route, Routes } from 'react-router-dom';

import { StartPage } from '@/features/start';

export function StartRoutes() {
  return (
    <Routes>
      <Route path="" element={<StartPage />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
}
