import create, { StateCreator } from 'zustand';
import { devtools, persist, PersistOptions } from 'zustand/middleware';

import { UserTypes } from '@/types';

type User = {
  id: string;
  name: string;
  type: UserTypes;
  descricao: string;
  avatar: string;
  email?: string;
  gestorMasterId?: string;
  questionario?: boolean;
  instanciasId: string[];
  unidadesId: string[];
  isAdmin: boolean;
  isMaster: boolean;
  profissionalId: string;
  codigoVinculo: string;
  pacienteId: string;
};

type UserStore = {
  user: User | null;
  setUser: (user: User | null) => void;
};

type UserPersist = (
  config: StateCreator<UserStore>,
  options: PersistOptions<UserStore>,
) => StateCreator<UserStore>;

export const useUserStore = create<UserStore>(
  devtools(
    (persist as UserPersist)(
      set => ({
        user: null,
        setUser: user => set(state => ({ user })),
      }),
      {
        name: 'user-store',
        getStorage: () => localStorage,
      },
    ),
    {
      name: 'UserStore',
    },
  ),
);
