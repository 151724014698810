import { Box, Flex } from '@chakra-ui/react';
import { Card } from 'antd';
import { TableSectionExams } from './TableSectionExams';

export default function ExamsCard({ patientId, patient }: any) {
  return (
    <Flex flex="1">
      <Box backgroundColor="#fff" flex="1">
        <Card
          style={{
            maxHeight: '60rem',
            height: '100%',
            borderRadius: '10px',
            backgroundColor: '#fff',
            marginBottom: '30px',
          }}
        >
          <TableSectionExams patientId={patientId} patient={patient} />
        </Card>
      </Box>
    </Flex>
  );
}
