import {
  Box,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  InputProps,
  Tooltip,
} from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { FieldError } from 'react-hook-form';
import { IconType } from 'react-icons';
import { RiErrorWarningFill } from 'react-icons/ri';

interface TextInputProps extends InputProps {
  icon?: IconType;
  error?: FieldError;
  iconView?: IconType;
  setView?: () => void;
}

const TextInputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  TextInputProps
> = ({ name, icon, iconView, setView, error, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      <InputGroup>
        {icon && (
          <InputLeftElement pl="4" pointerEvents="none">
            <Icon as={icon} fontSize="20px" color="gray.600" />
          </InputLeftElement>
        )}

        <Input
          {...rest}
          id={name}
          name={name}
          ref={ref}
          pl={icon ? '12' : '4'}
          pr="8"
          h="42px"
          borderColor="gray.500"
          background="white"
          color="gray.700"
          _placeholder={{ color: 'gray.500' }}
          _hover={{
            borderColor: 'brand-orange.400',
          }}
        />

        <InputRightElement pr="4">
          {error ? (
            <Tooltip
              label={error.message}
              aria-label="Warning tooltip"
              bg="gray.800"
              color="white"
            >
              <Box as="span" pt="1">
                <Icon as={RiErrorWarningFill} fontSize="20px" color="red" />
              </Box>
            </Tooltip>
          ) : (
            iconView && (
              <Box as="span" pt="1">
                <Icon
                  as={iconView}
                  onClick={setView}
                  cursor="pointer"
                  fontSize="20px"
                  color="gray.400"
                />
              </Box>
            )
          )}
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export const TextInput = forwardRef(TextInputBase);
