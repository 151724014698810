import { Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';

interface FileProps {
  files: any[];
  onDelete: (id: string) => void;
}

export function FilesList({ files, onDelete }: FileProps) {
  return (
    <Flex w="100%" gap="2" wrap="wrap" align="flex-start" justify="flex-start">
      {files.map(file => (
        <Tag
          key={file.id}
          size="sm"
          variant="solid"
          colorScheme="brand-green"
          borderRadius="full"
        >
          <TagLabel>{file.file.name}</TagLabel>
          <TagCloseButton onClick={() => onDelete(file.id)} />
        </Tag>
      ))}
    </Flex>
  );
}
