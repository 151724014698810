import { BodySection, TopSection } from '../components';

export function PrivacyPages() {
  return (
    <div>
      <TopSection />
      <BodySection />
    </div>
  );
}
