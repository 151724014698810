import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { IoSearchOutline } from 'react-icons/io5';

export function PatientSearchInput({ value, onChange }: any) {
  function handleChange(event: any) {
    onChange(event.target.value);
  }

  return (
    <InputGroup maxW={['100%', '390px']} w="100%">
      <InputLeftElement h="100%" pointerEvents="none">
        <Icon as={IoSearchOutline} fontSize="xl" color="#bfbfbf" />
      </InputLeftElement>
      <Input
        size="lg"
        variant="filled"
        placeholder="Buscar dados"
        h="52px"
        borderRadius="14px"
        bg="white"
        color="brand-dark.500"
        _placeholder={{ color: '#bfbfbf' }}
        _hover={{ bg: 'brand-dark.100' }}
        value={value}
        onChange={handleChange}
      />
    </InputGroup>
  );
}
