import { Flex, Heading, Image } from '@chakra-ui/react';

import medicsImg from '@/assets/medics_group.svg';

export function LoginImageSection() {
  return (
    <Flex
      mb="12"
      pt={['12', '12', '24']}
      pb="6"
      px={['8', '14', '14']}
      w="100%"
      h="100%"
      bg="brand-green.50"
      direction="column"
      align="center"
    >
      <Heading size="md" mb="12" color="gray.700" textAlign="center">
        Profissionais da saúde e pacientes próximos nessa jornada
      </Heading>

      <Image
        src={medicsImg}
        alt="Grupo de médicos reunidos em um símbolo da medicina"
      />
    </Flex>
  );
}
