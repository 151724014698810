import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Radio,
  RadioGroup,
  Stack,
  VStack,
  useToast,
} from '@chakra-ui/react';

import { RegisterUserForm } from '@/features/registers';
import { useAddUserModalStore } from '@/stores';
import { useEffect, useState } from 'react';
import { RegisterUserFormFast } from '../components/RegisterUserFormFast';

export function RegisterNewUserPage() {
  const [formStep, setFormStep] = useState(0);
  const [completeRegistration, setCompleteRegistration] = useState(null);
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const {
    isOpen,
    onClose,
    content: contentShown,
    changeContent: setContentShown,
  } = useAddUserModalStore();

  function renderModalHeaderTitle() {
    return 'Novo Cadastro';
  }

  function renderModalContent() {
    if (formStep === 0) {
      return (
        <VStack spacing="4">
          <RadioGroup
            onChange={setCompleteRegistration}
            value={completeRegistration}
          >
            <Stack>
              <Radio size="lg" value="fast">
                Cadastro Rápido
              </Radio>
              <Radio size="lg" value="complete">
                Cadastro Completo
              </Radio>
            </Stack>
          </RadioGroup>
          <Button
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
            onClick={() => {
              if (!completeRegistration) {
                toast({
                  title: 'Selecione o tipo de cadastro.',
                  status: 'error',
                });
                return;
              }
              setFormStep(1);
            }}
          >
            Avançar
          </Button>
        </VStack>
      );
    }
    return completeRegistration === 'fast' ? (
      <RegisterUserFormFast onCancel={onClose} />
    ) : (
      <RegisterUserForm onCancel={onClose} />
    );
  }

  useEffect(() => {
    setFormStep(0);
    setCompleteRegistration(null);
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        setContentShown('buttons');
      }}
      size="xl"
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color="brand-dark.300"
          fontSize="xl"
          fontWeight="medium"
          textAlign="center"
        >
          {renderModalHeaderTitle()}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="12" pb="8">
          {renderModalContent()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
