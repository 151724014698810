import { Navigate, Route, Routes } from 'react-router-dom';
import { AuthRoutes } from '@/features/auth';
import { PublicPrivacyPage } from '@/features/privacy/pages';
import { PublicTermsPage } from '@/features/terms/pages';

export function PublicRoutes() {
  return (
    <Routes>
      <Route path="/auth/*" element={<AuthRoutes />} />
      <Route path="/privacy" element={<PublicPrivacyPage />} />
      <Route path="/terms" element={<PublicTermsPage />} />
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
}
