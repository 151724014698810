export function mapPrescription(obj: any, PacienteId: any, ConsultaId: any) {
  const {
    Exames,
    Atestados,
    Encaminhamentos,
    Relatorios,
    Itens,
    Documentos,
    URLQRCode,
  } = obj;

  const newMedicamentos = Itens.map((medicamento: any) => ({
    tipoMedicamento: medicamento.TipoMedicamento || null,
    farmacos: medicamento.Farmacos.join(', ') || null,
    comentario: medicamento.Comentario || null,
    concentracao: medicamento.Concentracao || null,
    controleEspecial: medicamento.ControleEspecial || null,
    embalagem: medicamento.Embalagem || null,
    nome: medicamento.Nome || null,
    posologia: medicamento.Posologia || null,
    quantidade: medicamento.Quantidade || null,
    usoContinuo: medicamento.UsoContinuo || null,
    idMedicamento: medicamento.IdMedicamento || null,
    viaAdministracao: medicamento.ViaAdministracao || null,
  }));

  const newAtestado = Atestados.map((atestado: any) => ({
    consentimentoCID: atestado.ConsentimentoCID || null,
    observacao: atestado.Observacao || null,
    texto: atestado.Texto || null,
    tipoPeriodo: atestado.TipoPeriodo || null,
    dataAtestado: atestado.DataAtestado || null,
    textoCompleto: atestado.TextoCompleto || null,
    dataInicio: atestado.DataInicio || null,
    dataTermino: atestado.DataTermino || null,
  }));

  const newEncaminhamento = Encaminhamentos.map((encaminhamentos: any) => ({
    ciD10: {
      codigo: encaminhamentos.CID10.SubCategoria,
      idCID10: encaminhamentos.CID10.IdSubCategoria,
      descricao: encaminhamentos.CID10.Descricao,
    },
    conduta: encaminhamentos.Conduta,
    dataEncaminhamento: encaminhamentos.DataEncaminhamento,
    dataInclusao: encaminhamentos.DataInclusao,
    dataModificacao: encaminhamentos.DataModificacao,
    historiaClinica: encaminhamentos.HistoriaClinica,
    idEncaminhamentoPrescricao: encaminhamentos.idEncaminhamentoPrescricao,
    motivo: encaminhamentos.Motivo,
    especialidadeMevo: {
      idEspecialidade: encaminhamentos.Especialidade.IdEspecialidade,
      nome: encaminhamentos.Especialidade.Nome,
      nomeProfissional: encaminhamentos.Especialidade.NomeProfissional,
    },
  }));

  const newRelatorio = Relatorios.map((relatorio: any) => ({
    idPrescricaoLaudoMedico: relatorio.idPrescricaoLaudoMedico,
    texto: relatorio.Texto,
    titulo: relatorio.Titulo,
    dataLaudoMedico: relatorio.DataLaudoMedico,
    dataInclusao: relatorio.DataInclusao,
    dataModificacao: relatorio.DataModificacao,
  }));

  const medicamentoUrl = Documentos.filter(
    (doc: any) => doc.Categoria === 'RECEITA',
  );

  const exameUrl = Documentos.filter((doc: any) => doc.Categoria === 'EXAME');

  const atestadoUrl = Documentos.filter(
    (doc: any) => doc.Categoria === 'ATESTADO',
  );

  const encaminhamentoUrl = Documentos.filter(
    (doc: any) => doc.Categoria === 'ENCAMINHAMENTO',
  );

  const relatorioUrl = Documentos.filter(
    (doc: any) => doc.Categoria === 'RELATORIO',
  );

  const newObj = {
    exames: Exames,
    medicamentos: newMedicamentos,
    atestado: newAtestado,
    encaminhamento: newEncaminhamento,
    relatorio: newRelatorio,
    pacienteId: PacienteId,
    consultaId: ConsultaId,
    exameUrl: exameUrl[0]?.URL || '',
    atestadoUrl: atestadoUrl[0]?.URL || '',
    medicamentoUrl: medicamentoUrl[0]?.URL || '',
    encaminhamentoUrl: encaminhamentoUrl[0]?.URL || '',
    relatorioUrl: relatorioUrl[0]?.URL || '',
    qrCodeUrl: URLQRCode,
  };

  return newObj;
}
