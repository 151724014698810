// import { useChatNotifications } from '@/context/chat-notifications';
import {
  ChatProps,
  useGetChatList,
} from '@/features/schedule/hooks/useGetChatList';
import { useProfessionalStore, useUserStore } from '@/stores';
import {
  Text,
  Avatar,
  Box,
  Skeleton,
  Stack,
  Center,
  Spinner,
} from '@chakra-ui/react';
import { Badge } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useChatNotifications } from '@/context/chat-notifications';

interface RoomsProps {
  onChatRoom: (room: string) => void;
  setRoomId: (room: string) => void;
  setProSelect: any;
  search: string;
}

export function Rooms({
  onChatRoom,
  setRoomId,
  setProSelect,
  search,
}: RoomsProps) {
  const [selectedProfessional, setSelectedProfessional] = useState<string>();
  const [chatList, setChatList] = useState<ChatProps[]>([]);
  // const [filteredProfList, setFilteredProfList] = useState<professionalProps[]>(
  //   [],
  // );
  const { professional } = useProfessionalStore();
  const user = useUserStore(state => state.user);
  const containerRef = useRef<HTMLDivElement>(null);
  const [page, setPage] = useState(1);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);

  const { data, isLoading, refetch } = useGetChatList({
    search,
    page,
  });

  const { notifications } = useChatNotifications();

  // const updatePersonsWithNotifications = () => {
  //   const newListUpdated = professionals.map(person => {
  //     const notificationsForPerson = notifications.filter(
  //       notification => notification.ChatRoomId === person.chatId,
  //     );

  //     if (notificationsForPerson.length > 0) {
  //       const lastNotification = notificationsForPerson.reduce(
  //         (prev, current) =>
  //           new Date(current.DataCriacao) > new Date(prev.DataCriacao)
  //             ? current
  //             : prev,
  //       );

  //       return {
  //         ...person,
  //         mensagensNaoVisualizadas: notificationsForPerson.length,
  //         dateLastMessage: lastNotification.DataCriacao,
  //       };
  //     }
  //     return person;
  //   });

  //   newListUpdated.sort((a, b) => {
  //     const dateA = new Date(a.dateLastMessage).getTime();
  //     const dateB = new Date(b.dateLastMessage).getTime();
  //     return dateB - dateA;
  //   });

  //   setProfessionals(newListUpdated);
  // };

  // useEffect(() => {
  //   if (notifications.length > 0) {
  //     updatePersonsWithNotifications();
  //   }
  // }, [notifications]);

  // const updateView = (id: any) => {
  //   if (professionals) {
  //     const date = professionals.map((a: any) => {
  //       if (a.id === id) {
  //         return { ...a, mensagensNaoVisualizadas: 0 };
  //       }
  //       return a;
  //     });
  //     setProfessionals(date);
  //   }
  // };

  useEffect(() => {
    refetch();
  }, [notifications]);

  const setChatRoom = (selectedPro: any) => {
    if (chatList && selectedPro) {
      onChatRoom('');
      const profIndex = chatList.findIndex(p => p.id === selectedPro);
      if (
        chatList[profIndex].chat.id === '00000000-0000-0000-0000-000000000000'
      ) {
        setRoomId(chatList[profIndex].id);
        return;
      }
      onChatRoom(chatList[profIndex].chat.id);
    }
  };

  useEffect(() => {
    setChatRoom(selectedProfessional);
  }, [selectedProfessional]);

  useEffect(() => {
    if (data) {
      const dataChat = data?.chats.sort((a, b) => {
        // @ts-ignore
        return new Date(b.chat.dataEdicao) - new Date(a.chat.dataEdicao);
      });
      setChatList([]);
      setChatList(prevList => [...prevList, ...dataChat]);
    }
  }, [data]);

  useEffect(() => {
    refetch();
  }, [professional]);

  //= ======================infinity scrolling==================================

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      if (
        data?.pagina &&
        container!.scrollTop + container!.clientHeight >=
          container!.scrollHeight &&
        data?.temProximo &&
        !loadingMore
      ) {
        setLoadingMore(true);
        setPage(data.pagina + 1);
      }
    };

    container?.addEventListener('scroll', handleScroll);

    return () => {
      container?.removeEventListener('scroll', handleScroll);
    };
  }, [containerRef, data]);

  useEffect(() => {
    setPage(1);
    setChatList([]);
    setChatList(data?.chats ?? []);
  }, [search]);

  useEffect(() => {
    if (!isLoading && loadingMore) {
      setLoadingMore(false);
    }
  }, [isLoading]);

  //= ==========================================================================
  return (
    <Box
      ref={containerRef}
      overflowY="auto"
      w={
        (isLoading && !loadingMore) || (chatList && chatList?.length > 0)
          ? '300px'
          : '0'
      }
      display="flex"
      flexDir="column"
      gap="2"
      maxH={{ base: '100px', xl: '420px', '2xl': '600px' }}
    >
      {isLoading && !loadingMore ? (
        <Stack>
          {[1, 2, 3, 4, 5, 6].map(item => (
            <Skeleton key={item} h="14" />
          ))}
        </Stack>
      ) : (
        <>
          {chatList &&
            chatList.map(person => {
              if (person.id !== user!.id) {
                return (
                  <Badge
                    count={
                      selectedProfessional === person.id
                        ? 0
                        : person.chat.mensagemNaoVisualizada
                    }
                    offset={[-15, 15]}
                    showZero={false}
                    key={person.id}
                  >
                    <Box
                      key={person.id}
                      display="flex"
                      justifyContent="space-between"
                      gap="4"
                      p="3"
                      bg={
                        person.id === selectedProfessional
                          ? 'green.500'
                          : 'green.50'
                      }
                      color={
                        person.id === selectedProfessional
                          ? 'gray.100'
                          : 'black.100'
                      }
                      borderRadius="xl"
                      cursor="pointer"
                      _hover={{
                        background: 'gray.100',
                        color: 'teal.500',
                      }}
                      onClick={() => {
                        setSelectedProfessional(person.id);
                        setChatRoom(person.id);
                        setProSelect({ name: person.nome, type: person.type });
                        // updateView(person.id);
                      }}
                    >
                      <Box display="flex" flexDirection="row">
                        <Avatar name={person.nome} w="8" h="8" src="" />
                        <Box display="flex" flexDirection="column">
                          <Text
                            isTruncated
                            maxW="200px"
                            px="2"
                            fontSize="xl"
                            fontWeight="medium"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {person.nome}
                          </Text>
                          <Text
                            isTruncated
                            maxW="200px"
                            px="2"
                            py="1"
                            fontSize="sm"
                            overflow="hidden"
                            whiteSpace="nowrap"
                            textOverflow="ellipsis"
                          >
                            {person.type}
                          </Text>
                        </Box>
                      </Box>
                    </Box>
                  </Badge>
                );
              }
              return null;
            })}
          {loadingMore && (
            <Center py="4">
              <Spinner />
            </Center>
          )}
        </>
      )}
    </Box>
  );
}
