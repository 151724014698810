import { Tr, Td } from '@chakra-ui/react';
import {
  TablesReport,
  useHealthProfessionalsTableQuery,
} from '@/features/reports';

export function HealthProfessionalsTable() {
  const { data } = useHealthProfessionalsTableQuery();

  return (
    <TablesReport head={data ? data.tableHead : []}>
      {data?.tableBody.map(b => (
        <Tr>
          <Td>{b.nomeProfissional}</Td>
          <Td>{b.pacienteAssociado}</Td>
          <Td>{b.pacienteDesassociado}</Td>
          <Td>{b.consultaAgendada}</Td>
          <Td>{b.consultaAtendida}</Td>
          <Td>{b.consultaRemarcada}</Td>
        </Tr>
      ))}
    </TablesReport>
  );
}
