import { format, parseISO } from 'date-fns';
import { useQuery } from 'react-query';

import { useScheduleDateStore } from '@/features/schedule/stores';
import { useUserStore, useProfessionalStore, useUnidadeStore } from '@/stores';
import { api } from '@/lib';
import { useViewContext } from './useViewContext';

type Person = {
  nome: string;
  avatar: string;
  idade: number;
  historico: string;
  fatorRisco: string;
  acao: string;
  atender: string;
  medicamentos: string;
  consulta: string;
  cpf: string;
};

type User = {
  pessoa: Person;
  urlFoto: string;
  idade: string;
};

type Patient = {
  ansiedadeFlag: number;
  atividade: number;
  depressaoFlag: number;
  id: string;
  idMedico: string | null;
  linkTermos: string;
  linkTermosPoliticaPrivacidade: string;
  prontuario: string;
  sono: number;
  termoPesquisaId: string;
  usuario: User;
  usuarioId: string;
};

type Cid = {
  descricao: string;
  status: boolean;
};

type Anamnese = {
  alergica: string;
  antecedentesFamiliares: string;
  antecedentesPessoais: string;
  cid: Cid[];
  conduta: string;
  consultaId: string;
  dataAgendamento: Date;
  dataAtendimento: Date;
  duracao: string;
  evolucao: string;
  id: string;
  modalUrlMevo: string;
  orientacao: string;
  pacienteId: string;
  usuarioId: string;
};

type Appointment = {
  resultado: any;
  id: string;
  ativo: boolean;
  horario: string;
  intervalo: string;
  idAgenda: string;
  idMedico: string;
  idAlertaWeb: string;
  paciente: Patient;
  idPaciente: string;
  idConsulta: string;
  tipoConsulta: number;
  idUnidade: string;
  nameUnidade: string;
  status: number;
  agenda: {
    status: number;
    observacao: string;
    horarioFim: string;
  };
  consulta: {
    status: number;
    tipoConsulta: number;
    idUnidadeSaude: string;
  };
};

type AppointmentAPIResponse = {
  data: {
    horasAgendas: Appointment[];
    id: string;
    idUsuario: string;
    data: string;
    idSecretaria: string | null;
    idUnidade: string;
    horarioInicio: string;
    horarioFim: string;
    observacao: string;
    intervalo: string;
    status: number;
    title: string;
    anaminesias: Anamnese[];
  }[];
};

async function getScheduleWeek(id: string, date: string, idUnidade?: string) {
  const { data } = await api.get<AppointmentAPIResponse>(
    `agenda?data=${date}&idUsuario=${id}&intervalo=6&idUnidade=${idUnidade}`,
  );

  const schedule = data.data;

  const unidades = await api.get('/instancia/unidades');

  if (!schedule) {
    return [];
  }

  const appointments = schedule
    .filter((d: any) => {
      return d.horasAgendas.length !== 0 ? d : null;
    })
    .map(scheduleItem => {
      const anamneses = scheduleItem.anaminesias[0];
      const appointment = scheduleItem.horasAgendas[0];

      const idUser = scheduleItem.idUsuario;

      const [scheduleHour, scheduleMinute] = appointment.horario.split(':');
      const [hourEnd, minuteEnd] = scheduleItem.horarioFim.split(':');
      const dataAtual = new Date(scheduleItem.data);
      const startHour = parseInt(scheduleHour, 10);
      const startMinute = parseInt(scheduleMinute, 10);
      const endHour = parseInt(hourEnd, 10);
      const endMinute = parseInt(minuteEnd, 10);
      const status = appointment.agenda.status !== 4 || false;
      const obsBloqueio = appointment.agenda.observacao;
      const user = appointment.paciente?.usuario || null;
      const paciente = appointment.paciente?.usuario.pessoa || null;
      const relatorio = anamneses;

      const name = unidades?.data.data.filter(
        (item: any) => item.id === appointment?.consulta?.idUnidadeSaude,
      );

      return {
        idScheduleTime: appointment?.id,
        idAgenda: appointment?.idAgenda,
        ativo: status,
        status: appointment?.agenda.status,
        start: new Date(dataAtual.setHours(startHour, startMinute)),
        end: new Date(dataAtual.setHours(endHour, endMinute)),
        title: paciente?.nome || '',
        cpf: paciente?.cpf || '',
        idHorasAgenda: appointment?.id,
        idDoctor: appointment?.idMedico,
        idAlertaWeb: appointment.idAlertaWeb,
        idUsuario: scheduleItem?.idUsuario,
        idPatient: appointment?.idPaciente,
        idConsultation: appointment?.idConsulta,
        idUnidade: appointment?.consulta?.idUnidadeSaude || '',
        nameUnidade: name.length ? name[0].descricao : '',
        urlPhoto:
          user?.urlFoto ||
          'https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_960_720.png',
        idade: user?.idade || 0,
        obsBloqueio: obsBloqueio || '',
        anamneses: relatorio,
        resource: paciente,
        consultation: appointment.consulta?.tipoConsulta,
        statusConsulta: appointment.consulta?.status,
        resultado: appointment?.resultado,
      };
    });
  return appointments;
}

async function getSchedule(id: string, date: string, idUnidade?: string) {
  const { data } = await api.get<AppointmentAPIResponse>(
    `agenda?data=${date}&idUsuario=${id}&idUnidade=${idUnidade}`,
  );

  const unidades = await api.get('/instancia/unidades');

  const schedule = data.data;

  if (!schedule) {
    return [];
  }

  const appointments = schedule
    .filter((d: any) => {
      return d.horasAgendas.length !== 0 ? d : null;
    })
    .map(scheduleItem => {
      const anamneses = scheduleItem.anaminesias[0];
      const appointment = scheduleItem.horasAgendas[0];

      const idUser = scheduleItem.idUsuario;

      const [scheduleHour, scheduleMinute] = appointment.horario.split(':');
      const [hourEnd, minuteEnd] = scheduleItem.horarioFim.split(':');
      const startHour = parseInt(scheduleHour, 10);
      const startMinute = parseInt(scheduleMinute, 10);
      const endHour = parseInt(hourEnd, 10);
      const endMinute = parseInt(minuteEnd, 10);
      const status = appointment.agenda.status !== 4 || false;
      const obsBloqueio = appointment.agenda.observacao;
      const user = appointment.paciente?.usuario || null;
      const paciente = appointment.paciente?.usuario.pessoa || null;
      const relatorio = anamneses;

      const name = unidades?.data.data.filter(
        (item: any) => item.id === appointment?.consulta?.idUnidadeSaude,
      );

      return {
        idScheduleTime: appointment?.id,
        idAgenda: appointment?.idAgenda,
        ativo: status,
        status: appointment?.agenda.status,
        start: new Date(new Date().setHours(startHour, startMinute)),
        end: new Date(new Date().setHours(endHour, endMinute)),
        title: paciente?.nome || '',
        cpf: paciente?.cpf || '',
        idHorasAgenda: appointment?.id,
        idDoctor: appointment?.idMedico,
        idAlertaWeb: appointment.idAlertaWeb,
        idUsuario: scheduleItem?.idUsuario,
        idPatient: appointment?.idPaciente,
        idConsultation: appointment?.idConsulta,
        idUnidade: appointment?.consulta?.idUnidadeSaude || '',
        nameUnidade: name.length ? name[0].descricao : '',
        urlPhoto:
          user?.urlFoto ||
          'https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_960_720.png',
        idade: user?.idade || 0,
        obsBloqueio: obsBloqueio || '',
        anamneses: relatorio,
        resource: paciente,
        consultation: appointment.consulta?.tipoConsulta,
        statusConsulta: appointment.consulta?.status,
        resultado: appointment?.resultado,
      };
    });

  return appointments;
}

export function useScheduleIntervalQuery() {
  const unidadeSet = useUnidadeStore(state => state.unidade);
  const user = useUserStore(state => state.user);
  const professional = useProfessionalStore(state => state.professional);
  const { view, setView } = useViewContext();

  const referenceDate = useScheduleDateStore(state => state.referenceDate);
  const refresh = useScheduleDateStore(state => state.refresh);
  const intlFormattedDate = format(parseISO(referenceDate), 'MM/dd/yyyy');
  const sendId = user?.type !== 'medico' ? professional?.idMedico : user.id;

  if (view === 'week') {
    const queryMounth = useQuery(
      ['schedule', sendId, intlFormattedDate, unidadeSet?.id],
      () =>
        getScheduleWeek(sendId as string, intlFormattedDate, unidadeSet?.id),
      {
        refetchOnWindowFocus: false,
        cacheTime: 0,
      },
    );
    return queryMounth;
  }

  const query = useQuery(
    ['schedule', sendId, intlFormattedDate, unidadeSet?.id],
    () => getSchedule(sendId as string, intlFormattedDate, unidadeSet?.id),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  return query;
}

export function useScheduleWeekQuery() {
  const unidadeSet = useUnidadeStore(state => state.unidade);
  const user = useUserStore(state => state.user);
  const professional = useProfessionalStore(state => state.professional);

  const referenceDate = useScheduleDateStore(state => state.referenceDate);
  const refresh = useScheduleDateStore(state => state.refresh);
  const intlFormattedDate = format(parseISO(referenceDate), 'MM/dd/yyyy');
  const sendId = user?.type !== 'medico' ? professional?.idMedico : user.id;

  const queryMounth = useQuery(
    ['schedule', sendId, intlFormattedDate, unidadeSet?.id],
    () => getScheduleWeek(sendId as string, intlFormattedDate, unidadeSet?.id),
    {
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  );

  return queryMounth;
}
