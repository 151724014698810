import {
  Tr,
  Td,
  Icon,
  Flex,
  Heading,
  Stack,
  Text,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import { FiEye, FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { useGendersQuery } from '@/hooks';
import {
  Tables,
  GenericModal,
  EditManagerForm,
  ViewManagerInfo,
} from '@/features/registers';
import { format, parseISO } from 'date-fns';
import { api } from '@/lib';
import { useInstanciaStore, useUnidadeStore, useUserStore } from '@/stores';
import { PaginationButton } from '../components/Pagination/PaginationButton';
import { DeleteUserModal } from '../components/DeleteModals/DeleteUserModal';
import { TopSectionGestor } from '../components/TopSection/TopSectionGestor';

interface bInterface {
  nomeCompleto: string;
  dataNascimento: string;
  celular: string;
  email: string;
  isMaster: boolean;
  type: number;
  codigoUsuario?: string;
  primeiroAcesso?: boolean;
}

export function ManagersListPage() {
  const [manager, setManager] = useState<any>();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [selectPage, setSelectPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const user = useUserStore(state => state.user);
  // const { data } = useManagerListQuery();
  const [text, setText] = useState('');
  // 0 - de instância
  // 1 - de unidade
  const [typeGestor, setTypeGestor] = useState('unidade');
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState<any>([]);
  const [head, setHead] = useState<string[]>([]);
  const unidadeId = useUnidadeStore(state => state.unidade?.id);
  const instanciaId = useInstanciaStore(state => state.instancia?.id);

  const { data: genders = [] } = useGendersQuery();

  const onCloseModal = () => setIsEditOpen(false);
  const onCloseDeleteModal = () => setIsDeleteOpen(false);
  const onCloseViewModal = () => setIsViewOpen(false);

  const onOpenEditModal = (m: any) => {
    setManager(m);
    setIsEditOpen(true);
  };

  const onDeleteEditModal = (m: any) => {
    setManager(m);
    setIsDeleteOpen(true);
  };

  const onOpenViewModal = (m: any) => {
    const gender = genders?.filter(item => item.value === m.sexoId);
    setManager({ ...m, gender });
    setIsViewOpen(true);
  };

  const setSelectType = (event: any) => {
    setTypeGestor(event.target.value);
  };

  const setDataContent = async (back = false, next = false) => {
    setData([]);
    setSearch([]);
    const params = {
      id: unidadeId,
      pageSize: 5,
      pageNumber:
        !back && !next
          ? 1
          : back && selectPage > 1
          ? selectPage - 1
          : next
          ? selectPage + 1
          : selectPage,
    };

    const { data: total } = await api.get(
      `/instancia/instancia-listar-gestor?id=${unidadeId}`,
    );
    setTotalPage(Math.ceil(total.data.length / 5)); // mock solicitar total page no endpoint

    let response;
    let resp = [];
    let respG;
    response = await api.get(`/instancia/unidade-listar-gestor`, { params });
    if (response.data.data.length > 0) {
      resp = response.data.data.map((respo: any) => {
        const dateInitial = format(
          parseISO(respo.usuario.pessoa.dataNascimento),
          'dd/MM/yyyy',
        );

        const date = `${dateInitial.slice(0, 2)}${dateInitial.slice(
          2,
          4,
        )}${dateInitial.slice(4, 12)}`;
        return {
          id: respo?.usuario?.id,
          gestorMasterId: respo?.gestorMasterId,
          nomeCompleto: respo?.usuario?.pessoa?.nome,
          email: respo?.usuario?.email,
          dataCriacao: respo?.usuario?.dataCriacao,
          dataEdicao: respo?.usuario?.dataEdicao,
          userName: respo?.usuario?.userName,
          cpf: respo?.usuario?.pessoa?.cpf,
          celular: respo?.usuario?.pessoa?.celular,
          sexoId: respo?.usuario?.pessoa?.sexoId,
          dataNascimento: date,
          cep: respo?.usuario?.pessoa?.endereco?.cep,
          numero: respo?.usuario?.pessoa?.endereco?.numero,
          endereco: respo?.usuario?.pessoa?.endereco?.logradouro,
          bairro: respo?.usuario?.pessoa?.endereco?.bairro,
          cidade: respo?.usuario?.pessoa?.endereco?.cidade,
          complemento: respo?.usuario?.pessoa?.endereco?.complemento,
          estado: respo?.usuario?.pessoa?.endereco?.estado,
          primeiroAcesso: respo?.usuario?.primeiroAcesso,
          codigoUsuario: respo?.usuario?.codigoUsuario,
          instancias: respo?.instancias,
          unidades: respo?.unidades,
          type: 1,
        };
      });
    }

    response = await api.get(
      `/usuario/Obter-GestorMaster-Instancia?instanciaId=${instanciaId}`,
    );
    if (response.data.data) {
      const dateInitial = format(
        parseISO(response.data.data.dataNascimento),
        'dd/MM/yyyy',
      );

      const date = `${dateInitial.slice(0, 2)}${dateInitial.slice(
        2,
        4,
      )}${dateInitial.slice(4, 12)}`;

      respG = {
        id: response.data.data.id,
        nomeCompleto: response.data.data.nome,
        email: response.data.data.email,
        userName: response.data.data.username,
        cpf: response.data.data.cpf,
        celular: response.data.data.telefone,
        sexoId: response.data.data.generoId,
        dataNascimento: date,
        cep: response.data.data.cep,
        numero: response.data.data.numero,
        endereco: response.data.data.endereco,
        bairro: response.data.data.bairro,
        cidade: response.data.data.cidade,
        complemento: response.data.data.complemento,
        estado: response.data.data.estado,
        instancias: response.data.data.instancias,
        unidades: response.data.data.unidades,
        type: 2,
      };
    }

    if (respG) {
      setData([respG, ...resp]);
      setSearch([respG, ...resp]);
    } else {
      setData(resp);
      setSearch(resp);
    }

    if (back) {
      setSelectPage(selectPage === 1 ? 1 : selectPage - 1);
    }
    if (next) {
      setSelectPage(selectPage + 1);
    }

    // if (!back && !next) {
    //   setData([]);
    //   setSearch([]);
    // }
  };

  useEffect(() => {
    setDataContent(false, false);
  }, [isEditOpen, isDeleteOpen, unidadeId, typeGestor]);

  useEffect(() => {
    if (data) {
      const lowerSearch = text.toLowerCase();

      const result = data.filter((d: any) => {
        return (
          d.nomeCompleto.toLowerCase().includes(lowerSearch) ||
          d.email.toLowerCase().includes(lowerSearch)
        );
      });

      setSearch(result);
    }
  }, [text]);

  useEffect(() => {
    if (user?.type === 'admin' || user?.type === 'master') {
      head.push('EDITAR');
    }
    if (user?.type === 'admin' || user?.type === 'master') {
      head.push('DELETAR');
    }
  }, []);

  const showEditBtn = (b: any) => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'paciente' &&
      user?.type !== 'medico' &&
      user?.type !== 'assistente'
    ) {
      return (
        <Icon
          as={FiEdit}
          onClick={() => {
            if (b.primeiroAcesso) return;
            onOpenEditModal(b);
          }}
          cursor="pointer"
          color={b.primeiroAcesso ? 'brand-dark.100' : 'brand-dark.200'}
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  const showDeleteBtn = (b: any) => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'paciente' &&
      user?.type !== 'medico' &&
      user?.type !== 'assistente'
    ) {
      return (
        <Icon
          as={MdDeleteOutline}
          onClick={() => {
            if (b.primeiroAcesso) return;
            onDeleteEditModal(b);
          }}
          cursor="pointer"
          color={b.primeiroAcesso ? 'brand-dark.100' : 'brand-dark.200'}
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  return (
    <>
      <TopSectionGestor
        title="Gestores Cadastrados"
        search={text}
        onChange={(str: any) => setText(str)}
        typeGestor={typeGestor}
        setTypeGestor={(str: any) => setTypeGestor(str)}
      />
      {/* <Select
        size="lg"
        variant="filled"
        placeholder="Tipo de gestor"
        maxW={['100%', '300px']}
        w="100%"
        h="52px"
        borderRadius="14px"
        bg="white"
        color="brand-dark.500"
        _placeholder={{ color: '#bfbfbf' }}
        _hover={{ bg: 'brand-dark.100' }}
        onChange={setSelectType}
      >
        <option value="unidade">Gestores da unidade</option>
        <option value="instancia">Gestores da instância</option>
      </Select> */}

      <Tables
        head={[
          'NOME DO GESTOR',
          'TIPO',
          'CELULAR',
          'E-MAIL',
          'STATUS',
          'VISUALIZAR',
          ...head,
        ]}
      >
        {search.map((b: bInterface) => {
          return (
            <Tr key={b.nomeCompleto}>
              <Td>{b.nomeCompleto}</Td>
              {b.type === 2 ? (
                <Td>
                  <Tag
                    key={b.nomeCompleto}
                    size="sm"
                    variant="solid"
                    colorScheme="blue"
                    borderRadius="full"
                    position="relative"
                    right="0"
                  >
                    <TagLabel>Gestor da Instância</TagLabel>
                  </Tag>
                </Td>
              ) : (
                <Td>Gestor de Unidade</Td>
              )}
              {/* <Td>{new Date(b.dataNascimento).toLocaleDateString('pt-br')}</Td> */}
              <Td>{b.celular}</Td>
              <Td>{b.email}</Td>
              <Td style={{ color: b.primeiroAcesso ? 'red' : '' }}>
                {b.primeiroAcesso ? 'PENDENTE' : 'VALIDADO'}
              </Td>
              <Td>
                <Icon
                  as={FiEye}
                  onClick={() => onOpenViewModal(b)}
                  cursor="pointer"
                  color="brand-dark.200"
                  fontSize="20px"
                  fontWeight="400"
                />
              </Td>
              <Td>{showEditBtn(b)}</Td>
              <Td>{showDeleteBtn(b)}</Td>
            </Tr>
          );
        })}
      </Tables>
      {/* <Pagination
        page={data?.page || 0}
        totalPage={data?.totalPages || 0}
        totalRegisters={data ? data?.tableBody?.length : 0}
      /> */}

      <Flex
        direction={['column', 'column', 'row']}
        w="100%"
        align="center"
        justify="space-between"
      >
        <Heading
          size="xs"
          w="100%"
          mb={['4', '4', '0']}
          color="#707070"
          fontWeight="medium"
        >
          {/* Página {selectPage} de {dataPatients?.data.length} registros */}
          Página {selectPage}/{totalPage}
        </Heading>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          w="100%"
          justify="flex-end"
        >
          <PaginationButton
            text="Voltar"
            onClick={() => setDataContent(true, false)}
          />
          {/* <PaginationButton text="Ultimo" onClick={() => setSelectPage(1)} /> */}
          {/* <NumbersPage
            onClick={value => setSelectPage(value)}
            page={selectPage}
            totalPage={dataPatients?.totalPages || 0}
          /> */}
          <PaginationButton
            text="Proximo"
            onClick={() => setDataContent(false, true)}
          />
          {/* <PaginationButton
            text="Ultimo"
            onClick={() => setSelectPage(dataPatients?.totalPages || 0)}
          /> */}
        </Stack>
      </Flex>

      <GenericModal
        open={isEditOpen}
        textAlign="center"
        title="Editar Gestor"
        onClose={onCloseModal}
        closeOnOverlayClick={false}
      >
        <EditManagerForm manager={manager} onCancel={onCloseModal} />
      </GenericModal>
      <GenericModal
        open={isViewOpen}
        title={`Gestor: ${manager && manager.nomeCompleto}`}
        onClose={onCloseViewModal}
      >
        <ViewManagerInfo manager={manager} />
      </GenericModal>
      <GenericModal
        open={isDeleteOpen}
        textAlign="center"
        title={`Deletar Gestor: ${manager && manager.nomeCompleto}`}
        onClose={onCloseDeleteModal}
      >
        <DeleteUserModal
          user={manager}
          type="gestor"
          onCancel={onCloseDeleteModal}
        />
      </GenericModal>
    </>
  );
}
