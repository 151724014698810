import {
  Box,
  FormControl,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import { HTMLAttributes } from 'react';
import {
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import { IconType } from 'react-icons';
import { RiErrorWarningFill } from 'react-icons/ri';
import NumberFormat, { NumberFormatValues } from 'react-number-format';

import { MASKED_INPUT_MASKS } from '@/features/auth/utils';

type MaskedInputProps<T extends FieldValues> = {
  mask: keyof typeof MASKED_INPUT_MASKS;
  icon?: IconType;
  error?: FieldError;
} & UseControllerProps<T> &
  HTMLAttributes<HTMLInputElement>;

export function MaskedInput<T extends FieldValues>({
  name,
  control,
  mask,
  icon,
  error,
  ...rest
}: MaskedInputProps<T>) {
  return (
    <FormControl isInvalid={!!error}>
      <InputGroup>
        {icon && (
          <InputLeftElement pl="4" pointerEvents="none">
            <Icon as={icon} fontSize="20px" color="gray.600" />
          </InputLeftElement>
        )}

        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) => (
            <Input
              {...rest}
              as={NumberFormat}
              value={value}
              format={MASKED_INPUT_MASKS[mask]}
              onValueChange={(v: NumberFormatValues) => {
                onChange(v.value);
              }}
              pl={icon ? '12' : '4'}
              pr="8"
              h="42px"
              borderColor="gray.500"
              background="white"
              color="gray.700"
              _placeholder={{ color: 'gray.500' }}
              _hover={{
                borderColor: 'brand-orange.400',
              }}
            />
          )}
        />

        <InputRightElement pr="4">
          {error && (
            <Tooltip
              label={error.message}
              aria-label="Warning tooltip"
              bg="gray.800"
              color="white"
            >
              <Box as="span" pt="1">
                <Icon as={RiErrorWarningFill} fontSize="20px" color="red" />
              </Box>
            </Tooltip>
          )}
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
}
