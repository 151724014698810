import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Modal,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useScheduleDateStore } from '@/features/schedule';
import confirmationImg from '@/assets/register_confirmed.svg';
import { api } from '@/lib';
import { format, parseISO } from 'date-fns';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';
import { useScheduleQuery } from '@/features/schedule/hooks/useScheduleQuery';
import { useSchedulePatientQuery } from '@/features/schedule/hooks/useSchedulePatientQuery';

export function CancelScale({ onCancel, event, deletar }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });
  const { refetch } = useScheduleIntervalQuery();
  const { refetch: refresh } = useScheduleQuery();
  const { refetch: refreshPac } = useSchedulePatientQuery();

  const [wasFormSubmittedWithSuccess, setWasFormSubmittedWithSuccess] =
    useState(false);
  const { handleSubmit } = useForm();

  const referenceDate = useScheduleDateStore(state => state.referenceDate);
  const data = format(parseISO(referenceDate), 'dd/MM/yyyy');
  const hora = format(event.start, 'HH:mm');

  async function onSubmit() {
    setIsLoading(true);
    if (deletar) {
      try {
        await api.delete(`agenda?idAgenda=${event.idAgenda}`);
        refetch();
        refresh();
        refreshPac();
        setWasFormSubmittedWithSuccess(true);
      } catch (error: any) {
        toast({
          title: error.response.data.errors[0] || 'Ocorreu um erro!',
          status: 'error',
        });
        setWasFormSubmittedWithSuccess(false);
      } finally {
        setIsLoading(false);
      }
    } else {
      try {
        await api.put(
          `/consulta/Desvincular-Paciente?id=${event.idConsultation}`,
        );
        refetch();
        refresh();
        refreshPac();
        setWasFormSubmittedWithSuccess(true);
      } catch (error: any) {
        toast({
          title: error.response.data.errors[0] || 'Ocorreu um erro!',
          status: 'error',
        });
        setWasFormSubmittedWithSuccess(false);
      } finally {
        setIsLoading(false);
      }
    }
  }

  return wasFormSubmittedWithSuccess ? (
    <Flex w="100%" direction="column" align="center">
      <Heading
        mt="6"
        color="brand-dark.300"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
      >
        O horário foi cancelado!
      </Heading>

      <Image
        mt="8"
        mb="4"
        src={confirmationImg}
        alt="Mulher confirmando o sucesso de uma operação em um celular grande"
      />

      <Text
        color="brand-dark.200"
        fontSize="sm"
        fontWeight="normal"
        textAlign="center"
      >
        Você pode editar as configurações na aba de escalas.
      </Text>

      <Button
        type="button"
        onClick={onCancel}
        colorScheme="brand-green"
        mt="10"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Ir para escalas
      </Button>
    </Flex>
  ) : (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      textAlign="center"
    >
      <Heading mt="6" mb="4" color="red" fontSize="xl" fontWeight="medium">
        {event.title ? 'Cancelar a consulta' : 'Cancelar horário'}
      </Heading>
      <Box>
        <Text color="brand-dark.300" fontSize="xl" fontWeight="medium">
          {event.title
            ? 'Deseja realmente cancelar a consulta?'
            : 'Deseja realmente cancelar este horário?'}
        </Text>
      </Box>

      <VStack spacing="4" w="100%">
        <SimpleGrid
          minChildWidth="150px"
          spacing="4"
          w="100%"
          paddingTop="30px"
        >
          <SimpleGrid>
            <Text mb="1" color="brand-dark.200" fontSize="sm">
              <Text as="strong">Paciente: </Text>
              <br />
              {event.title ? event.title : 'SEM PACIENTE'}
            </Text>
          </SimpleGrid>
          <SimpleGrid>
            <Text mb="1" color="brand-dark.200" fontSize="sm">
              <Text as="strong">Tipo da consulta: </Text>
              <br />
              {event.idConsultation
                ? event.consultation
                  ? 'ON-LINE'
                  : 'PRESENCIAL'
                : 'A DEFINIR'}
            </Text>
          </SimpleGrid>

          <SimpleGrid>
            <Text mb="1" color="brand-dark.200" fontSize="sm">
              <Text as="strong">Data da consulta: </Text>
              <br />
              {data}
            </Text>
          </SimpleGrid>
          <SimpleGrid>
            <Text mb="1" color="brand-dark.200" fontSize="sm">
              <Text as="strong">Horário da consulta: </Text>
              <br />
              {hora} horas
            </Text>
          </SimpleGrid>
        </SimpleGrid>

        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          <Button
            disabled={isLoading}
            type="button"
            onClick={onCancel}
            variant="outline"
            colorScheme="red"
            h="45px"
            borderRadius="28px"
            color="red"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Voltar
          </Button>

          <Button
            isLoading={isLoading}
            type="submit"
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Cancelar
          </Button>
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
