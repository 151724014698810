import { api } from '@/lib';
import { mapPrescription } from './mapPrescription';

export async function PostPrescription(
  data: any,
  patientId: string,
  userId: string,
) {
  try {
    const { data: date } = await api.get<any>(
      `/anamnese?idMedico=${userId}&idPaciente=${patientId}`,
    );

    let dadosMod = {};

    if (data) {
      dadosMod = mapPrescription(data, patientId, date.data.consultaId);
      // console.log('Dados da prescrição ||>>>>>>>>> ', dadosMod);
    }

    const resp = await api.post('/mevo/post-Mevo', dadosMod);

    return { data, status: 'success' };
  } catch (error) {
    return { error, status: 'error' };
  }
}
