import { formatCnpj } from '@/utils';
import {
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
  Text,
  Heading,
} from '@chakra-ui/react';

interface Props {
  unidade: any;
}

export function ViewUnidadeInfo({ unidade }: Props) {
  return (
    <VStack spacing="4">
      <SimpleGrid minChildWidth="150px" spacing="4" w="100%">
        <SimpleGrid>
          <Heading as="h6" size="sm" color="#707070">
            Descrição
          </Heading>
          <Text>{unidade && unidade.label}</Text>
        </SimpleGrid>
        <SimpleGrid>
          <Heading as="h6" size="sm" color="#707070">
            CNPJ
          </Heading>
          <Text>{unidade && formatCnpj(unidade.cnpj)}</Text>
        </SimpleGrid>
      </SimpleGrid>

      <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
          <Heading as="h6" size="sm" color="#707070">
            Endereço
          </Heading>
          <Text>{unidade.endereco && unidade.endereco.logradouro}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Heading as="h6" size="sm" color="#707070">
            Numero
          </Heading>
          <Text>{unidade.endereco && unidade.endereco.numero}</Text>
        </GridItem>
      </Grid>
      <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
          <Heading as="h6" size="sm" color="#707070">
            Bairro
          </Heading>
          <Text>{unidade.endereco && unidade.endereco.bairro}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Heading as="h6" size="sm" color="#707070">
            CEP
          </Heading>
          <Text>{unidade.endereco && unidade.endereco.cep}</Text>
        </GridItem>
      </Grid>
      <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
          <Heading as="h6" size="sm" color="#707070">
            Cidade
          </Heading>
          <Text>{unidade.endereco && unidade.endereco.cidade}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Heading as="h6" size="sm" color="#707070">
            Estado
          </Heading>
          <Text>{unidade.endereco && unidade.endereco.estado}</Text>
        </GridItem>
      </Grid>
    </VStack>
  );
}
