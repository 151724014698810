import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
  useToast,
  VStack,
  List,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Modal,
  Tag,
  TagLabel,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import registerConfirmationImg from '@/assets/register_confirmed.svg';
import { useEffect, useState } from 'react';
import { SelectInput } from '@/components';
import { useProfessionalStore, useUserStore } from '@/stores';
import { useInstanceQuery, useUnidadesIdQuery } from '@/hooks';

import { api } from '@/lib';
import { SearchIcon } from '@chakra-ui/icons';
import { SendMessageModal } from '@/features/historic/components/TableSection/Modal/SendMessageModal';

type CurrentPatient = {
  cpf: string;
  id: string;
  nome: string;
  especialidade?: string;
};

interface RegisterInstanceFormProps {
  onCancel: () => void;
}

export function NewMessagesPatientForm({
  onCancel,
}: RegisterInstanceFormProps) {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [inputFocus, setInputFocus] = useState(false);
  const [namePatient, setNamePatient] = useState('');
  const [patientsInput, setPatientsInput] = useState<
    CurrentPatient[] | undefined
  >([]);
  const [patientsInputBackup, setPatientsInputBackup] = useState<
    CurrentPatient[] | undefined
  >([]);
  const [patientSelected, setSelected] = useState<CurrentPatient | undefined>({
    cpf: '',
    id: '',
    nome: '',
  });
  const [hasValueDigitized, setHasValueDigitized] = useState(false);
  const { professional } = useProfessionalStore();

  const user = useUserStore(state => state.user);

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  function onCloseCancel() {
    setSelected({
      cpf: '',
      id: '',
      nome: '',
      especialidade: '',
    });
    setNamePatient('');
    setModalOpen(false);
  }

  const setDataContent = async () => {
    await api
      .get(`/usuario/listar-profissional-por-paciente?id=${user?.pacienteId}`)
      .then(response => {
        const res = response.data.data
          .filter((r: any) => r !== null)
          .map((p: any) => {
            return {
              nome: p?.usuarioProfissionalSaude?.pessoa?.nome,
              id: p?.usuarioProfissionalSaudeId,
              cpf: p?.usuarioProfissionalSaude?.pessoa?.cpf,
              especialidade:
                p.profissionalSaudeEspecialidade[0]?.especialidade?.descricao ||
                p.profissionalSaudeConselhos[0]?.conselho?.descricao,
            };
          });

        setPatientsInput(res);
        setPatientsInputBackup(res);
      })
      .catch(() => {
        setPatientsInput([]);
        setPatientsInputBackup([]);
      });
  };

  useEffect(() => {
    setDataContent();
  }, []);

  const changeNamePatient = (event: any) => {
    setNamePatient(event.target.value);
    setHasValueDigitized(true);

    if (!event.target.value) {
      setPatientsInput(patientsInputBackup);
      setHasValueDigitized(false);
      return;
    }

    const patientsFiltered = patientsInput?.filter(patient => {
      return patient.nome
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setPatientsInput(patientsFiltered);
  };

  const selectPatient = (patient: CurrentPatient) => () => {
    if (!patient.cpf) {
      toast({
        title:
          'Paciente não autorizado. Acesse o app e conclua cadastro para enviar mensagens.',
        status: 'error',
      });
      return;
    }

    const medicoId =
      user?.type === 'medico' ? user?.id : professional?.idMedico;
    setSelected(patient);
    setNamePatient(patient.nome);
    setInputFocus(false);
  };

  async function SendMessage() {
    if (!patientSelected?.id) {
      toast({
        title: 'Selecione um profissional.',
        status: 'error',
      });
      return;
    }
    setModalOpen(true);
  }

  return (
    <Box as="form">
      <VStack spacing="4">
        <Text
          textAlign="left"
          width="100%"
          fontWeight={500}
          color="brand-dark.500"
          fontSize={16}
        >
          Profissional de saúde
        </Text>
        <InputGroup mb="8px">
          <Input
            type="text"
            placeholder="Nome do Profissional"
            onChange={changeNamePatient}
            value={namePatient}
            onFocus={() => setInputFocus(true)}
            onBlur={() =>
              setTimeout(() => {
                setInputFocus(false);
              }, 500)
            }
          />
          <InputRightElement
            pointerEvents="none"
            children={<SearchIcon color="brand-dark.300" />}
          />
        </InputGroup>
        {patientsInput && patientsInput.length ? (
          inputFocus ? (
            <List
              mb="6"
              style={{
                maxHeight: '10rem',
                overflowY: 'scroll',
                width: '75%',
              }}
            >
              {patientsInput.map(patient => (
                <Flex
                  style={{ cursor: 'pointer' }}
                  justifyContent="space-between"
                  key={patient.id}
                  onClick={selectPatient(patient)}
                >
                  <Flex p="2" flexDirection="column">
                    <Text fontSize="sm" color="brand-dark.200">
                      {patient.nome.split('@', 1)[0]}
                    </Text>
                  </Flex>
                  <Flex p="2" flexDirection="column">
                    <Tag
                      key={patient.id}
                      size="sm"
                      variant="solid"
                      colorScheme="blue"
                      borderRadius="full"
                      position="relative"
                      right="0"
                    >
                      <TagLabel>{patient.especialidade}</TagLabel>
                    </Tag>
                  </Flex>
                </Flex>
              ))}
            </List>
          ) : (
            patientSelected?.nome && (
              <Flex justifyContent="space-between" width="100%">
                <Flex p="2" flexDirection="column">
                  <Text fontSize="md" color="brand-dark.300">
                    Nome
                  </Text>
                  <Text fontSize="sm" color="brand-dark.200">
                    {patientSelected?.nome}
                  </Text>
                </Flex>
                <Flex p="2" flexDirection="column">
                  <Text fontSize="md" color="brand-dark.300">
                    ESPECIALIDADE
                  </Text>
                  <Text fontSize="sm" color="brand-dark.200">
                    {patientSelected?.especialidade}
                  </Text>
                </Flex>
                <Flex p="2" flexDirection="column">
                  <Text fontSize="md" color="brand-dark.300">
                    CPF
                  </Text>
                  <Text fontSize="sm" color="brand-dark.200">
                    {patientSelected?.cpf}
                  </Text>
                </Flex>
              </Flex>
            )
          )
        ) : (
          hasValueDigitized && (
            <List mb="6" style={{ maxHeight: '10rem' }}>
              <Flex flexDirection="column">
                <Text
                  style={{ fontWeight: '500' }}
                  fontSize="sm"
                  color="brand-red.600"
                >
                  Nome inválido ou não cadastrado*
                </Text>
              </Flex>
            </List>
          )
        )}
        <SimpleGrid minChildWidth="120px" spacing="4" w="100%">
          <Button
            type="button"
            variant="outline"
            colorScheme="brand-orange"
            h="45px"
            borderRadius="28px"
            color="brand-orange.300"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
            onClick={() => onCancel()}
          >
            Cancelar
          </Button>
          <Button
            isLoading={false}
            // type="submit"
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
            onClick={SendMessage}
          >
            Continuar
          </Button>
        </SimpleGrid>
      </VStack>
      <Flex w="auto">
        <Modal isOpen={modalOpen} onClose={onCloseCancel} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody px="12" pb="10">
              <SendMessageModal
                onCancel={onCloseCancel}
                dados={{
                  idUsuarioRecebe: patientSelected?.id,
                  nome: patientSelected?.nome,
                  idUsuarioEnvia: user?.id,
                }}
                tipo={2}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
}
