import { useQuery } from 'react-query';

import { api } from '@/lib';
import { useProfessionalStore, useUserStore } from '@/stores';

async function getActivities(idusuario: string) {
  const { data } = await api.get(`/paciente/dashboard`, {
    params: {
      idusuario,
    },
  });

  return data.data;
}

interface useActivitiesPatientQueryProps {
  consultasFuturas: any[];
  numeroDeConsultas: number;
  cidAtual: any;
  medicacoesAtuais: any;
  monitoramentoAtual: any;
}

export function useActivitiesPatientQuery(idusuario: string) {
  const user = useUserStore(state => state.user);
  const pro = useProfessionalStore(state => state.professional);

  const query = useQuery<useActivitiesPatientQueryProps>(
    ['activities', idusuario],
    () => getActivities(idusuario as string),
    {
      refetchOnWindowFocus: false,
    },
  );

  return query;
}
