import { useQuery } from 'react-query';

import { api } from '@/lib';

async function getPrescription({ patientId, userId }: any) {
  const { data } = await api.get<any>(
    `/mevo/prescricao?MedicoId=${userId}&PacienteId=${patientId}`,
  );

  return data?.data;
}

export function usePrescriptionQuery(patientId: string, userId: string) {
  const query = useQuery(['Prescription', patientId, userId], () =>
    getPrescription({ patientId, userId }),
  );

  return query;
}
