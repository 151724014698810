import { api } from '@/lib';
import { useQuery } from 'react-query';
import { useUserStore } from '@/stores';

const useGetAssistantId = (assistenteId: string) => {
  const user = useUserStore(state => state.user);
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['assistant_thread', user!.id, assistenteId],
    queryFn: async () => {
      const { data: thread } = await api.post('/ai/assistant/thread', {
        assistenteId,
        usuarioId: user!.id,
      });
      // const { data: res } = await api.get(`ai/assistant/thread/`, {
      //   params: {
      //     usuarioId: user!.id,
      //   },
      // });
      return thread.data;
    },
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetAssistantId;
