import { differenceInDays } from 'date-fns';

export function getWeeksDiff(startDate: Date, endDate: Date): number {
  if (!startDate || !endDate) {
    return 0;
  }

  const options = { weekStartsOn: 1 };

  // Calculate the difference in days, considering week start
  const differenceInDaysWithOptions = differenceInDays(endDate, startDate);

  if (differenceInDaysWithOptions <= 6) {
    return 0;
  }

  const weeks = Math.floor(differenceInDaysWithOptions / 7);

  const endDayOfWeek = endDate.getDay(); // 0 (Sunday) - 6 (Saturday)

  return weeks + (endDayOfWeek < options.weekStartsOn ? 1 : 0);
}
