import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useQuery } from 'react-query';

type CitiesAPIResponse = {
  nome: string;
};

async function brazilCitiesRequest(
  state: string,
): Promise<CitiesAPIResponse[]> {
  const response = await axios.get(
    `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${state}/municipios?orderBy=nome`,
  );

  return response.data;
}

export function useBrazilCitiesQuery(state: string) {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const query = useQuery(
    ['brazilCities', state],
    () => brazilCitiesRequest(state),
    {
      enabled: state !== '',
      refetchInterval: false,
      select: originalResponse =>
        originalResponse.map(city => ({
          label: city.nome,
          value: city.nome,
        })),
      onError: () => {
        toast({
          title: 'Não foi possível trazer as cidades no momento',
          status: 'error',
        });
      },
    },
  );

  return query;
}
