import { useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { IntervalDateContext, ReportsPage } from '@/features/reports';

export function ReportsRoutes() {
  const today = new Date();
  const [dateInit, setDateInit] = useState(today.toISOString());
  const [dateFinal, setDateFinal] = useState(today.toISOString());

  const values = useMemo(
    () => ({ dateInit, setDateInit, dateFinal, setDateFinal }),
    [dateInit, setDateInit, dateFinal, setDateFinal],
  );

  return (
    <IntervalDateContext.Provider value={values}>
      <Routes>
        <Route path="" element={<ReportsPage />} />
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
    </IntervalDateContext.Provider>
  );
}
