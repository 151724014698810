import { useEffect, useState } from 'react';
import {
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
  Text,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Flex,
  Button,
  IconButton,
  Icon,
} from '@chakra-ui/react';
import { IoMailUnreadOutline } from 'react-icons/io5';

import { formatCPF, formatDate } from '@/utils';
import { SendCodeProfissionalModal } from './Modal/SendCodeProfissionalModal';

interface Props {
  professional: any;
}

const FormatData = ({
  label1,
  value1,
  label2,
  value2,
  cod,
  setOpenModal,
}: any) => {
  return (
    <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
      <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
        <Heading as="h6" size="sm" color="#707070">
          {label1}
        </Heading>
        <Text>{value1}</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Heading as="h6" size="sm" color="#707070">
          {label2}
        </Heading>
        <Text>
          {value2}
          {cod && (
            <IconButton
              onClick={() => setOpenModal(true)}
              aria-label="Aceitar notificação"
              icon={
                <Icon
                  as={IoMailUnreadOutline}
                  color="#039c00"
                  fontSize="27px"
                />
              }
              w="20px"
              h="20px"
              marginLeft="10px"
              borderRadius="full"
              bg="white"
              boxShadow="1px 1px 9px -3px rgba(5, 6, 27, 0.25)"
              justifySelf="right"
            />
          )}
        </Text>
      </GridItem>
    </Grid>
  );
};

export function ViewProfessionalInfo({ professional }: Props) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  function onCloseCancel() {
    setOpenModal(false);
  }

  return (
    <VStack spacing="4">
      {professional && professional.primeiroAcesso ? (
        <>
          <FormatData
            label1="Email"
            value1={professional && professional.email}
            label2="Celular"
            value2={professional && professional.celular}
          />
          <FormatData
            label1="Código Validação"
            value1={professional && professional.codigoUsuario}
            label2="Criado em"
            value2={
              professional && formatDate(new Date(professional.dataCriacao))
            }
          />
        </>
      ) : (
        <>
          {' '}
          <FormatData
            label1="Email"
            value1={professional && professional.email}
            label2="Código Vínculo"
            value2={professional && professional.codigoVinculo}
            cod={professional && professional.codigoVinculo}
            setOpenModal={setOpenModal}
          />
          <FormatData
            label1="CPF"
            value1={professional && formatCPF(professional.cpf)}
            label2="Celular"
            value2={professional && professional.celular}
          />
          <FormatData
            label1="Data de Nascimento"
            value1={
              professional && formatDate(new Date(professional.dataNascimento))
            }
            label2="Sexo"
            value2={professional && professional.gender[0]?.label}
          />
          <FormatData
            label1="Endereço"
            value1={professional && professional.endereco}
            label2="Usuário"
            value2={professional && professional.userName}
          />
          <div style={{ marginTop: 15 }} />
          {professional?.dataCriacao && (
            <FormatData
              label1="Criado em:"
              value1={
                professional && formatDate(new Date(professional.dataCriacao))
              }
              label2="Atualizado em:"
              value2={
                professional && formatDate(new Date(professional.dataEdicao))
              }
            />
          )}
        </>
      )}

      <Flex w="auto">
        <Modal isOpen={openModal} onClose={onCloseCancel} size="lg">
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody px="12" pb="8">
              <SendCodeProfissionalModal
                onCancel={onCloseCancel}
                professional={professional}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </VStack>
  );
}
