import { DMIcon } from '@/assets/icons';
import { useUserStore } from '@/stores';
import { ChatIcon } from '@chakra-ui/icons';
import { Text, Box, Input, Button, Image, Avatar } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import useWebSocket from 'react-use-websocket';
// eslint-disable-next-line import/no-duplicates
import { format } from 'date-fns';
// eslint-disable-next-line import/no-duplicates
import { ptBR } from 'date-fns/locale';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

interface messageProps {
  ChatRoomId: string;
  DataCriacao: string;
  Mensagem: string;
  UsuarioId: string;
  usuarioAtual: boolean;
}

export const msgSchema = yup.object().shape({
  message: yup.string().min(1),
});

interface ChatRoomProps {
  roomId2?: string;
  room?: string;
  proSelect?: any;
}
export function ChatRoom({ room, roomId2, proSelect }: ChatRoomProps) {
  const listRef = useRef<HTMLDivElement>(null);
  const user = useUserStore(state => state.user);

  const [messageHistory, setMessageHistory] = useState<messageProps[]>([]);
  const [lastSendMessage, setLastSendMessage] = useState('');

  const { handleSubmit, register, reset, setValue } = useForm<{
    message: string;
  }>({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(msgSchema),
  });

  const params = room
    ? `usuarioId=${user!.id}&chatId=${room}`
    : `usuarioId=${user!.id}&usuario2Id=${roomId2}`;

  const { sendMessage, lastJsonMessage, readyState } = useWebSocket(
    `wss://api-hlg.dmhealth.com.br/api/websocket/chat?${params}`,
    {
      retryOnError: true,
      reconnectInterval: 300,
      onError: () => {
        setValue('message', lastSendMessage);
      },
      shouldReconnect: () => true,
    },
  );

  useEffect(() => {
    const newMessages = Array.isArray(lastJsonMessage)
      ? (lastJsonMessage as messageProps[])
      : [lastJsonMessage as messageProps];

    if (newMessages[0] !== null) {
      setMessageHistory(prevMessages => [...newMessages, ...prevMessages]);
      if (newMessages.length === 1) {
        const novoItemElement = document.getElementById(
          newMessages[0].DataCriacao,
        );
        novoItemElement?.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    setMessageHistory([]);
  }, [readyState]);

  const handleSendMessage = ({ message }: { message: string }) => {
    if (message) {
      setLastSendMessage(message);
      sendMessage(message);
      reset();
    }
  };

  if (room || roomId2) {
    return (
      <Box
        display="flex"
        flexDir="column"
        gap="4"
        bg="gray.100"
        px="4"
        py="4"
        h="65vh"
      >
        <Box display="flex" flexDir="row" mt="4" gap="2">
          <Avatar
            name={proSelect.name}
            w="12"
            h="12"
            src=""
            style={{
              outline: `4px solid ${
                readyState === 1 ? 'green' : readyState === 0 ? 'yellow' : 'red'
              }`,
            }}
          />

          <Box display="flex" flexDir="column">
            <Text
              fontSize="xl"
              fontWeight="medium"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {proSelect.name}
            </Text>
            <Text
              fontSize="sm"
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
            >
              {proSelect.type}
            </Text>
          </Box>
        </Box>
        {readyState === 0 && (
          <Box
            w="100%"
            display="flex"
            background="yellow.300"
            borderRadius="md"
            justifyContent="center"
            alignItems="center"
            color="gray.700"
            fontWeight="extrabold"
          >
            conectando...
          </Box>
        )}
        <Box
          ref={listRef}
          display="flex"
          flexDir="column-reverse"
          overflow="auto"
          gap="2"
          flex="1"
        >
          {messageHistory.map(message => (
            <Box
              key={message.DataCriacao}
              p="4"
              rounded="xl"
              alignSelf={message.usuarioAtual ? 'flex-end' : 'flex-start'}
              bg={message.usuarioAtual ? 'white' : 'green.200'}
              fontWeight="medium"
              maxW="xl"
              position="relative"
            >
              {message.Mensagem}
              {message?.DataCriacao && (
                <Text
                  fontSize="x-small"
                  color="gray.500"
                  position="absolute"
                  bottom="1"
                  right="2"
                >
                  {format(Date.parse(message.DataCriacao).valueOf(), 'HH:mm', {
                    locale: ptBR,
                  })}
                </Text>
              )}
            </Box>
          ))}
        </Box>
        <Box display="flex" flexDir="row" mt="4">
          <Input
            size="lg"
            variant="filled"
            placeholder="Escrever mensagem"
            h="52px"
            borderStartRadius="14px"
            borderEndRadius="0px"
            bg="white"
            color="brand-dark.500"
            _placeholder={{ color: 'black.100' }}
            _hover={{ bg: 'brand-dark.100' }}
            {...register('message')}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleSubmit(handleSendMessage);
              }
            }}
          />
          <Button
            borderEndRadius="14px"
            borderStartRadius="0"
            h="52px"
            bg="#fff"
            _hover={{ backgroundColor: 'green.300' }}
            onClick={handleSubmit(handleSendMessage)}
          >
            <Image width="10" height="auto" src={DMIcon} />
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      gap="4"
    >
      <ChatIcon w="12" h="12" color="gray.500" />
      <Text textColor="gray.500" fontSize="xl" align="center">
        Selecione um chat e inicie a conversa.
      </Text>
    </Box>
  );
}
