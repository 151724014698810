import {
  Button,
  Flex,
  Heading,
  Image,
  VStack,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { IoPerson } from 'react-icons/io5';

import { StepOneSchema } from '@/features/auth/utils';
import { sendEmail, useLoginMutation, verifyLogin } from '@/features/auth';

import { AppVersion } from '@/components';
import { TextInput } from '@/features/auth/components/FormItems';

import logoImg from '@/assets/logo.png';
import { useState } from 'react';

type SendEmailFormFields = {
  userName: string;
};

export function SendEmailForm({ SetStep, setEmail, setDataUser }: any) {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<SendEmailFormFields>({
    defaultValues: {
      userName: '',
    },
    resolver: yupResolver(StepOneSchema),
    shouldFocusError: false,
  });

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const { isLoading, mutate } = useLoginMutation();

  async function onSubmit(values: SendEmailFormFields) {
    setEmail(values.userName);
    const res = await verifyLogin(values.userName);
    if (!res.success) {
      toast({
        title: res.data.errors[0] || 'Ocorreu um erro!',
        status: 'error',
      });
      return;
    }
    const dados = {
      instancia: res?.data?.usuarioAcesso?.instanciaSaude,
      unidade: res?.data?.usuarioAcesso?.unidadeSaudeNomes,
      profissional: res?.data?.usuarioAcesso?.nomeProfissional,
      user: {
        Nome: res?.data?.usuarioAcesso?.usuario?.pessoa?.nome,
        Cpf: res?.data?.usuarioAcesso?.usuario?.pessoa?.cpf,
        Telefone: res?.data?.usuarioAcesso?.usuario?.pessoa?.celular,
        DataNascimento:
          res?.data?.usuarioAcesso?.usuario?.pessoa?.dataNascimento,
        Email: res?.data?.usuarioAcesso?.usuario?.email,
        userName: res?.data?.usuarioAcesso?.usuario?.userName,
      },
    };

    setDataUser(dados);

    const numLogin = res?.data?.enumLogin;
    SetStep(numLogin + 1);
  }
  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      py={['12', '12', '8']}
      px={['8', '14', '14']}
      w="100%"
      height="100%"
      bg="white"
      borderRadius="0 22px 22px 0"
      direction="column"
      align="center"
      justify="center"
    >
      <Image
        src={logoImg}
        alt="Logo da Dynamic Medic Health"
        mt="auto"
        mb="8"
        w="166px"
        h="77px"
      />

      <VStack spacing="4" w="100%" mb="8">
        <Heading size="sm" mb="6" color="gray.700" textAlign="left">
          Entre com o usuário, e-mail ou código para acesso ao sistema DMHealth.
        </Heading>
        <TextInput
          icon={IoPerson}
          error={errors.userName}
          type="text"
          placeholder="Usuário / E-mail / codigo"
          {...register('userName')}
        />
      </VStack>

      <Button
        type="submit"
        isLoading={isLoading}
        disabled={isLoading}
        mb="10"
        w={['100%', '100%', '80%']}
        h="38px"
        borderRadius="6"
        bg="brand-orange.400"
        color="white"
        fontFamily="Inter"
        fontSize="md"
        fontWeight="normal"
        boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
        _hover={{
          background: 'brand-orange.300',
        }}
      >
        Próximo
      </Button>
      <AppVersion mt={['8', '8', 'auto']} />
    </Flex>
  );
}
