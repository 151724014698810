import { Tr, Td, Icon, Flex, Heading, Stack } from '@chakra-ui/react';
import { FiEye, FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import { useEffect, useState } from 'react';

import { api } from '@/lib';
import {
  Pagination,
  TopSection,
  Tables,
  GenericModal,
  EditSecretaryForm,
  ViewSecretaryInfo,
} from '@/features/registers';
import { useGendersQuery } from '@/hooks';
import { SecretariesProps } from '@/features/registers/types';
import { useUnidadeStore, useUserStore } from '@/stores';
import { PaginationButton } from '../components/Pagination/PaginationButton';
import { DeleteUserModal } from '../components/DeleteModals/DeleteUserModal';

export function SecretariesListPage() {
  const { data: genders = [] } = useGendersQuery();
  const [secretary, setSecretary] = useState<any>();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const user = useUserStore(state => state.user);
  const [selectPage, setSelectPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const unidadeId = useUnidadeStore(state => state.unidade?.id);

  // const { data } = useSecretaryListQuery();
  const [text, setText] = useState('');
  const [data, setData] = useState<any>([]);
  const [head, setHead] = useState<string[]>([]);
  const [search, setSearch] = useState<any>([]);
  const onCloseModal = () => setIsEditOpen(false);
  const onCloseDeleteModal = () => setIsDeleteOpen(false);
  const onCloseViewModal = () => setIsViewOpen(false);

  const onOpenEditModal = (m: SecretariesProps) => {
    setSecretary(m);
    setIsEditOpen(true);
  };

  const onDeleteEditModal = (m: SecretariesProps) => {
    setSecretary(m);
    setIsDeleteOpen(true);
  };

  const onOpenViewModal = (m: SecretariesProps) => {
    setSecretary(m);
    setIsViewOpen(true);
  };

  useEffect(() => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'paciente' &&
      user?.type !== 'medico' &&
      user?.type !== 'assistente'
    ) {
      head.push('EDITAR', 'DELETAR');
    }
  }, []);

  const showEditBtn = (secretariesData: SecretariesProps) => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'paciente' &&
      user?.type !== 'medico' &&
      user?.type !== 'assistente'
    ) {
      return (
        <Icon
          as={FiEdit}
          onClick={() => {
            if (secretariesData.primeiroAcesso) return;
            onOpenEditModal(secretariesData);
          }}
          cursor="pointer"
          color={
            secretariesData.primeiroAcesso ? 'brand-dark.100' : 'brand-dark.200'
          }
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  const showDeleteBtn = (secretariesData: SecretariesProps) => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'paciente' &&
      user?.type !== 'medico' &&
      user?.type !== 'assistente'
    ) {
      return (
        <Icon
          as={MdDeleteOutline}
          onClick={() => {
            if (secretariesData.primeiroAcesso && user?.type !== 'gestor')
              return;
            onDeleteEditModal(secretariesData);
          }}
          cursor="pointer"
          color={
            secretariesData.primeiroAcesso && user?.type !== 'gestor'
              ? 'brand-dark.100'
              : 'brand-dark.200'
          }
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  const setDataContent = async (back = false, next = false) => {
    const params = {
      id: unidadeId,
      pageSize: 5,
      pageNumber:
        !back && !next
          ? 1
          : back && selectPage > 1
          ? selectPage - 1
          : next
          ? selectPage + 1
          : selectPage,
    };

    const { data: total } = await api.get(
      `/instancia/unidade-listar-secretaria?id=${unidadeId}`,
    );
    setTotalPage(Math.ceil(total.data.length / 5)); // mock solicitar total page no endpoint

    const response = await api.get(`/instancia/unidade-listar-secretaria`, {
      params,
    });

    if (response.data.data.length > 0) {
      const resp = response.data.data.map((respo: any) => {
        const gender = genders?.filter(
          item => item?.value === respo?.usuario?.pessoa?.sexoId,
        );
        return {
          id: respo?.id,
          nome: respo?.usuario?.pessoa.nome,
          userName: respo?.usuario?.userName,
          email: respo?.usuario?.email,
          dataCriacao: respo?.usuario?.dataCriacao,
          dataEdicao: respo?.usuario?.dataEdicao,
          cpf: respo?.usuario?.pessoa?.cpf,
          telefone: respo?.usuario?.pessoa?.telefoneResidencial,
          generoId: respo?.usuario?.pessoa?.sexoId,
          dataNascimento: respo?.usuario?.pessoa?.dataNascimento,
          cep: respo?.usuario?.pessoa?.endereco?.cep,
          numero: respo?.usuario?.pessoa?.endereco?.numero,
          endereco: respo?.usuario?.pessoa?.endereco?.logradouro,
          bairro: respo?.usuario?.pessoa?.endereco?.bairro,
          cidade: respo?.usuario?.pessoa?.endereco?.cidade,
          estado: respo?.usuario?.pessoa?.endereco?.estado,
          complemento: respo?.usuario?.pessoa?.endereco?.complemento,
          sexo: gender || null,
          celular: respo?.usuario?.pessoa?.celular,
          codigoUsuario: respo?.usuario?.codigoUsuario,
          primeiroAcesso: respo?.usuario?.primeiroAcesso,
        };
      });
      setData(resp);
      setSearch(resp);
      if (back) {
        setSelectPage(selectPage === 1 ? 1 : selectPage - 1);
      }
      if (next) {
        setSelectPage(selectPage + 1);
      }
    } else if (!back && !next) {
      setData([]);
      setSearch([]);
    }
  };

  useEffect(() => {
    setSelectPage(1);
    setDataContent(false, false);
  }, []);

  useEffect(() => {
    setDataContent(false, false);
  }, [isEditOpen, isDeleteOpen, unidadeId]);

  useEffect(() => {
    if (data) {
      const lowerSearch = text.toLowerCase();

      const result = data.filter((d: any) => {
        return d.nome.toLowerCase().includes(lowerSearch);
      });

      setSearch(result);
    }
  }, [text]);

  return (
    <>
      <TopSection
        title="Assistentes Cadastrados"
        search={text}
        onChange={(str: any) => setText(str)}
      />

      <Tables
        head={[
          'NOME DO ASSISTENTE',
          'Celular',
          'E-mail',
          'Status',
          'Visualizar',
          ...head,
        ]}
      >
        {search?.map((secretariesData: SecretariesProps) => (
          <Tr key={secretariesData.id}>
            <Td>{secretariesData.nome}</Td>
            <Td>{secretariesData.celular}</Td>
            <Td>{secretariesData.email}</Td>
            <Td style={{ color: secretariesData.primeiroAcesso && 'red' }}>
              {secretariesData.primeiroAcesso ? 'PENDENTE' : 'VALIDADO'}
            </Td>
            <Td>
              <Icon
                as={FiEye}
                onClick={() => onOpenViewModal(secretariesData)}
                cursor="pointer"
                color="brand-dark.200"
                fontSize="20px"
                fontWeight="400"
                marginRight="15"
              />
            </Td>
            <Td>{showEditBtn(secretariesData)}</Td>
            <Td>{showDeleteBtn(secretariesData)}</Td>
          </Tr>
        ))}
      </Tables>

      <Flex
        direction={['column', 'column', 'row']}
        w="100%"
        align="center"
        justify="space-between"
      >
        <Heading
          size="xs"
          w="100%"
          mb={['4', '4', '0']}
          color="#707070"
          fontWeight="medium"
        >
          {/* Página {selectPage} de {dataPatients?.data.length} registros */}
          Página {selectPage}/{totalPage}
        </Heading>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          w="100%"
          justify="flex-end"
        >
          <PaginationButton
            text="Voltar"
            onClick={() => setDataContent(true, false)}
          />
          <PaginationButton
            text="Proximo"
            onClick={() => setDataContent(false, true)}
          />
        </Stack>
      </Flex>

      <GenericModal
        open={isEditOpen}
        textAlign="center"
        title="Editar Assistente"
        onClose={onCloseModal}
        closeOnOverlayClick={false}
      >
        <EditSecretaryForm secretary={secretary} onCancel={onCloseModal} />
      </GenericModal>
      <GenericModal
        open={isViewOpen}
        title={`Secretário(a): ${secretary && secretary.nome}`}
        onClose={onCloseViewModal}
      >
        <ViewSecretaryInfo secretary={secretary} />
      </GenericModal>
      <GenericModal
        open={isDeleteOpen}
        textAlign="center"
        title={`Deletar Secretário(a): ${secretary && secretary.nome}`}
        onClose={onCloseDeleteModal}
      >
        <DeleteUserModal
          user={secretary}
          type="secretary"
          onCancel={onCloseDeleteModal}
        />
      </GenericModal>
    </>
  );
}
