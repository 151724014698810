import create from 'zustand';

type NotificationsDateStore = {
  notifications: any[];
  setNotifications: (notifications: any[]) => void;
};

export const useNotificationDateStore = create<NotificationsDateStore>(set => ({
  notifications: [],
  setNotifications: (notifications: any) =>
    set(state => ({ ...state, notifications })),
}));
