import { api } from '@/lib';
import { useUnidadeStore, useUserStore } from '@/stores';
import { useQuery } from 'react-query';

interface useGetProfessionalByPatientProps {
  search?: string;
  page?: number;
  size?: number;
}

export interface ChatProps {
  id: string;
  nome: string;
  type: string;
  chat: {
    usuario1Id: string;
    usuario2Id: string;
    nome: string;
    descricao: string;
    mensagemNaoVisualizada: number;
    id: string;
    ativo: boolean;
    dataCriacao: string;
    dataEdicao: string | null;
  };
}

export interface chatListResponseProps {
  temProximo: boolean;
  pagina: number;
  chats: ChatProps[];
}

export function useGetChatList({
  search,
  page = 1,
  size = 15,
}: useGetProfessionalByPatientProps) {
  const user = useUserStore(state => state.user);
  const unidade = useUnidadeStore(state => state.unidade);
  const query = useQuery<chatListResponseProps>(
    ['chat-list', user?.id, search, page, size],
    async () => {
      const response = await api.get(`/chat/obter`, {
        data: {},
        params: {
          id: user?.id,
          ...(['master', 'gestor', 'assistente'].includes(user!.type) && {
            profissionalId: user!.profissionalId,
          }),
          ...(unidade?.id && { unidadeId: unidade!.id }),
          querystring: search,
          pagina: page,
          tamanhoPagina: size,
        },
      });

      return response.data.data;
    },
    {},
  );

  return query;
}
