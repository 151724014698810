import { useMemo, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { SchedulePage } from '@/features/schedule';
import { ViewContext } from '../hooks/useViewContext';

export function ScheduleRoutes() {
  const [view, setView] = useState('day');

  const values = useMemo(() => ({ view, setView }), [view, setView]);

  return (
    <ViewContext.Provider value={values}>
      <Routes>
        <Route path="" element={<SchedulePage />} />
        <Route path="*" element={<Navigate to="." />} />
      </Routes>
    </ViewContext.Provider>
  );
}
