import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Skeleton,
  Button,
} from '@chakra-ui/react';
import { Tooltip } from 'antd';
import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  useTable,
  usePagination,
  useSortBy,
  useExpanded,
  useGlobalFilter,
} from 'react-table';
import { TableColumnsDetails } from '@/features/historic';
import useGetPatientQuestionResponses from '@/features/historic/hooks/useGetPatientQuestionResponses';
import { format, parseISO } from 'date-fns';
import { formatterApplicationType } from '@/utils/formatterAplicationType';
import { SearchInput } from './SearchInput';
import ModalResults, { dataProps } from './ModalResults';

export function TableSectionResponse({ patientId }: { patientId: string }) {
  const navigate = useNavigate();
  const [response, setResponse] = useState<dataProps>();
  const [quizFilterId, setQuizFilterId] = useState<string>();

  const { data: patientQuizzesResponse, isLoading: isPatientResponsesLoading } =
    useGetPatientQuestionResponses({ patientId, quizId: quizFilterId });
  const data = useMemo<TableColumnsDetails[]>(
    () => patientQuizzesResponse || [],
    [patientQuizzesResponse],
  );

  const columns = useMemo(
    () => [
      {
        header: 'Questionário',
        accessor: 'questionario',
        Cell: ({ value }: any) => {
          return value.titulo;
        },
      },
      {
        header: 'Aplicação',
        accessor: 'aplicacao',
        Cell: ({ cell }: any) => {
          return formatterApplicationType(
            cell.row.original.questionario.periodicidade,
          );
        },
      },
      {
        header: 'Status',
        accessor: 'ativo',
        Cell: ({ cell }: any) => {
          return cell.row.original.questionario.ativo ? 'Ativo' : 'Desativado';
        },
      },
      {
        header: 'Data de Criação',
        accessor: 'creationTime',
        Cell: ({ cell }: any) => {
          return format(
            parseISO(cell.row.original.questionario.creationTime),
            'dd/MM/yyyy',
          );
        },
      },
      {
        header: 'Ações',
        accessor: 'id',
        Cell: ({ row }: any) => (
          <Flex alignItems="center" justifyContent="flex-start" padding="2">
            <Tooltip placement="bottom" title="Visualizar Monitoramento">
              <Button
                bg="transparent"
                onClick={() => setResponse(row.original.questionario)}
              >
                Visualizar
              </Button>
            </Tooltip>
          </Flex>
        ),
      },
    ],
    [],
  );

  function onCloseCancel() {
    setResponse(undefined);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    pageOptions,
    gotoPage,
    visibleColumns,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
    hooks => {
      hooks.visibleColumns.push(originalColumns => [...originalColumns]);
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const { pageIndex, globalFilter } = state;

  return (
    <>
      <SearchInput onQuestionSelect={setQuizFilterId} />
      {isPatientResponsesLoading ? (
        <Skeleton mt="8" w="100%" h="600px" border="20px" />
      ) : (
        <Box mt="8" w="100%">
          <TableContainer
            h="600px"
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': { width: '8px', height: '8px' },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: 20,
                background: 'brand-dark.200',
              },
            }}
          >
            <Table size="lg" bg="#fff" borderRadius="15px" {...getTableProps()}>
              <Thead h="50px">
                {headerGroups.map(headerGroup => {
                  return (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <Th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          color="#000000"
                          fontFamily="Rubik"
                          fontSize="lg"
                          fontWeight="normal"
                          // textAlign={
                          //   column.id === 'expander' ? 'center' : 'left'
                          // }
                          textAlign="left"
                          textTransform="initial"
                          border="1px solid #0005"
                        >
                          <>
                            {column.render('header')}

                            <Text as="span" color="#DEDEDE">
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? '▼'
                                  : '▲'
                                : ''}
                            </Text>
                          </>
                        </Th>
                      ))}
                    </Tr>
                  );
                })}
              </Thead>

              <Tbody {...getTableBodyProps()}>
                {page.map((row, id) => {
                  prepareRow(row);
                  return (
                    <Tr bg={id % 2 === 0 ? '#d9d9d9' : 'transparent'}>
                      {row.cells.map((cell, index) => {
                        return (
                          <Td
                            {...cell.getCellProps()}
                            color="brand-dark.300"
                            fontSize="md"
                            fontWeight="medium"
                            whiteSpace="pre-wrap"
                            cursor="pointer"
                            position="relative"
                            border="1px solid #0005"
                            // onClick={() => {
                            //   if (index !== 5) {
                            //     navigate('/app/historic', {
                            //       state: { paciente: cell.row.original },
                            //     });
                            //   }
                            // }}
                          >
                            <span
                              style={{
                                display: 'flex',
                                justifyContent: `${
                                  index === 5 ? 'flex-end' : 'flex-start'
                                }`,
                              }}
                            >
                              {row.original.recente &&
                                cell.column.id === 'paciente'}
                              {cell.render('Cell')}
                            </span>
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>

          <Flex>
            <Modal isOpen={!!response} onClose={onCloseCancel} size="7xl">
              <ModalOverlay />
              <Box>
                <ModalContent width="90%">
                  <ModalCloseButton />
                  <ModalBody py={20}>
                    <ModalResults data={response!} patientId={patientId} />
                  </ModalBody>
                </ModalContent>
              </Box>
            </Modal>
          </Flex>

          {/* <Flex
            mt="4"
            w="100%"
            direction={['column', 'column', 'row']}
            align="center"
            justify="space-between"
          >
            <Text color="brand-dark.300" fontSize="sm">
              Página {pageIndex + 1} de {pageOptions.length}
            </Text>

            <Pagination
              currentPage={pageIndex}
              totalCountOfRegisters={data.length}
              canPreviousPage={canPreviousPage}
              canNextPage={nextPage}
              onPageChange={gotoPage}
            />
          </Flex> */}
        </Box>
      )}
    </>
  );
}
