import { useQuery } from 'react-query';

import { api } from '@/lib';

type CouncilsAPIResponse = {
  data: {
    id: string;
    nome: string;
    numero: string;
  }[];
};

async function councilsRequest(): Promise<CouncilsAPIResponse> {
  const response = await api.get('/conselho');
  return response.data;
}

export function useCouncilsQuery() {
  const query = useQuery('councils', councilsRequest, {
    refetchInterval: false,
    select: originalResponse => {
      return originalResponse.data.map(council => {
        // const [numero, descricao] = council.nome.split('. ');
        return {
          label: council.nome,
          value: council.id,
          numero: council.numero,
        };
      });
    },
  });

  return query;
}
