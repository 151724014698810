import { api } from '@/lib';
import { useUserStore } from '@/stores';
import { formatDate } from '@/utils';
import { useQuery } from 'react-query';
import { useIntervalDateContext } from './useIntervalDateContext';

const tableHead = [
  'Nome Paciente',
  'Data',
  'Horas Inicial',
  'Horas Final',
  'Status',
  'Ultima Consulta',
  'Cid - Ultima Consulta',
];

async function getAttendance(
  gestorMasterId: string | undefined,
  DataInicial: Date,
  DataFinal: Date,
) {
  const params = {
    IdGestor: gestorMasterId,
    DataInicial,
    DataFinal,
  };

  const response = await api.get('/relatorio/atendimento', { params });
  const oldTableBody: any[] = response.data.data;

  const tableBody = oldTableBody.map(row => ({
    nomePaciente: row.nomePaciente ?? '-',
    data: formatDate(new Date(row.data), 'P') ?? '-',
    horasInicial: row.horasInicial ?? '-',
    horasFinal: row.horasFinal ?? '-',
    status: row.statusAtendimento ?? '-',
    dataUltimaConsulta: row.dataUltimaConsulta ?? '-',
    cidUltimaConsulta: row.cidUltimaConsulta ?? '-',
  }));

  return { tableHead, tableBody };
}

export function useAttendanceTableQuery() {
  const user = useUserStore(state => state.user);
  const { dateInit, dateFinal } = useIntervalDateContext();

  const DataInicial = new Date(dateInit);
  const DataFinal = new Date(dateFinal);

  const query = useQuery(
    ['attendanceList'],
    () => getAttendance(user?.gestorMasterId, DataInicial, DataFinal),
    {
      refetchOnWindowFocus: false,
    },
  );

  return query;
}
