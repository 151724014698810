import { useQuery, useMutation } from 'react-query';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';

import { useProfessionalStore, useUnidadeStore, useUserStore } from '@/stores';
import { api } from '@/lib';
import { useScheduleDateStore } from '../stores';
import { useScheduleIntervalQuery } from './useScheduleIntervalQuery';
import { useScheduleQuery } from './useScheduleQuery';

type Patient = {
  cpf: string;
  id: string;
  nome: string;
  associado?: boolean;
};

type PatientsAPIResponse = {
  data: Patient[];
  success: boolean;
};

type PatientSchedule = {
  tipoConsulta: number | null;
  profissonalSaudeId: string;
  pacienteId: string;
  horasAgendaId: string;
  idAgenda: string;
  dataPedido: Date;
  status?: number;
  idUnidadeSaude?: string;
};

async function getAllPatients(id?: string): Promise<Patient[]> {
  const response = await api.get<PatientsAPIResponse>(
    `/paciente/obter-gestormaster?gestorId=${id}`,
  );
  const { data } = response;

  return data.data;
}
async function getListAllPatients(id?: string, nome?: string) {
  try {
    if (nome && nome.length < 4) {
      return [];
    }
    const response = await api.get<PatientsAPIResponse>(
      `/paciente/obter-gestormaster?gestorId=${id}&nomePaciente=${nome}`,
    );
    const { data } = response;

    return data.data;
  } catch (e) {
    /* @ts-ignore */
    return [];
  }
}

async function postPatients(data: PatientSchedule) {
  const response = await api.post('/consulta/portal', data);

  return response.data;
}
async function getPatientsByProfissional(
  id?: string,
  unidadeId?: string,
): Promise<Patient[]> {
  // const res = await api.get<PatientsAPIResponse>(
  //   `/paciente/obter-paciente-profissional-paginado?idUsuarioProfissional=${id}`,
  // );
  // const { data: paciente } = res;
  // const pageSize = paciente.data.length;

  const params = {
    idUsuarioProfissional: id,
    idUnidade: unidadeId,
  };

  const response = await api.get<PatientsAPIResponse>(
    `/paciente/obter-paciente-profissional-paginado`,
    { params },
  );
  const { data } = response;

  // const teste = paciente?.data.map(p => {
  //   const associado = data?.data.filter((d: any) => {
  //     return d.id === p.id;
  //   });

  //   if (associado.length > 0) {
  //     return { ...p, associado: true };
  //   }
  //   return { ...p, associado: false };
  // });

  const associado = data?.data.map((d: any) => {
    return { ...d, associado: true };
  });

  return associado;
}

export function useAllPatients() {
  const user = useUserStore(state => state.user);

  const query = useQuery(
    ['patients', user?.id],
    () => getAllPatients(user?.id),
    {},
  );

  return query;
}
export function useListAllPatients(nome: string) {
  const user = useUserStore(state => state.user);

  const query = useQuery(['patients', user?.gestorMasterId], () =>
    getListAllPatients(user?.gestorMasterId, nome),
  );

  return query;
}

export function useSavePatient() {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const { setRefresh } = useScheduleDateStore();
  const { refetch } = useScheduleIntervalQuery();

  const { refetch: refresh } = useScheduleQuery();

  const mutation = useMutation((data: PatientSchedule) => postPatients(data), {
    onSuccess: () => {
      setRefresh();
      refetch();
      refresh();
      toast({
        title: 'Consulta agendada com sucesso!',
        status: 'success',
      });
    },
    onError: error => {
      if (axios.isAxiosError(error)) {
        const { response } = error;

        if (response?.data && response.data.errors[0]) {
          toast({
            title: response.data.errors[0],
            status: 'error',
          });
        }
      }
    },
  });

  return mutation;
}

export function usePatientByProfissional() {
  const user = useUserStore(state => state.user);
  const unidadeId = useUnidadeStore(state => state.unidade?.id);
  const { professional } = useProfessionalStore();

  const id = user?.type === 'medico' ? user?.id : professional?.idMedico;

  const query = useQuery(
    ['patients', id, unidadeId],
    () => getPatientsByProfissional(id, unidadeId),
    {},
  );

  return query;
}

export function usePatientSelectByProfissional() {
  const user = useUserStore(state => state.user);
  const unidadeId = useUnidadeStore(state => state.unidade?.id);
  const { professional } = useProfessionalStore();

  const id = user?.type === 'medico' ? user?.id : professional?.idMedico;

  const query = useQuery(
    ['patients', id, unidadeId],
    async () => {
      const response = await getPatientsByProfissional(id, unidadeId);

      const formattedData = response.map(item => ({
        value: item.id,
        label: item.nome,
      }));

      return formattedData;
    },
    {
      initialData: [],
    },
  );

  return query;
}
