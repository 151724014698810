import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Image,
  Modal,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  addScaleSchema,
  useAddScaleMutation,
  useScheduleDateStore,
} from '@/features/schedule';
import confirmationImg from '@/assets/register_confirmed.svg';
import { api } from '@/lib';
import { format, parseISO, parse } from 'date-fns';
import { MaskedInput, TextAreaInput } from '@/components/Form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useProfessionalStore, useUserStore } from '@/stores';
import moment from 'moment';
import { DatePicker, Input } from 'antd';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';
import { useSetInterval } from '@/features/schedule/hooks/useSetInterval';
import { useScheduleQuery } from '@/features/schedule/hooks/useScheduleQuery';

type AddScaleFormFields = {
  data: string;
  horarioInicio: string;
  horarioFim: string;
  observacao: string;
};

export function LockScaleRescheduleCard({ onCancel, event }: any) {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });
  const [wasFormSubmittedWithSuccess, setWasFormSubmittedWithSuccess] =
    useState(false);
  const professional = useProfessionalStore(state => state.professional);
  const user = useUserStore(state => state.user);
  const nameMedico = user?.type !== 'medico' ? professional?.name : user?.name;

  const referenceDate = useScheduleDateStore(state => state.referenceDate);
  const dataAtual = format(parseISO(referenceDate), 'dd/MM/yyyy');
  const [data, setData] = useState(moment(referenceDate));
  const [horaInicio, setHoraInicio] = useState(format(event.start, 'HH:mm'));
  const [horaFim, setHoraFim] = useState(format(event.end, 'HH:mm'));
  const [check, setCheck] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<AddScaleFormFields>({
    defaultValues: {
      data: dataAtual || '',
      horarioInicio: '',
      horarioFim: '',
      observacao: '',
    },
    resolver: yupResolver(addScaleSchema),
  });

  const { mutate, isLoading } = useAddScaleMutation();
  const { refetch } = useScheduleIntervalQuery();
  const { refetch: refresh } = useScheduleQuery();

  useEffect(() => {
    setHoraFim(
      useSetInterval(
        format(event.start, 'HH:mm'),
        format(event.end, 'HH:mm'),
        horaInicio,
      ),
    );
  }, [horaInicio]);

  async function buscarAgenda(date: any) {
    const { data: result } = await api.get(
      `agenda?data=${date}&idUsuario=${event.idUsuario}`,
    );

    const res = result?.data.filter((a: any) => {
      if (
        a.horarioInicio.slice(0, 5) === horaInicio &&
        a.horarioFim.slice(0, 5) !== horaFim
      ) {
        toast({
          title: `Erro no intervalo de horário.
            Existe uma agenda aberta das ${a.horarioInicio} horas até às ${a.horarioFim} horas`,
          status: 'error',
        });
        return null;
      }
      return a.horarioInicio.slice(0, 5) === horaInicio ? a : null;
    });

    return res;
  }

  const bloquearAgenda = async () => {
    try {
      await api.put(
        `/consulta/Desvincular-Paciente?id=${event.idConsultation}`,
      );
      try {
        await api.post(`agenda/bloquear?idAgenda=${event.idAgenda}`);
        refetch();
        refresh();
        setWasFormSubmittedWithSuccess(true);
      } catch (error: any) {
        toast({
          title: 'Ocorreu um erro ao bloquear horário',
          status: 'error',
        });
      }
    } catch (error: any) {
      toast({
        title: error.response.data.errors[0] || 'Ocorreu um erro!',
        status: 'error',
      });
      setWasFormSubmittedWithSuccess(false);
    }
  };

  const postPatient = async (idAgenda: any, idHorasAgenda: any) => {
    const dados = {
      tipoConsulta: event.consultation,
      profissonalSaudeId: event.idUsuario,
      pacienteId: event.idPatient,
      horasAgendaId: idHorasAgenda || '',
      dataPedido: new Date(),
      idAgenda: idAgenda || '',
      idUnidadeSaude: event.idUnidade,
    };

    try {
      const d = await api.post('/consulta/portal', dados);
      toast({
        title: 'Nova consulta marcada',
        status: 'success',
      });
      bloquearAgenda();
    } catch (error: any) {
      toast({
        title:
          error.response.data.errors[0] ||
          'Ocorreu um erro ao marcar nova consulta',
        status: 'error',
      });
    }
  };

  async function onSubmit(values: AddScaleFormFields) {
    const d = data.toISOString();
    const date = format(parseISO(d), 'MM/dd/yyyy');

    const res = await buscarAgenda(date);

    if (res[0]) {
      const idHorasAgenda = res[0].horasAgendas[0].id;
      const { idAgenda } = res[0].horasAgendas[0];
      postPatient(idAgenda, idHorasAgenda);
    } else {
      try {
        const [scheduleHour, scheduleMinute] = horaInicio.split(':');
        const [fimHour, fimMinute] = horaFim.split(':');
        if (
          scheduleHour > fimHour ||
          (scheduleHour === fimHour && scheduleMinute > fimMinute)
        ) {
          toast({
            title: 'Horário final menor que horário incial',
            status: 'error',
          });

          return;
        }
        mutate(
          {
            ...values,
            data: d,
            horarioInicio: horaInicio,
            horarioFim: horaFim,
          },
          {
            onSuccess: response => {
              const { idAgenda, idHorasAgenda } = response.data.data;
              postPatient(idAgenda, idHorasAgenda);
            },
          },
        );
      } catch (error: any) {
        toast({
          title: error.response.data.errors[0] || 'Ocorreu um erro!',
          status: 'error',
        });
        setWasFormSubmittedWithSuccess(false);
      }
    }
  }

  return wasFormSubmittedWithSuccess ? (
    <Flex w="100%" direction="column" align="center">
      <Heading
        mt="6"
        color="brand-dark.300"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
      >
        O horário foi bloqueado e a consulta reagendada!
      </Heading>

      <Image
        mt="8"
        mb="4"
        src={confirmationImg}
        alt="Mulher confirmando o sucesso de uma operação em um celular grande"
      />

      <Text
        color="brand-dark.200"
        fontSize="sm"
        fontWeight="normal"
        textAlign="center"
      >
        Você pode editar as configurações na aba de escalas.
      </Text>

      <Button
        type="button"
        onClick={onCancel}
        colorScheme="brand-green"
        mt="10"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Ir para escalas
      </Button>
    </Flex>
  ) : (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      textAlign="center"
    >
      <Heading mt="6" mb="4" color="red" fontSize="xl" fontWeight="medium">
        Bloquear horário e reagendar consulta
      </Heading>
      {/* <Box>
        <Text color="brand-dark.300" fontSize="xl" fontWeight="medium">
          Existe uma consulta agendada nesse horário. Deseja realmente
          bloqueá-lo?
        </Text>
      </Box> */}

      <SimpleGrid
        minChildWidth="100px"
        spacing="6"
        w="100%"
        paddingTop="10px"
        paddingBottom="30px"
      >
        <SimpleGrid>
          <Text mb="1" color="brand-dark.200" fontSize="sm">
            <Text as="strong">Paciente: </Text>
            <br />
            {event.title ? event.title : 'SEM PACIENTE'}
          </Text>
        </SimpleGrid>
        <SimpleGrid>
          <Text mb="1" color="brand-dark.200" fontSize="sm">
            <Text as="strong">Profissional de saúde: </Text>
            <br />
            {nameMedico}
          </Text>
        </SimpleGrid>
        <SimpleGrid>
          <Text mb="1" color="brand-dark.200" fontSize="sm">
            <Text as="strong">Tipo da consulta: </Text>
            <br />
            {event.idConsultation
              ? event.consultation
                ? 'ON-LINE'
                : 'PRESENCIAL'
              : 'A DEFINIR'}
          </Text>
        </SimpleGrid>
      </SimpleGrid>

      <SimpleGrid
        minChildWidth="150px"
        spacing="6"
        w="100%"
        paddingTop="10px"
        paddingBottom="30px"
      >
        <SimpleGrid>
          <Text mb="1" color="brand-dark.200" fontSize="sm">
            <Text as="strong">Data da consulta: </Text>
            <br />
            {dataAtual}
          </Text>
        </SimpleGrid>
        <SimpleGrid>
          <Text mb="1" color="brand-dark.200" fontSize="sm">
            <Text as="strong">Horário da consulta: </Text>
            <br />
            {horaInicio} horas
          </Text>
        </SimpleGrid>
      </SimpleGrid>

      <VStack spacing="4">
        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Nova Data
            </FormLabel>
            <DatePicker
              getPopupContainer={triggerNode => {
                return triggerNode?.parentNode as HTMLElement;
              }}
              onSelect={(selectedDay: any) => {
                if (selectedDay) {
                  setData(selectedDay);
                }
              }}
              disabledDate={(current: any) => {
                return moment().add(-1, 'days') >= current;
              }}
              size="large"
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              placeholder="Selecionar data"
            />
          </FormControl>
        </SimpleGrid>

        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Horário - Início
            </FormLabel>
            <Input
              type="time"
              id="horarioInicio"
              name="horarioInicio"
              value={horaInicio}
              style={{
                width: '100%',
              }}
              placeholder="Selecionar hora"
              onChange={(e: any) => {
                if (e) {
                  setHoraInicio(e.target.value);
                }
              }}
              required
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Horário - Fim
            </FormLabel>
            <Input
              type="time"
              id="horarioFim"
              name="horarioFim"
              value={horaFim}
              style={{ width: '100%' }}
              placeholder="Selecionar hora"
              onChange={(e: any) => {
                if (e) {
                  setHoraFim(e.target.value);
                }
              }}
              required
              disabled
            />
          </FormControl>
        </SimpleGrid>

        <TextAreaInput
          placeholder="Ex: Retorno ao paciente"
          label="Observação (opcional)"
          {...register('observacao')}
        />

        <Checkbox
          colorScheme="green"
          fontSize="xs"
          fontWeight="medium"
          textTransform="uppercase"
          onChange={e => setCheck(e.target.checked)}
        >
          Bloquear e não reagendar
        </Checkbox>

        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          <Button
            disabled={isLoading}
            type="button"
            onClick={onCancel}
            variant="outline"
            colorScheme="red"
            h="45px"
            borderRadius="28px"
            color="red"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Cancelar
          </Button>

          {check ? (
            <Button
              isLoading={isLoading}
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
              onClick={bloquearAgenda}
            >
              Bloquear
            </Button>
          ) : (
            <Button
              isLoading={isLoading}
              type="submit"
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Reagendar
            </Button>
          )}
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
