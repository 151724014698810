import {
  Flex,
  Heading,
  Image,
  Text,
  VStack,
  Button as ButtonUi,
  SimpleGrid,
  useToast,
  Button,
} from '@chakra-ui/react';
import { useState } from 'react';

import { useReplicateScaleMutation } from '@/features/schedule';

import confirmationImg from '@/assets/register_confirmed.svg';
import { DatePicker, Form, Input, Radio } from 'antd';
import moment from 'moment';

type ReplicateScaleFormFields = {
  tipo: string;
  dataOrigem: string;
  dataDestino: string;
  observacao: string;
};

interface ReplicateScaleFormProps {
  onCancel: () => void;
}

export function ReplicateScaleForm({ onCancel }: ReplicateScaleFormProps) {
  const [form] = Form.useForm();
  const tipo = Form.useWatch('tipo', form);
  const [wasFormSubmittedWithSuccess, setWasFormSubmittedWithSuccess] =
    useState(false);

  const { mutate, isLoading } = useReplicateScaleMutation();

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  function onSubmit(values: ReplicateScaleFormFields) {
    if (!values.dataOrigem || !values.dataDestino || !values.tipo) {
      return toast({
        title: 'Preencha todos os campos obrigatórios!',
        status: 'error',
      });
    }

    return mutate(values, {
      onSuccess: () => {
        setWasFormSubmittedWithSuccess(true);
      },
    });
  }

  return wasFormSubmittedWithSuccess ? (
    <Flex w="100%" direction="column" align="center">
      <Heading
        mt="6"
        color="brand-dark.300"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
      >
        A Escala foi replicada!
      </Heading>

      <Image
        mt="8"
        mb="4"
        src={confirmationImg}
        alt="Mulher confirmando o sucesso de uma operação em um celular grande"
      />

      <Text
        color="brand-dark.200"
        fontSize="sm"
        fontWeight="normal"
        textAlign="center"
      >
        Você pode editar as configurações na aba de escalas.
      </Text>

      <Button
        type="button"
        onClick={onCancel}
        colorScheme="brand-green"
        mt="10"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Ir para escalas
      </Button>
    </Flex>
  ) : (
    <>
      <Heading mt="6" mb="4" color="#60c0af" fontSize="xl" fontWeight="medium">
        Replicar escala
      </Heading>

      <VStack spacing="4">
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          style={{ width: '70%' }}
        >
          <Form.Item initialValue="2" name="tipo" label="Replicar">
            <Radio.Group size="large">
              {/* <Radio value="3" name="semanaPadrao">
                Semana Padrão
              </Radio> */}
              <Radio value="2" name="semana">
                Semana
              </Radio>
              <Radio value="1" name="dia">
                Dia
              </Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="dataOrigem"
            label={tipo !== '1' ? 'Replicar Semana' : 'Replicar Dia'}
          >
            {tipo !== '1' ? (
              <DatePicker
                picker="week"
                size="large"
                placeholder="Selecionar semana"
                style={{ width: '100%' }}
                // format="DD/MM/YYYY"
                getPopupContainer={triggerNode => {
                  return triggerNode?.parentNode as HTMLElement;
                }}
              />
            ) : (
              <DatePicker
                placeholder="Selecionar dia"
                getPopupContainer={triggerNode => {
                  return triggerNode?.parentNode as HTMLElement;
                }}
                format="DD/MM/YYYY"
                size="large"
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>

          <Form.Item
            name="dataDestino"
            label={tipo !== '1' ? 'Para a Semana' : 'Para o Dia'}
          >
            {tipo !== '1' ? (
              <DatePicker
                picker="week"
                size="large"
                placeholder="Selecionar semana"
                // format="DD/MM/YYYY"
                style={{ width: '100%' }}
                getPopupContainer={triggerNode => {
                  return triggerNode?.parentNode as HTMLElement;
                }}
                disabledDate={(current: any) => {
                  return moment().day(6) >= current;
                }}
              />
            ) : (
              <DatePicker
                placeholder="Selecionar dia"
                getPopupContainer={triggerNode => {
                  return triggerNode?.parentNode as HTMLElement;
                }}
                format="DD/MM/YYYY"
                size="large"
                style={{ width: '100%' }}
                disabledDate={(current: any) => {
                  return moment().add(-1, 'days') >= current;
                }}
              />
            )}
          </Form.Item>

          <Form.Item name="observacao" label="Observação">
            <Input size="large" />
          </Form.Item>

          <SimpleGrid minChildWidth="120px" spacing="4" w="100%">
            <ButtonUi
              disabled={isLoading}
              type="button"
              onClick={onCancel}
              variant="outline"
              colorScheme="red"
              h="45px"
              borderRadius="28px"
              color="red"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Cancelar
            </ButtonUi>

            <ButtonUi
              isLoading={isLoading}
              disabled={isLoading}
              type="submit"
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Replicar
            </ButtonUi>
          </SimpleGrid>
        </Form>
      </VStack>
    </>
  );
}
