import { useState } from 'react';
import {
  ActivitiesSection,
  ScheduleSection,
  TopSection,
} from '@/features/start';
import { Box } from '@chakra-ui/react';

import { useUserStore } from '@/stores';
import { SchedulePatientSection } from '../components/SchedulePatientSection';
import { ActivitiesSectionPatient } from '../components/ActivitiesSectionPatient';

export function StartPage() {
  const user = useUserStore(state => state.user);
  const [patientSelect, setPatientSelect] = useState();

  return (
    <>
      <TopSection />
      {user?.type !== 'paciente' ? (
        <>
          <ActivitiesSection
            patientSelect={patientSelect}
            setPatientSelect={setPatientSelect}
          />
          <ScheduleSection setPatientSelect={setPatientSelect} />
        </>
      ) : (
        <>
          <Box
            mt="4"
            w="100%"
            sx={{
              '.carousel-item': {
                '&:not(:last-child)': { paddingRight: '20px' },
              },
            }}
          >
            <ActivitiesSectionPatient
              patientSelect={{ idPatient: user.pacienteId }}
            />
          </Box>
          <SchedulePatientSection />
        </>
      )}
    </>
  );
}
