import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Image,
  Text,
  Button,
  Icon,
  useToast,
} from '@chakra-ui/react';
import { useEffect } from 'react';
import { IconType } from 'react-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdLogout } from 'react-icons/md';
import {
  useProfessionalStore,
  useUnidadeStore,
  useUserStore,
  useInstanciaStore,
  useMobileSidebarStore,
} from '@/stores';

import sidebarLogoImg from '@/assets/logo_sidebar.png';

import { NavItem } from './NavItem';

interface MobileSidebarProps {
  navItems: {
    icon: IconType;
    label: string;
    link?: string;
    onClick?: () => void;
    subLinks?: {
      label: string;
      icon: IconType;
      link?: string;
      onClick?: () => void;
    }[];
  }[];
}

export function MobileSidebar({ navItems }: MobileSidebarProps) {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isOpen = useMobileSidebarStore(state => state.isOpen);
  const onClose = useMobileSidebarStore(state => state.onClose);

  useEffect(() => {
    onClose();
  }, [pathname]);

  const setUser = useUserStore(state => state.setUser);
  const setInstancia = useInstanciaStore(state => state.setInstancia);
  const setUnidade = useUnidadeStore(state => state.setUnidade);
  const setProfessional = useProfessionalStore(state => state.setProfessional);

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    status: 'success',
    isClosable: true,
  });

  function handleLogout() {
    localStorage.clear();

    setUser(null);
    setInstancia(null);
    setUnidade(null);
    setProfessional(null);

    toast({
      title: 'Até mais!',
    });

    navigate('/auth');
  }

  return (
    <Drawer placement="left" isOpen={isOpen} onClose={onClose}>
      <DrawerOverlay />

      <DrawerContent background="white">
        <DrawerCloseButton color="brand-dark.500" />

        <DrawerBody p="0">
          <Grid
            my="2"
            py="8"
            px="6"
            borderRadius="8"
            templateColumns="repeat(2, max-content)"
            columnGap="3"
            alignItems="center"
            overflowX="hidden"
          >
            <GridItem
              as={Flex}
              w="48px"
              h="48px"
              align="center"
              justify="center"
            >
              <Image
                src={sidebarLogoImg}
                alt="Dynamic Medic Health Logo"
                w="100%"
                h="auto"
              />
            </GridItem>
            <GridItem>
              <Text
                ml="3"
                color="brand-dark.500"
                fontSize="md"
                fontWeight="bold"
                isTruncated
              >
                DMH
              </Text>
            </GridItem>
          </Grid>

          <Box as="nav" my="4" px="6" pb="8">
            {navItems.map(item => (
              <NavItem
                key={item.label}
                icon={item.icon}
                label={item.label}
                onClick={item?.onClick}
                subLinks={item?.subLinks}
              />
            ))}
            <Button
              onClick={handleLogout}
              leftIcon={<Icon as={MdLogout} />}
              w="100%"
              bg="transparent"
              color="brand-dark.400"
              justifyContent="flex-start"
            >
              Sair
            </Button>
          </Box>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  );
}
