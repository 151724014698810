import { Input } from 'antd';
import { styled } from 'styled-components';

export const CodeInputContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
`;

export const CodeInputBox = styled(Input)<{
  $borderColor?: string;
}>`
  border: none;
  background-color: transparent;
  font-size: 24px;
  width: 38px;
  height: 52px;
  text-align: center;
  margin: 0 4px;
  border-bottom: 2px solid #ccc;
  outline: none;
  padding: 0;
  border: ${props => props.$borderColor || '1px solid #515151'};

  &:focus {
    -webkit-appearance: none;
  }
`;
