import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { useScheduleDateStore } from '@/features/schedule';
import { useViewContext } from '@/features/schedule/hooks/useViewContext';

import { DayButton } from '../../features/schedule/components/SchedulerSection/DayButton';

function Header(props: any) {
  const { label, date } = props;
  const { setView } = useViewContext();

  const { setReferenceDate } = useScheduleDateStore();

  const [dayNumber, dayName] = label.split(' ');
  const day = {
    name: dayName,
    numeric: parseInt(dayNumber, 10),
  };

  const selectDay = (d: any) => {
    setReferenceDate(d.toISOString());
    setView('day');
  };

  return (
    <DayButton
      key={dayNumber}
      day={day}
      isActive={false}
      onDaySelect={() => selectDay(date)}
    />
  );
}

export default Header;
