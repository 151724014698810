import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { lockScaleSchema, useScheduleDateStore } from '@/features/schedule';

import { MaskedInput, TextAreaInput } from '@/components';

import confirmationImg from '@/assets/register_confirmed.svg';
import { AnyMessageParams } from 'yup/lib/types';
import { useProfessionalStore, useUserStore } from '@/stores';
import { api } from '@/lib';
import { formatDate } from '@/utils';
import { format, parseISO } from 'date-fns';
import { PickerInput } from '@/components/Form/PickerInput';
import { IoCalendarClearOutline } from 'react-icons/io5';
import { DatePicker } from 'antd';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';
import { useScheduleQuery } from '@/features/schedule/hooks/useScheduleQuery';

type LockScaleFormFields = {
  dataInicio: string;
  dataFim: string;
  horarioInicio: string;
  horarioFim: string;
  motivo: string;
};

interface LockScaleFormProps {
  onCancel: () => void;
  event?: any;
  pageStart?: any;
}

export function LockScaleForm({
  onCancel,
  event,
  pageStart,
}: LockScaleFormProps) {
  const user = useUserStore(state => state.user);
  const pro = useProfessionalStore(state => state.professional);
  let id = user?.id;

  if (user && user.type !== 'medico') {
    id = pro?.idMedico;
  }

  const [isLoading, setIsLoading] = useState(false);
  const { refetch } = useScheduleIntervalQuery();
  const { refetch: refresh } = useScheduleQuery();

  const [wasFormSubmittedWithSuccess, setWasFormSubmittedWithSuccess] =
    useState(false);
  const referenceDate = useScheduleDateStore(state => state.referenceDate);
  const momento =
    moment() >= moment(referenceDate) ? moment() : moment(referenceDate);

  const [data, setData] = useState(momento);
  const [dataF, setDataF] = useState(momento);
  const [horaInicio, setHoraInicio] = useState('10:00');
  const [horaFim, setHoraFim] = useState('11:00');

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<LockScaleFormFields>({
    defaultValues: {
      dataInicio: '',
      dataFim: '',
      horarioInicio: event ? event.inicio : '',
      horarioFim: event ? event.fim : '',
      motivo: '',
    },
    resolver: yupResolver(lockScaleSchema),
  });

  async function onSubmit(values: any) {
    setIsLoading(true);
    const dataInicio = values.dataInicio.toString().split('');
    const dataFim = values.dataFim.toString().split('');
    const horarioInicio = horaInicio.toString().split('');
    const horarioFim = horaFim.toString().split('');

    dataInicio.splice(2, 0, '/');
    dataInicio.splice(5, 0, '/');
    dataFim.splice(2, 0, '/');
    dataFim.splice(5, 0, '/');
    // horarioInicio.splice(-2, 0, ':');
    // horarioFim.splice(-2, 0, ':');

    const timeInitFormated = horarioInicio.join('');
    const timeEndFormated = horarioFim.join('');
    const dataInicioFormated = dataInicio.join('');
    const dataFimFormated = dataFim.join('');

    const newLock = {
      ...values,
      idUsuario: id,
      dataInicio: data.toISOString(),
      dataFim: dataF.toISOString(),
      horarioInicio: timeInitFormated,
      horarioFim: timeEndFormated,
    };

    try {
      const result: any = await api.post('/agenda/bloquearemlote', newLock);
      if (result?.statusText === 'OK') {
        refetch();
        refresh();
        setWasFormSubmittedWithSuccess(true);
      }
      if (result?.statusText !== 'OK') {
        refetch();
        refresh();
        setWasFormSubmittedWithSuccess(true);
      }
    } catch (error) {
      toast({
        title: 'Algo deu errado',
        status: 'error',
      });
      setWasFormSubmittedWithSuccess(false);
    } finally {
      setIsLoading(false);
    }
  }

  return wasFormSubmittedWithSuccess ? (
    <Flex w="100%" direction="column" align="center">
      <Heading
        mt="6"
        color="brand-dark.300"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
      >
        O bloqueio da Escala foi realizado!
      </Heading>

      <Image
        mt="8"
        mb="4"
        src={confirmationImg}
        alt="Mulher confirmando o sucesso de uma operação em um celular grande"
      />

      <Text
        color="brand-dark.200"
        fontSize="sm"
        fontWeight="normal"
        textAlign="center"
      >
        Você pode editar as configurações na aba de escalas.
      </Text>

      <Button
        type="button"
        onClick={onCancel}
        colorScheme="brand-green"
        mt="10"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Ir para escalas
      </Button>
    </Flex>
  ) : (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      textAlign="center"
    >
      <Heading mt="6" mb="4" color="red" fontSize="xl" fontWeight="medium">
        Bloquear horário
      </Heading>

      <VStack spacing="4">
        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          {/* <PickerInput
            name="dataInicio"
            label="Data - Início"
            control={control}
            mask="date"
            error={errors.dataInicio}
            placeholder="Ex: 01/01/1970"
            icon={IoCalendarClearOutline}
          /> */}

          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Data - Início
            </FormLabel>
            <DatePicker
              getPopupContainer={triggerNode => {
                return triggerNode?.parentNode as HTMLElement;
              }}
              onSelect={(selectedDay: any) => {
                if (selectedDay) {
                  setData(selectedDay);
                }
              }}
              disabledDate={(current: any) => {
                return moment().add(-1, 'days') >= current;
              }}
              size="large"
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              placeholder="Selecionar data de início"
            />
          </FormControl>

          {/* <PickerInput
            name="dataFim"
            label="Data - Fim"
            control={control}
            mask="date"
            error={errors.dataFim}
            placeholder="Ex: 01/01/1970"
            icon={IoCalendarClearOutline}
          /> */}
          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Data - Fim
            </FormLabel>
            <DatePicker
              getPopupContainer={triggerNode => {
                return triggerNode?.parentNode as HTMLElement;
              }}
              onSelect={(selectedDay: any) => {
                if (selectedDay) {
                  setDataF(selectedDay);
                }
              }}
              disabledDate={(current: any) => {
                return data >= current;
              }}
              size="large"
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              placeholder="Selecionar data de fim"
            />
          </FormControl>
        </SimpleGrid>

        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          {/* <MaskedInput
            name="horarioInicio"
            label="Horário - Início"
            control={control}
            mask="time"
            error={errors.horarioInicio}
            placeholder="Ex: 14:50"
          />

          <MaskedInput
            name="horarioFim"
            label="Horário - Fim"
            control={control}
            mask="time"
            error={errors.horarioFim}
            placeholder="Ex: 15:50"
          /> */}
          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Horário - Início
            </FormLabel>
            <Input
              type="time"
              id="horarioInicio"
              name="horarioInicio"
              value={horaInicio}
              min="07:00"
              max="18:00"
              style={{
                width: '100%',
              }}
              placeholder="Selecionar hora"
              onChange={(e: any) => {
                if (e) {
                  setHoraInicio(e.target.value);
                }
              }}
              required
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Horário - Fim
            </FormLabel>
            <Input
              type="time"
              id="horarioFim"
              name="horarioFim"
              value={horaFim}
              min="07:00"
              max="18:00"
              style={{ width: '100%' }}
              placeholder="Selecionar hora"
              onChange={(e: any) => {
                if (e) {
                  setHoraFim(e.target.value);
                }
              }}
              required
            />
          </FormControl>
        </SimpleGrid>
        <TextAreaInput
          placeholder="Ex: Escala cancelada"
          label="Motivo"
          error={errors.motivo}
          {...register('motivo')}
        />
        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          <Button
            disabled={isLoading}
            type="button"
            onClick={onCancel}
            variant="outline"
            colorScheme="red"
            h="45px"
            borderRadius="28px"
            color="red"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Cancelar
          </Button>

          <Button
            isLoading={isLoading}
            type="submit"
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Bloquear
          </Button>
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
