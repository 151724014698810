import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Skeleton,
  Button,
  SimpleGrid,
  FormControl,
  FormLabel,
  Select,
  Radio,
  Textarea,
  Icon,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  Input,
} from '@chakra-ui/react';
import { DatePicker } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useExpanded,
  useGlobalFilter,
} from 'react-table';
import { IoArrowBack } from 'react-icons/io5';
import { TableColumnsDetails } from '@/features/historic';
import { useUnidadeStore } from '@/stores';
import useGetQuestions from '@/features/historic/hooks/useGetQuestions';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerQuestionarySchema } from '@/features/historic/utils/schemas';
import { api } from '@/lib';
import useGetPatientQuestions from '@/features/historic/hooks/useGetPatientQuestions';
import { format, parseISO, setWeek } from 'date-fns';
import { formatterApplicationType } from '@/utils/formatterAplicationType';
import moment from 'moment';
import { Pagination } from '@/features/notifications/components/TableSection/Pagination';
import { getWeeksDiff } from '@/utils/weeksDiff';
import { weeks, months } from '@/utils/weeksAndMonthsMap';
import { getMonthsDiff } from '@/utils/monthDiff';
import { SearchInput } from './SearchInput';
import ModalResults, { dataProps } from './ModalResults';

type SchemaProps = {
  questionarioId: string;
  descricao: string;
  periodicidade: number;
  startDate: Date;
  finalDate: Date;
  horarios: Date[];
  horariosWithWeeks: any[];
  horariosWithMonths: any[];
};

export function TableSectionQuestion({ patientId }: { patientId: string }) {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  // const navigate = useNavigate();
  const unidade = useUnidadeStore(state => state.unidade);
  const { data: quizzes } = useGetQuestions({});
  const [formIsOpen, setFormIsOpen] = useState(false);
  const [disparosSelected, setDisparosSelected] =
    useState<{ id: number; horario: string }[]>();
  const [quizFilterId, setQuizFilterId] = useState<string>();
  const [newTime, setNewTime] = useState<Date | null>();
  const [newWeek, setNewWeek] = useState<string>();
  const [newMonth, setNewMonth] = useState<string>();
  const [newDay, setNewDay] = useState<Date | null>();
  const [isSending, setIsSending] = useState(false);
  const [response, setResponse] = useState<dataProps>();

  function onCloseCancel() {
    setResponse(undefined);
  }

  const {
    data: patientQuizzes,
    refetch,
    isLoading: isPatientQuizzesLoading,
  } = useGetPatientQuestions({ patientId, quizId: quizFilterId });

  const openForm = (id?: string) => {
    setFormIsOpen(true);

    // if (id) {
    //   setFormEditorId(id);
    // }
  };

  const openDispatchersModal = (
    disparos?: { id: number; horario: string }[],
  ) => {
    setDisparosSelected(disparos);
  };

  const deleteQuiz = async (id: number) => {
    await api.delete('/Quizz', {
      params: {
        questionarioUsuarioDisparoId: id,
        parar: false,
      },
    });

    refetch();
  };

  const stopQuiz = async (id: number) => {
    await api.delete('/Quizz', {
      params: {
        questionarioUsuarioDisparoId: id,
        parar: true,
      },
    });

    refetch();
  };

  const closeDispatchersModal = () => {
    setDisparosSelected(undefined);
  };

  const closeForm = () => {
    setFormIsOpen(false);
    // setFormEditorId(undefined);
  };

  const {
    control,
    handleSubmit,
    reset,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<SchemaProps>({
    resolver: yupResolver(registerQuestionarySchema),
    defaultValues: {
      horarios: [],
      horariosWithWeeks: [],
      horariosWithMonths: [],
    },
  });

  const addTime = () => {
    if (!newTime) {
      return;
    }
    const timestamp = new Date(newTime.getTime() + 3 * 3600000);

    const selectedTimes = watch('horarios');

    const existingTime = selectedTimes.find(
      time =>
        time.getHours() === timestamp.getHours() &&
        time.getMinutes() === timestamp.getMinutes(),
    );
    if (!existingTime) {
      const updatedTimes = selectedTimes.slice();
      updatedTimes.push(timestamp);

      const sortedTimes = updatedTimes.sort(
        (a, b) => a.getTime() - b.getTime(),
      );
      setValue('horarios', sortedTimes);
    }
    setNewTime(undefined);
  };

  const addTimeWithWeek = () => {
    if (!newTime || !newWeek) {
      return;
    }
    const timestamp = new Date(newTime.getTime() + 3 * 3600000);

    const newTimeWithWeek = {
      week: newWeek,
      time: timestamp,
    };

    const selectedTimes = watch('horariosWithWeeks');

    const existingTime = selectedTimes.find(
      t =>
        t.time.getHours() === timestamp.getHours() &&
        t.time.getMinutes() === timestamp.getMinutes() &&
        t.week === newWeek,
    );
    if (!existingTime) {
      const updatedTimes = selectedTimes.slice();
      updatedTimes.push(newTimeWithWeek);
      setValue('horariosWithWeeks', updatedTimes);
    }
  };
  const addTimeWithMonth = () => {
    if (!newTime || !newMonth || !newDay) {
      return;
    }

    const timestamp = new Date(newTime.getTime() + 3 * 3600000);

    const newTimeWithMonth = {
      month: newMonth,
      day: newDay,
      time: timestamp,
    };

    const selectedTimes = watch('horariosWithMonths');

    const existingTimeMonth = selectedTimes.find(
      t =>
        t.time.getHours() === timestamp.getHours() &&
        t.time.getMinutes() === timestamp.getMinutes() &&
        t.month === newMonth &&
        t.day.getDate() === newDay.getDate(),
    );
    if (!existingTimeMonth) {
      const updatedTimesMonth = selectedTimes.slice();
      updatedTimesMonth.push(newTimeWithMonth);
      setValue('horariosWithMonths', updatedTimesMonth);
    }
  };

  function removeTime(timeToRemove: Date) {
    const selectedTimes = watch('horarios');

    const indexToRemove = selectedTimes.findIndex(
      time => time.getTime() === timeToRemove.getTime(),
    );

    if (indexToRemove !== -1) {
      const updatedTimes = [
        ...selectedTimes.slice(0, indexToRemove),
        ...selectedTimes.slice(indexToRemove + 1),
      ];

      setValue('horarios', updatedTimes);
    }
  }

  function removeTimeWithWeek(timeWeekToRemove: any) {
    const selectedTimes = watch('horariosWithWeeks');
    const indexToRemove = selectedTimes.findIndex(
      i =>
        i.time.getTime() === timeWeekToRemove.time.getTime() &&
        i.week === timeWeekToRemove.week,
    );

    if (indexToRemove !== -1) {
      const updatedTimes = [
        ...selectedTimes.slice(0, indexToRemove),
        ...selectedTimes.slice(indexToRemove + 1),
      ];

      setValue('horariosWithWeeks', updatedTimes);
    }
  }

  useEffect(() => {
    const questionarioId = watch('questionarioId');
    if (questionarioId) {
      const quizz = quizzes?.find(q => q.key === Number(questionarioId));
      const disparos = quizz?.disparos.map(d =>
        moment(d.horario, 'HH:mm').toDate(),
      );
      if (disparos && disparos?.length > 0) {
        setValue('horarios', disparos);
      }
      setValue('periodicidade', quizz?.periodicidade || 0);
    }
  }, [watch('questionarioId')]);

  useEffect(() => {
    const periodicidade = watch('periodicidade');
    if ([2, 3].includes(periodicidade)) {
      setValue('horarios', []);
    }
  }, [watch('periodicidade')]);

  const numberOfWeeks = getWeeksDiff(watch('startDate'), watch('finalDate'));
  const numberOfMonths = getMonthsDiff(watch('startDate'), watch('finalDate'));

  const onSubmit = async (data: SchemaProps) => {
    let disparoDirect;
    if (data.periodicidade < 2) {
      disparoDirect = data.horarios.map(h => ({
        horarioString: `${String(h.getHours()).padStart(2, '0')}:${String(
          h.getMinutes(),
        ).padStart(2, '0')}`,
        semanaInt: null,
        mensalInt: null,
        mensalDate: null,
      }));
    } else if (Number(data.periodicidade) === 2) {
      disparoDirect = data.horariosWithWeeks.map(h => ({
        horarioString: `${String(h.time.getHours()).padStart(2, '0')}:${String(
          h.time.getMinutes(),
        ).padStart(2, '0')}`,
        semanaInt: h?.week ?? 0,
        mensalInt: null,
        mensalDate: null,
      }));
    } else if (Number(data.periodicidade) === 3) {
      disparoDirect = data.horariosWithMonths.map(h => ({
        horarioString: `${String(h.time.getHours()).padStart(2, '0')}:${String(
          h.time.getMinutes(),
        ).padStart(2, '0')}`,
        semanaInt: null,
        mensalInt: h!.month + 1,
        mensalDate: new Date(h?.day).toISOString(),
      }));
    }

    try {
      setIsSending(true);
      await api.post('/Quizz', {
        pacienteId: patientId,
        questionarioId: data.questionarioId,
        unidadeSaudeId: unidade!.id,
        periodicidade: data.periodicidade,
        descricao: data.descricao,
        dataInicial: data.startDate,
        dataFinal: data.finalDate,
        disparoDirect,
      });

      toast({
        title: 'Questionário aplicado',
        status: 'success',
      });

      refetch();
      reset();
      closeForm();
    } catch (error: any) {
      toast({
        title:
          error.response?.data?.errors[0] ||
          'Ocorreu um erro ao tentar adicionar um novo questionário',
        status: 'error',
      });
    } finally {
      setIsSending(false);
    }
  };

  const data = useMemo<TableColumnsDetails[]>(
    () => patientQuizzes || [],
    [patientQuizzes],
  );

  const columns = useMemo(
    () => [
      {
        header: 'Questionário',
        accessor: 'quetionario',
        Cell: ({ cell }: any) => {
          return cell.row.original.questionario?.titulo || '';
        },
      },
      {
        header: 'Período',
        accessor: 'dataInicial',
        Cell: ({ cell }: any) => {
          return `${format(
            parseISO(cell.row.original.dataInicial),
            'dd/MM/yyyy',
          )} a ${format(parseISO(cell.row.original.dataFinal), 'dd/MM/yyyy')}`;
        },
      },
      {
        header: 'Frequência',
        accessor: 'descricao',
        Cell: ({ cell }: any) => {
          const formatarHorario = (horario: string) => {
            const dataObj = new Date(`1970-01-01T${horario}Z`);

            const horas = dataObj.getUTCHours();
            const minutos = dataObj.getUTCMinutes();

            const formatoHora = String(horas).padStart(2, '0');
            const formatoMinuto = String(minutos).padStart(2, '0');

            return `${formatoHora}:${formatoMinuto}`;
          };

          const horariosFormatados =
            cell.row.original.questionario?.questionarioDisparo?.map((d: any) =>
              formatarHorario(d.horario),
            );

          return `${formatterApplicationType(
            cell.row.original?.periodicidade,
          )} - ${horariosFormatados?.join(' - ')}`;
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    pageOptions,
    gotoPage,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
    hooks => {
      hooks.visibleColumns.push(originalColumns => [
        ...originalColumns,
        {
          id: 'expander',
          header: 'Ações',
          Cell: ({ row }) => (
            <Flex alignItems="center" justifyContent="flex-start" paddingY="2">
              <Button
                type="button"
                bg="transparent"
                onClick={() => setResponse(row?.original as dataProps)}
              >
                Visualizar
              </Button>
              {/* <Text paddingX="2">|</Text> */}
              {/* <Button
                type="button"
                bg="transparent"
                onClick={() =>
                  stopQuiz(row?.original.questionarioUsuarioDisparoId)
                }
              >
                Parar
              </Button> */}
              {/* <Text paddingX="2">|</Text>
              <Button
                type="button"
                bg="transparent"
                onClick={() =>
                  deleteQuiz(row?.original.questionarioUsuarioDisparoId)
                }
              >
                Deletar
              </Button> */}
            </Flex>
          ),
        },
      ]);
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const { pageIndex, globalFilter } = state;

  return (
    <Box>
      {formIsOpen ? (
        <Flex
          as="form"
          onSubmit={handleSubmit(onSubmit)}
          direction="column"
          gap={4}
        >
          <Icon
            as={IoArrowBack}
            fontSize="30px"
            color="#8A8A8A"
            right="0"
            justifyContent="flex-end"
            marginBottom="10"
            cursor="pointer"
            onClick={closeForm}
          />
          <SimpleGrid columns={2} spacing="4" w="100%">
            <FormControl>
              <Radio isChecked marginBottom={2}>
                Usar questionário
              </Radio>
              <Select
                size="md"
                variant="filled"
                placeholder="Selecionar"
                borderColor="brand-dark.100"
                bg="white"
                color="brand-dark.500"
                _placeholder={{ color: '#bfbfbf' }}
                _hover={{ bg: 'brand-dark.100' }}
                {...register('questionarioId')}
              >
                {quizzes?.map(quiz => (
                  <option value={quiz.key}>{quiz.name}</option>
                ))}
              </Select>
            </FormControl>
            <FormControl>
              <FormLabel marginBottom={2}>Descrição</FormLabel>
              <Textarea
                pl="4"
                pr="8"
                borderColor="rgba(0, 0, 0, 0.12)"
                background="white"
                color="brand-dark.500"
                size="md"
                resize="vertical"
                {...register('descricao')}
              />
            </FormControl>
          </SimpleGrid>

          <Box
            borderColor="gray.500"
            borderWidth={1}
            borderRadius={8}
            padding={6}
            display="flex"
            flexDirection="column"
            gap={4}
          >
            <Text fontSize="lg" fontWeight="semibold">
              Frequência
            </Text>

            <Flex gap={8} flexWrap="wrap">
              <Controller
                name="periodicidade"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Radio isChecked={value === 0} onChange={() => onChange(0)}>
                    Único
                  </Radio>
                )}
              />
              <Controller
                name="periodicidade"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Radio isChecked={value === 1} onChange={() => onChange(1)}>
                    Diário
                  </Radio>
                )}
              />
              <Controller
                name="periodicidade"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Radio isChecked={value === 2} onChange={() => onChange(2)}>
                    Semanal
                  </Radio>
                )}
              />
              <Controller
                name="periodicidade"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Radio isChecked={value === 3} onChange={() => onChange(3)}>
                    Mensal
                  </Radio>
                )}
              />
            </Flex>

            {watch('periodicidade') !== undefined &&
              watch('periodicidade').toString() !== '0' && (
                <SimpleGrid columns={2} spacing="4" w="100%">
                  <FormControl>
                    <FormLabel htmlFor="data" width="100%">
                      Data de início
                    </FormLabel>
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <>
                          <DatePicker
                            size="large"
                            placeholder="Selecionar data de início"
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            getPopupContainer={triggerNode => {
                              return triggerNode?.parentNode as HTMLElement;
                            }}
                            onChange={e => {
                              onChange(e?.toDate());
                            }}
                          />
                          {errors?.startDate && (
                            <Text color="red.300">
                              {errors?.startDate.message}
                            </Text>
                          )}
                        </>
                      )}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel htmlFor="data" width="100%">
                      Data de Fim
                    </FormLabel>
                    <Controller
                      name="finalDate"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <>
                          <DatePicker
                            size="large"
                            placeholder="Selecionar data de fim"
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            getPopupContainer={triggerNode => {
                              return triggerNode?.parentNode as HTMLElement;
                            }}
                            onChange={e => {
                              onChange(e?.toDate());
                            }}
                          />
                          {errors?.finalDate && (
                            <Text color="red.300">
                              {errors?.finalDate.message}
                            </Text>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                </SimpleGrid>
              )}

            {watch('periodicidade') !== undefined &&
              watch('periodicidade').toString() === '0' && (
                <SimpleGrid columns={2} spacing="4" w="100%">
                  <FormControl>
                    <FormLabel htmlFor="data" width="100%">
                      Data
                    </FormLabel>
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field: { onChange } }) => (
                        <>
                          <DatePicker
                            size="large"
                            placeholder="Selecionar data"
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            getPopupContainer={triggerNode => {
                              return triggerNode?.parentNode as HTMLElement;
                            }}
                            onChange={e => {
                              onChange(e?.toDate());
                            }}
                          />
                          {errors?.startDate && (
                            <Text color="red.300">
                              {errors?.startDate.message}
                            </Text>
                          )}
                        </>
                      )}
                    />
                  </FormControl>
                </SimpleGrid>
              )}

            {watch('periodicidade') !== undefined &&
              watch('periodicidade').toString() === '2' && (
                <Box display="flex" flexDirection="column" gap={4}>
                  <SimpleGrid columns={2} spacing="4" w="100%">
                    <Box display="flex" alignItems="center" gap={4}>
                      <Text fontSize="md" fontWeight="medium">
                        Semanas:
                      </Text>
                      <Select
                        size="md"
                        variant="filled"
                        placeholder="Selecionar"
                        borderColor="brand-dark.100"
                        bg="white"
                        color="brand-dark.500"
                        _placeholder={{ color: '#bfbfbf' }}
                        _hover={{ bg: 'brand-dark.100' }}
                        onChange={e => setNewWeek(e.target.value)}
                      >
                        {weeks?.map((week, i) => (
                          <option key={week} value={i}>
                            {week}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    <Box display="flex" alignItems="center" gap={4}>
                      <Text fontSize="md" fontWeight="medium">
                        Quant. Semanas:
                      </Text>
                      <Text fontSize="md" fontWeight="medium">
                        {numberOfWeeks}
                      </Text>
                    </Box>
                  </SimpleGrid>

                  <Text fontSize="md" fontWeight="medium">
                    Horário
                  </Text>

                  <SimpleGrid columns={2} spacing="4" w="100%">
                    <Input
                      type="time"
                      placeholder="Digite a hora (HH:mm)"
                      onChange={e => {
                        setNewTime(e.target.valueAsDate);
                      }}
                    />

                    <Button
                      colorScheme="green"
                      color="white"
                      fontWeight="medium"
                      textTransform="uppercase"
                      ml="5"
                      py={6}
                      fontSize="large"
                      maxW="3xs"
                      onClick={addTimeWithWeek}
                    >
                      Adicionar
                    </Button>
                  </SimpleGrid>

                  {errors?.horariosWithWeeks !== undefined && (
                    <Text color="red.300">Adicione ao menos um horário</Text>
                  )}

                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Semana</Th>
                          <Th>Horário</Th>
                          <Th>Ações</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {watch('horariosWithWeeks')?.map(i => {
                          return (
                            <Tr
                              key={`${i.week}-${i.time.getTime().toString()}`}
                            >
                              <Td>
                                <Text fontSize="md" fontWeight="medium">
                                  {weeks[i.week]}
                                </Text>
                              </Td>
                              <Td>
                                <Text fontSize="md" fontWeight="medium">
                                  {format(i?.time, 'HH:mm')}
                                </Text>
                              </Td>
                              <Td>
                                <Button onClick={() => removeTimeWithWeek(i)}>
                                  Remover
                                </Button>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              )}

            {watch('periodicidade') !== undefined &&
              watch('periodicidade').toString() === '3' && (
                <Box display="flex" flexDirection="column" gap={4}>
                  <SimpleGrid columns={2} spacing="4" w="100%">
                    <Box display="flex" alignItems="center" gap={4}>
                      <Text fontSize="md" fontWeight="medium">
                        Meses:
                      </Text>
                      <Select
                        size="md"
                        variant="filled"
                        placeholder="Selecionar"
                        borderColor="brand-dark.100"
                        bg="white"
                        color="brand-dark.500"
                        _placeholder={{ color: '#bfbfbf' }}
                        _hover={{ bg: 'brand-dark.100' }}
                        onChange={e => setNewMonth(e.target.value)}
                      >
                        {months?.map((month, i) => (
                          <option key={month} value={i}>
                            {month}
                          </option>
                        ))}
                      </Select>
                    </Box>
                    <Box display="flex" alignItems="center" gap={4}>
                      <Text fontSize="md" fontWeight="medium">
                        Quant. Meses:
                      </Text>
                      <Text fontSize="md" fontWeight="medium">
                        {numberOfMonths}
                      </Text>
                    </Box>
                  </SimpleGrid>

                  <SimpleGrid columns={2} spacing="4" w="100%">
                    <Box display="flex" alignItems="center" gap={4}>
                      <Text fontSize="md" fontWeight="medium">
                        Data:
                      </Text>
                      <DatePicker
                        size="large"
                        placeholder="Selecione uma data"
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                        getPopupContainer={triggerNode => {
                          return triggerNode?.parentNode as HTMLElement;
                        }}
                        onChange={e => {
                          setNewDay(e?.toDate());
                        }}
                      />
                    </Box>
                  </SimpleGrid>

                  <Text fontSize="md" fontWeight="medium">
                    Horário
                  </Text>

                  <SimpleGrid columns={2} spacing="4" w="100%">
                    <Input
                      type="time"
                      placeholder="Digite a hora (HH:mm)"
                      onChange={e => {
                        setNewTime(e.target.valueAsDate);
                      }}
                    />

                    <Button
                      colorScheme="green"
                      color="white"
                      fontWeight="medium"
                      textTransform="uppercase"
                      ml="5"
                      py={6}
                      fontSize="large"
                      maxW="3xs"
                      onClick={addTimeWithMonth}
                    >
                      Adicionar
                    </Button>
                  </SimpleGrid>

                  {errors?.horariosWithWeeks !== undefined && (
                    <Text color="red.300">Adicione ao menos um horário</Text>
                  )}

                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Mensal</Th>
                          <Th>Dia</Th>
                          <Th>Horário</Th>
                          <Th>Ações</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {watch('horariosWithMonths')?.map(i => {
                          return (
                            <Tr
                              key={`${i.week}-${i.time.getTime().toString()}`}
                            >
                              <Td>
                                <Text fontSize="md" fontWeight="medium">
                                  {months[i.month]}
                                </Text>
                              </Td>
                              <Td>
                                <Text fontSize="md" fontWeight="medium">
                                  {i.day.getDate()}
                                </Text>
                              </Td>
                              <Td>
                                <Text fontSize="md" fontWeight="medium">
                                  {format(i?.time, 'HH:mm')}
                                </Text>
                              </Td>
                              <Td>
                                <Button onClick={() => removeTimeWithWeek(i)}>
                                  Remover
                                </Button>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              )}

            {watch('periodicidade') !== undefined &&
              [0, 1].includes(watch('periodicidade').valueOf()) && (
                <Box display="flex" flexDirection="column" gap={4}>
                  <Text fontSize="md" fontWeight="medium">
                    Horário
                  </Text>
                  <SimpleGrid columns={2} spacing="4" w="100%">
                    <Input
                      type="time"
                      placeholder="Digite a hora (HH:mm)"
                      onChange={e => {
                        setNewTime(e.target.valueAsDate);
                      }}
                    />

                    <Button
                      colorScheme="green"
                      color="white"
                      fontWeight="medium"
                      textTransform="uppercase"
                      ml="5"
                      py={6}
                      fontSize="large"
                      maxW="3xs"
                      onClick={addTime}
                    >
                      Adicionar
                    </Button>
                  </SimpleGrid>

                  {errors?.horarios !== undefined && (
                    <Text color="red.300">Adicione ao menos um horário</Text>
                  )}

                  <TableContainer>
                    <Table variant="simple">
                      <Thead>
                        <Tr>
                          <Th>Horário</Th>
                          <Th>Ações</Th>
                        </Tr>
                      </Thead>
                      <Tbody>
                        {watch('horarios')?.map(h => {
                          return (
                            <Tr key={h.getTime().toString()}>
                              <Td>
                                <Text fontSize="md" fontWeight="medium">
                                  {format(h, 'HH:mm')}
                                </Text>
                              </Td>
                              <Td>
                                <Button onClick={() => removeTime(h)}>
                                  Remover
                                </Button>
                              </Td>
                            </Tr>
                          );
                        })}
                      </Tbody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
          </Box>

          <Button
            type="submit"
            isLoading={isSending}
            colorScheme="brand-green"
            color="white"
            fontWeight="medium"
            textTransform="uppercase"
            ml="5"
            py={6}
            fontSize="large"
            maxW="3xs"
          >
            Salvar
          </Button>
        </Flex>
      ) : (
        <Box>
          <SearchInput onQuestionSelect={setQuizFilterId} />
        </Box>
      )}
      <Box>
        {isPatientQuizzesLoading ? (
          <Skeleton mt="8" w="100%" h="600px" border="20px" />
        ) : (
          <Box mt="8" w="100%">
            <TableContainer
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': { width: '8px', height: '8px' },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: 20,
                  background: 'brand-dark.200',
                },
              }}
            >
              <Table
                size="lg"
                bg="#fff"
                borderRadius="15px"
                {...getTableProps()}
              >
                <Thead h="50px">
                  {headerGroups.map(headerGroup => {
                    return (
                      <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <Th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps(),
                            )}
                            color="#000000"
                            fontFamily="Rubik"
                            fontSize="lg"
                            fontWeight="normal"
                            // textAlign={
                            //   column.id === 'expander' ? 'center' : 'left'
                            // }
                            textAlign="left"
                            textTransform="initial"
                            border="1px solid #0005"
                          >
                            <>
                              {column.render('header')}

                              <Text as="span" color="#DEDEDE">
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? '▼'
                                    : '▲'
                                  : ''}
                              </Text>
                            </>
                          </Th>
                        ))}
                      </Tr>
                    );
                  })}
                </Thead>

                <Tbody {...getTableBodyProps()}>
                  {page.map((row, id) => {
                    prepareRow(row);
                    return (
                      <Tr bg={id % 2 === 0 ? '#d9d9d9' : 'transparent'}>
                        {row.cells.map((cell, index) => {
                          return (
                            <Td
                              {...cell.getCellProps()}
                              color="brand-dark.300"
                              fontSize="md"
                              fontWeight="medium"
                              whiteSpace="pre-wrap"
                              cursor="pointer"
                              position="relative"
                              border="1px solid #0005"
                              // onClick={() => {
                              //   if (index !== 5) {
                              //     navigate('/app/historic', {
                              //       state: { paciente: cell.row.original },
                              //     });
                              //   }
                              // }}
                            >
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: `${
                                    index === 5 ? 'flex-end' : 'flex-start'
                                  }`,
                                }}
                              >
                                {row.original.recente &&
                                  cell.column.id === 'paciente'}
                                {cell.render('Cell')}
                              </span>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>

            <Flex
              w="100%"
              direction={['column', 'column', 'row']}
              align="center"
              justify="space-between"
            >
              <Text color="brand-dark.300" fontSize="sm">
                Página {pageIndex + 1} de {pageOptions.length}
              </Text>

              <Pagination
                currentPage={pageIndex}
                totalCountOfRegisters={data.length}
                canPreviousPage={canPreviousPage}
                canNextPage
                onPageChange={gotoPage}
              />
            </Flex>
          </Box>
        )}
      </Box>

      {!formIsOpen && (
        <Button
          onClick={() => openForm()}
          py={6}
          fontSize="large"
          fontWeight="medium"
          textTransform="uppercase"
          marginTop={10}
        >
          NOVO MONITORAMENTO
        </Button>
      )}

      <Modal isOpen={!!response} onClose={onCloseCancel} size="7xl">
        <ModalOverlay />
        <Box>
          <ModalContent width="90%">
            <ModalCloseButton />
            <ModalBody py={20}>
              <ModalResults data={response!} />
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>

      {/* frequencia modal */}

      <Modal
        isOpen={!!disparosSelected}
        onClose={closeDispatchersModal}
        size="7xl"
      >
        <ModalOverlay />
        <Box>
          <ModalContent width="40%">
            <ModalCloseButton />
            <ModalBody>
              <ModalHeader>Frequência</ModalHeader>
              <Box
                display="flex"
                flexDirection="row"
                alignItems="center"
                justifyContent="center"
                flexWrap="wrap"
                gap={4}
                mb={23}
                minH="150px"
              >
                {disparosSelected?.map(item => (
                  <Box
                    bg="green.300"
                    p={2}
                    borderRadius={30}
                    color="gray.600"
                    fontWeight="extrabold"
                  >
                    {item.horario}
                  </Box>
                ))}
              </Box>
            </ModalBody>
          </ModalContent>
        </Box>
      </Modal>
    </Box>
  );
}
