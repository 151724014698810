import { apiQuestions } from '@/lib';
import { useQuery } from 'react-query';

interface useGetPatientQuestionResponsesProps {
  patientId?: string;
  quizId?: string;
}

const useGetPatientQuestionResponses = ({
  patientId,
  quizId,
}: useGetPatientQuestionResponsesProps) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['patient-question-responses', patientId, quizId],
    queryFn: async () => {
      const res = await apiQuestions.get(
        `questionario-cliente/questionarios-usuarios/${patientId}`,
        {
          params: {
            idQuestionario: quizId,
          },
        },
      );
      return res.data;
    },
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetPatientQuestionResponses;
