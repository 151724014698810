import { SimpleGrid, Button } from '@chakra-ui/react';

interface Props {
  onCancel: () => void;
  onSubmit: () => void;
  onClose?: () => void;
  isComplete?: Boolean;
}

export function ButtonsForm({
  onCancel,
  onSubmit,
  isComplete,
  onClose,
}: Props) {
  function teste() {
    onSubmit();
    if (onClose) {
      onClose();
    }
  }
  return (
    <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
      <Button
        type="button"
        onClick={onCancel}
        variant="outline"
        colorScheme="brand-orange"
        h="45px"
        borderRadius="28px"
        color="brand-orange.300"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Voltar
      </Button>

      <Button
        type="button"
        onClick={teste}
        colorScheme="brand-green"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        {isComplete === true ? 'Salvar' : 'Continuar'}
      </Button>
    </SimpleGrid>
  );
}
