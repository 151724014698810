import { Box, Skeleton } from '@chakra-ui/react';
import { useState } from 'react';

import { TopSection } from '../TopSection';
import { Prescription } from './Prescription';

export function PrescriptionSection() {
  const [globalFilter, setGlobalFilter] = useState<any>();
  const isLoading = false;

  return (
    <>
      <TopSection filter={globalFilter} setFilter={setGlobalFilter} />

      {isLoading ? (
        <Skeleton mt="8" w="100%" h="600px" border="20px" />
      ) : (
        <Box mt="8" w="100%">
          <Prescription />
        </Box>
      )}
    </>
  );
}
