import {
  Box,
  Divider,
  Flex,
  Heading,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { ScaleActions } from '@/features/schedule/components/SchedulerSection/ScaleActions';

import { useMemo, useState, useEffect } from 'react';
import { useScheduleQuery } from '@/features/schedule/hooks/useScheduleQuery';
import { formatDate } from '@/utils';
import { Patient } from '@/features/schedule';
import { Calendar } from '@/components';
import { UnlockScaleCard } from '@/components/Calendar/EventActions/UnlockScaleCard';
import { useUserStore } from '@/stores';

export function ScheduleSection({ setPatientSelect }: any) {
  const user = useUserStore(state => state.user);
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(23);
  const [times, setTimes] = useState<any>([]);
  const pageStart = true;
  const [openModal, setOpenModal] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: appointments, isLoading, refetch } = useScheduleQuery();

  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 2000);
  }, []);

  const today = new Date();
  const formattedToday = useMemo(
    () => formatDate(today, "dd 'de' MMMM 'de' yyyy"),
    [today],
  );

  useEffect(() => {
    if (appointments && appointments?.length > 0) {
      const orderTimes = appointments.sort(
        (a: any, b: any) => a.start - b.start,
      );
      const minTime = orderTimes[0].start;
      const maxTime = orderTimes[orderTimes.length - 1].end;

      setTimes(orderTimes);
      setMin(minTime.getHours());

      if (maxTime.toString() !== 'Invalid Date') {
        setMax(maxTime.getHours() + 2 > 13 ? 23 : maxTime.getHours() + 2);
      }
    } else {
      setTimes([]);
    }
  }, [appointments]);

  return isLoading ? (
    <Skeleton mt="10" w="100%" h="560px" borderRadius="20px" />
  ) : (
    <Box mt="10" p="10" w="100%" borderRadius="20px" bg="white">
      <Flex w="100%" align="center" justify="space-between">
        <Heading color="brand-dark.300" size="md" fontWeight="semibold">
          Agenda do Dia
        </Heading>
        <Box right="0">
          <ScaleActions pageStart="true" />
        </Box>
        <Text color="brand-dark.300" fontSize="xl" fontWeight="semibold">
          {formattedToday}
        </Text>
      </Flex>

      <Divider mt="4" mb="8" />

      <Box h="800px" maxW="100%" w="100%" overflowY="auto">
        <Calendar
          defaultView="day"
          events={appointments}
          toolbar={false}
          onSelectEvent={(slot: any, e: any) => {
            if (slot.cpf && user?.type === 'medico') {
              setPatientSelect(slot);
              // onOpen();
            }
            // } else if (slot.status === 4) {
            //   setSelectedPatient(slot);
            //   setOpenModal(true);
            // }
          }}
          scrollToTime={
            min > 0
              ? new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDay(),
                  min,
                )
              : new Date(today.getFullYear(), today.getMonth(), today.getDay())
          }
          min={new Date(today.getFullYear(), today.getMonth(), today.getDay())}
          max={
            new Date(today.getFullYear(), today.getMonth(), today.getDay(), max)
          }
          // onSelectEvent={(event: any) => {
          //   if (event.resource) {
          //     setSelectedPatient(event);
          //     onOpen();
          //   }
          // }}
        />
      </Box>

      {openModal && (
        <UnlockScaleCard
          onCancel={() => setOpenModal(false)}
          event={selectedPatient}
        />
      )}
      {/* <PatientInfoModal
        isOpen={isOpen}
        onClose={onClose}
        patient={selectedPatient}
      /> */}
    </Box>
  );
}
