import { Box } from '@chakra-ui/react';
import { Outlet } from 'react-router-dom';

import { TopHeader } from './Header';
import { Sidebar } from './Sidebar';

export function AppLayout() {
  return (
    <Box as="main">
      <Sidebar />

      <Box as="section" ml={[0, 0, '24']}>
        <TopHeader />
        <Box as="main" p="8">
          <Outlet />
        </Box>
      </Box>
    </Box>
  );
}
