import { Flex, Heading, Stack, Text } from '@chakra-ui/react';
import { PatientSearchInput } from './PatientSearchInput';

interface Props {
  globalFilter: string;
  onChange: any;
}

export function TopSection({ filter, setFilter }: any) {
  return (
    <div>
      <Flex
        direction={['column', 'column', 'row']}
        w="100%"
        align="center"
        justify="space-between"
      >
        <Heading
          size="md"
          w="100%"
          mb={['6', '4', '0']}
          color="brand-dark.200"
          fontFamily="Rubik"
        >
          <Text
            color="brand-dark.200"
            fontSize="lg"
            fontWeight="normal"
            fontFamily="Rubik"
          >
            PACIENTES
          </Text>
        </Heading>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          w="100%"
          justify="flex-end"
        >
          {/* <PatientSearchInput filter={filter} setFilter={setFilter} /> */}
        </Stack>
      </Flex>
    </div>
  );
}
