import { Steps } from 'antd';
import { styled } from 'styled-components';

export const CSteps = styled(Steps)`
  margin: 0 0 2rem;
  .ant-steps-icon {
    color: #ffff !important;
  }

  .ant-steps-item-process .ant-steps-item-icon {
    background: #7ab49b;
  }
  .ant-steps-item-finish .ant-steps-item-icon {
    background: #7ab49b;
  }
  .ant-steps-item-error .ant-steps-item-icon {
    background: #d71b1b;
    border-color: #d71b1b;
  }

  .ant-steps-item-error
    .ant-steps-item-container
    .ant-steps-item-content
    .ant-steps-item-title {
    color: #d71b1b;
  }

  .ant-steps-item-wait .ant-steps-item-icon {
    background: #cfcfcf;
  }
`;

interface StepIconProps {
  color: 'error' | 'process' | 'finish' | 'wait';
}

const colors = {
  error: '#D71B1B',
  process: '#7AB49B',
  finish: '#7AB49B',
  wait: '#CFCFCF',
};

export const StepIcon = styled.div<StepIconProps>`
  background: ${({ color }) => colors[color]};
  width: 2rem;
  height: 2rem;
  border-radius: 9999px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
