import { Box, Circle, Icon, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';
import {
  IoPersonAddOutline,
  IoPeopleOutline,
  IoPersonRemoveOutline,
  IoCalendarClearOutline,
  IoTimeOutline,
} from 'react-icons/io5';

import { ActivityTypes } from '@/features/start';
import { formatDate } from '@/utils';

interface ActivityCardProps {
  activity: {
    tipoAtividade: ActivityTypes;
    numeroPacientes: number;
    dataAtualizacao: Date;
  };
}

const icons: Record<ActivityTypes, IconType> = {
  consultasDia: IoPersonRemoveOutline,
  consultasSemana: IoPeopleOutline,
  consultasAtendidas: IoPersonAddOutline,
  consultasCanceladas: IoCalendarClearOutline,
  consultasNaoRealizadas: IoTimeOutline,
  consultasDisponibilizadas: IoTimeOutline,
  consultasAgendadas: IoTimeOutline,
  horariosVagos: IoTimeOutline,
  consultasRemarcadas: IoTimeOutline,
  mensagensNaoRespondidasDia: IoTimeOutline,
  mensagensNaoRespondidasSemana: IoTimeOutline,
  notificacoesNaoVistasDia: IoTimeOutline,
  notificacoesNaoVistasSemana: IoTimeOutline,
};

const titles: Record<ActivityTypes, string> = {
  consultasDia: 'Consultas do dia',
  consultasSemana: 'Consultas da semana',
  consultasAtendidas: 'Consultas não atendidas',
  consultasNaoRealizadas: 'Consultas não realizadas',
  consultasDisponibilizadas: 'Consultas disponibilizadas',
  consultasAgendadas: 'Consultas Agendadas',
  horariosVagos: 'Horários vagos',
  consultasRemarcadas: 'Consultas remarcadas',
  consultasCanceladas: 'Consultas canceladas',
  // consultasBloqueadas: 'Consultas bloqueadas',
  mensagensNaoRespondidasDia: 'Mensagens do dia',
  mensagensNaoRespondidasSemana: 'Mensagens da semana',
  notificacoesNaoVistasDia: 'Notificações do dia',
  notificacoesNaoVistasSemana: 'Notificações da semana',
};

export function ActivityCard({ activity }: ActivityCardProps) {
  const { dataAtualizacao, numeroPacientes, tipoAtividade } = activity;

  const formattedLastUpdateDate = formatDate(dataAtualizacao, "P 'às' HH:mm");

  return (
    <Box
      as="article"
      p="5"
      borderRadius="20px"
      bg={
        tipoAtividade === 'consultasAgendadas'
          ? 'brand-orange.50'
          : 'brand-green.50'
      }
      position="relative"
    >
      <Circle
        size="40px"
        bg="brand-dark.100"
        position="absolute"
        top="15px"
        right="15px"
      >
        <Icon
          as={icons[tipoAtividade]}
          fontSize="20px"
          color={
            tipoAtividade === 'consultasAgendadas'
              ? 'brand-orange.300'
              : 'brand-green.300'
          }
        />
      </Circle>
      <Text color="brand-dark.500" fontSize="3xl" fontWeight="medium">
        {numeroPacientes}
      </Text>
      <Text
        mt="3"
        mb="8"
        color="brand-dark.200"
        fontSize="xs"
        fontWeight="medium"
      >
        {titles[tipoAtividade]}
      </Text>
      <Text color="#bfbfbf" fontSize="xs" fontWeight="medium">
        Última atualização {formattedLastUpdateDate}
      </Text>
    </Box>
  );
}
