import { Box, BoxProps, Icon } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { IoEllipse } from 'react-icons/io5';

type CardItemProps = BoxProps & {
  active?: boolean;
  children: ReactNode;
  defaultValue: string;
};

export function CardItem({
  active = false,
  children,
  ...props
}: CardItemProps) {
  return (
    <Box {...props}>
      <Box
        cursor="pointer"
        fontSize="lg"
        fontWeight="500"
        border={active ? '1px solid #0005' : ''}
        bg={active ? ' #d4d4d4' : ''}
        fontFamily="Rubik"
        px={2}
        py={2}
        // my={10}
      >
        {children}
        <Icon
          as={IoEllipse}
          color="red"
          fontSize="30px"
          position="absolute"
          left="20%"
        />
      </Box>
    </Box>
  );
}
