import * as yup from 'yup';

import { isValidDate, isValidNumber } from '@/utils';
import { useState } from 'react';

export const registerSecretarySchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  username: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  cpf: yup.string().length(11, 'CPF inválido').required('Campo obrigatório'),
  telefone: yup
    .string()
    .min(10, 'Telefone inválido')
    .required('Campo obrigatório'),
  generoId: yup.string().required('Campo obrigatório'),
  dataNascimento: yup
    .string()
    .test('isValidDate', 'Data inválida', value => isValidDate(value))
    .required('Campo obrigatório'),
  cep: yup.string().length(8, 'CEP inválido').required('Campo obrigatório'),
  numero: yup.string(),
  endereco: yup.string().required('Campo obrigatório'),
  bairro: yup.string().required('Campo obrigatório'),
  cidade: yup.string().required('Campo obrigatório'),
  complemento: yup.string(),
  senha: yup
    .string()
    .min(6, 'Sua senha precisa ter ao menos 6 caracteres')
    .test(
      'has-numeric',
      'Sua senha precisa ter ao menos um caractere numérico',
      value => (value ? /\d/.test(value) : false),
    )
    .required('Campo obrigatório'),
});

export const registerPatientSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  rg: yup.string().length(9, 'RG inválido').required('Campo obrigatório'),
  cpf: yup.string().length(11, 'CPF inválido').required('Campo obrigatório'),
  telefone: yup
    .string()
    .min(10, 'Telefone inválido')
    .required('Campo obrigatório'),
  genero: yup.string().required('Campo obrigatório'),
  dataNascimento: yup
    .string()
    .test('isValidDate', 'Data inválida', value => isValidDate(value))
    .required('Campo obrigatório'),
  cep: yup.string().length(8, 'CEP inválido').required('Campo obrigatório'),
  numero: yup.string(),
  endereco: yup.string().required('Campo obrigatório'),
  bairro: yup.string().required('Campo obrigatório'),
  cidade: yup.string().required('Campo obrigatório'),
  estado: yup.string().required('Campo obrigatório'),
  complemento: yup.string(),
  senha: yup.string().required('Campo obrigatório'),
  userType: yup.string().required(),
  professionalId: yup.string().required('Campo obrigatório'),
});

export const registerProfessionalSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  rg: yup.string().length(9, 'RG inválido').required('Campo obrigatório'),
  cpf: yup.string().length(11, 'CPF inválido').required('Campo obrigatório'),
  telefone: yup
    .string()
    .min(10, 'Telefone inválido')
    .required('Campo obrigatório'),
  genero: yup.string().required('Campo obrigatório'),
  dataNascimento: yup
    .string()
    .test('isValidDate', 'Data inválida', value => isValidDate(value))
    .required('Campo obrigatório'),
  cep: yup.string().length(8, 'CEP inválido').required('Campo obrigatório'),
  numero: yup.string(),
  endereco: yup.string().required('Campo obrigatório'),
  bairro: yup.string().required('Campo obrigatório'),
  cidade: yup.string().required('Campo obrigatório'),
  complemento: yup.string(),
  conselho: yup.string().required('Campo obrigatório'),
  numeroConselho: yup
    .string()
    .test('isValidCouncilNumber', 'Número inválido', value =>
      isValidNumber(value),
    )
    .required('Campo obrigatório'),
  estado: yup.string().required('Campo obrigatório'),
  ufConselho: yup.string().required('Campo obrigatório'),
  senha: yup
    .string()
    .min(6, 'Sua senha precisa ter ao menos 6 caracteres')
    .required('Campo obrigatório'),
});

export const registerGestorSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),

  cpf: yup.string().length(11, 'CPF inválido').required('Campo obrigatório'),
  genero: yup.string().required('Campo obrigatório'),
  dataNascimento: yup
    .string()
    .test('isValidDate', 'Data inválida', value => isValidDate(value))
    .required('Campo obrigatório'),

  // senha: yup
  //   .string()
  //   .min(8, 'Sua senha precisa ter ao menos 8 caracteres')
  //   .test(
  //     'has-numeric',
  //     'Sua senha precisa ter ao menos um caractere numérico',
  //     value => (value ? /\d/.test(value) : false),
  //   )
  //   .test(
  //     'has-numeric',
  //     'Sua senha precisa ter ao menos pelo menos um caracter especial ($,#,@,!,etc)',
  //     value => (value ? /[$#@!]/.test(value) : false),
  //   )
  //   .required('Campo obrigatório'),
  cep: yup.string().length(8, 'CEP inválido').required('Campo obrigatório'),
  numero: yup.string(),
  endereco: yup.string().required('Campo obrigatório'),
  estado: yup.string().required('Campo obrigatório'),
  cidade: yup.string().required('Campo obrigatório'),
  bairro: yup.string().required('Campo obrigatório'),
  complemento: yup.string(),
  userType: yup.string().required('Campo obrigatório'),
  instanciaId: yup.string().required('Campo obrigatório'),
  // unidadeId: yup.string().required('Campo obrigatório'),

  // conselhoId: yup.string().required('Campo obrigatório'),
  // numeroConselho: yup
  //   .string()
  //   .test('isValidCouncilNumber', 'Número inválido', value =>
  //     isValidNumber(value),
  //   )
  //   .required('Campo obrigatório'),
  // ufConselho: yup.string().required('Campo obrigatório'),
});

export const registerUserSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  celular: yup.string().required('Campo obrigatório'),
  userType: yup.string().required('Campo obrigatório'),
  instanciaId: yup.string().required('Campo obrigatório'),
  // unidadeId: yup.string().required('Campo obrigatório'),

  // conselhoId: yup.string().required('Campo obrigatório'),
  // numeroConselho: yup
  //   .string()
  //   .test('isValidCouncilNumber', 'Número inválido', value =>
  //     isValidNumber(value),
  //   )
  //   .required('Campo obrigatório'),
  // ufConselho: yup.string().required('Campo obrigatório'),
});

export const editPatientSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  cpf: yup.string().length(11, 'CPF inválido').required('Campo obrigatório'),
  sexoId: yup.string().required('Campo obrigatório'),
  dataNascimento: yup
    .string()
    .test('isValidDate', 'Data inválida', value => isValidDate(value))
    .required('Campo obrigatório'),
  cep: yup.string().length(8, 'CEP inválido').required('Campo obrigatório'),
  numero: yup.string(),
  endereco: yup.string().required('Campo obrigatório'),
  estado: yup.string().required('Campo obrigatório'),
  cidade: yup.string().required('Campo obrigatório'),
  bairro: yup.string().required('Campo obrigatório'),
  complemento: yup.string(),
  celular: yup
    .string()
    .min(10, 'Celular inválido')
    .required('Campo obrigatório'),
});

export const editAssistentSchema = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  username: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  cpf: yup.string().length(11, 'CPF inválido').required('Campo obrigatório'),
  generoId: yup.string().required('Campo obrigatório'),
  dataNascimento: yup
    .string()
    .test('isValidDate', 'Data inválida', value => isValidDate(value))
    .required('Campo obrigatório'),
  cep: yup.string().length(8, 'CEP inválido').required('Campo obrigatório'),
  numero: yup.string(),
  endereco: yup.string().required('Campo obrigatório'),
  estado: yup.string().required('Campo obrigatório'),
  cidade: yup.string().required('Campo obrigatório'),
  bairro: yup.string().required('Campo obrigatório'),
  complemento: yup.string(),
  celular: yup
    .string()
    .min(10, 'Celular inválido')
    .required('Campo obrigatório'),
});

export const editProfessional = yup.object().shape({
  nome: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  cpf: yup.string().min(11, 'CPF inválido').required('Campo obrigatório'),
  generoId: yup.string().required('Campo obrigatório'),
  dataNascimento: yup
    .string()
    .test('isValidDate', 'Data inválida', value => isValidDate(value))
    .required('Campo obrigatório'),
  cep: yup.string().length(8, 'CEP inválido').required('Campo obrigatório'),
  numero: yup.string(),
  endereco: yup.string().required('Campo obrigatório'),
  estado: yup.string().required('Campo obrigatório'),
  cidade: yup.string().required('Campo obrigatório'),
  bairro: yup.string().required('Campo obrigatório'),
  complemento: yup.string(),
  celular: yup
    .string()
    .min(10, 'Celular inválido')
    .required('Campo obrigatório'),
});
