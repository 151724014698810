import React, { useContext } from 'react';

export const ViewContext = React.createContext({
  view: 'week',
  setView: (data: any) => {},
});

export function useViewContext() {
  return useContext(ViewContext);
}
