import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { endOfDay, format, parse, parseISO } from 'date-fns';
import { useMutation } from 'react-query';

import { useUserStore, useProfessionalStore } from '@/stores';
import { api } from '@/lib';
import { UserTypes } from '@/types';
import moment from 'moment';

type AddScale = {
  data: string;
  horarioInicio: string;
  horarioFim: string;
  observacao: string;
  idSecretaria?: string;
  idUnidadeSaude?: string;
};

async function postAddScale(
  payload: AddScale,
  userId?: string,
  userType?: UserTypes,
  idSecretaria?: string,
): Promise<any> {
  const [scheduleHour, scheduleMinute] = payload.horarioInicio.split(':');
  const [fimHour, fimMinute] = payload.horarioFim.split(':');
  const startHour =
    parseInt(scheduleHour, 10) * 60 + parseInt(scheduleMinute, 10);
  const endHour = parseInt(fimHour, 10) * 60 + parseInt(fimMinute, 10);

  const hora =
    Math.floor((endHour - startHour) / 60) < 0
      ? Math.floor((endHour - startHour) / 60) * -1
      : Math.floor((endHour - startHour) / 60);
  const minuto =
    Math.floor((endHour - startHour) % 60) < 0
      ? Math.floor((endHour - startHour) % 60) * -1
      : Math.floor((endHour - startHour) % 60);

  const intervaloH = hora < 10 ? `0${hora.toString()}` : `${hora.toString()}`;
  const intervaloM =
    minuto < 10 ? `0${minuto.toString()}` : `${minuto.toString()}`;

  const formattedPayload = {
    ...payload,
    intervalo: `${intervaloH}:${intervaloM}`,
    idUsuario: userId,
    data: format(parseISO(payload.data), 'MM/dd/yyyy'),
    idSecretaria: idSecretaria || undefined,
    // unidadeId: mock add unidade
  };

  const mutation = await api.post('/agenda', formattedPayload);
  return mutation;
}

export function useAddScaleMutation() {
  const user = useUserStore(state => state.user);
  const pro = useProfessionalStore(state => state.professional);
  let id = user?.id;

  if (user && user.type !== 'medico') {
    id = pro?.idMedico;
  }

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const mutation = useMutation(
    (payload: AddScale) =>
      postAddScale(payload, id, user?.type, payload.idSecretaria),
    {
      onSuccess: response => {
        const { data } = response.data.data;
      },
      onError: error => {
        if (axios.isAxiosError(error)) {
          const { response } = error;

          if (response?.data && response.data.errors[0]) {
            toast({
              title: response.data.errors[0],
              status: 'error',
            });
          }
        }
      },
    },
  );

  return mutation;
}
