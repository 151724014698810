import { Button, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

export function ErrorToken() {
  const navigate = useNavigate();

  return (
    <Flex
      w="100%"
      h="100vh"
      bg="#faf3f3"
      direction="column"
      align="center"
      justify="center"
    >
      <Heading textTransform="uppercase">Erro no token!</Heading>
      <Text mt="6px">
        O token pode ter expirado, solicite um novo e-mail para recuperação de
        senha.
      </Text>
      <Button
        type="button"
        onClick={() => navigate('/auth/password')}
        mt="24px"
        borderRadius="6"
        bg="brand-orange.400"
        color="white"
        fontFamily="Inter"
        fontSize="md"
        fontWeight="normal"
        boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
        _hover={{
          background: 'brand-orange.300',
        }}
      >
        Tentar novamente
      </Button>
    </Flex>
  );
}
