export function useSetInterval(
  horaInicio: string,
  horaFim: string,
  novoHorario: string,
) {
  const [scheduleHour, scheduleMinute] = horaInicio.split(':');
  const [fimHour, fimMinute] = horaFim.split(':');
  const [novoHour, novoMinute] = novoHorario.split(':');
  const startHour =
    parseInt(scheduleHour, 10) * 60 + parseInt(scheduleMinute, 10);
  const endHour = parseInt(fimHour, 10) * 60 + parseInt(fimMinute, 10);
  const newHour = parseInt(novoHour, 10) * 60 + parseInt(novoMinute, 10);

  const teste = newHour + (endHour - startHour);

  const hora =
    Math.floor(teste / 60) < 0
      ? Math.floor(teste / 60) * -1
      : Math.floor(teste / 60);
  const minuto =
    Math.floor(teste % 60) < 0
      ? Math.floor(teste % 60) * -1
      : Math.floor(teste % 60);

  const intervaloH = hora < 10 ? `0${hora.toString()}` : `${hora.toString()}`;
  const intervaloM =
    minuto < 10 ? `0${minuto.toString()}` : `${minuto.toString()}`;

  const data = `${intervaloH}:${intervaloM}`;

  return data;
}
