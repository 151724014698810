import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import registerConfirmationImg from '@/assets/register_confirmed.svg';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MaskedInput, SelectInput, TextInput } from '@/components';
import { useUserStore } from '@/stores';
import {
  useInstanceQuery,
  useTypeInstanceQuery,
  useUnidadesQuery,
} from '@/hooks';

import { api, getCep } from '@/lib';
import { Councils, Unidade, Expertise } from '@/features/registers';
import { useBrazilCitiesQuery, useBrazilStatesQuery } from '../hooks';
import { registerSchema } from '../utils';
import { useUpdadeContext } from '../hooks/useUpdateContext';

type RegisterInstanceFormFields = {
  registerType: string;
  typeInstance: string;
  instance: string;
  descricao: string;
  cnpj: string;
  instanciaId: string;
  cep: string;
  numero: string;
  endereco: string;
  bairro: string;
  cidade: string;
  estado: string;
  complemento: string;
};

interface RegisterInstanceFormProps {
  onCancel: () => void;
}

export function RegisterInstanceForm({ onCancel }: RegisterInstanceFormProps) {
  const { updateUnidades, setUpUnidades } = useUpdadeContext();
  const [formStep, setFormStep] = useState(0);
  const [registerType, setRegisterType] = useState<any>('');
  const [userType, setUserType] = useState<any>('');
  const [unidade, setUnidades] = useState<Unidade[]>([]);
  const [unidadesId, setUnidadesId] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [update, setUpdade] = useState<boolean>(true);

  const [path, setPath] = useState<string>('');

  const user = useUserStore(state => state.user);

  const {
    control,
    formState: { errors },
    register,
    watch,
    trigger,
    setValue,
    getValues,
    handleSubmit,
  } = useForm<RegisterInstanceFormFields>({
    defaultValues: {
      registerType: '',
      typeInstance: '',
      instance: '',
      descricao: '',
      instanciaId: '',
      cnpj: '',
      cep: '',
      numero: '',
      endereco: '',
      estado: '',
      cidade: '',
      bairro: '',
      complemento: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(registerSchema),
  });

  const { data: typeIntance = [] } = useTypeInstanceQuery();
  const { refetch: refreshInstance } = useInstanceQuery();
  const { data: allInstances = [], refetch: refreshAllInstance } =
    useInstanceQuery();
  const { refetch: refreshUnidades } = useUnidadesQuery();

  const { data: states = [] } = useBrazilStatesQuery();
  const { data: cities = [] } = useBrazilCitiesQuery(watch('estado'));

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  function handleStepBack() {
    if (formStep === 0) {
      onCancel();
    } else {
      setFormStep(currStep => currStep - 1);
    }
  }

  async function handleStepCompletion() {
    let isFormValid = false;

    if (formStep === 0 && registerType === 'instancia') {
      const [CNPJ] = getValues(['cnpj']);
      if (!CNPJ) {
        toast({
          title: 'Preencha todos os campos obrigatórios!',
          status: 'error',
        });
        isFormValid = false;
      } else {
        isFormValid = await trigger([
          'registerType',
          'typeInstance',
          'descricao',
          'cnpj',
        ]);
      }
    }

    if (formStep === 0 && registerType === 'unidade') {
      isFormValid = await trigger(['registerType', 'instanciaId', 'descricao']);
    }

    if (isFormValid) {
      setFormStep(currStep => currStep + 1);
    }
  }

  async function postInstance(params: any) {
    setLoading(true);
    setPath('/app/instanciasList');
    const result = await api
      .post(
        '/instancia/instancia',
        {},
        {
          params,
        },
      )
      .then(() => {
        refreshInstance();
        refreshAllInstance();
        setFormStep(2);
        setLoading(false);
      })
      .catch(error => {
        const { errors: responseErrors } = error.response.data;

        responseErrors?.map((errorText: string | null | undefined) =>
          toast({
            title: errorText || 'Erro ao cadastrar instância, tente novamente!',
            status: 'error',
          }),
        );
        setLoading(false);
      });

    return result;
  }

  async function postUnidade(params: any) {
    setLoading(true);
    setUpUnidades(true);
    setPath('/app/unidadesList');
    const result = await api
      .post(
        '/instancia/unidade',
        {},
        {
          params,
        },
      )
      .then(() => {
        refreshUnidades();
        setFormStep(2);
        setUpUnidades(false);
        setLoading(false);
      })
      .catch(error => {
        const { errors: responseErrors } = error.response.data;

        responseErrors?.map((errorText: string | null | undefined) =>
          toast({
            title: errorText || 'Erro ao cadastrar unidade, tente novamente!',
            status: 'error',
          }),
        );
        setLoading(false);
      });

    return result;
  }

  const handleChangeRegisterType = useCallback((e: string) => {
    setRegisterType(e);
    setValue('registerType', e);
  }, []);

  function getRegisters() {
    if (user?.type === 'admin') {
      return [
        {
          label: 'Instância',
          value: 'Instancia',
        },
        {
          label: 'Unidade',
          value: 'Unidade',
        },
      ];
    }

    if (user?.type === 'master') {
      return [
        {
          label: 'Unidade',
          value: 'Unidade',
        },
      ];
    }

    return [];
  }

  async function checkCEP(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.value) return;

    const cep = e.target.value.replace(/\D/g, '');

    if (cep.length < 8) return;

    const address = await getCep(cep);

    setValue('endereco', address?.data.logradouro);
    setValue('bairro', address?.data.bairro);
    setValue('complemento', address?.data.complemento);
    setValue('estado', address?.data.uf);

    function setAddress() {
      setValue('cidade', address?.data.localidade);
    }

    setTimeout(setAddress, 1000);
  }

  useEffect(() => {
    if (user?.type !== 'admin') {
      setRegisterType('unidade');
      setValue('registerType', 'unidade');
    }
  }, []);

  async function onSubmit(values: RegisterInstanceFormFields) {
    const payload = {
      Descricao: values.descricao,
      CNPJ: values.cnpj,
      'Endereco.Cep': values.cep,
      'Endereco.Numero': values.numero,
      'Endereco.Logradouro': values.endereco,
      'Endereco.Estado': values.estado,
      'Endereco.Cidade': values.cidade,
      'Endereco.Bairro': values.bairro,
      'Endereco.Complemento': values.complemento,
    };

    if (registerType === 'instancia') {
      const [type] = getValues(['typeInstance']);

      await postInstance({ ...payload, TipoInstanciaSaudeId: type });
    } else if (registerType === 'unidade') {
      const [instance] = getValues(['instance']);

      await postUnidade({ ...payload, InstanciaSaudeId: instance });
    }
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      {formStep === 0 && (
        <VStack spacing="4">
          {user?.type === 'admin' ? (
            <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
              <SelectInput
                options={getRegisters()}
                placeholder="Selecione o tipo de cadastro"
                label=""
                error={errors.registerType}
                onChange={e =>
                  handleChangeRegisterType(e.target.value.toLowerCase())
                }
              />
            </SimpleGrid>
          ) : (
            <TextInput
              value="Nova Unidade de Saúde"
              label=""
              isDisabled
              {...register('instanciaId')}
            />
          )}

          {registerType === 'instancia' && (
            <>
              <SelectInput
                label="Tipo de Instância"
                options={typeIntance}
                placeholder="Selecione o Tipo de Instância"
                error={errors.typeInstance}
                {...register('typeInstance')}
              />

              <TextInput
                placeholder="Ex: Hospital Geral"
                label="Descrição"
                error={errors.descricao}
                {...register('descricao')}
              />

              <MaskedInput
                name="cnpj"
                label="CNPJ"
                control={control}
                mask="cnpj"
                error={errors.cnpj}
                placeholder="Ex: 12.345.678.0000-00"
              />
            </>
          )}

          {registerType === 'unidade' && (
            <>
              <SelectInput
                label="Instância de Saúde"
                options={allInstances}
                placeholder="Selecione a Instância de Saúde"
                error={errors.instance}
                {...register('instance')}
              />

              <TextInput
                placeholder="Ex: Hospital Geral 1"
                label="Descrição"
                error={errors.descricao}
                {...register('descricao')}
              />

              <MaskedInput
                name="cnpj"
                label="CNPJ"
                control={control}
                mask="cnpj"
                error={errors.cnpj}
                placeholder="Ex: 12.345.678.0000-00"
              />
            </>
          )}

          {(registerType === 'instancia' || registerType === 'unidade') && (
            <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
              <Button
                isDisabled={loading}
                type="button"
                onClick={handleStepBack}
                variant="outline"
                colorScheme="brand-orange"
                h="45px"
                borderRadius="28px"
                color="brand-orange.300"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
              >
                Voltar
              </Button>

              {registerType === 'instancia' || registerType === 'unidade' ? (
                <Button
                  isLoading={loading}
                  type="button"
                  onClick={handleStepCompletion}
                  colorScheme="brand-green"
                  h="45px"
                  borderRadius="28px"
                  color="white"
                  fontSize="xs"
                  fontWeight="medium"
                  textTransform="uppercase"
                >
                  Cadastrar
                </Button>
              ) : (
                <Button
                  isLoading={loading}
                  type="button"
                  onClick={handleStepCompletion}
                  colorScheme="brand-green"
                  h="45px"
                  borderRadius="28px"
                  color="white"
                  fontSize="xs"
                  fontWeight="medium"
                  textTransform="uppercase"
                >
                  Continuar
                </Button>
              )}
            </SimpleGrid>
          )}
        </VStack>
      )}
      {formStep === 1 && (
        <VStack spacing="4">
          {(registerType === 'instancia' || registerType === 'unidade') && (
            <>
              <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
                <MaskedInput
                  name="cep"
                  label="CEP"
                  control={control}
                  mask="zipCode"
                  error={errors.cep}
                  placeholder="Ex: 12345-678"
                  onBlur={checkCEP}
                  onChange={checkCEP}
                />

                <TextInput
                  placeholder="Ex: 123"
                  label="Número"
                  error={errors.numero}
                  {...register('numero')}
                />
              </SimpleGrid>

              <TextInput
                placeholder="Ex: Rua José Tavares"
                label="Endereço"
                error={errors.endereco}
                {...register('endereco')}
              />

              <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
                <SelectInput
                  options={states}
                  placeholder="Escolher estado"
                  label="Estado"
                  error={errors.estado}
                  {...register('estado')}
                />

                <SelectInput
                  options={cities}
                  placeholder="Escolher cidade"
                  label="Cidade"
                  error={errors.cidade}
                  {...register('cidade')}
                  defaultValue="São Sebastião"
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
                <TextInput
                  placeholder="Ex: Bairro Nova Era"
                  label="Bairro"
                  error={errors.bairro}
                  {...register('bairro')}
                />

                <TextInput
                  placeholder="Ex: Próximo ao mercado"
                  label="Complemento (opcional)"
                  error={errors.complemento}
                  {...register('complemento')}
                />
              </SimpleGrid>

              <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
                <Button
                  isDisabled={loading}
                  type="button"
                  onClick={handleStepBack}
                  variant="outline"
                  colorScheme="brand-orange"
                  h="45px"
                  borderRadius="28px"
                  color="brand-orange.300"
                  fontSize="xs"
                  fontWeight="medium"
                  textTransform="uppercase"
                >
                  Voltar
                </Button>

                {registerType === 'instancia' || registerType === 'unidade' ? (
                  <Button
                    isLoading={loading}
                    type="submit"
                    colorScheme="brand-green"
                    h="45px"
                    borderRadius="28px"
                    color="white"
                    fontSize="xs"
                    fontWeight="medium"
                    textTransform="uppercase"
                  >
                    Cadastrar
                  </Button>
                ) : (
                  <Button
                    isLoading={loading}
                    type="button"
                    onClick={handleStepCompletion}
                    colorScheme="brand-green"
                    h="45px"
                    borderRadius="28px"
                    color="white"
                    fontSize="xs"
                    fontWeight="medium"
                    textTransform="uppercase"
                  >
                    Continuar
                  </Button>
                )}
              </SimpleGrid>
            </>
          )}
        </VStack>
      )}
      {formStep === 2 && (
        <Flex w="100%" direction="column" align="center">
          <Heading
            color="brand-dark.300"
            fontSize="md"
            fontWeight="medium"
            textAlign="center"
          >
            Cadastro Realizado com Sucesso!
          </Heading>

          <Image
            mt="8"
            mb="4"
            src={registerConfirmationImg}
            alt="Mulher confirmando o sucesso de uma operação em um celular grande"
          />

          <Text
            color="brand-dark.200"
            fontSize="sm"
            fontWeight="normal"
            textAlign="center"
          >
            Você pode editar as configurações na aba de cadastros.
          </Text>

          <Link
            to={path}
            style={{ color: 'inherit', textDecoration: 'none' }}
            replace
          >
            <Button
              type="button"
              onClick={onCancel}
              colorScheme="brand-green"
              mt="10"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Finalizar
            </Button>
          </Link>
        </Flex>
      )}
    </Box>
  );
}
