import * as yup from 'yup';

import { isValidDate, isValidNumber } from '@/utils';

export const StepOneSchema = yup.object().shape({
  userName: yup.string().required('Campo obrigatório'),
});

export const StepTwoSchema = yup.object().shape({
  Nome: yup.string().required('Campo obrigatório'),
  Cpf: yup.string().required('Campo obrigatório'),
  Telefone: yup.string().required('Campo obrigatório'),
  DataNascimento: yup.string().required('Campo obrigatório'),
});

export const passwordSchema = yup.object().shape({
  password: yup.string().required('Campo obrigatório'),
});

export const registerSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  email: yup.string().email('E-mail inválido').required('Campo obrigatório'),
  birthDate: yup
    .string()
    .test('isValidDate', 'Data inválida', value => isValidDate(value))
    .required('Campo obrigatório'),
  cpf: yup.string().length(11, 'CPF inválido').required('Campo obrigatório'),
  gender: yup.string().required('Campo obrigatório'),
  password: yup.string().required('Campo obrigatório'),
  confirmPassword: yup
    .string()
    .oneOf([null, yup.ref('password')], 'As senhas precisam ser iguais'),
  council: yup.string().required('Campo obrigatório'),
  councilNumber: yup
    .string()
    .test('isValidCouncilNumber', 'Número inválido', value =>
      isValidNumber(value),
    )
    .required('Campo obrigatório'),
  state: yup.string().required('Campo obrigatório'),
  expertise: yup.string().required('Campo obrigatório'),
  rqe: yup
    .string()
    .test('isValidRqe', 'RQE inválido', value => isValidNumber(value))
    .required('Campo obrigatório'),
});

export const RecoveryPassSchema = yup.object().shape({
  email: yup.string().required('Campo obrigatório'),
});

export const newPasswordSchema = yup.object().shape({
  password: yup.string().required('Campo obrigatório'),
  confirmPassword: yup
    .string()
    .oneOf([null, yup.ref('password')], 'As senhas precisam ser iguais'),
});
