import { Flex, Heading, Stack } from '@chakra-ui/react';
import { PatientSearchInput } from './PatientSearchInput';

interface Props {
  globalFilter: string;
  onChange: any;
}

export function TopSection({ filter, setFilter }: any) {
  return (
    <Flex
      direction={['column', 'column', 'row']}
      w="100%"
      align="center"
      justify="space-between"
    >
      <Heading
        size="lg"
        w="100%"
        mb={['4', '4', '0']}
        color="brand-dark.500"
        fontWeight="medium"
      >
        Tela de Prescrição
      </Heading>
      {/* <Stack
        direction={['column', 'row']}
        spacing="4"
        w="100%"
        justify="flex-end"
      >
        <PatientSearchInput filter={filter} setFilter={setFilter} />
      </Stack> */}
    </Flex>
  );
}
