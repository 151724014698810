import { Text, TextProps } from '@chakra-ui/react';
import { useMemo } from 'react';

export function AppVersion(props: TextProps) {
  const currentYear = useMemo(() => new Date().getFullYear(), []);

  return (
    <Text fontSize="sm" color="gray.500" textAlign="center" {...props}>
      {currentYear} - Versão 1.0.6.V
    </Text>
  );
}
