import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUserStore } from '@/stores';
import { api } from '@/lib';
import confirmationImg from '@/assets/register_confirmed.svg';
import { TextInput } from '@/components';
import { useNotificationsTableQuery } from '../../hooks';

export function ResponseMessageModal({ onCancel, notification }: any) {
  const user = useUserStore(state => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });
  const { refetch } = useNotificationsTableQuery();

  const [wasFormSubmittedWithSuccess, setWasFormSubmittedWithSuccess] =
    useState(false);
  const { handleSubmit } = useForm();

  async function onSubmit() {
    if (!message) {
      toast({
        title: 'Escreva uma mensagem!',
        status: 'error',
      });
      return;
    }
    setIsLoading(true);
    const params = {
      tipoLocalOrientacao: 2,
      profissionalId: notification?.idProfissional,
      pacienteId: notification?.idPaciente,
      descricao: message,
    };
    try {
      await api.post(`/CondutaOrientacao`, params);
      await api.put(`/alerta/web?status=true&id=${notification?.id}`);

      refetch();
      setWasFormSubmittedWithSuccess(true);
    } catch (error: any) {
      toast({
        title: error.response.data.errors[0] || 'Ocorreu um erro!',
        status: 'error',
      });
      setWasFormSubmittedWithSuccess(false);
    } finally {
      setIsLoading(false);
    }
  }

  return wasFormSubmittedWithSuccess ? (
    <Flex w="100%" direction="column" align="center">
      <Heading
        mt="6"
        color="brand-dark.300"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
      >
        Mensagem enviada ao paciente
      </Heading>

      <Image
        mt="8"
        mb="4"
        src={confirmationImg}
        alt="Mulher confirmando o sucesso de uma operação em um celular grande"
      />

      <Button
        type="button"
        onClick={onCancel}
        colorScheme="brand-green"
        mt="10"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Fechar
      </Button>
    </Flex>
  ) : (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      textAlign="center"
    >
      <Heading mt="6" mb="4" color="#039c00" fontSize="xl" fontWeight="medium">
        Responder mensagem ao paciente
      </Heading>
      <Box>
        <Text color="brand-dark.300" fontSize="xl" fontWeight="medium">
          {`"${notification.descricao}"`}
        </Text>
      </Box>

      <VStack spacing="4" w="100%">
        <SimpleGrid
          minChildWidth="150px"
          spacing="4"
          w="100%"
          paddingTop="30px"
        >
          <TextInput
            placeholder=""
            label="Mensagem"
            onChange={text => setMessage(text.target.value)}
            value={message}
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          <Button
            disabled={isLoading}
            type="button"
            onClick={onCancel}
            variant="outline"
            colorScheme="red"
            h="45px"
            borderRadius="28px"
            color="red"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Voltar
          </Button>

          <Button
            isLoading={isLoading}
            type="submit"
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Enviar
          </Button>
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
