import create from 'zustand';

type ScheduleDateStore = {
  referenceDate: string;
  refresh: number;
  setReferenceDate: (referenceDate: string) => void;
  setRefresh: () => void;
};

export const useScheduleDateStore = create<ScheduleDateStore>(set => ({
  referenceDate: new Date().toISOString(),
  refresh: 0,
  setReferenceDate: (referenceDate: string) =>
    set(state => ({ ...state, referenceDate })),
  setRefresh: () => set(state => ({ ...state, refresh: state.refresh + 1 })),
}));
