import { api } from '@/lib';
import { useQuery } from 'react-query';
import { useUserStore } from '@/stores';

const useGetChatId = () => {
  const user = useUserStore(state => state.user);
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['chat', user!.id],
    queryFn: async () => {
      await api.post('/ai/chat', {
        usuarioId: user!.id,
      });
      const { data: res } = await api.get(`ai/chat`, {
        params: {
          usuarioId: user!.id,
        },
      });
      return res.data[0].id;
    },
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetChatId;
