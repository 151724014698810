import { Icon, Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { useState } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { useAsyncDebounce } from 'react-table';

export function PatientSearchInput({ filter, setFilter }: any) {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce(v => {
    setFilter(v || undefined);
  });
  return (
    <InputGroup maxW={['100%', '390px']} w="100%">
      <InputLeftElement h="100%" pointerEvents="none">
        <Icon as={IoSearchOutline} fontSize="xl" color="#bfbfbf" />
      </InputLeftElement>
      <Input
        size="lg"
        variant="filled"
        placeholder="Buscar dados"
        h="52px"
        borderRadius="14px"
        bg="white"
        color="brand-dark.500"
        _placeholder={{ color: '#bfbfbf' }}
        _hover={{ bg: 'brand-dark.100' }}
        onChange={e => {
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      />
    </InputGroup>
  );
}
