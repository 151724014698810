import React, { useCallback, useEffect, useState } from 'react';
import {
  VStack,
  SimpleGrid,
  Box,
  Button,
  HStack,
  IconButton,
  useToast,
  Icon,
} from '@chakra-ui/react';
import { IoAdd } from 'react-icons/io5';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { MaskedInput, SelectInput, TextInput } from '@/components';
import { GENDER_OPTIONS } from '@/utils';
import {
  useCouncilsQuery,
  useGendersQuery,
  useSpecialtiesQuery,
} from '@/hooks';
import { useUserStore } from '@/stores';
import {
  editProfessional,
  ButtonsForm,
  Expertise,
  ProfessionalProps,
  Councils,
} from '@/features/registers';
import { api, getCep } from '@/lib';
import { useBrazilStatesQuery, useBrazilCitiesQuery } from '../../hooks';
import { defaultValues } from './helpers';
import { Expertises } from '../RegisterProfessionalForm/Expertises';
import { CouncilsList } from '../RegisterProfessionalForm/CouncilsList';
import { EditInstances } from '../EditInstances';

interface Props {
  professional: ProfessionalProps;
  onCancel: () => void;
}

interface Specialties {
  descricao: string;
  especialidadeId: string;
  rqe: string;
}

interface EditFieldsModal {
  bairro: string;
  celular: string;
  cep: string;
  cidade: string;
  complemento?: string;
  conselhoId: string;
  cpf: string;
  dataNascimento: string;
  email: string;
  endereco: string;
  estado: string;
  estadoConselho: string;
  generoId: string;
  nome: string;
  nomeConselho: string;
  numero: number;
  numeroConselho: string;
  usuarioProfissionalSaudeId: string;
  descricao: string;
  rqe: string;
  especialidades: Specialties[];
  conselhos: Councils[];
  username: string;
}

// interface Specialties {
//   especialidadeId: string;
//   especialidades: Specialties[];
// }

export function EditProfessionalForm({ onCancel, professional }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { data: genders = [] } = useGendersQuery();
  const [formStep, setFormStep] = useState(0);
  const { data: states = [] } = useBrazilStatesQuery();
  const { data: councils = [] } = useCouncilsQuery();
  const { data: specialties = [] } = useSpecialtiesQuery();
  const [rqeSelect, setRqeSelect] = useState<number>();
  const [specialtyId, setSpecialtyId] = useState<string>();
  const [specialtyIds, setSpecialtyIds] = useState<any[]>([]);
  const [specialtyMap, setSpecialtyMap] = useState<any>();
  const [expertises, setExpertises] = useState<Expertise[]>([]);

  const [conselhos, setConselhos] = useState<Councils[]>(
    professional.conselhos,
  );
  const [conselhosId, setConselhosId] = useState<string[]>([]);
  const [disableSpe, setDisable] = useState<boolean>(false);
  const [update, setUpdade] = useState<boolean>(true);
  const changeUpdate = () => {
    setUpdade(!update);
  };

  const user = useUserStore(state => state.user);

  const dateInit = new Date(professional.dataNascimento);

  const day =
    dateInit.getDate() <= 9
      ? `0${dateInit.getDate().toString()}`
      : dateInit.getDate();

  const month =
    dateInit.getMonth() === 0
      ? `01`
      : dateInit.getMonth() <= 9
      ? `0${dateInit.getMonth() + 1}`
      : dateInit.getMonth() + 1;

  const dateNasc = `${day}${month}${dateInit.getFullYear()}`;

  useEffect(() => {
    const ids = specialtyIds;
    professional.especialidades.map(especialidade => {
      const { id } = especialidade.especialidade;
      const { descricao } = especialidade.especialidade;
      const { rqe } = especialidade.especialidade;

      specialtyIds.push({ especialidadeId: id, descricao, rqe });
      expertises.push({ descricao, rqe });
      changeUpdate();
      return null;
    });
    setSpecialtyIds(ids);
  }, [professional]);

  const {
    control,
    formState,
    register,
    reset,
    handleSubmit,
    trigger,
    setValue,
    watch,
    getValues,
    setError,
  } = useForm<EditFieldsModal>({
    defaultValues: {
      nome: professional.nome || '',
      email: professional.email || '',
      cpf: professional.cpf || '',
      dataNascimento: dateNasc || '',
      cep: professional.cep || '',
      complemento: professional.complemento || '',
      estado: professional.estado || '',
      cidade: professional.cidade || '',
      endereco: professional.endereco || '',
      bairro: professional.bairro || '',
      numero: professional.numero,
      nomeConselho: professional.conselhoId || '',
      numeroConselho: '',
      estadoConselho: professional.estadoConselho || '',
      conselhoId: professional.conselhoId || '',
      descricao: professional.descricao || '',
      rqe: professional.rqe || '',
      generoId: professional.generoId || '',
      celular: professional.celular || '',
      especialidades: specialtyIds,
      conselhos: professional.conselhos || '',
      username: professional.userName || '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(editProfessional),
  });

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const { data: cities = [] } = useBrazilCitiesQuery(watch('estado'));

  const { errors } = formState;

  useEffect(() => {}, [conselhos]);

  async function onSubmit(values: any) {
    const dateFormated = `${values.dataNascimento.slice(
      2,
      4,
    )}-${values.dataNascimento.slice(0, 2)}-${values.dataNascimento.slice(
      4,
      9,
    )}`;

    const nConselho = councils?.filter(
      item => item.value === values.conselhoId,
    );

    const councill = conselhos.map(c => {
      return {
        conselhoId: c.conselhoId,
        nConselho: Number(c.nConselho),
        ufConselho: c.ufConselho,
      };
    });
    const newProfessional = {
      id: professional.id,
      usuarioProfissionalSaudeId: professional.usuarioProfissionalSaudeId,
      nome: values.nome,
      email: values.email,
      cpf: values.cpf,
      generoId: values.generoId,
      dataNascimento: new Date(dateFormated),
      celular: values.celular,
      cep: values.cep,
      numero: values.numero,
      endereco: values.endereco,
      complemento: values.complemento,
      bairro: values.bairro,
      cidade: values.cidade,
      estado: values.estado,
      userName: values.username,
      especialidades: specialtyIds,
      conselhos: councill,
    };
    try {
      setIsLoading(true);
      const result = await api.put(
        '/usuario/profissionalsaude-novo',
        newProfessional,
      );

      if (result?.statusText === 'OK') {
        toast({
          title: 'Profissional de saúde editado com sucesso!',
          status: 'success',
        });
        //  onCancel();
      }
    } catch (error) {
      toast({
        title: 'Ops algo deu errado!',
        status: 'error',
      });
    } finally {
      if (user?.type === 'admin' || user?.type === 'master') {
        setFormStep(3);
      } else {
        onCancel();
      }
      setIsLoading(false);
    }
  }

  function handleStepBack() {
    if (formStep === 0) {
      onCancel();
    } else {
      setFormStep(currStep => currStep - 1);
    }
  }

  async function stepCompletion() {
    let isFormValid = false;

    if (formStep === 0) {
      isFormValid = await trigger([
        'nome',
        'email',
        'cpf',
        'dataNascimento',
        'generoId',
        'celular',
      ]);

      const [username] = getValues(['username']);
      if (!username || username.length < 8 || username.length > 15) {
        setError('username', {
          type: 'manual',
          message: 'Username deve possuir de 8 a 15 caracteres',
        });
        return;
      }

      const regexSpecialChar = /[$#@!]/;
      const hasSpecialChar = regexSpecialChar.test(username);
      if (hasSpecialChar) {
        setError('username', {
          type: 'manual',
          message: 'Username inválido',
        });
        return;
      }

      const userExists = await api.get(
        `/usuario/Usuario-Por-Username?username=${username}`,
      );

      if (username !== professional.userName && userExists?.data.data) {
        setError('username', {
          type: 'manual',
          message: 'Username não está disponível',
        });
        return;
      }

      isFormValid = true;
    }

    if (formStep === 1) {
      isFormValid = await trigger([
        'cep',
        'endereco',
        'estado',
        'cidade',
        'rqe',
      ]);
    }

    if (isFormValid) {
      setFormStep(formStep + 1);
    }
  }

  async function checkCEP(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.value) return;

    const cep = e.target.value.replace(/\D/g, '');

    if (cep.length < 8) return;

    const address = await getCep(cep);

    setValue('endereco', address?.data.logradouro);
    setValue('bairro', address?.data.bairro);
    setValue('complemento', address?.data.complemento);
    setValue('estado', address?.data.uf);

    setTimeout(() => {
      setValue('cidade', address?.data.localidade);
    }, 1000);
  }

  useEffect(() => {
    if (specialties.length > 0) {
      const specialtiesMap = specialties.reduce(
        (obj, item) => ({
          ...obj,
          [item.value]: item,
        }),
        {},
      );
      setSpecialtyMap(specialtiesMap);
    }
  }, [specialties]);

  const handleChangeSpecialty = useCallback(
    (target: any) => {
      const id = target;
      setSpecialtyId(id);
      setRqeSelect(specialtyMap[id].rqe);
      setValue('rqe', specialtyMap[id].rqe);
      setValue('descricao', specialtyMap[id].label);
    },
    [specialtyMap],
  );

  function handleAddExpertise() {
    const [currExpertise, currRqe] = getValues(['descricao', 'rqe']);

    if (!currExpertise || !currRqe || !specialtyId) {
      toast({
        title: 'Preencha os campos corretamente!',
        status: 'error',
      });

      return;
    }

    if (specialtyIds.length === 3) {
      toast({
        title: 'Somente 3 especialidades podem ser adicionadas!',
        status: 'error',
      });

      return;
    }

    const found = specialtyIds?.find(
      item => item.especialidadeId === specialtyId,
    );
    if (found) {
      toast({
        title: 'Especialidade já adicionada!',
        status: 'error',
      });
      handleChangeSpecialty(specialtyId);
      return;
    }

    const ids = specialtyIds;
    ids.push({
      especialidadeId: specialtyId,
      descricao: currExpertise,
      rqe: currRqe,
    });
    setSpecialtyIds(ids);
    expertises.push({ descricao: currExpertise, rqe: currRqe });
    handleChangeSpecialty(specialtyId);
    changeUpdate();

    // setValue('descricao', '');
    // setValue('rqe', '');
  }

  useEffect(() => {
    let found2 = 0;

    conselhos?.map(item => {
      if (item.conselho.numero === '9' || item.numeroConselho === '9') {
        found2 += 1;
      }
      return null;
    });
    if (found2 === 1) {
      setDisable(false);
    } else {
      setDisable(true);
      specialtyIds?.map(item => specialtyIds.pop());
    }
  });

  useEffect(() => {
    setConselhos(conselhos);
    setSpecialtyIds(specialtyIds);
  });

  const handleChangeCouncils = useCallback(
    (target: any) => {
      const id = target;
      setConselhosId(id);
      setConselhos(conselhos);
      // setValue('numeroConselho', '');
      // setValue('estadoConselho', '');
    },
    [conselhos],
  );

  useEffect(() => {}, []);

  function handleAddCouncils() {
    const [id, uf, numero] = getValues([
      'conselhoId',
      'estadoConselho',
      'numeroConselho',
    ]);

    if (!id || !uf || !numero) {
      toast({
        title: 'Preencha os campos do conselho corretamente!',
        status: 'error',
      });

      return;
    }

    if (conselhos.length === 3) {
      toast({
        title: 'Somente 3 conselhos podem ser adicionados!',
        status: 'error',
      });

      return;
    }

    const found = conselhos?.find(item => item.conselhoId === id);
    if (found) {
      handleChangeCouncils(id);
      setValue('numeroConselho', numero);
      setValue('estadoConselho', uf);

      toast({
        title: 'Conselho já adicionado!',
        status: 'error',
      });
      return;
    }

    const nome = councils?.filter(item => item.value === id);
    const n = nome[0].label;
    const numeroConselho = nome[0].numero;

    const addConselho = conselhos;
    addConselho.push({
      profissionalSaudeId: professional.usuarioProfissionalSaudeId,
      conselhoId: id,
      nConselho: numero,
      numeroConselho,
      ufConselho: uf,
      conselho: {
        id,
        descricao: n,
        numero,
        nome: n,
        ativo: true,
      },
    });
    setConselhos(addConselho);
    handleChangeCouncils(id);

    changeUpdate();

    setValue('numeroConselho', numero);
    setValue('estadoConselho', uf);
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      {formStep === 0 && (
        <VStack spacing="4">
          <TextInput
            placeholder="Ex: Mariana Helzberg"
            label="Nome Completo"
            error={errors.nome}
            {...register('nome')}
          />

          <TextInput
            type="email"
            placeholder="Ex: mariana@exemplo.com"
            label="E-mail"
            error={errors.email}
            {...register('email')}
          />

          <SimpleGrid minChildWidth="150px" spacing="4" w="100%">
            <MaskedInput
              name="cpf"
              label="CPF"
              control={control}
              mask="cpf"
              error={errors.cpf}
              placeholder="Ex: 123.456.789-46"
            />
            <MaskedInput
              name="celular"
              label="Celular"
              control={control}
              mask="phone"
              error={errors.celular}
              placeholder="Ex: (12) 99999-9999"
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="150px" spacing="4" w="100%">
            <SelectInput
              options={genders}
              placeholder="Escolher gênero"
              label="Gênero"
              error={errors.generoId}
              {...register('generoId')}
            />

            <MaskedInput
              name="dataNascimento"
              label="Data de Nascimento"
              control={control}
              mask="date"
              error={errors.dataNascimento}
              placeholder="Ex: 01/01/1970"
            />
          </SimpleGrid>

          <TextInput
            placeholder="Ex: mariana.helzberg"
            label="Usuário"
            error={errors.username}
            {...register('username')}
          />

          <ButtonsForm onCancel={onCancel} onSubmit={stepCompletion} />
        </VStack>
      )}

      {formStep === 1 && (
        <VStack spacing="4">
          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <MaskedInput
              name="cep"
              label="CEP"
              control={control}
              mask="zipCode"
              error={errors.cep}
              placeholder="Ex: 12345-678"
              onBlur={checkCEP}
              onChange={checkCEP}
            />

            <SelectInput
              options={states}
              placeholder="Escolher estado"
              label="Estado"
              error={errors.estado}
              {...register('estado')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <SelectInput
              options={cities}
              placeholder="Escolher cidade"
              label="Cidade"
              error={errors.cidade}
              {...register('cidade')}
            />

            <TextInput
              placeholder="Ex: Bairro Nova Era"
              label="Bairro"
              error={errors.bairro}
              {...register('bairro')}
            />
          </SimpleGrid>

          <TextInput
            placeholder="Ex: Rua José Tavares"
            label="Endereço"
            error={errors.endereco}
            {...register('endereco')}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <TextInput
              placeholder="Ex: 123"
              label="Número"
              error={errors.numero}
              {...register('numero')}
            />
            <TextInput
              placeholder="Ex: Próximo ao mercado"
              label="Complemento (opcional)"
              error={errors.complemento}
              {...register('complemento')}
            />
          </SimpleGrid>

          <ButtonsForm onCancel={onCancel} onSubmit={stepCompletion} />
          {/* <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
          </SimpleGrid> */}
        </VStack>
      )}

      {formStep === 2 && (
        <VStack spacing="4">
          <SelectInput
            label="Conselho"
            options={councils}
            error={errors.conselhoId}
            placeholder="Conselho"
            {...register('conselhoId')}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <TextInput
              label="Número do Conselho"
              error={errors.numeroConselho}
              placeholder="Número do conselho"
              {...register('numeroConselho')}
            />

            <HStack spacing="2" align="flex-end">
              <SelectInput
                options={states}
                placeholder="Escolher estado"
                label="Estado"
                error={errors.estadoConselho}
                {...register('estadoConselho')}
              />
              <IconButton
                aria-label="Adicionar conselho"
                icon={<Icon as={IoAdd} color="white" fontSize="20px" />}
                onClick={handleAddCouncils}
                colorScheme="brand-green"
                w="52px"
                h="52px"
                borderRadius="14px"
              />
            </HStack>
          </SimpleGrid>

          <CouncilsList
            councils={conselhos}
            onDelete={(id: any) => {
              setConselhos(prevState =>
                prevState.filter(conselho => conselho.conselhoId !== id),
              );
            }}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <SelectInput
              id="especialidade"
              label="Especialidade"
              placeholder="Escolher especialidade"
              options={specialties}
              isDisabled={disableSpe}
              error={errors.descricao}
              onChange={({ target }) => handleChangeSpecialty(target.value)}
            />

            <HStack spacing="2" align="flex-end">
              <TextInput
                label="RQE"
                // value={rqeSelect}
                error={errors.rqe}
                isDisabled={disableSpe}
                placeholder="RQE"
                {...register('rqe')}
              />
              <IconButton
                aria-label="Adicionar especialidade"
                icon={<Icon as={IoAdd} color="white" fontSize="20px" />}
                onClick={handleAddExpertise}
                isDisabled={disableSpe}
                colorScheme="brand-green"
                w="52px"
                h="52px"
                borderRadius="14px"
              />
            </HStack>
          </SimpleGrid>

          <Expertises
            specialits={specialtyIds}
            onDelete={rqe => {
              setSpecialtyIds(prevState =>
                prevState.filter(expertise => expertise.rqe !== rqe),
              );
            }}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
            <Button
              type="button"
              onClick={handleStepBack}
              variant="outline"
              colorScheme="brand-orange"
              h="45px"
              borderRadius="28px"
              color="brand-orange.300"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Voltar
            </Button>

            <Button
              isLoading={isLoading}
              type="submit"
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Cadastrar
            </Button>
          </SimpleGrid>
        </VStack>
      )}

      {formStep === 3 && (
        <EditInstances type={1} id={professional.id} onCancel={onCancel} />
      )}
    </Box>
  );
}
