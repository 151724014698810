import { Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';
import { Unidade } from '../../types';

interface CouncilProps {
  unidades: Unidade[];
  onDelete: (id: string) => void;
}

export function UnidadeList({ unidades, onDelete }: CouncilProps) {
  return (
    <Flex w="100%" gap="2" wrap="wrap" align="flex-start" justify="flex-start">
      {unidades.map(coun => (
        <Tag
          key={coun.id}
          size="sm"
          variant="solid"
          colorScheme="brand-green"
          borderRadius="full"
        >
          <TagLabel>{coun.nome}</TagLabel>
          <TagCloseButton onClick={() => onDelete(coun.id)} />
        </Tag>
      ))}
    </Flex>
  );
}
