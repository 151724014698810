import { Box, Text, BoxProps, Button } from '@chakra-ui/react';
import useGetAssistants from './hooks/useGetAssistants';

type AssistantsChatProps = {
  setSelectAssistant: ({ id, nome }: any) => void;
  onNext: () => void;
};

export function ListAssistantsChat({
  setSelectAssistant,
  onNext,
}: AssistantsChatProps) {
  const { data: assistants } = useGetAssistants();

  return (
    <Box
      display="flex"
      flexDir="column"
      gap="2"
      bg="white"
      px="4"
      py="4"
      maxH="60vh"
    >
      {assistants &&
        assistants.length > 0 &&
        assistants.map((ass: { nome: string; id: string }) => (
          <Button
            fontSize="lg"
            fontWeight="medium"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            onClick={() => {
              setSelectAssistant(ass);
              onNext();
            }}
          >
            {ass.nome}
          </Button>
        ))}
      <Button
        fontSize="lg"
        fontWeight="medium"
        overflow="hidden"
        whiteSpace="nowrap"
        textOverflow="ellipsis"
        onClick={() => {
          onNext();
        }}
      >
        FAÇA UMA PERGUNTA
      </Button>
    </Box>
  );
}
