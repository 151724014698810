import { useQuery } from 'react-query';
import { useUnidadeStore, useUserStore } from '@/stores';
import { api } from '@/lib';

type ProfessionalsAPIResponse = {
  data: {
    idMedico: string;
    nome: string;
  }[];
};

// export async function professionalsRequest(id: string): Promise<any> {
//   const { data } = await api.get<ProfessionalsAPIResponse>(
//     `/usuario/obter-profissionalsaude-gestormaster-paginado-novo?idGestor=${id}`,
//   );

//   return data.data.map((value: any) => ({
//     label: value.nome,
//     value: value.id,
//     idMedico: value.usuarioProfissionalSaudeId,
//   }));
// }

// export function useProfessionalsQuery() {
//   const user = useUserStore(state => state.user);
//   const query = useQuery(
//     ['professionals', user?.gestorMasterId],
//     () => professionalsRequest(user?.gestorMasterId || ''),
//     {
//       refetchInterval: false,
//     },
//   );

//   return query;
// }

export async function professionalsRequest(idUnidade: string): Promise<any> {
  const { data } = await api.get<ProfessionalsAPIResponse>(
    `/instancia/unidade-listar-profssionais?id=${idUnidade}`,
  );

  return data.data
    .filter((d: any) => !d.usuarioProfissionalSaude?.primeiroAcesso)
    .map((value: any) => ({
      label: value.usuarioProfissionalSaude.pessoa.nome,
      value: value.codigoVinculo,
      idMedico: value.usuarioProfissionalSaudeId,
      idUsuario: value.id,
      primeiroAcesso: value.usuarioProfissionalSaude?.primeiroAcesso,
    }));
}

export function useProfessionalsQuery() {
  const unidadeId = useUnidadeStore(state => state.unidade?.id);

  const query = useQuery(
    ['professionals', unidadeId],
    () => professionalsRequest(unidadeId || ''),
    {
      refetchInterval: false,
    },
  );

  return query;
}

export function useProfessionalsIdQuery(idUnidade: string) {
  const query = useQuery(
    ['professionals', idUnidade],
    () => professionalsRequest(idUnidade || ''),
    {
      refetchInterval: false,
    },
  );

  return query;
}
