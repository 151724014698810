export const examTypeSelectOptions = [
  { value: 'Aspirado de Medula Óssea', label: 'Aspirado de Medula Óssea' },
  {
    value: 'Citologia Oncótica (Papanicolau)',
    label: 'Citologia Oncótica (Papanicolau)',
  },
  { value: 'Colesterol Total', label: 'Colesterol Total' },
  { value: 'Creatinina', label: 'Creatinina' },
  { value: 'Densitometria Óssea', label: 'Densitometria Óssea' },
  { value: 'Eletrocardiograma (ECG)', label: 'Eletrocardiograma (ECG)' },
  {
    value: 'Exame de Fezes (coprocultura)',
    label: 'Exame de Fezes (coprocultura)',
  },
  { value: 'Exame de Urina (urinálise)', label: 'Exame de Urina (urinálise)' },
  { value: 'Glicemia em Jejum', label: 'Glicemia em Jejum' },
  {
    value: 'HIV (Teste para o Vírus da Imunodeficiência Humana)',
    label: 'HIV (Teste para o Vírus da Imunodeficiência Humana)',
  },
  { value: 'Hemograma Completo', label: 'Hemograma Completo' },
  { value: 'Lipidograma', label: 'Lipidograma' },
  {
    value: 'Mapa (Monitorização Ambulatorial da Pressão Arterial)',
    label: 'Mapa (Monitorização Ambulatorial da Pressão Arterial)',
  },
  { value: 'Parasitológico de Fezes', label: 'Parasitológico de Fezes' },
  {
    value: 'PFC (Painel Farmacológico Completo)',
    label: 'PFC (Painel Farmacológico Completo)',
  },
  { value: 'Ressonância Magnética', label: 'Ressonância Magnética' },
  { value: 'Série de Anticorpos', label: 'Série de Anticorpos' },
  { value: 'Tomografia Computadorizada', label: 'Tomografia Computadorizada' },
  { value: 'Ultrassonografia', label: 'Ultrassonografia' },
  { value: 'Vitamina D', label: 'Vitamina D' },
];
