import { Flex, Grid, GridItem } from '@chakra-ui/react';

import { RecoveryPasswordForm, RegisterImageSection } from '@/features/auth';

export function RecoveryPasswordPage() {
  return (
    <Flex
      as="main"
      w="100%"
      minH="100vh"
      bg="brand-green.400"
      align="center"
      justify="center"
    >
      <Grid
        as="section"
        templateColumns={['1fr', '1fr', '45% 55%']}
        m="8"
        maxW="1020px"
        w="100%"
        h={['auto', 'auto', 'max(70vh, 685px)']}
        bg="white"
        borderRadius="10px"
        overflow="hidden"
      >
        <GridItem w="100%">
          <RecoveryPasswordForm />
        </GridItem>

        <GridItem w="100%">
          <RegisterImageSection />
        </GridItem>
      </Grid>
    </Flex>
  );
}
