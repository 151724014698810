import { useQuery } from 'react-query';

import { api } from '@/lib';

type UserTermAPIResponse = {
  data: {
    gestorMasterId: string;
    name: string;
    email: string;
    genderId: string;
    researcherId: string;
    reasearch: string;
    id: string;
  }[];
};

async function userTermRequest(): Promise<UserTermAPIResponse> {
  const { data } = await api.get('/usuario/termo-obter-todos');

  return data.data;
}

export function useUserTermQuery() {
  const query = useQuery('userTerm', userTermRequest, {
    refetchInterval: false,
    select: (originalResponse: any) => {
      return {
        id: originalResponse[0].id,
        genderId: originalResponse[0].genderId,
        gestorMasterId: originalResponse[0].gestorMasterId,
        name: originalResponse[0].name,
        email: originalResponse[0].email,
        researcherId: originalResponse[0].researcherId,
        reasearch: originalResponse[0].reasearch,
      };
    },
  });

  return query;
}
