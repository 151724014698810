import { VStack, SimpleGrid, Text, Box, Button } from '@chakra-ui/react';

interface Props {
  changeUser?: boolean;
  title: string;
  onChange: () => void;
  onCancel: () => void;
  isLoading: boolean;
}

export function ChangeEnvironmentModal({
  onChange,
  title,
  onCancel,
  changeUser,
  isLoading,
}: Props) {
  return (
    <VStack spacing="4">
      <Box>
        <Text color="brand-dark.300" fontSize="xl" align="center">
          {changeUser
            ? `Você realmente quer mudar para seu perfil de ${title}?`
            : `Você realmente quer mudar de ${title} de Saúde?`}
        </Text>
      </Box>

      <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
        <Button
          type="button"
          onClick={onCancel}
          variant="outline"
          colorScheme="brand-orange"
          h="45px"
          borderRadius="28px"
          color="brand-orange.300"
          fontSize="xs"
          fontWeight="medium"
          textTransform="uppercase"
          disabled={isLoading}
          isLoading={isLoading}
        >
          Voltar
        </Button>

        <Button
          type="submit"
          colorScheme="brand-green"
          h="45px"
          borderRadius="28px"
          color="white"
          fontSize="xs"
          fontWeight="medium"
          textTransform="uppercase"
          onClick={() => onChange()}
          disabled={isLoading}
          isLoading={isLoading}
        >
          Confirmar
        </Button>
      </SimpleGrid>
    </VStack>
  );
}
