import { Navigate, Route, Routes } from 'react-router-dom';

import {
  LoginPage,
  RecoveryPasswordPage,
  RegisterPage,
  NewPasswordPage,
  ErrorToken,
  PageLoading,
} from '@/features/auth';
import { useQueryParams } from '../hooks/useQueryParams';

export function AuthRoutes() {
  const data = useQueryParams();
  function renderContent() {
    if (data?.username && data?.token) {
      return <NewPasswordPage />;
    }
    return <ErrorToken />;
  }

  return (
    <Routes>
      <Route path="login" element={<LoginPage />} />
      <Route path="register" element={<RegisterPage />} />
      <Route path="password" element={<RecoveryPasswordPage />} />
      <Route path="recovery-password" element={renderContent()} />
      <Route path="*" element={<Navigate to="/auth/login" />} />
    </Routes>
  );
}
