import {
  Box,
  Circle,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Skeleton,
  Badge,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useGlobalFilter,
} from 'react-table';
import { formatDate } from '@/utils';
import {
  TableColumnsDetails,
  useNotificationsTableQuery,
} from '@/features/notifications';
import { useProfessionalStore } from '@/stores';
import { TopSection } from '../TopSection';

import { ActionButtons } from './ActionButtons';
import { Pagination } from './Pagination';

export function TableSection() {
  const {
    data: getNotification,
    refetch,
    isLoading,
  } = useNotificationsTableQuery();
  const professional = useProfessionalStore(state => state.professional);
  const [currentPage, setCurrentPage] = useState(0);

  const [notifications, setNotification] = useState<any>();

  const data = useMemo<TableColumnsDetails[]>(
    () => notifications || [],
    [notifications],
  );

  const columns = useMemo(
    () => [
      {
        header: 'Paciente',
        accessor: 'paciente',
      },
      {
        header: 'Data Solicitação',
        accessor: 'dataCadastro',
        Cell: ({ value }: { value: Date }) => formatDate(value),
      },
      {
        header: 'Descrição',
        accessor: 'descricao',
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    pageOptions,
    state: { pageIndex, globalFilter },
    setGlobalFilter,
    gotoPage,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: currentPage,
        pageSize: 5,
      },
    },
    hooks => {
      hooks.visibleColumns.push(originalColumns => [
        {
          id: 'recente',
          width: 100,
          header: '',
          Cell: ({ row }) =>
            row.original.recente && (
              <Badge
                bg="brand-orange.400"
                w="2"
                h="2"
                borderRadius="full"
                position="absolute"
                bottom="50%"
              />
            ),
        },
        ...originalColumns,
        {
          id: 'acoes',
          header: 'Ação',
          Cell: ({ row }) =>
            row.original.status !== 'Lido' && (
              <ActionButtons row={row} refetch={refetch} />
            ),
        },
      ]);
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  useEffect(() => {
    setNotification(getNotification);
  }, [getNotification, professional, isLoading, refetch]);

  useEffect(() => {
    gotoPage(currentPage);
  }, [currentPage]);

  return (
    <>
      <TopSection filter={globalFilter} setFilter={setGlobalFilter} />

      {isLoading ? (
        <Skeleton mt="8" w="100%" h="600px" border="20px" />
      ) : (
        <Box mt="8" w="100%">
          <TableContainer
            h="600px"
            overflowY="auto"
            sx={{
              '&::-webkit-scrollbar': { width: '8px', height: '8px' },
              '&::-webkit-scrollbar-thumb': {
                borderRadius: 20,
                background: 'brand-dark.200',
              },
            }}
          >
            <Table size="lg" {...getTableProps()}>
              <Thead h="96px">
                {headerGroups.map(headerGroup => {
                  return (
                    <Tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => (
                        <Th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps(),
                          )}
                          color="brand-dark.400"
                          fontSize="lg"
                          fontWeight="medium"
                          textAlign="center"
                          textTransform="initial"
                        >
                          <>
                            {column.render('header')}
                            <Text as="span">
                              {column.isSorted
                                ? column.isSortedDesc
                                  ? '▼'
                                  : '▲'
                                : ''}
                            </Text>
                          </>
                        </Th>
                      ))}
                    </Tr>
                  );
                })}
              </Thead>

              <Tbody {...getTableBodyProps()}>
                {page.map(row => {
                  prepareRow(row);
                  return (
                    <Tr
                      {...row.getRowProps()}
                      bg={
                        row.original.status === 'Não lido'
                          ? '#fdf2ec'
                          : 'transparent'
                      }
                    >
                      {row.cells.map(cell => (
                        <Td
                          {...cell.getCellProps()}
                          color="brand-dark.300"
                          fontSize="md"
                          fontWeight="medium"
                          whiteSpace="pre-wrap"
                          textAlign="center"
                          // textAlign={
                          //   cell.column.id === 'descricao' ? 'left' : 'center'
                          // }
                          position="relative"
                        >
                          {row.original.recente &&
                            cell.column.id === 'paciente'}
                          {cell.render('Cell')}
                        </Td>
                      ))}
                    </Tr>
                  );
                })}
              </Tbody>
            </Table>
          </TableContainer>

          <Flex
            mt="4"
            w="100%"
            direction={['column', 'column', 'row']}
            align="center"
            justify="space-between"
          >
            <Text color="brand-dark.300" fontSize="sm">
              Página {currentPage + 1} de {pageOptions.length}
            </Text>

            <Pagination
              currentPage={currentPage}
              canPreviousPage={currentPage > 0}
              canNextPage={currentPage + 1 < pageOptions.length}
              onPageChange={setCurrentPage}
            />
          </Flex>
        </Box>
      )}
    </>
  );
}
