/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-useless-fragment */
import { useState, useEffect, ChangeEvent } from 'react';
import {
  Button,
  Select,
  HStack,
  Input,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
  Text,
  List,
  InputGroup,
  InputRightElement,
  FormLabel,
  Tag,
  TagLabel,
  Box,
  useToast,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';

import {
  useAddUserModalStore,
  useInstanciaStore,
  useProfessionalStore,
  useUnidadeStore,
  useUserStore,
} from '@/stores';
import { api } from '@/lib';
import {
  // useAllPatients,
  useSavePatient,
  Patient,
  usePatientByProfissional,
} from '@/features/schedule';
import { SelectInput } from '@/components';
import { useUnidadesIdQuery } from '@/hooks';
import '@/styles/global.css';
import { AssociarPaciente } from './ScaleActions/AssociarPaciente';

interface PatientInfoModalProps {
  isOpen: boolean;
  onClose: () => void;
  currentPatient: Patient | null;
  appointments: any;
}

type CurrentPatient = {
  cpf: string;
  id: string;
  nome: string;
  associado?: boolean;
};

export function SchedulePatientModal({
  isOpen,
  onClose,
  currentPatient,
  appointments,
}: PatientInfoModalProps) {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });
  const [inputFocus, setInputFocus] = useState(false);
  const [namePatient, setNamePatient] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [patientSelectedAssociate, setSelectedAssociate] = useState<
    CurrentPatient | undefined
  >({
    cpf: '',
    id: '',
    nome: '',
    associado: false,
  });
  const [hasValueDigitized, setHasValueDigitized] = useState(false);
  const [openModalAssociate, setOpenModalAssociate] = useState(false);
  const closeModalAssociate = () => setOpenModalAssociate(false);
  const setOpenAssociate = () => setOpenModalAssociate(true);

  const [patientsInput, setPatientsInput] = useState<
    CurrentPatient[] | undefined
  >([]);
  const [patientsInputBackup, setPatientsInputBackup] = useState<
    CurrentPatient[] | undefined
  >([]);
  const professional = useProfessionalStore(state => state.professional);
  const user = useUserStore(state => state.user);

  const {
    onOpen: onAddUserModalOpen,
    changeContent: changeContentModalAddUser,
  } = useAddUserModalStore();

  const [patientSelected, setSelected] = useState<CurrentPatient | undefined>({
    cpf: '',
    id: '',
    nome: '',
    associado: false,
  });
  const [typeConsultation, setTypeConsultation] = useState<any>(null);
  const [validat, setValidat] = useState<boolean>(true);
  const { data: patients, refetch } = usePatientByProfissional();
  // const { data: instances = [], refetch: refreshInstance } = useInstanceQuery();
  const instances = useInstanciaStore(state => state.instancia);
  const [instanciaId, setInstanciaId] = useState(instances?.id);
  const { data: unidades = [] } = useUnidadesIdQuery(instanciaId || '');
  const unidadeSet = useUnidadeStore(state => state.unidade);
  const [unidadeId, setUnidadeId] = useState(unidadeSet?.id);
  const { mutate } = useSavePatient();

  useEffect(() => {
    if (unidadeId) {
      setPatientsInput(patients);
      setPatientsInputBackup(patients);
    }
  }, [patients]);

  const setDataContent = async () => {
    const params = {
      idUsuarioProfissional:
        user?.type === 'medico' ? user?.id : professional?.idMedico,
      idUnidade: unidadeId,
    };

    if (unidadeId) {
      await api
        .get('/paciente/obter-paciente-profissional-paginado', { params })
        .then(response => {
          setPatientsInput(response.data.data);
          setPatientsInputBackup(response.data.data);
        })
        .catch(e => {
          setPatientsInput([]);
          setPatientsInputBackup([]);
        });
    }
  };

  useEffect(() => {
    setDataContent();
  }, [unidadeId]);

  useEffect(() => {
    setUnidadeId(unidadeSet.id);
  }, [unidadeSet]);

  const changeNamePatient = (event: any) => {
    setNamePatient(event.target.value);
    setHasValueDigitized(true);

    if (!event.target.value) {
      setPatientsInput(patientsInputBackup);
      setHasValueDigitized(false);
      return;
    }

    // if (event.target.value.length > 3) {
    //   const { data: patientsList } = useListAllPatients(event.target.value);
    //   setPatientsInput(patientsList);
    // }

    const patientsFiltered = patients?.filter(patient =>
      patient.nome.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    setPatientsInput(patientsFiltered);
  };

  const selectTypeConsultation = (event: ChangeEvent<HTMLSelectElement>) => {
    setTypeConsultation(Number(event.target.value));
  };

  const selectPatient = (patient: CurrentPatient) => () => {
    if (!patient.cpf) {
      toast({
        title:
          'Paciente não autorizado. Acesse o app e conclua cadastro para agendar consultas.',
        status: 'error',
      });
      return;
    }

    setSelected(patient);
    setNamePatient(patient.nome);
    setInputFocus(false);
  };

  const closeModal = () => {
    onClose();
    setNamePatient('');
    setHasValueDigitized(false);
    setPatientsInput(patientsInputBackup);
    setTypeConsultation(null);
    setValidat(true);
    setSelected({
      cpf: '',
      id: '',
      nome: '',
    });
  };

  const schedulePatient = async () => {
    setIsLoading(true);

    try {
      const sendId = user?.type !== 'medico' ? professional?.idMedico : user.id;

      if (!patientSelected?.cpf) {
        setValidat(false);
        return;
      }

      if (!patientSelected?.id || typeConsultation == null) {
        setValidat(false);
        return;
      }

      const mutation = mutate({
        tipoConsulta: typeConsultation,
        profissonalSaudeId: sendId!,
        pacienteId: patientSelected.id,
        horasAgendaId: currentPatient?.idScheduleTime || '',
        dataPedido: new Date(),
        idAgenda: currentPatient?.idAgenda || '',
        idUnidadeSaude: unidadeId,
      });

      closeModal();
    } catch (e) {
      // TODO
    } finally {
      setIsLoading(false);
    }
  };

  const registerNewPatient = () => {
    closeModal();
    onAddUserModalOpen();
    changeContentModalAddUser('patient');
  };

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="md" isCentered>
      <ModalOverlay />
      {!openModalAssociate ? (
        <ModalContent p="8" borderRadius="20px">
          <ModalHeader
            as={HStack}
            spacing="6"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Text color="brand-dark.300" fontSize="2xl" fontWeight="medium">
              Agendar Paciente
            </Text>
          </ModalHeader>
          <ModalCloseButton />

          <ModalBody pb="8">
            <SelectInput
              label="Unidades de Saúde"
              options={unidades}
              placeholder="Selecione unidade de saúde"
              disabled
              value={unidadeId}
            />
            <Text
              textAlign="left"
              width="100%"
              fontWeight={500}
              color="brand-dark.500"
              fontSize={16}
              marginTop={5}
            >
              Nome do Paciente
            </Text>
            <InputGroup mb="8px">
              <Input
                type="text"
                placeholder="Nome"
                onChange={changeNamePatient}
                value={namePatient}
                onFocus={() => setInputFocus(true)}
                onBlur={() =>
                  setTimeout(() => {
                    setInputFocus(false);
                  }, 500)
                }
              />
              <InputRightElement
                pointerEvents="none"
                children={<SearchIcon color="brand-dark.300" />}
              />
            </InputGroup>
            {patientsInput && patientsInput.length ? (
              <>
                {inputFocus ? (
                  <List
                    mb="6"
                    style={{ maxHeight: '10rem', overflowY: 'scroll' }}
                  >
                    {patientsInput
                      .filter(
                        (d: { primeiroAcesso: boolean }) => !d.primeiroAcesso,
                      )
                      .map(patient => (
                        <Flex
                          style={{ cursor: 'pointer' }}
                          justifyContent="space-between"
                          key={patient.id}
                          onClick={selectPatient(patient)}
                        >
                          <Flex p="2" flexDirection="column" width="95%">
                            <Text
                              fontSize="sm"
                              color="brand-dark.200"
                              overflowWrap="break-words"
                            >
                              {patient.nome.split('@', 1)[0]}
                            </Text>
                          </Flex>
                        </Flex>
                      ))}
                  </List>
                ) : (
                  <>
                    {patientSelected?.nome && (
                      <Flex justifyContent="space-between">
                        <Flex p="2" flexDirection="column" width="45%">
                          <Text fontSize="md" color="brand-dark.300">
                            Nome
                          </Text>

                          <Text
                            fontSize="sm"
                            color="brand-dark.200"
                            overflowWrap="break-words"
                          >
                            {patientSelected?.nome}
                          </Text>
                        </Flex>
                        <Flex p="2" flexDirection="column">
                          <Text fontSize="md" color="brand-dark.300">
                            CPF
                          </Text>
                          <Text fontSize="sm" color="brand-dark.200">
                            {patientSelected?.cpf}
                          </Text>
                        </Flex>
                      </Flex>
                    )}
                  </>
                )}
              </>
            ) : (
              <>
                {hasValueDigitized && (
                  <List mb="6" style={{ maxHeight: '10rem' }}>
                    <Flex flexDirection="column">
                      <Text
                        style={{ fontWeight: '500' }}
                        fontSize="sm"
                        color="brand-red.600"
                      >
                        Nome inválido ou não cadastrado*
                      </Text>
                      <Text
                        style={{
                          textAlign: 'end',
                          fontWeight: '500',
                          cursor: 'pointer',
                        }}
                        fontSize="sm"
                        color="#0057F0"
                        onClick={registerNewPatient}
                      >
                        Cadastrar paciente
                      </Text>
                    </Flex>
                  </List>
                )}
              </>
            )}
            <Text
              textAlign="left"
              width="100%"
              fontWeight={500}
              color="brand-dark.500"
              fontSize={16}
              marginTop={5}
            >
              Tipo de Consulta
            </Text>
            <Select
              id="consultaID"
              mb="6"
              placeholder="Selecionar"
              borderColor={
                validat === false && typeConsultation === null
                  ? 'red'
                  : 'rgba(0, 0, 0, 0.05)'
              }
              borderWidth="2px"
              color="brand-dark.500"
              sx={{
                option: {
                  color: 'brand-dark.200',
                },
              }}
              onChange={selectTypeConsultation}
            >
              <option value={0}>Presencial</option>
              <option value={1}>Online</option>
            </Select>
            <SimpleGrid minChildWidth="120px" spacing="4" w="100%">
              <Button
                type="button"
                variant="outline"
                colorScheme="brand-orange"
                h="45px"
                borderRadius="28px"
                color="brand-orange.300"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
                onClick={() => onClose()}
              >
                Cancelar
              </Button>

              <Button
                type="button"
                colorScheme="brand-green"
                h="45px"
                w="100%"
                borderRadius="28px"
                color="white"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
                isLoading={isLoading}
                onClick={schedulePatient}
              >
                Agendar
              </Button>
            </SimpleGrid>
          </ModalBody>
        </ModalContent>
      ) : (
        <Modal
          isOpen={openModalAssociate}
          onClose={closeModalAssociate}
          size="md"
          isCentered
        >
          <ModalContent>
            <ModalHeader
              as={HStack}
              spacing="6"
              alignItems="flex-start"
              justifyContent="center"
            >
              <Text color="#039c00" fontSize="2xl" fontWeight="medium">
                Associar Paciente
              </Text>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <AssociarPaciente
                onCancel={closeModalAssociate}
                event={patientSelectedAssociate}
                refetch={refetch}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </Modal>
  );
}
