import {
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
  Text,
  Heading,
} from '@chakra-ui/react';
import { useEffect } from 'react';

interface Props {
  patient: any;
}

const FormatData = ({ label1, value1, label2, value2 }: any) => {
  return (
    <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
      <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
        <Heading as="h6" size="sm" color="#707070">
          {label1}
        </Heading>
        <Text>{value1}</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Heading as="h6" size="sm" color="#707070">
          {label2}
        </Heading>
        <Text>{value2}</Text>
      </GridItem>
    </Grid>
  );
};

export function ViewPatientConsultInfo({ patient }: Props) {
  useEffect(() => {}, [patient]);

  return (
    <VStack spacing="4">
      <FormatData
        label1="Email"
        value1={patient && patient.email}
        label2="Celular"
        value2={patient && patient.celular}
      />
      <FormatData
        label1="CPF"
        value1={patient && patient.cpf}
        label2="Sexo"
        value2={patient && patient.sexo}
      />
      <FormatData
        label1="Data de Nascimento"
        value1={patient && patient.nascimento}
        label2="Última Consulta"
        value2={patient && patient.ultimaConsulta}
      />
    </VStack>
  );
}
