import { Box, BoxProps, Icon } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { IoEllipse } from 'react-icons/io5';

type CardItemProps = BoxProps & {
  active?: boolean;
  children: ReactNode;
  colorIndicator?: number;
};

export function CardItem({
  active = false,
  children,
  colorIndicator,
  ...props
}: CardItemProps) {
  const defaultColor = () => {
    switch (colorIndicator) {
      case 0:
        return 'green';
      case 1:
        return 'yellow';
      case 2:
        return 'red';
      default:
        return 'white';
    }
  };

  return (
    <Box {...props}>
      <Box
        cursor="pointer"
        fontSize="lg"
        fontWeight="500"
        border={active ? '1px solid #0005' : ''}
        bg={active ? ' #d4d4d4' : ''}
        fontFamily="Rubik"
        px={2}
        py={2}
        display="flex"
        justifyContent="center"
        alignItems="center"
        gap={4}
        _hover={{
          bg: '#d4d4d4',
        }}
      >
        {children}
        <Icon as={IoEllipse} color={defaultColor as any} fontSize="30px" />
      </Box>
    </Box>
  );
}
