import { ReactNode, useEffect, useRef } from 'react';
import Carousel from 'react-multi-carousel';

interface ItemsCarouselProps {
  activeSlide?: number;
  children: ReactNode;
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    partialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    partialVisibilityGutter: 30,
  },
};

export function ItemsCarousel({
  children,
  activeSlide = 0,
}: ItemsCarouselProps) {
  const carouselRef = useRef<any>();

  useEffect(() => {
    if (carouselRef && carouselRef.current) {
      carouselRef.current.goToSlide(activeSlide);
    }
  }, [activeSlide]);

  return (
    <Carousel
      ref={carouselRef}
      responsive={responsive}
      autoPlay={false}
      shouldResetAutoplay={false}
      focusOnSelect
      centerMode
      containerClass="carousel-container"
      itemClass="carousel-item"
    >
      {children}
    </Carousel>
  );
}
