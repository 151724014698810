import { useState } from 'react';
import { useProfessionalStore, useUserStore } from '@/stores';
import { Text, Grid, GridItem, VStack, Box, Skeleton } from '@chakra-ui/react';
import { useGendersQuery } from '@/hooks';
import { GenericModal } from '@/features/registers';
import { ActivitiesCarousel } from '../ActivitiesSection/ActivitiesCarousel';
import { ActivityCardPatient } from './ActivityCardPatient';
import { useActivitiesPatientQuery } from '../../hooks/useActivitiesPatientQuery';
import { ViewPatientDashInfo } from './ViewPatientDashInfo';

export function ActivitiesSectionPatient({ patientSelect }: any) {
  const user = useUserStore(state => state.user);
  const [openModal, setOpenModal] = useState(false);
  const { data: activities, isLoading } = useActivitiesPatientQuery(
    patientSelect?.idPatient,
  );

  const OpenModal = () => {
    if (user?.type !== 'paciente') {
      setOpenModal(true);
    }
  };

  const formatData = activities?.consultasFuturas
    .filter(actFil => actFil.status !== 2)
    .map(act => {
      const [scheduleHour, scheduleMinute] =
        act.horasAgenda.agenda.horarioInicio.split(':');
      const startHour = parseInt(scheduleHour, 10);
      const startMinute = parseInt(scheduleMinute, 10);
      return {
        id: act.id,
        pacienteId: act.pacienteId,
        idUnidadeSaude: act.idUnidadeSaude,
        urlVideo: act.urlVideo,
        tipoConsulta: act.tipoConsulta,
        profissional: act.profissional.usuarioProfissionalSaude?.pessoa?.nome,
        endereco: act.unidadeSaude.endereco,
        unidadeSaude: act.unidadeSaude,
        date: new Date(
          new Date(act.horasAgenda.agenda.data).setHours(
            startHour,
            startMinute,
          ),
        ),
      };
    })
    .sort((a: any, b: any) => {
      if (a.date < b.date) {
        return -1;
      }
      if (b.date > a.date) {
        return 1;
      }
      return 0;
    });

  return (
    <>
      <Box>
        {activities && !isLoading ? (
          <ActivitiesCarousel>
            <ActivityCardPatient
              activity={{
                dataAtualizacao: new Date(),
                infos: formatData || [],
                tipoAtividade: 'consultasFuturas',
                setOpenModal: OpenModal,
              }}
            />
            <ActivityCardPatient
              activity={{
                dataAtualizacao: new Date(),
                infos: activities.numeroDeConsultas || 0,
                tipoAtividade: 'numeroDeConsultas',
                setOpenModal: OpenModal,
              }}
            />
            <ActivityCardPatient
              activity={{
                dataAtualizacao: new Date(),
                infos: activities.cidAtual || 'Não informado',
                tipoAtividade: 'cidAtual',
                setOpenModal: OpenModal,
              }}
            />
            <ActivityCardPatient
              activity={{
                dataAtualizacao: new Date(),
                infos: activities.medicacoesAtuais || [],
                tipoAtividade: 'medicacoesAtuais',
                setOpenModal: OpenModal,
              }}
            />
            <ActivityCardPatient
              activity={{
                dataAtualizacao: new Date(),
                infos: activities.monitoramentoAtual || 'Não informado',
                tipoAtividade: 'monitoramentoAtual',
                corAlerta: activities?.monitoramentoAtual?.corAlerta,
                setOpenModal: OpenModal,
              }}
            />
          </ActivitiesCarousel>
        ) : (
          <Box
            mt="4"
            w="100%"
            sx={{
              '.carousel-item': {
                '&:not(:last-child)': { paddingRight: '20px' },
              },
            }}
          >
            <ActivitiesCarousel>
              {Array.from(Array(5).keys()).map(key => (
                <Skeleton key={key} w="100%" h="165px" borderRadius="20px" />
              ))}
            </ActivitiesCarousel>
          </Box>
        )}
      </Box>
      <GenericModal
        size="5xl"
        open={openModal}
        title=""
        onClose={() => {
          setOpenModal(false);
        }}
      >
        <ViewPatientDashInfo
          activities={{ ...activities, consultasFuturas: formatData }}
          patient={patientSelect}
        />
      </GenericModal>
    </>
  );
}

export function ConsultationInfo({ patientSelect }: any) {
  const { data: genders = [] } = useGendersQuery();

  const gender = genders?.filter(
    item => item?.value === patientSelect?.resource?.sexoId,
  );
  return (
    <Grid width="100%" templateColumns="repeat(12, 1fr)">
      <GridItem display="flex" colSpan={12}>
        <VStack spacing="1" ml="4" align="flex-start" width="30%">
          <Text
            color="brand-dark.200"
            fontSize="xs"
            fontWeight="normal"
            fontFamily="Rubik"
          >
            PACIENTE
          </Text>
          <Text
            color="brand-dark.500"
            fontSize="lg"
            fontWeight="large"
            fontFamily="Rubik"
            overflowWrap="anywhere"
          >
            {patientSelect.title}
          </Text>
        </VStack>

        <VStack
          spacing="1"
          ml="10"
          pl="10"
          align="flex-start"
          borderLeft="1px solid #ABABAB"
        >
          <Text
            color="brand-dark.200"
            fontSize="xs"
            fontWeight="normal"
            fontFamily="Rubik"
          >
            CPF
          </Text>
          <Text
            color="brand-dark.500"
            fontSize="lg"
            fontWeight="large"
            fontFamily="Rubik"
          >
            {/* {location.paciente?.email} */} {patientSelect.cpf}
          </Text>
        </VStack>

        <VStack
          spacing="1"
          ml="10"
          pl="10"
          align="flex-start"
          borderLeft="1px solid #ABABAB"
        >
          <Text
            color="brand-dark.200"
            fontSize="xs"
            fontWeight="normal"
            fontFamily="Rubik"
          >
            IDADE
          </Text>
          <Text
            color="brand-dark.500"
            fontSize="lg"
            fontWeight="large"
            fontFamily="Rubik"
          >
            {patientSelect.idade}
          </Text>
        </VStack>

        <VStack
          spacing="1"
          ml="10"
          pl="10"
          align="flex-start"
          borderLeft="1px solid #ABABAB"
        >
          <Text
            color="brand-dark.200"
            fontSize="xs"
            fontWeight="normal"
            fontFamily="Rubik"
          >
            SEXO
          </Text>
          <Text
            color="brand-dark.500"
            fontSize="lg"
            fontWeight="large"
            fontFamily="Rubik"
          >
            {gender[0].label || 'Não informado'}
          </Text>
        </VStack>

        <VStack
          spacing="1"
          ml="10"
          pl="10"
          align="flex-start"
          borderLeft="1px solid #ABABAB"
        >
          <Text
            color="brand-dark.200"
            fontSize="xs"
            fontWeight="normal"
            fontFamily="Rubik"
          >
            TELEFONE
          </Text>
          <Text
            color="brand-dark.500"
            fontSize="lg"
            fontWeight="large"
            fontFamily="Rubik"
          >
            {patientSelect?.resource?.celular}
          </Text>
        </VStack>
      </GridItem>
    </Grid>
  );
}
