import { Box, Flex, Grid, GridItem, Image, Text } from '@chakra-ui/react';
import { IconType } from 'react-icons';

import sidebarLogoImg from '@/assets/logo_sidebar.png';

import { NavItem } from './NavItem';

interface WideSidebarProps {
  navItems: {
    icon: IconType;
    id?: string;
    label: string;
    link?: string;
    onClick?: () => void;
    subLinks?: {
      label: string;
      icon: IconType;
      link?: string;
      onClick?: () => void;
    }[];
  }[];
}

export function WideSidebar({ navItems }: WideSidebarProps) {
  return (
    <Box
      as="aside"
      w="24"
      h="100vh"
      borderRadius="0 20px 20px 0"
      background="white"
      boxShadow="lg"
      position="fixed"
      zIndex={10}
      overflowY="auto"
      transition="width 0.3s"
      _hover={{
        width: '64',
      }}
      sx={{
        '&::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <Grid
        my="2"
        py="8"
        px="6"
        borderRadius="8"
        templateColumns="repeat(2, max-content)"
        columnGap="3"
        alignItems="center"
        overflowX="hidden"
      >
        <GridItem as={Flex} w="48px" h="48px" align="center" justify="center">
          <Image
            src={sidebarLogoImg}
            alt="Dynamic Medic Health Logo"
            w="100%"
            h="auto"
          />
        </GridItem>
        <GridItem>
          <Text
            ml="3"
            color="brand-dark.500"
            fontSize="md"
            fontWeight="bold"
            isTruncated
          >
            DMH
          </Text>
        </GridItem>
      </Grid>

      <Box as="nav" my="4" px="6" pb="8">
        {navItems.map(item => (
          <NavItem
            key={item.label}
            id={item.id}
            icon={item.icon}
            label={item.label}
            onClick={item?.onClick}
            subLinks={item?.subLinks}
          />
        ))}
      </Box>
    </Box>
  );
}
