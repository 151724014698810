import React, { useContext } from 'react';

export const UpdateContext = React.createContext({
  updateUnidades: false,
  setUpUnidades: (data: boolean) => {},
});

export function useUpdadeContext() {
  return useContext(UpdateContext);
}
