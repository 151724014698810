import * as yup from 'yup';

export const registerQuestionarySchema = yup.object().shape({
  questionarioId: yup.string().required('Campo obrigatório'),
  descricao: yup.string().required('Campo obrigatório'),
  periodicidade: yup.string().required('Campo obrigatório'),
  startDate: yup.date().required('Campo obrigatório'),
  finalDate: yup.date(),
  horarios: yup
    .array()
    .of(yup.date())
    .when('periodicidade', {
      is: (value: number) => value < 2,
      then: yup.array().of(yup.date()).min(1, 'Adicione pelo menos um horário'),
    }),
  horariosWithWeeks: yup
    .array()
    .of(yup.object().shape({}))
    .when('periodicidade', {
      is: (value: number) => value === 2,
      then: yup.array().of(yup.date()).min(1, 'Adicione pelo menos um horário'),
    }),
  horariosWithMonths: yup
    .array()
    .of(yup.object().shape({}))
    .when('periodicidade', {
      is: (value: number) => value === 3,
      then: yup.array().of(yup.date()).min(1, 'Adicione pelo menos um horário'),
    }),
});
