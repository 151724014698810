import { ReactNode } from 'react';

import { TableContainer, Table, Thead, Tbody, Tr, Th } from '@chakra-ui/react';

interface Props {
  head: string[];
  children: ReactNode;
}

export function TablesReport({ head = [], children }: Props) {
  return (
    <TableContainer h="410" overflowY="auto">
      <Table
        variant="striped"
        backgroundColor="white"
        colorScheme="rgba(96, 192, 175, 0.08);"
        borderRadius="8px"
        boxShadow="0px 2px 4px rgba(52, 46, 85, 0.06)"
      >
        <Thead h="45" backgroundColor="white">
          <Tr h="45">
            {head.map(h => (
              <Th key={h} fontSize="14px" fontWeight="600">
                {h}
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody>{children}</Tbody>
      </Table>
    </TableContainer>
  );
}
