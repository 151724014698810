import { Flex, Heading, Image } from '@chakra-ui/react';

import personImg from '@/assets/healthy_person.svg';

export function RegisterImageSection() {
  return (
    <Flex
      mb="12"
      pt={['12', '12', '24']}
      pb="6"
      px={['8', '14', '14']}
      w="100%"
      h="100%"
      bg="brand-green.50"
      direction="column"
      align="center"
    >
      <Heading size="md" mb="12" color="gray.700" textAlign="center">
        Acolhimento ao paciente, compromisso com os profissionais de saúde
      </Heading>

      <Image src={personImg} alt="Pessoa saudável segurando um telefone" />
    </Flex>
  );
}
