import { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Center,
  Icon,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Skeleton,
  Spinner,
  Text,
  useBreakpointValue,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { MdLogout, MdArrowDropDown, MdArrowDropUp } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { useInstanciaStore } from '@/stores/instancias';
import { useProfessionalStore, useUnidadeStore, useUserStore } from '@/stores';
import { api } from '@/lib';
import { usechangePersonaMutation } from '@/features/auth';
import { GenericModal } from '@/features/registers';
import { ChangeEnvironmentModal } from './components/ChangeEnvironmentModal';

export function UserInfo() {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const [isOpen, setIsOpen] = useState<boolean>();
  const [data, setData] = useState<any>([]);
  const [openModal, setOpenModal] = useState<any>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { mutate } = usechangePersonaMutation();

  const user = useUserStore((state: { user: any }) => state.user);
  const setUser = useUserStore((state: { setUser: any }) => state.setUser);
  const setInstancia = useInstanciaStore(
    (state: { setInstancia: any }) => state.setInstancia,
  );
  const setUnidade = useUnidadeStore(
    (state: { setUnidade: any }) => state.setUnidade,
  );
  const setProfessional = useProfessionalStore(
    (state: { setProfessional: any }) => state.setProfessional,
  );

  const navigate = useNavigate();

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    status: 'success',
    isClosable: true,
  });

  function handleLogout() {
    localStorage.clear();

    setUser(null);
    setInstancia(null);
    setUnidade(null);
    setProfessional(null);

    toast({
      title: 'Até mais!',
    });

    navigate('/auth');
  }

  const GetPersonas = async () => {
    setIsLoading(true);
    const params = {
      usuarioId: user?.id,
      instanciaId: user?.instanciasId[0],
      // unidadeId: user?.unidadesId[0],
    };

    const { data: response } = await api.get(`/usuario/Listar-Personas`, {
      params,
    });
    setData(response.data);
    setIsLoading(false);
  };

  useEffect(() => {
    GetPersonas();
  }, [isOpen]);

  const changePersonaLogin = (values: any) => {
    setIsLoading(true);
    mutate(values, {
      onSuccess: () => {
        GetPersonas();
        setIsLoading(false);
        setOpenModal(null);
      },
    });
  };

  return (
    <Box>
      <Popover placement="bottom-end">
        {/* @ts-ignore */}
        <PopoverTrigger>
          <Button pr="12" h="60px" bg="transparent" justifyItems="center">
            <VStack spacing="1" mr="4" align="flex-start">
              <Text color="brand-dark.400" fontSize="md" fontWeight="medium">
                {user?.name}
              </Text>
              <Text color="brand-dark.300" fontSize="xs" fontWeight="normal">
                {user?.descricao}
              </Text>
            </VStack>
          </Button>
        </PopoverTrigger>

        <Portal>
          <Box zIndex="popover" w="full" h="full" position="fixed">
            <PopoverContent width="inherit" bg="white" overflow="hidden">
              <PopoverBody p="0" w="min(50vw, 180px)">
                <Button
                  onClick={() => setIsOpen(!isOpen)}
                  leftIcon={
                    <Icon as={isOpen ? MdArrowDropUp : MdArrowDropDown} />
                  }
                  w="100%"
                  backgroundColor="white"
                  color="brand-dark.400"
                  justifyContent="flex-start"
                >
                  Alterar usuário
                </Button>
                {isOpen &&
                  (isLoading ? (
                    <Center w="100%" backgroundColor="brand-dark.600" h="50px">
                      <Spinner color="green.500" size="sm" />
                    </Center>
                  ) : data && data.length > 0 ? (
                    data.map((d: any) => {
                      return (
                        <Button
                          onClick={() => {
                            const payload = {
                              personaUsuarioId: d?.usuarioId,
                              // personaUsuarioId: user?.id,
                              instanciaId: user?.instanciasId[0],
                              title: d?.tipo,
                            };
                            setOpenModal(payload);
                          }}
                          w="100%"
                          backgroundColor="brand-dark.600"
                          justifyContent="center"
                          py="2"
                          color="#959595"
                          display="flex"
                          flexDirection="column"
                          fontSize="md"
                          paddingX={10}
                        >
                          {d.tipo}
                        </Button>
                      );
                    })
                  ) : (
                    <Text py="4" textAlign="center">
                      Nenhuma outra persona
                    </Text>
                  ))}
                <Button
                  onClick={handleLogout}
                  leftIcon={<Icon as={MdLogout} />}
                  w="100%"
                  backgroundColor="white"
                  color="brand-dark.400"
                  justifyContent="flex-start"
                >
                  Sair
                </Button>
              </PopoverBody>
            </PopoverContent>{' '}
          </Box>
        </Portal>
      </Popover>
      <GenericModal
        open={openModal && openModal?.personaUsuarioId}
        title="Alterar usuário"
        onClose={() => setOpenModal(false)}
      >
        <ChangeEnvironmentModal
          changeUser
          onChange={() => {
            changePersonaLogin(openModal);
            setIsOpen(false);
          }}
          title={openModal?.title}
          onCancel={() => setOpenModal(null)}
          isLoading={isLoading}
        />
      </GenericModal>
    </Box>
  );
}
