import { Flex, Heading } from '@chakra-ui/react';

export function TopSection() {
  return (
    <Flex
      direction={['column', 'column', 'row']}
      w="100%"
      align="center"
      justify="center"
    >
      <Heading
        size="lg"
        mb={['4', '4', '0']}
        color="brand-dark.500"
        fontWeight="medium"
        textAlign="center"
      >
        POLÍTICA DE PRIVACIDADE DM HEALTH
      </Heading>
    </Flex>
  );
}
