import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { ReactNode, useCallback, useEffect, useState } from 'react';

interface Props {
  children: ReactNode;
  onClose: () => void;
  open: boolean;
  title: string;
  textAlign?: any;
  closeOnOverlayClick?: boolean;
  size?: string;
}

export function GenericModal({
  title,
  open,
  children,
  onClose,
  textAlign = 'left',
  closeOnOverlayClick = true,
  size = 'lg',
}: Props) {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleClose = useCallback(() => {
    setIsOpen(false);
    onClose();
  }, [onClose]);

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      closeOnOverlayClick={closeOnOverlayClick}
      size={size}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color="brand-dark.300"
          fontSize="xl"
          fontWeight="medium"
          textAlign={textAlign}
        >
          {title}
        </ModalHeader>
        {closeOnOverlayClick && <ModalCloseButton />}
        <ModalBody px="10" pb="8">
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
