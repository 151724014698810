/* eslint-disable import/no-duplicates */
import { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import {
  Calendar as BigCalendar,
  CalendarProps,
  dateFnsLocalizer,
} from 'react-big-calendar';
// import overlap from 'react-big-calendar/lib/utils/layout-algorithms/overlap';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import ptBR from 'date-fns/locale/pt-BR';
import { useSchedulePatientQuery } from '@/features/schedule/hooks/useSchedulePatientQuery';
import { IoAddOutline } from 'react-icons/io5';
import { Icon } from '@chakra-ui/icons';
import { RequestConsultationModal } from '@/features/start/components/SchedulePatientSection/RequestConsultationModal';
import { CustomEventPatient } from './CustomEventPatient';
import '@/styles/calendar.style.css';

const locales = {
  'pt-BR': ptBR,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

export function CalendarPatient(props: Omit<CalendarProps, 'localizer'>) {
  const [newProps, setNewProps] = useState<any>(props);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    data: appointments = [],
    isLoading,
    refetch,
  } = useSchedulePatientQuery();

  useEffect(() => {
    const { events } = props;
    if (events && events?.length > 0) {
      const newEvents = events.map((env, i) => {
        return {
          ...env,
          top: i * 10,
        };
      });
      setNewProps({
        ...props,
        events: newEvents,
      });
    }
  }, [props]);

  return isLoading ? (
    <Skeleton mt="8" w="100%" h="600px" border="20px" />
  ) : (
    <>
      {' '}
      {/* @ts-ignore 
      <BigCalendar
        {...newProps}
        culture="pt-BR"
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        components={{
          event: CustomEventPatient,
          toolbar: Toolbar,
        }}
        formats={{ eventTimeRangeFormat: () => '' }}
        dayLayoutAlgorithm="overlap"
        eventPropGetter={(
          e: any,
          end: any,
          start: any,
          isSelected: boolean,
        ) => {
          return {
            style: {
              cursor: e.ativo ? 'pointer' : 'default',
              position: 'absolute',
              padding: '10px 10px 50px',
              border: 'none',
              borderRadius: '8px',
              background: 'white',
              // maxWidth: e.ativo ? (isMobile() ? 'auto' : '30%') : '100%',
              maxWidth: isMobile() ? 'auto' : '30%',
              borderBottom: '5px solid rgba(0,0,0,0.1)',
              color: '#252525',
              backgroundColor: e.ativo ? 'default' : '#C5C5C5',
              boxShadow: '1px 1px 9px -3px rgba(5, 6, 27, 0.25)',
            },
          };
        }}
      /> */}{' '}
      <Flex
        mt="10"
        w="100%"
        h="560px"
        borderRadius="20px"
        border="1px solid rgba(0,0,0,0.1)"
        flexDirection="column"
        alignItems="center"
        p="5"
      >
        <Flex w="100%" alignItems="center" justifyContent="space-between">
          <Heading
            color="brand-dark.300"
            size="lg"
            fontWeight="semibold"
            mt="5"
          >
            Consultas do Dia
          </Heading>
          <Button
            fontSize="sm"
            borderRadius="30"
            bg="#60C2B0"
            color="white"
            mt="5"
            onClick={onOpen}
          >
            <Icon
              as={IoAddOutline}
              fontSize="20"
              color="#fff"
              right="0"
              justifyContent="flex-end"
            />
            Agendar
          </Button>
        </Flex>
        {appointments && appointments.length > 0 ? (
          appointments.map((event: any) => (
            <CustomEventPatient event={event} title="" />
          ))
        ) : (
          <Text
            mt="5"
            mb="8"
            color="brand-dark.200"
            fontSize="2xl"
            fontWeight="medium"
          >
            Nenhuma consulta
          </Text>
        )}
      </Flex>
      <RequestConsultationModal
        isOpen={isOpen}
        onClose={onClose}
        refetch={refetch}
      />
    </>
  );
}
