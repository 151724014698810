import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { api } from '@/lib';

type RegisterPayload = {
  email: string;
  senha: string;
  nome: string;
  cpf: string;
  rg: string | null;
  celular: string | null;
  dataNascimento: Date;
  endereco: {
    cep: string | null;
    numero: number | null;
    logradouro: string | null;
    complemento: string | null;
    bairro: string | null;
    cidade: string | null;
    estado: string;
    latitude: number | null;
    longitude: number | null;
    regiao: string | null;
    codIBGE: string | null;
  };
  sexoId: string;
  pronomeTratamentoId: string | null;
  enderecoFoto: string | null;
  telefoneResidencial: string | null;
  tokenDeviceId: string | null;
};

async function registerRequest(data: RegisterPayload) {
  const response = await api.post('/usuario', data);

  return response.data;
}

export function useRegisterMutation() {
  const navigate = useNavigate();

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const mutation = useMutation(
    (data: RegisterPayload) => registerRequest(data),
    {
      onSuccess: () => {
        toast({
          title: 'Conta criada com sucesso!',
          status: 'success',
        });

        navigate('/app/dashboard');
      },
      onError: () => {
        toast({
          title: 'Não foi possível criar a conta no momento',
          status: 'error',
        });
      },
    },
  );

  return mutation;
}
