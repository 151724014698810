import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useScheduleDateStore } from '@/features/schedule';
import confirmationImg from '@/assets/register_confirmed.svg';
import { api } from '@/lib';
import { format, parseISO } from 'date-fns';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';
import { useScheduleQuery } from '@/features/schedule/hooks/useScheduleQuery';

export function UnlockScaleCard({ onCancel, event }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });
  const { refetch } = useScheduleIntervalQuery();
  const { refetch: refresh } = useScheduleQuery();

  const [wasFormSubmittedWithSuccess, setWasFormSubmittedWithSuccess] =
    useState(false);
  const { handleSubmit } = useForm();

  const referenceDate = useScheduleDateStore(state => state.referenceDate);
  const data = format(parseISO(referenceDate), 'dd/MM/yyyy');
  const hora = format(event.start, 'HH:mm');

  async function onSubmit() {
    setIsLoading(true);
    try {
      await api.put(`/agenda/trocarStatus?IdAgenda=${event.idAgenda}&status=0`);
      refetch();
      refresh();
      setWasFormSubmittedWithSuccess(true);
    } catch (error: any) {
      toast({
        title: error.response.data.errors[0] || 'Ocorreu um erro!',
        status: 'error',
      });
      setWasFormSubmittedWithSuccess(false);
    } finally {
      setIsLoading(false);
    }
  }

  return wasFormSubmittedWithSuccess ? (
    <Flex w="100%" direction="column" align="center">
      <Heading
        mt="6"
        color="brand-dark.300"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
      >
        O horário foi desbloqueado!
      </Heading>

      <Image
        mt="8"
        mb="4"
        src={confirmationImg}
        alt="Mulher confirmando o sucesso de uma operação em um celular grande"
      />

      <Text
        color="brand-dark.200"
        fontSize="sm"
        fontWeight="normal"
        textAlign="center"
      >
        Você pode editar as configurações na aba de escalas.
      </Text>

      <Button
        type="button"
        onClick={onCancel}
        colorScheme="brand-green"
        mt="10"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Ir para escalas
      </Button>
    </Flex>
  ) : (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      textAlign="center"
    >
      <Heading mt="6" mb="4" color="#039c00" fontSize="xl" fontWeight="medium">
        Desbloquear horário
      </Heading>
      <Box>
        <Text color="brand-dark.300" fontSize="xl" fontWeight="medium">
          Deseja desbloquear horário?
        </Text>
      </Box>

      <SimpleGrid minChildWidth="180px" spacing="8" w="100%" paddingTop="30px">
        <Button
          disabled={isLoading}
          type="button"
          onClick={onCancel}
          variant="outline"
          colorScheme="red"
          h="45px"
          borderRadius="28px"
          color="red"
          fontSize="xs"
          fontWeight="medium"
          textTransform="uppercase"
        >
          Cancelar
        </Button>

        <Button
          isLoading={isLoading}
          type="submit"
          colorScheme="brand-green"
          h="45px"
          borderRadius="28px"
          color="white"
          fontSize="xs"
          fontWeight="medium"
          textTransform="uppercase"
        >
          Confirmar
        </Button>
      </SimpleGrid>
    </Box>
  );
}
