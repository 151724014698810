import create, { StateCreator } from 'zustand';
import { devtools, persist, PersistOptions } from 'zustand/middleware';

type Unidade = {
  id: string;
  descricao: string;
  instanciaSaudeId: string;
};

type UnidadeStore = {
  unidade: Unidade | null;
  refresh: number;
  setUnidade: (unidade: Unidade | null) => void;
  setRefresh: () => void;
};

type UnidadePersist = (
  config: StateCreator<UnidadeStore>,
  options: PersistOptions<UnidadeStore>,
) => StateCreator<UnidadeStore>;

export const useUnidadeStore = create<UnidadeStore>(
  devtools(
    (persist as UnidadePersist)(
      set => ({
        unidade: null,
        refresh: 0,
        setUnidade: unidade => set(state => ({ unidade })),
        setRefresh: () =>
          set(state => ({ ...state, refresh: state.refresh + 1 })),
      }),
      {
        name: 'unidade-store',
        getStorage: () => localStorage,
      },
    ),
    {
      name: 'UnidadeStore',
    },
  ),
);
