/* eslint-disable import/no-duplicates */
import { isValid, parse, isFuture, isToday } from 'date-fns';
import { ptBR } from 'date-fns/locale';

/**
 * isFormatted: caso a data passada esteja formata (dd/mm/yyy), usar true
 */
type IsValidDateOptions = {
  isFormatted?: boolean;
};

export function isValidDate(
  value: string | undefined,
  { isFormatted }: IsValidDateOptions = { isFormatted: false },
) {
  if (!value || value.length < 8) {
    return false;
  }

  let day = 0;
  let month = 0;
  let year = 0;

  if (isFormatted) {
    const [d, m, y] = value.split('/');

    day = parseInt(d, 10);
    month = parseInt(m, 10);
    year = parseInt(y, 10);
  } else {
    day = parseInt(value.slice(0, 2), 10);
    month = parseInt(value.slice(2, 4), 10);
    year = parseInt(value.slice(4), 10);
  }

  const parsedDate = parse(
    `${day}/${month}/${year}`,
    'dd/MM/yyyy',
    new Date(),
    { locale: ptBR },
  );

  return isValid(parsedDate);
}

export function isValidDateAndNotPast(
  value: string | undefined,
  { isFormatted }: IsValidDateOptions = { isFormatted: false },
) {
  if (!value || value.length < 8) {
    return false;
  }

  let day = 0;
  let month = 0;
  let year = 0;

  if (isFormatted) {
    const [d, m, y] = value.split('/');

    day = parseInt(d, 10);
    month = parseInt(m, 10);
    year = parseInt(y, 10);
  } else {
    day = parseInt(value.slice(0, 2), 10);
    month = parseInt(value.slice(2, 4), 10);
    year = parseInt(value.slice(4), 10);
  }

  const parsedDate = parse(
    `${day}/${month}/${year}`,
    'dd/MM/yyyy',
    new Date(),
    { locale: ptBR },
  );

  const itIsAFutureDate = isFuture(parsedDate);
  const theDateIsToday = isToday(parsedDate);

  if ((theDateIsToday || itIsAFutureDate) && isValid(parsedDate)) return true;

  return false;
}

/**
 * isFormatted: caso o mês esteja formato com o ano (mm/yyy), usar true
 */
type IsValidMonthOptions = {
  isFormatted?: boolean;
};

export function isValidMonth(
  value: string | undefined,
  { isFormatted }: IsValidMonthOptions = { isFormatted: false },
) {
  if (!value || value.length < 6) {
    return false;
  }

  let month = 0;
  let year = 0;

  if (isFormatted) {
    const [m, y] = value.split('/');

    month = parseInt(m, 10);
    year = parseInt(y, 10);
  } else {
    month = parseInt(value.slice(0, 2), 10);
    year = parseInt(value.slice(2), 10);
  }

  const parsedDate = parse(`${month}/${year}`, 'MM/yyyy', new Date(), {
    locale: ptBR,
  });

  return isValid(parsedDate);
}

export function isValidNumber(value: string | undefined) {
  if (!value) {
    return false;
  }

  const onlyNumbersRegex = /^[0-9]*$/;

  return onlyNumbersRegex.test(value);
}

/**
 * isFormatted: caso o horário passada esteja formata (hh:mm), usar true
 */
type IsValidTimeOptions = {
  isFormatted?: boolean;
};

export function isValidTime(
  value: string | undefined,
  { isFormatted }: IsValidTimeOptions = { isFormatted: false },
) {
  if (!value || value.length < 4) {
    return false;
  }

  // Tratar a parte de formatação
  if (isFormatted) {
    return false;
  }

  const hours = parseInt(value.slice(0, 2), 10);
  const minutes = parseInt(value.slice(2, 4), 10);

  if (hours > 23 || minutes > 59) {
    return false;
  }

  return true;
}

export function formatCPF(cpf: string | undefined) {
  if (!cpf) {
    return cpf;
  }
  const cpfFormatted = cpf.replace(/[^\d]/g, '');
  return cpfFormatted.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
}
