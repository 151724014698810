import { Flex, Heading, Stack } from '@chakra-ui/react';
import { useUserStore } from '@/stores';
import { DrawerIaChat } from '@/components/IaChat/DrawerIaChat';
import { SelectPatientToGuidance } from '@/components/IaChat/components/SelectPatientToGuidance';
import { useState } from 'react';
import { AddUserButton } from './AddUserButton';

export function TopSection() {
  const user = useUserStore(state => state.user);
  const [chatMessageSelected, setChatMessageSelected] = useState<string>();

  return (
    <Flex
      direction={['column', 'column', 'row']}
      w="100%"
      align="center"
      justify="space-between"
    >
      <Heading
        size="lg"
        mb={['4', '4', '0']}
        color="brand-dark.500"
        fontWeight="medium"
      >
        Início
      </Heading>
      <Stack
        direction={['column', 'row']}
        spacing="4"
        w="100%"
        justify="flex-end"
      >
        {/* <ScaleActions /> */}
        {/* <PatientSearchInput />

        <DateRangeSelect /> */}

        {user?.type !== 'paciente' && (
          <DrawerIaChat
            isFluctuant
            onMessageCallback={setChatMessageSelected}
          />
        )}
        {/* {user?.type !== 'paciente' && <AddUserButton />} */}
      </Stack>

      <SelectPatientToGuidance
        isOpen={!!chatMessageSelected}
        message={chatMessageSelected || ''}
        onClose={() => setChatMessageSelected(undefined)}
      />
    </Flex>
  );
}
