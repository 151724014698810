import { Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';

import { Expertise, Specialties } from '@/features/registers';
import { useEffect } from 'react';

interface SpecialtiesProps {
  specialits: Specialties[];
  onDelete: (rqe: string) => void;
}

export function Expertises({ specialits, onDelete }: SpecialtiesProps) {
  return (
    <Flex w="100%" gap="2" wrap="wrap" align="flex-start" justify="flex-start">
      {specialits.map(spec => (
        <Tag
          key={spec.especialidadeId}
          size="sm"
          variant="solid"
          colorScheme="brand-green"
          borderRadius="full"
        >
          <TagLabel>{spec.descricao}</TagLabel>
          <TagCloseButton onClick={() => onDelete(spec.rqe)} />
        </Tag>
      ))}
    </Flex>
  );
}
