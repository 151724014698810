import { useState } from 'react';
import { api } from '@/lib';
import {
  Badge,
  Box,
  Flex,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useUserStore } from '@/stores';
import { useNavigate } from 'react-router-dom';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';
import { useScheduleQuery } from '@/features/schedule/hooks/useScheduleQuery';
import { formatDate } from '@/utils/formatters';
import { useNotificationsTableQuery } from '../../hooks';
import { ResponseMessageModal } from '../Modal/ResponseMessageModal';

interface NotificationItemProps {
  notification: {
    pacienteNome: string;
    descricao: string;
    data: string;
    hora: string;
    status: number;
    recente: boolean;
    vincular: number;
  };
}

export function NotificationItem({ notification }: any) {
  const navigate = useNavigate();
  const user = useUserStore(state => state.user);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const { refetch: refreshAgenda } = useScheduleIntervalQuery();
  const { refetch: refresh } = useScheduleQuery();
  const { refetch } = useNotificationsTableQuery();
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  function onCloseCancel() {
    setOpenModal(false);
    refetch();
  }

  const Visualizar = (data: any) => {
    api
      .put(`/alerta/web?status=true&id=${data.id}`)
      .then(res => {
        toast({ title: 'Notificação visualizada', status: 'success' });
      })
      .catch(err => {
        toast({ title: err.response.data.errors[0], status: 'error' });
      })
      .finally(() => {
        refetch();
        refresh();
        refreshAgenda();
      });
  };

  const RejeitarNotificacao = (data: any) => {
    if (data.vincular === 0) {
      api
        .put(
          '/paciente/vincular-profissional',
          {},
          {
            params: {
              idPaciente: data.idPaciente,
              idProfissional: data.idProfissional,
              idUnidade: data.idUnidade,
              status: 5,
            },
          },
        )
        .then(res => {
          toast({ title: 'Notificação reprovada', status: 'success' });
        })
        .catch(err => {
          toast({ title: err.response.data.errors[0], status: 'error' });
        })
        .finally(() => {
          refetch();
          refresh();
          refreshAgenda();
        });
    } else if (data.vincular === 1) {
      api
        .put(
          '/consulta',
          {},
          {
            params: {
              idAlertaWeb: data.idAlertaWeb,
              statusHoraAgenda: 4,
              idHorasAgenda: data.idHorasAgenda,
              idUnidade: data.idUnidade,
            },
          },
        )
        .then(res => {
          toast({ title: 'Notificação reprovada', status: 'success' });
        })
        .catch(err => {
          toast({
            title: 'Ocorreu um erro',
            status: 'error',
          });
        })
        .finally(() => {
          refetch();
          refresh();
          refreshAgenda();
        });
    }
  };

  const AprovarNotificacao = (data: any) => {
    if (data.vincular === 0) {
      api
        .put(
          '/paciente/vincular-profissional',
          {},
          {
            params: {
              idPaciente: data.idPaciente,
              idProfissional: data.idProfissional,
              idUnidade: data.idUnidade,
              status: 0,
            },
          },
        )
        .then(res => {
          toast({ title: 'Notificação aprovada', status: 'success' });
          Visualizar(data);
        })
        .catch(err => {
          toast({ title: err.response.data.errors[0], status: 'error' });
        })
        .finally(() => {
          refetch();
          refresh();
          refreshAgenda();
        });
    } else if (data.vincular === 1) {
      api
        .put(
          '/consulta',
          {},
          {
            params: {
              idAlertaWeb: data.idAlertaWeb,
              statusHoraAgenda: 0,
              idHorasAgenda: data.idHorasAgenda,
              idUnidade: data.idUnidade,
            },
          },
        )
        .then(res => {
          toast({ title: 'Notificação aprovada', status: 'success' });
          Visualizar(data);
        })
        .catch(err => {
          toast({
            title: 'Ocorreu um erro',
            status: 'error',
          });
        })
        .finally(() => {
          refetch();
          refresh();
          refreshAgenda();
        });
    }
  };

  const Prescrever = (data: any) => {
    Visualizar(data);
    navigate('/app/prescriptions', {
      state: {
        idPaciente: data.idPaciente,
        idMedico: data.idProfissional || '',
      },
    });
  };

  return (
    <Box
      py="3.5"
      pl="8"
      pr="10"
      w="100%"
      bg={notification.recente ? 'brand-orange.100' : 'white'}
      color="brand-dark.500"
      position="relative"
    >
      {notification.recente && (
        <Badge
          bg="brand-orange.400"
          w="2"
          h="2"
          borderRadius="full"
          position="absolute"
          left="12px"
          bottom="50%"
        />
      )}

      <Text color="brand-dark.500" fontSize="sm" fontWeight="medium">
        {notification.pacienteNome}
      </Text>

      {user?.type !== 'paciente' && (
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Abrir opções para a notificação"
            icon={
              <Icon
                as={HiOutlineDotsVertical}
                fontSize="18px"
                color="brand-dark.500"
              />
            }
            bg="transparent"
            position="absolute"
            top="4px"
            right="4px"
          />
          {notification.categoria === 2 ? (
            <MenuList>
              <MenuItem onClick={() => Visualizar(notification)}>
                Visualizar
              </MenuItem>
            </MenuList>
          ) : notification.categoria === 4 ? (
            <MenuList>
              <MenuItem onClick={() => setOpenModal(true)}>Responder</MenuItem>
            </MenuList>
          ) : notification.categoria === 3 ? (
            <MenuList>
              <MenuItem onClick={() => Prescrever(notification)}>
                Prescrever
              </MenuItem>
            </MenuList>
          ) : (
            <MenuList>
              <MenuItem onClick={() => AprovarNotificacao(notification)}>
                Confirmar
              </MenuItem>
              <MenuItem onClick={() => RejeitarNotificacao(notification)}>
                Negar
              </MenuItem>
            </MenuList>
          )}
        </Menu>
      )}

      <Text
        mt="1"
        mb="2"
        color="brand-dark.300"
        fontSize="xs"
        fontWeight="normal"
      >
        {notification.descricao}
      </Text>
      <Flex w="auto">
        <Modal isOpen={openModal} onClose={onCloseCancel} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody px="12" pb="8">
              <ResponseMessageModal
                onCancel={onCloseCancel}
                notification={notification}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </Box>
  );
}
