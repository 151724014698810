import { Icon, Button, Text } from '@chakra-ui/react';
import { FiLogOut } from 'react-icons/fi';

export function ExportButton() {
  return (
    <Button
      aria-label="Adicionar um novo usuário"
      maxW={['100%', '230px']}
      w="100%"
      h="52px"
      borderRadius="14px"
      bg="white"
      onClick={() => {}}
      _hover={{ bg: 'brand-dark.100' }}
    >
      <Icon
        as={FiLogOut}
        color="brand-dark.200"
        fontSize="20px"
        fontWeight="400"
      />
      <Text
        marginLeft="3"
        color="brand-dark.200"
        fontSize="16px"
        fontWeight="400"
      >
        Exportar Planilha
      </Text>
    </Button>
  );
}
