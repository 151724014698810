import React from 'react';
import { Link, Text } from '@chakra-ui/react';

export function TextTitle({ children }: any) {
  return (
    <Text
      display="flex"
      mb="8"
      mt="3"
      color="#707070"
      fontSize="20px"
      fontWeight="600"
      paddingTop="30px"
      marginBottom="0"
    >
      {children}
    </Text>
  );
}
export function TextSub({ children }: any) {
  return (
    <Text display="flex" color="#707070" fontSize="12px" fontWeight="500">
      {children}
    </Text>
  );
}
export function TextBase({ children }: any) {
  return (
    <Text
      display="flex"
      mb="6"
      mt="3"
      color="#959595"
      fontSize="18px"
      fontWeight="400"
      marginBottom="10px"
    >
      {children}
    </Text>
  );
}

export function TextLink({ children }: any) {
  return (
    <Link
      href={children}
      target="_blank"
      style={{ color: '#0B57D0', textDecoration: 'none' }}
    >
      {children}
    </Link>
  );
}
