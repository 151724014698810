import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';
import { HTMLAttributes } from 'react';
import {
  Controller,
  FieldError,
  FieldValues,
  UseControllerProps,
} from 'react-hook-form';
import { RiErrorWarningFill } from 'react-icons/ri';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import { MASKED_INPUT_MASKS } from '@/utils';
import { IconType } from 'react-icons';

type MaskedInputProps<T extends FieldValues> = {
  icon?: IconType;
  mask: keyof typeof MASKED_INPUT_MASKS;
  label: string;
  error?: FieldError;
  useMaskedValue?: boolean;
} & UseControllerProps<T> &
  HTMLAttributes<HTMLInputElement>;

export function MaskedInput<T extends FieldValues>({
  name,
  control,
  mask,
  error,
  useMaskedValue = false,
  label,
  icon,
  ...rest
}: MaskedInputProps<T>) {
  return (
    <FormControl isInvalid={!!error}>
      {label && <FormLabel htmlFor={name}>{label}</FormLabel>}

      <InputGroup>
        {icon && (
          <InputLeftElement pl="4" pointerEvents="none">
            <Icon as={icon} fontSize="20px" color="gray.600" />
          </InputLeftElement>
        )}
        <Controller
          name={name}
          control={control}
          render={({ field: { value, onChange } }) =>
            icon ? (
              <Input
                {...rest}
                as={NumberFormat}
                value={value}
                format={MASKED_INPUT_MASKS[mask]}
                onValueChange={(v: NumberFormatValues) => {
                  onChange(useMaskedValue ? v.formattedValue : v.value);
                }}
                pl="12"
                pr="8"
                h="42px"
                borderColor="gray.500"
                background="white"
                color="gray.700"
                _placeholder={{ color: 'gray.500' }}
                _hover={{
                  borderColor: 'brand-orange.400',
                }}
              />
            ) : (
              <Input
                {...rest}
                as={NumberFormat}
                value={value}
                format={MASKED_INPUT_MASKS[mask]}
                onValueChange={(v: NumberFormatValues) => {
                  onChange(useMaskedValue ? v.formattedValue : v.value);
                }}
                pl="4"
                pr="8"
                h="50px"
                borderColor="rgba(0, 0, 0, 0.12)"
                background="white"
                color="brand-dark.500"
                _placeholder={{ color: '#bfbfbf' }}
                _hover={{
                  borderColor: 'brand-green.400',
                }}
              />
            )
          }
        />

        <InputRightElement pr="2">
          {error && (
            <Tooltip
              label={error.message}
              aria-label="Warning tooltip"
              bg="gray.800"
              color="white"
            >
              <Box as="span" pt="4">
                <Icon as={RiErrorWarningFill} fontSize="20px" color="red" />
              </Box>
            </Tooltip>
          )}
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
}
