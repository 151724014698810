import { api } from '@/lib';
import { useUserStore } from '@/stores';
import { useQuery } from 'react-query';

const tableHead = ['CID - 10', 'Total', 'Ativos', 'Inativos'];

async function getCid(gestorMasterId: string | undefined) {
  const params = {
    idGestor: gestorMasterId,
  };

  const response = await api.get('/relatorio/cid10', { params });
  const oldTableBody: any[] = response.data.data;

  const tableBody = oldTableBody.map(row => ({
    descricaoCid: row.descricaoCid ?? '',
    totalCid: row.totalCid ?? '',
    cidAtivos: row.cidAtivos ?? '',
    cidInativos: row.cidInativos ?? '',
  }));

  return { tableHead, tableBody };
}

export function useCidTableQuery() {
  const user = useUserStore(state => state.user);

  const query = useQuery(['cidList'], () => getCid(user?.gestorMasterId), {
    refetchOnWindowFocus: false,
  });

  return query;
}
