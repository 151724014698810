import {
  Box,
  Button,
  Checkbox,
  Flex,
  Heading,
  Image,
  List,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import * as yup from 'yup';
import registerConfirmationImg from '@/assets/register_confirmed.svg';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MaskedInput, SelectInput, TextInput } from '@/components';
import { isValidDate } from '@/utils';
import { FiX } from 'react-icons/fi';
import { api } from '@/lib';
import { useUserStore } from '@/stores';
import { examTypeSelectOptions } from './types';
import { FilesList } from './FilesList';

type CurrentCID = {
  descricao: string;
  codCid: string;
};

export const registerSchema = yup.object().shape({
  name: yup.string().required('Campo obrigatório'),
  description: yup.string(),
  cid: yup.string().required('Campo obrigatório'),
  date: yup
    .string()
    .test('isValidDate', 'Data inválida', value => isValidDate(value))
    .required('Campo obrigatório'),
  file: yup.string(),
});

type RegisterExamsFormFields = {
  name: string;
  date: string;
  cid: string;
  files: string;
  description: string;
};

export function ModalAddExams({
  patientId,
  onClose,
  loading,
  setLoading,
}: {
  patientId: string;
  onClose: () => void;
  loading: boolean;
  setLoading: (value: boolean) => void;
}) {
  const [formStep, setFormStep] = useState(0);
  const [inputFocus, setInputFocus] = useState(false);
  const [CIDsInput, setCIDsInput] = useState<CurrentCID[] | any>([]);
  const [CIDsSelected, setSelected] = useState<string>();
  const [openHealthCheck, setOpenHealthCheck] = useState(false);
  const [hasValueDigitized, setHasValueDigitized] = useState(false);
  const [update, setUpdate] = useState(false);
  const [files, setFiles] = useState<any[]>([]);
  const user = useUserStore(state => state.user);
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const {
    control,
    formState: { errors },
    register,
    watch,
    trigger,
    setValue,
    getValues,
    handleSubmit,
    setError,
  } = useForm<RegisterExamsFormFields>({
    defaultValues: {
      name: '',
      date: '',
      cid: '',
      files: '',
      description: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(registerSchema),
  });

  const SetUpdate = () => setUpdate(!update);

  async function onSubmit(values: RegisterExamsFormFields) {
    if (files.length < 1) {
      setError('files', {
        type: 'manual',
        message: 'Arquivo obrigatório',
      });
      return;
    }

    const intlDate = `${values.date.slice(2, 4)}-${values.date.slice(
      0,
      2,
    )}-${values.date.slice(4, 8)}`;

    const exames = files.map(file => {
      return {
        extensao: file.type,
        base64: file.base64,
        titulo: values.name,
        descricao: values.description,
        cid: values.cid,
        enviarOpenHealth: openHealthCheck,
      };
    });

    const body = {
      pacienteId: patientId,
      profissionalId: user!.id,
      dataExame: new Date(intlDate),
      exames,
    };

    setLoading(true);
    await api
      .post(`mevo/post-Exames`, body)
      .then(async response => {
        setFormStep(1);
      })
      .catch(error => {
        toast({
          title: error.response.data.errors[0] || 'Ocorreu um erro!',
          status: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  const changeCidSelect = async (e: any) => {
    setSelected(e.target.value);
    if (!e.target.value) return;

    await api
      .get(`/anamnese/Cid-Nome?nome=${e.target.value}`)
      .then((u: any) => {
        if (u.data.data.length > 0) {
          // const cidsFiltered = u.data.data.filter((cid: any) =>
          //   cid.descricao.toLowerCase().includes(e.target.value.toLowerCase()),
          // );
          setCIDsInput(u.data.data);
        } else {
          setCIDsInput([]);
          setHasValueDigitized(true);
        }
      })
      .catch(u => {});

    if (!e.target.value) {
      setCIDsInput([]);
      setHasValueDigitized(false);
    }
  };

  const selectCID = (cid: CurrentCID) => () => {
    setValue('cid', `${cid.codCid} - ${cid.descricao}`);
    setSelected(`${cid.codCid} - ${cid.descricao}`);
    setInputFocus(false);
  };

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      {formStep === 0 && (
        <VStack spacing="4">
          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <SelectInput
              options={examTypeSelectOptions}
              placeholder="Selecionar Exame"
              label="Exame"
              error={errors.name}
              {...register('name')}
            />
          </SimpleGrid>

          <TextInput
            placeholder="Descrição do exame"
            label="Descrição"
            error={errors.description}
            {...register('description')}
          />

          <TextInput
            placeholder="Insira o CID"
            onChange={changeCidSelect}
            value={CIDsSelected}
            onFocus={() => setInputFocus(true)}
            label="Diagnóstico (CID)"
            // error={errors.type}
            // {...register('type')}
          />

          {CIDsInput && CIDsInput.length && inputFocus && (
            <List
              mb="10"
              h="100%"
              backgroundColor="#FFF"
              borderRadius="15"
              border="1px solid #E4E4E4"
              padding="5"
              style={{
                maxHeight: '10rem',
                overflowY: 'scroll',
                width: '100%',
                height: '100%',
              }}
              sx={{
                '&::-webkit-scrollbar': { width: '6px' },
                '&::-webkit-scrollbar-track': { borderRadius: '8px' },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '15',
                  bg: '#bfbfbf',
                },
              }}
            >
              {CIDsInput.map((c: any) => (
                <Flex
                  style={{ cursor: 'pointer' }}
                  justifyContent="space-between"
                  key={c.id}
                  onClick={selectCID(c)}
                >
                  <Flex
                    p="2"
                    flexDirection="column"
                    border="1px solid #E4E4E4"
                    w="100%"
                    borderRadius="10"
                    mb="5"
                  >
                    <Text
                      fontSize="sm"
                      w="100%"
                      color="#616161"
                      fontFamily="Rubik"
                    >
                      Código: {c.codCid}
                    </Text>
                    <Text
                      fontSize="sm"
                      w="100%"
                      color="#616161"
                      fontFamily="Rubik"
                    >
                      Descrição: {c.descricao}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </List>
          )}

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <MaskedInput
              name="date"
              label="Data do Exame"
              control={control}
              mask="date"
              error={errors.date}
              placeholder="Ex: 01/01/2024"
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <Checkbox
              colorScheme="green"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
              onChange={e => setOpenHealthCheck(e.target.checked)}
            >
              Enviar para Open Health?
            </Checkbox>
          </SimpleGrid>

          <TextInput
            pt="2"
            label="Arquivo"
            type="file"
            accept="image/*, .pdf"
            multiple
            iconView={FiX}
            setView={() => {
              setValue('files', '');
            }}
            onChange={c => {
              if (c.target.files) {
                const { files: fileInput } = c.target;
                for (let i = 0; i < fileInput.length; i += 1) {
                  const reader = new FileReader();
                  reader.onloadend = () => {
                    files.push({
                      file: fileInput[i],
                      id: files.length,
                      base64: reader.result.split(',')[1],
                      type: fileInput[i].type.split('/')[1],
                    });
                    SetUpdate();
                  };
                  reader.readAsDataURL(fileInput[i]);
                }
              }
            }}
            error={errors.files}
            // {...register('files')}
          />
          {files && files.length > 0 && (
            <List
              mb="10"
              h="100%"
              backgroundColor="#FFF"
              style={{
                width: '100%',
              }}
              sx={{
                '&::-webkit-scrollbar': { width: '6px' },
                '&::-webkit-scrollbar-track': { borderRadius: '8px' },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: '15',
                  bg: '#bfbfbf',
                },
              }}
            >
              <FilesList
                files={files}
                onDelete={(id: any) => {
                  setFiles(prevState =>
                    prevState.filter(file => file.id !== id),
                  );
                  SetUpdate();
                }}
              />
            </List>
          )}

          <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
            <Button
              isLoading={loading}
              type="submit"
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Cadastrar
            </Button>
          </SimpleGrid>
        </VStack>
      )}
      {formStep === 1 && (
        <Flex w="100%" direction="column" align="center">
          <Heading
            color="brand-dark.300"
            fontSize="md"
            fontWeight="medium"
            textAlign="center"
          >
            Exame enviado com Sucesso!
          </Heading>

          <Image
            mt="8"
            mb="4"
            src={registerConfirmationImg}
            alt="Mulher confirmando o sucesso de uma operação em um celular grande"
          />

          <Text
            color="brand-dark.200"
            fontSize="sm"
            fontWeight="normal"
            textAlign="center"
          >
            Você pode editar as configurações na aba de exames.
          </Text>

          <Button
            type="button"
            onClick={onClose}
            colorScheme="brand-green"
            mt="10"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Finalizar
          </Button>
        </Flex>
      )}
    </Box>
  );
}
