import { api } from '@/lib';
import { useQuery } from 'react-query';

const useGetAssistants = (nome?: string) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['assistants', nome],
    queryFn: async () => {
      const { data: res } = await api.get(
        '/ai/assistant',
        {},
        {
          params: { nome },
        },
      );
      return res.data;
    },
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetAssistants;
