import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useMutation } from 'react-query';

import { api } from '@/lib';

async function sendEmailRequest(userName: any): Promise<any> {
  const response = await api.post(
    `/usuario/pedir-recuperar-senha?userName=${userName}`,
  );
  return response.data;
}

export function useSendEmailMutation() {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const mutation = useMutation((userName: any) => sendEmailRequest(userName), {
    onSuccess: response => {
      const { data } = response;
    },
    onError: error => {
      if (axios.isAxiosError(error)) {
        const { response } = error;

        toast({
          title:
            response?.data.errors[0] ||
            'Ocorreu um erro. Tente novamente mais tarde.',
          status: 'error',
        });
      } else {
        toast({
          title: 'Servidor não disponível no momento',
          status: 'error',
        });
      }
    },
  });

  return mutation;
}
