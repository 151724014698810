import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useUserStore } from '@/stores';
import * as yup from 'yup';
import confirmationImg from '@/assets/register_confirmed.svg';
import { MaskedInput, TextInput } from '@/components';
import { yupResolver } from '@hookform/resolvers/yup';
import { api } from '@/lib';

type RegisterFormFields = {
  celular: string;
};

export const registerSchema = yup.object().shape({
  celular: yup
    .string()
    .min(11, 'Celular inválido')
    .required('Campo obrigatório'),
});

export function SendCodeProfissionalModal({ onCancel, professional }: any) {
  const user = useUserStore(state => state.user);
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState('');
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });
  const [wasFormSubmittedWithSuccess, setWasFormSubmittedWithSuccess] =
    useState(false);
  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm<RegisterFormFields>({
    defaultValues: {
      celular: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(registerSchema),
  });

  async function onSubmit(data: RegisterFormFields) {
    setIsLoading(true);

    try {
      await api.post(
        `/usuario/enviar-profissional-codigo?NumeroDeTelefone=55${data.celular}&IdProfissional=${professional.id}`,
      );

      setWasFormSubmittedWithSuccess(true);
    } catch (error: any) {
      toast({
        title: error.response.data.errors[0] || 'Ocorreu um erro!',
        status: 'error',
      });
      setWasFormSubmittedWithSuccess(false);
    } finally {
      setIsLoading(false);
    }
  }

  return wasFormSubmittedWithSuccess ? (
    <Flex w="100%" direction="column" align="center">
      <Heading
        mt="6"
        color="brand-dark.300"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
      >
        Código enviado ao paciente
      </Heading>

      <Image
        mt="8"
        mb="4"
        src={confirmationImg}
        alt="Mulher confirmando o sucesso de uma operação em um celular grande"
      />

      <Button
        type="button"
        onClick={onCancel}
        colorScheme="brand-green"
        mt="10"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Fechar
      </Button>
    </Flex>
  ) : (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      textAlign="center"
    >
      <Heading mt="6" mb="4" color="#039c00" fontSize="xl" fontWeight="medium">
        Enviar SMS com código ao paciente
      </Heading>
      <Box>
        <Text color="brand-dark.300" fontSize="xl" fontWeight="medium">
          {`Código de vínculo: "${professional.codigoVinculo}"`}
        </Text>
      </Box>

      <VStack spacing="4" w="100%">
        <SimpleGrid
          minChildWidth="150px"
          spacing="4"
          w="100%"
          paddingTop="30px"
        >
          <MaskedInput
            name="celular"
            label="Celular"
            control={control}
            mask="phone"
            error={errors.celular}
            placeholder="Ex: (12) 91234-5678"
          />
        </SimpleGrid>

        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          <Button
            disabled={isLoading}
            type="button"
            onClick={onCancel}
            variant="outline"
            colorScheme="red"
            h="45px"
            borderRadius="28px"
            color="red"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Voltar
          </Button>

          <Button
            isLoading={isLoading}
            type="submit"
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Enviar
          </Button>
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
