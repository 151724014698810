import { useEffect, useState } from 'react';

import { TopSection } from '@/features/registers';
import { Box, Button, Flex, Icon, Input } from '@chakra-ui/react';
import { useProfessionalStore, useUserStore } from '@/stores';
import { DrawerIaChat } from '@/components/IaChat/DrawerIaChat';
import { SelectPatientToGuidance } from '@/components/IaChat/components/SelectPatientToGuidance';
import { BiSearch } from 'react-icons/bi';
import { Rooms } from '../components/Rooms';
import { ChatRoom } from '../components/ChatRoom';

export function ChatPage() {
  const user = useUserStore(state => state.user);

  const [patientId, setPatientId] = useState<string>(
    user?.type === 'paciente' ? user.pacienteId : '',
  );
  const [search, setSearch] = useState('');
  const [inputSearch, setInputSearch] = useState('');
  const [chatMessageSelected, setChatMessageSelected] = useState<string>();
  const [chatRoomId, setChatRoomId] = useState<string>();
  const [roomId, setRoomId] = useState<string>();
  const [proSelect, setProSelect] = useState<{ name: string; type: string }>();
  const { professional } = useProfessionalStore();

  useEffect(() => {
    setChatRoomId('');
    setProSelect({ name: '', type: '' });
  }, [patientId, professional]);

  useEffect(() => {
    if (professional) {
      setPatientId('');
    }
  }, [professional]);

  useEffect(() => {
    setSearch(inputSearch);
  }, [inputSearch]);

  return (
    <Box display="flex" flexDir="column" overflowY="auto" overflowX="hidden">
      {user?.type === 'medico' && (
        <Flex mb={2}>
          <TopSection title="Chat" />
          <DrawerIaChat
            isFluctuant
            onMessageCallback={setChatMessageSelected}
          />
        </Flex>
      )}

      <Box maxW="2xl" display="flex">
        <Input
          placeholder="Buscar por"
          size="lg"
          value={inputSearch}
          borderRadius="12px 0 0 12px "
          onChange={e => setInputSearch(e.target.value)}
        />
        <Button
          onClick={() => setSearch(inputSearch)}
          borderRadius="0 12px 12px 0"
          size="lg"
        >
          <Icon as={BiSearch} fontSize="27px" />
        </Button>
      </Box>

      <Flex mt="8" direction="row" gap="6" overflowX="hidden">
        <Box maxH="100%" overflowY="auto">
          <Rooms
            key={patientId}
            search={search}
            onChatRoom={setChatRoomId}
            setRoomId={setRoomId}
            setProSelect={setProSelect}
          />
        </Box>
        <Box flex="1" overflowX="hidden">
          <ChatRoom
            key={chatRoomId}
            room={chatRoomId}
            roomId2={roomId}
            proSelect={proSelect}
          />
        </Box>
      </Flex>

      <SelectPatientToGuidance
        isOpen={!!chatMessageSelected}
        message={chatMessageSelected || ''}
        onClose={() => setChatMessageSelected(undefined)}
      />
    </Box>
  );
}
