import create, { StateCreator } from 'zustand';
import { devtools, persist, PersistOptions } from 'zustand/middleware';

type Instancia = {
  id: string;
  descricao: string;
  tipoInstanciaId: string;
};

type InstanciaStore = {
  instancia: Instancia | null;
  refresh: number;
  setInstancia: (instancia: Instancia | null) => void;
  setRefresh: () => void;
};

type InstanciaPersist = (
  config: StateCreator<InstanciaStore>,
  options: PersistOptions<InstanciaStore>,
) => StateCreator<InstanciaStore>;

export const useInstanciaStore = create<InstanciaStore>(
  devtools(
    (persist as InstanciaPersist)(
      set => ({
        instancia: null,
        refresh: 0,
        setInstancia: instancia => set(state => ({ instancia })),
        setRefresh: () =>
          set(state => ({ ...state, refresh: state.refresh + 1 })),
      }),
      {
        name: 'instancia-store',
        getStorage: () => localStorage,
      },
    ),
    {
      name: 'InstanciaStore',
    },
  ),
);
