import {
  Icon,
  Input,
  InputGroup,
  InputLeftElement,
  SimpleGrid,
} from '@chakra-ui/react';
import { useState } from 'react';
import { IoSearchOutline } from 'react-icons/io5';
import { useAsyncDebounce } from 'react-table';

interface SearchInputProps {
  onQuestionSelect: (id: string) => void;
}

export function SearchInput({ filter, setFilter }: any) {
  const [value, setValue] = useState(filter);
  const onChange = useAsyncDebounce(v => {
    setFilter(v || undefined);
  });

  return (
    <SimpleGrid minChildWidth="180px" gap={2} w="100%" marginBottom="10">
      <InputGroup maxW={['100%', '390px']} w="100%">
        <InputLeftElement h="100%" pointerEvents="none">
          <Icon as={IoSearchOutline} fontSize="xl" color="#bfbfbf" />
        </InputLeftElement>
        <Input
          size="lg"
          variant="filled"
          placeholder="Buscar exame"
          h="52px"
          borderRadius="14px"
          bg="white"
          color="brand-dark.500"
          borderColor="brand-dark.100"
          _placeholder={{ color: '#bfbfbf' }}
          _hover={{ bg: 'brand-dark.100' }}
          onChange={e => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
        />
      </InputGroup>
    </SimpleGrid>
  );
}
