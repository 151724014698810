import { apiQuestions } from '@/lib';
import { useQuery } from 'react-query';

interface useGetPatientQuestionResponsesProps {
  disparoId: number;
}

const useGetUsuarioDisparosQuiz = ({
  disparoId,
}: useGetPatientQuestionResponsesProps) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['patient-quizz-disparos', disparoId],
    queryFn: async () => {
      const res = await apiQuestions.get(
        `questionario-cliente/questionarios-usuarios/${disparoId}`,
        {},
      );
      return res?.data[0]?.questionarioUsuario;
    },
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetUsuarioDisparosQuiz;
