import { useQuery } from 'react-query';

import { api } from '@/lib';
import { useProfessionalStore, useUserStore } from '@/stores';

async function getActivities(profissionalId: string, usuarioId: string) {
  const { data } = await api.get(`/usuario/Dashboard`, {
    params: {
      profissionalId,
      usuarioId,
    },
  });

  return data.data;
}

interface useActivitiesQueryProps {
  consultasDia: any[];
  consultasSemana: any[];
  consultasAtendidas: number;
  consultasNaoRealizadas: number;
  consultasDisponibilizadas: number;
  consultasAgendadas: number;
  horariosVagos: number;
  consultasRemarcadas: number;
  consultasCanceladas: number;
  // consultasBloqueadas: number;
  mensagensNaoRespondidasDia: number;
  mensagensNaoRespondidasSemana: number;
  notificacoesNaoVistasDia: number;
  notificacoesNaoVistasSemana: number;
}

export function useActivitiesQuery() {
  const user = useUserStore(state => state.user);
  const pro = useProfessionalStore(state => state.professional);

  const query = useQuery<useActivitiesQueryProps>(
    ['activities', pro?.idUsuario, user?.id],
    () => getActivities(pro?.idUsuario as string, user?.id as string),
    {
      refetchOnWindowFocus: false,
    },
  );

  return query;
}
