import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { useQuery } from 'react-query';

type StatesAPIResponse = {
  sigla: string;
};

async function brazilStatesRequest(): Promise<StatesAPIResponse[]> {
  const response = await axios.get(
    'https://servicodados.ibge.gov.br/api/v1/localidades/estados?orderBy=nome',
  );

  return response.data;
}

export function useBrazilStatesQuery() {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const query = useQuery('brazilStates', brazilStatesRequest, {
    refetchInterval: false,
    select: originalResponse =>
      originalResponse.map(state => ({
        label: state.sigla,
        value: state.sigla,
      })),
    onError: () => {
      toast({
        title: 'Não foi possível trazer os estados no momento',
        status: 'error',
      });
    },
  });

  return query;
}
