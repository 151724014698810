import { api } from '@/lib';
import { useQuery } from 'react-query';

const handleFileDataType = (ext: any) => {
  switch (ext) {
    case 'pdf':
      return 'application/pdf';
    case 'jpg':
      return 'image/jpeg';
    case 'jpeg':
      return 'image/jpeg';
    case 'png':
      return 'image/png';
    case 'tiff':
      return 'image/tiff';
    case 'docx':
      return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
    case 'txt':
      return 'text/plain';
    default:
      return 'image/jpeg';
  }
};

const useGetPatientExams = (pacienteId: string) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['patient-exams', pacienteId],
    queryFn: async () => {
      const res = await api.get(`mevo/listar-Exames`, {
        params: {
          pacienteId,
        },
      });

      const examFormat = await Promise.all(
        // eslint-disable-next-line array-callback-return
        res.data.data.map(async (ex: any) => {
          if (ex.groupId !== '00000000-0000-0000-0000-000000000000') {
            const file = await api.get(`mevo/get-Exame?id=${ex.groupId}`);
            return {
              ...ex,
              files: file.data.data,
            };
          }

          return ex;
        }),
      );

      return examFormat;
    },
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetPatientExams;
