import { Box, Flex, Stack, Skeleton, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import {
  MONTHS,
  Day,
  useScheduleDateStore,
  Patient,
} from '@/features/schedule';
import { Calendar } from '@/components';
import { UnlockScaleCard } from '@/components/Calendar/EventActions/UnlockScaleCard';
import { ItemsCarousel } from './ItemsCarousel';
import { MonthButton } from './MonthButton';
import { DayButton } from './DayButton';
import { useScheduleIntervalQuery } from '../../hooks/useScheduleIntervalQuery';

export function SchedulerSection() {
  const { referenceDate, setReferenceDate } = useScheduleDateStore();

  const [activeMonth, setActiveMonth] = useState(0);
  const [monthSlide, setMonthSlide] = useState<number>(0);
  const [daySlide, setDaySlide] = useState<number>(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(23);
  const [times, setTimes] = useState<any>([]);
  const [days, setDays] = useState<Day[]>([]);
  const [activeDay, setActiveDay] = useState(0);
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: appointments = [], isLoading } = useScheduleIntervalQuery();

  const today = new Date();

  function generateDaysInMonth(month: number) {
    const dayInMonth = new Date(today.getFullYear(), month, 1);
    const allDaysInMonth = [];

    while (dayInMonth.getMonth() === month) {
      allDaysInMonth.push({
        numeric: dayInMonth.getDate(),
        name: new Intl.DateTimeFormat('pt-BR', { weekday: 'short' }).format(
          dayInMonth,
        ),
      });

      dayInMonth.setDate(dayInMonth.getDate() + 1);
    }

    setDays(allDaysInMonth);
  }

  function handleMonthSelection(month: number) {
    const parsedReferenceDate = parseISO(referenceDate);

    parsedReferenceDate.setMonth(month);
    parsedReferenceDate.setDate(1);

    setReferenceDate(parsedReferenceDate.toISOString());
  }

  function handleDaySelection(day: number) {
    const parsedReferenceDate = parseISO(referenceDate);

    parsedReferenceDate.setDate(day);

    setReferenceDate(parsedReferenceDate.toISOString());
  }

  useEffect(() => {
    const parsedReferenceDate = parseISO(referenceDate);

    setActiveMonth(parsedReferenceDate.getMonth());
    generateDaysInMonth(parsedReferenceDate.getMonth());
    setActiveDay(parsedReferenceDate.getDate());
  }, [referenceDate]);

  useEffect(() => {
    if (!isLoading && activeDay !== 0) {
      if (activeDay < 3) {
        setDaySlide(activeDay - 1);
      } else {
        setTimeout(() => {
          setDaySlide(activeDay - 3);
        }, 500);
      }

      if (activeMonth < 2) {
        setMonthSlide(activeMonth);
      } else {
        setTimeout(() => {
          setMonthSlide(activeMonth - 2);
        }, 500);
      }
    }
  }, [isLoading, activeDay]);

  useEffect(() => {
    setMin(0);
    if (appointments && appointments.length > 0) {
      const orderTimes = appointments.sort(
        (a: any, b: any) => a.start - b.start,
      );
      const minTime = orderTimes[0].start;
      const maxTime = orderTimes[orderTimes.length - 1].end;

      setTimes(orderTimes);
      setMin(minTime.getHours());

      if (maxTime.toString() !== 'Invalid Date') {
        setMax(maxTime.getHours() + 2 > 13 ? 23 : maxTime.getHours() + 2);
      }
    } else {
      setTimes([]);
    }
  }, [appointments]);

  // const adjEvents = times.map((it: any, ind: any) => ({
  //   ...it,
  //   isResizable: ind % 2 === 0,
  //   isDraggable: ind % 2 === 0,
  // }));

  // const [myEvents, setMyEvents] = useState(adjEvents);
  // const [resizable, setResizable] = useState(true);

  // const eventPropGetter = useCallback(
  //   event => ({
  //     // add class if not allowing resizing at all, or if
  //     // allowing resizing, but not on a specific event
  //     ...((!resizable || !event.isResizable) && { className: 'nonResizable' }),
  //   }),
  //   [resizable],
  // );

  // const toggleResizing = useCallback(() => setResizable(prev => !prev), []);

  // const resizeEvent = useCallback(
  //   (e: any) => {
  //     // setMyEvents((prev: any) => {
  //     //   const existing = prev.find((ev: any) => ev.id === event.id) ?? {};
  //     //   const filtered = prev.filter((ev: any) => ev.id !== event.id);
  //     //   return [...filtered, { ...existing, start, end }];
  //     // });
  //   },
  //   [setMyEvents],
  // );

  return (
    <Stack
      spacing="4"
      mt="4"
      w="100%"
      direction={['column', null, null, null, 'row']}
      sx={{
        '.carousel-item': { '&:not(:last-child)': { paddingRight: '20px' } },
        '.carousel-container': { padding: '12px' },
        '.react-multiple-carousel__arrow': { zIndex: 1 },
      }}
    >
      <Flex w="100%" direction="column" align="center">
        <Box maxWidth="1000px" w="100%">
          <ItemsCarousel activeSlide={monthSlide}>
            {MONTHS.map(month => (
              <MonthButton
                key={month.numeric}
                month={month}
                isActive={activeMonth === month.numeric}
                onMonthSelect={handleMonthSelection}
              />
            ))}
          </ItemsCarousel>
        </Box>

        <Box mt="4" maxWidth="1000px" w="100%">
          <ItemsCarousel activeSlide={daySlide}>
            {days.map(day => (
              <DayButton
                key={day.numeric}
                day={day}
                isActive={activeDay === day.numeric}
                onDaySelect={handleDaySelection}
              />
            ))}
          </ItemsCarousel>
        </Box>

        {isLoading ? (
          <Skeleton mt="4" w="100%" h="800px" borderRadius="20px" />
        ) : (
          <Box
            mt="4"
            maxW="100%"
            w="100%"
            h="1000px"
            overflowY="auto"
            // overflowX="inherit"
            justifyContent="space-between"
          >
            <Calendar
              defaultView="day"
              events={times}
              toolbar={false}
              onSelectEvent={(slot: any, e: any) => {
                // if (slot.status === 4) {
                //   setSelectedPatient(slot);
                //   onOpen();
                // }
              }}
              scrollToTime={
                min > 0
                  ? new Date(
                      today.getFullYear(),
                      today.getMonth(),
                      today.getDay(),
                      min,
                    )
                  : new Date(
                      today.getFullYear(),
                      today.getMonth(),
                      today.getDay(),
                      7,
                    )
              }
              min={
                new Date(today.getFullYear(), today.getMonth(), today.getDay())
              }
              max={
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDay(),
                  23,
                )
              }
            />
          </Box>
        )}
      </Flex>

      {isOpen && <UnlockScaleCard onCancel={onClose} event={selectedPatient} />}

      {/* <ScaleActions /> */}

      {/* {isOpen && (
        <Box
          bg="white"
          borderRadius="10px"
          boxShadow="0.8px 0.8px 7px -2px rgba(5, 6, 27, 0.25)"
          marginLeft="-1"
          position="absolute"
          padding="10px"
          top={`${pos[1]}px`}
          left={`${pos[0]}px`}
          zIndex="3"
          width="200px"
        >
          <Button bg="white" width="100%">
            <Text textAlign="left" width="100%">
              Teste
            </Text>
          </Button>
          <Button bg="white" width="100%">
            <Text textAlign="left" width="100%">
              Teste
            </Text>
          </Button>
          <Button bg="white" width="100%">
            <Text textAlign="left" width="100%">
              Teste
            </Text>
          </Button>
        </Box>
      )} */}
    </Stack>
  );
}
