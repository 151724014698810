export function isMobile() {
  if (
    window.screen.width < 640 ||
    window.screen.height < 480 ||
    window.screen.width < 1024 ||
    window.screen.height < 768
  ) {
    return true;
  }

  return false;
}
