/* eslint-disable import/no-duplicates */
import { useState, useEffect } from 'react';
import { Skeleton } from '@chakra-ui/react';
import {
  Calendar as BigCalendar,
  CalendarProps,
  dateFnsLocalizer,
} from 'react-big-calendar';
// import overlap from 'react-big-calendar/lib/utils/layout-algorithms/overlap';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import { isMobile } from '@/utils';
import ptBR from 'date-fns/locale/pt-BR';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';
import { CustomEvent } from './CustomEvent';
import '@/styles/calendar.style.css';
import Toolbar from '../CalendarWeek.tsx/Toolbar';

const locales = {
  'pt-BR': ptBR,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// const localizer = momentLocalizer(moment);

export function Calendar(
  props: Omit<CalendarProps, 'localizer'>,
  home: boolean,
) {
  const [newProps, setNewProps] = useState<any>(props);
  const [groupEvents, setGroupEvents] = useState<any>([]);
  const { data: appointments = [], isLoading } = useScheduleIntervalQuery();

  const getColor = (e: boolean) => {
    return e ? 'default' : '#C5C5C5';
  };

  useEffect(() => {
    const { events } = props;

    if (events && events?.length > 0) {
      const newEvents = events.map((env, i) => {
        return {
          ...env,
          top: i * 10,
        };
      });
      setNewProps({
        ...props,
        events: newEvents,
      });

      setGroupEvents(
        events?.reduce((acc, item, i) => {
          const hour: any = new Date(item.start).getHours();
          const minute: any = new Date(item.start).getMinutes() < 30 ? 1 : 2;
          return {
            ...acc, // @ts-ignore
            [`${hour}${minute}`]: [
              // @ts-ignore
              ...(acc[`${hour}${minute}`] ?? []),
              item,
            ],
          };
        }, {}),
      );
    }
  }, [props]);

  return isLoading ? (
    <Skeleton mt="8" w="100%" h="600px" border="20px" />
  ) : (
    <>
      {/* @ts-ignore */}
      <BigCalendar
        {...newProps}
        culture="pt-BR"
        localizer={localizer}
        startAccessor="start"
        endAccessor="end"
        timeslots={1}
        components={{
          event: CustomEvent,
          toolbar: Toolbar,
        }}
        formats={{ eventTimeRangeFormat: () => '' }}
        dayLayoutAlgorithm="overlap"
        eventPropGetter={(
          e: any,
          // end: any,
          // start: any,
          // isSelected: boolean,
        ) => {
          // const start: any = new Date(e.start);
          // const end: any = new Date(e.end);
          // const calc = Math.abs(start - end) / 60000;
          // const diference = calc > 15 ? 50 : calc > 4 ? calc * 3.2 : calc * 5;
          const hour: any = new Date(e.start).getHours();
          const minute: any = new Date(e.start).getMinutes() < 30 ? 1 : 2;
          const hourLength = groupEvents[`${hour}${minute}`]?.length || 0;
          const diference =
            hourLength < 3
              ? '95%'
              : hourLength < 5
              ? '45%'
              : hourLength < 7
              ? '30%'
              : '10%';
          return {
            style: {
              cursor: e.ativo ? 'pointer' : 'default',
              position: 'absolute',
              padding: '10px 10px 50px',
              border: 'none',
              borderRadius: '8px',
              background: 'white',
              width: 'auto',
              maxWidth: diference,
              // maxWidth: isMobile() ? 'auto' : diference < 300000 ? '5%' : '30%',
              borderBottom: '5px solid rgba(0,0,0,0.1)',
              color: '#252525',
              backgroundColor: getColor(e.ativo),
              boxShadow: '1px 1px 9px -3px rgba(5, 6, 27, 0.25)',
              maxHeight: '10px',
            },
          };
        }}
      />
    </>
  );
}
