import { useQuery } from 'react-query';

import { api } from '@/lib';

type TypeInstanceAPIResponse = {
  data: {
    id: string;
    descricao: string;
  }[];
};

async function typeInstanceRequest(): Promise<TypeInstanceAPIResponse> {
  const response = await api.get('/instancia/tipos-instancia');
  return response.data;
}

export function useTypeInstanceQuery() {
  const query = useQuery('typeInstance', typeInstanceRequest, {
    refetchInterval: false,
    select: originalResponse => {
      return originalResponse.data.map(instance => {
        return {
          label: instance.descricao,
          value: instance.id,
        };
      });
    },
  });

  return query;
}
