import { SelectPatientToGuidance } from '@/components/IaChat/components/SelectPatientToGuidance';
import { DrawerIaChat } from '@/components/IaChat/DrawerIaChat';
import { useUserStore } from '@/stores';
import { Flex, Heading, Stack } from '@chakra-ui/react';
import { useState } from 'react';
import { PatientSearchInput } from './PatientSearchInput';

export function TopSection({ filter, setFilter }: any) {
  const user = useUserStore(state => state.user);

  const [chatMessageSelected, setChatMessageSelected] = useState<string>();

  return (
    <Flex flexDir="column" gap={3}>
      {user?.type !== 'paciente' && (
        <DrawerIaChat
          ml="auto"
          isFluctuant
          onMessageCallback={setChatMessageSelected}
        />
      )}

      <Flex
        direction={['column', 'column', 'row']}
        w="100%"
        align="center"
        justify="space-between"
      >
        <Heading
          size="lg"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          w="100%"
        >
          Agenda | Prescrição
        </Heading>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          w="100%"
          justify="flex-end"
        >
          <PatientSearchInput filter={filter} setFilter={setFilter} />
        </Stack>
      </Flex>

      <SelectPatientToGuidance
        isOpen={!!chatMessageSelected}
        message={chatMessageSelected || ''}
        onClose={() => setChatMessageSelected(undefined)}
      />
    </Flex>
  );
}
