import * as yup from 'yup';

export const registerSchema = yup.object().shape({
  descricao: yup.string().required('Campo obrigatório'),
  cep: yup.string().length(8, 'CEP inválido').required('Campo obrigatório'),
  numero: yup.string(),
  endereco: yup.string().required('Campo obrigatório'),
  estado: yup.string().required('Campo obrigatório'),
  cidade: yup.string().required('Campo obrigatório'),
  bairro: yup.string().required('Campo obrigatório'),
  complemento: yup.string(),
});
