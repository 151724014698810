import { useQuery } from 'react-query';

import { api } from '@/lib';
import { useUserStore } from '@/stores';

type InstanceAPIResponse = {
  data: {
    id: string;
    descricao: string;
    tipoInstanciaSaudeId: string;
  };
};

async function instanceAllRequest() {
  const response = await api.get(`/instancia/instancias`);
  return response.data;
}

export function useInstanceQuery() {
  const user = useUserStore(state => state.user);

  const ids = user?.instanciasId;
  const admin = user?.isAdmin;
  const query = useQuery('instance', instanceAllRequest, {
    refetchInterval: false,
    select: originalResponse => {
      const { data } = originalResponse;

      if (!admin) {
        return originalResponse.data
          .filter((i: any) => {
            return ids?.filter(c => c === i.id).length;
          })
          .map((instance: any) => {
            return {
              label: instance.descricao,
              value: instance.id,
              tipo: instance.tipoInstanciaSaudeId,
              cnpj: instance.cnpj,
              endereco: instance.endereco,
              enderecoId: instance.enderecoId,
            };
          });
      }
      return originalResponse.data.map((instance: any) => {
        return {
          label: instance.descricao,
          value: instance.id,
          tipo: instance.tipoInstanciaSaudeId,
          cnpj: instance.cnpj,
          endereco: instance.endereco,
          enderecoId: instance.enderecoId,
        };
      });
    },
  });

  return query;
}
