import { Button, Flex, Heading, Image, Stack, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { IoLockClosed } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { newPasswordSchema } from '@/features/auth/utils';

import { AppVersion } from '@/components';
import { TextInput } from '@/features/auth/components/FormItems';

import logoImg from '@/assets/logo.png';
import { useState } from 'react';
import { FiEye } from 'react-icons/fi';
import { useRecoveryPassMutation } from '../../hooks';
import { useQueryParams } from '../../hooks/useQueryParams';

type RecoveryFormFields = {
  password: string;
  confirmPassword: string;
};

export function NewPasswordForm() {
  const [formStep, setFormStep] = useState(0);
  const [viewPass, setViewPass] = useState(false);
  const [viewConfirmPass, setViewConfirmPass] = useState(false);
  const data = useQueryParams();

  const navigate = useNavigate();

  const { mutate } = useRecoveryPassMutation();

  const {
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    getValues,
  } = useForm<RecoveryFormFields>({
    defaultValues: {
      password: '',
      confirmPassword: '',
    },
    resolver: yupResolver(newPasswordSchema),
    shouldFocusError: false,
  });

  async function onSubmit(values: RecoveryFormFields) {
    const payload = {
      password: values.password,
      tokenNovo: data?.token,
      username: data?.username,
    };

    mutate(payload, {
      onSuccess: response => {
        setFormStep(currStep => currStep + 1);
      },
    });
  }

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      py={['12', '12', '8']}
      px={['8', '14', '14']}
      w="100%"
      height="100%"
      bg="white"
      borderRadius="0 22px 22px 0"
      direction="column"
      align="center"
      justify="center"
    >
      <Image
        src={logoImg}
        alt="Logo da Dynamic Medic Health"
        mt="auto"
        mb="8"
        w="166px"
        h="77px"
      />

      {formStep === 0 && (
        <VStack spacing="4" w="100%">
          <Heading size="md" mb="6" color="gray.700" textAlign="center">
            Digite sua nova senha
          </Heading>
          {/* <Text color="gray.400">Digite sua nova senha</Text> */}

          <TextInput
            icon={IoLockClosed}
            iconView={FiEye}
            setView={() => setViewPass(!viewPass)}
            error={errors.password}
            type={viewPass ? 'text' : 'password'}
            placeholder="Nova senha"
            {...register('password')}
          />
          <TextInput
            icon={IoLockClosed}
            iconView={FiEye}
            setView={() => setViewConfirmPass(!viewConfirmPass)}
            error={errors.confirmPassword}
            type={viewConfirmPass ? 'text' : 'password'}
            placeholder="Confirmar nova senha"
            {...register('confirmPassword')}
          />
        </VStack>
      )}

      {formStep === 1 && (
        <VStack spacing="4" w="100%">
          <Heading size="md" mb="6" color="gray.700" textAlign="center">
            Sua senha foi alterada com sucesso.
          </Heading>
        </VStack>
      )}

      <Stack direction={['column', 'row']} spacing="4" mt="6" w="100%">
        {formStep === 0 ? (
          <Button
            type="submit"
            // isLoading={isLoading}
            mb="10"
            w="100%"
            h="38px"
            borderRadius="6"
            bg="brand-orange.400"
            color="white"
            fontFamily="Inter"
            fontSize="md"
            fontWeight="normal"
            boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
            _hover={{
              background: 'brand-orange.300',
            }}
          >
            Salvar
          </Button>
        ) : formStep === 1 ? (
          <Button
            type="button"
            onClick={() => navigate('/auth/login')}
            mb="10"
            w="100%"
            h="38px"
            borderRadius="6"
            bg="brand-orange.400"
            color="white"
            fontFamily="Inter"
            fontSize="md"
            fontWeight="normal"
            boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
            _hover={{
              background: 'brand-orange.300',
            }}
          >
            Faça Login
          </Button>
        ) : null}
      </Stack>
      <AppVersion mt={['8', '8', 'auto']} />
    </Flex>
  );
}
