import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import registerConfirmationImg from '@/assets/register_confirmed.svg';

import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useState } from 'react';

import { MaskedInput, SelectInput, TextInput } from '@/components';

import { api, getCep } from '@/lib';
import { useGendersQuery } from '@/hooks';
import { useBrazilStatesQuery, useBrazilCitiesQuery } from '../../hooks';
import { registerSchema } from '../../utils';

interface Props {
  unidade: any;
  onCancel: () => void;
  // onSubmit: (data: any) => void;
}

type EditUnidadeFormFields = {
  descricao: string;
  cnpj: string;
  instanciaId: string;
  enderecoId: string;
  cep: string;
  numero: string;
  endereco: string;
  bairro: string;
  cidade: string;
  estado: string;
  complemento: string;
};

export function EditUnidadeForm({ unidade, onCancel }: Props) {
  const { data: genders = [] } = useGendersQuery();
  const [formStep, setFormStep] = useState(0);
  const { data: states = [] } = useBrazilStatesQuery();
  const [loading, setLoading] = useState<boolean>(false);

  const {
    control,
    formState,
    register,
    getValues,
    handleSubmit,
    setValue,
    watch,
    trigger,
  } = useForm<EditUnidadeFormFields>({
    defaultValues: {
      descricao: unidade.label || '',
      cnpj: unidade.cnpj || '',
      cep: unidade?.endereco?.cep || '',
      numero: unidade?.endereco?.numero || '',
      endereco: unidade?.endereco?.logradouro || '',
      bairro: unidade?.endereco?.bairro || '',
      cidade: unidade?.endereco?.cidade || '',
      estado: unidade?.endereco?.estado || '',
      complemento: unidade?.endereco?.complemento || '',
      enderecoId: unidade?.enderecoId || '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(registerSchema),
  });

  const { data: cities = [] } = useBrazilCitiesQuery(watch('estado'));

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const { errors } = formState;

  async function onSubmit(values: EditUnidadeFormFields) {
    const payload = {
      id: unidade.value,
      descricao: values.descricao,
      cnpj: values.cnpj,
      instanciaSaudeId: unidade.instance,
      instanciaSaude: {
        id: unidade.instance,
      },
      enderecoId: unidade.enderecoId || null,
      endereco: {
        id: unidade.enderecoId || null,
        cep: values.cep,
        numero: values.numero,
        logradouro: values.endereco,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        estado: values.estado,
        latitude: 0,
        longitude: 0,
        regiao: null,
        codIBGE: null,
      },
    };

    try {
      setLoading(true);
      const result: any = await api.put(`/instancia/Editar-Unidade`, payload);
      if (result?.statusText === 'OK') {
        toast({
          title: 'Unidade editada com sucesso!',
          status: 'success',
        });
        // window.location.reload();
      }
    } catch (error) {
      toast({
        title: 'Ops algo deu errado!',
        status: 'error',
      });
    } finally {
      onCancel();
      setLoading(false);
    }
  }

  async function checkCEP(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.value) return;

    const cep = e.target.value.replace(/\D/g, '');

    if (cep.length < 8) return;

    const address = await getCep(cep);

    setValue('endereco', address?.data.logradouro);
    setValue('bairro', address?.data.bairro);
    setValue('complemento', address?.data.complemento);
    setValue('estado', address?.data.uf);

    setTimeout(() => {
      setValue('cidade', address?.data.localidade);
    }, 1000);
  }

  function handleStepBack() {
    if (formStep === 0) {
      onCancel();
    } else {
      setFormStep(currStep => currStep - 1);
    }
  }

  async function handleStepCompletion() {
    let isFormValid = false;

    if (formStep === 0) {
      const [CNPJ] = getValues(['cnpj']);
      if (false) {
        toast({
          title: 'Preencha todos os campos obrigatórios!',
          status: 'error',
        });
        isFormValid = false;
      } else {
        isFormValid = await trigger(['descricao', 'cnpj']);
      }
    }

    if (isFormValid) {
      setFormStep(currStep => currStep + 1);
    }
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      {formStep === 0 && (
        <VStack spacing="4">
          <TextInput
            placeholder="Ex: Hospital Geral"
            label="Descrição"
            error={errors.descricao}
            {...register('descricao')}
          />

          <MaskedInput
            name="cnpj"
            label="CNPJ (opcional)"
            control={control}
            mask="cnpj"
            error={errors.cnpj}
            placeholder="Ex: 12.345.678.0000-00"
          />

          <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
            <Button
              isDisabled={loading}
              type="button"
              onClick={handleStepBack}
              variant="outline"
              colorScheme="brand-orange"
              h="45px"
              borderRadius="28px"
              color="brand-orange.300"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Voltar
            </Button>
            <Button
              isLoading={loading}
              type="button"
              onClick={handleStepCompletion}
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Continuar
            </Button>
          </SimpleGrid>
        </VStack>
      )}
      {formStep === 1 && (
        <VStack spacing="4">
          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <MaskedInput
              name="cep"
              label="CEP"
              control={control}
              mask="zipCode"
              error={errors.cep}
              placeholder="Ex: 12345-678"
              onBlur={checkCEP}
              onChange={checkCEP}
            />

            <TextInput
              placeholder="Ex: 123"
              label="Número"
              error={errors.numero}
              {...register('numero')}
            />
          </SimpleGrid>

          <TextInput
            placeholder="Ex: Rua José Tavares"
            label="Endereço"
            error={errors.endereco}
            {...register('endereco')}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <SelectInput
              options={states}
              placeholder="Escolher estado"
              label="Estado"
              error={errors.estado}
              {...register('estado')}
            />

            <SelectInput
              options={cities}
              placeholder="Escolher cidade"
              label="Cidade"
              error={errors.cidade}
              {...register('cidade')}
              defaultValue="São Sebastião"
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <TextInput
              placeholder="Ex: Bairro Nova Era"
              label="Bairro"
              error={errors.bairro}
              {...register('bairro')}
            />

            <TextInput
              placeholder="Ex: Próximo ao mercado"
              label="Complemento (opcional)"
              error={errors.complemento}
              {...register('complemento')}
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
            <Button
              isDisabled={loading}
              type="button"
              onClick={handleStepBack}
              variant="outline"
              colorScheme="brand-orange"
              h="45px"
              borderRadius="28px"
              color="brand-orange.300"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Voltar
            </Button>
            <Button
              isLoading={loading}
              type="submit"
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Cadastrar
            </Button>
          </SimpleGrid>
        </VStack>
      )}
      {formStep === 2 && (
        <Flex w="100%" direction="column" align="center">
          <Heading
            color="brand-dark.300"
            fontSize="md"
            fontWeight="medium"
            textAlign="center"
          >
            Cadastro Realizado com Sucesso!
          </Heading>

          <Image
            mt="8"
            mb="4"
            src={registerConfirmationImg}
            alt="Mulher confirmando o sucesso de uma operação em um celular grande"
          />

          <Text
            color="brand-dark.200"
            fontSize="sm"
            fontWeight="normal"
            textAlign="center"
          >
            Você pode editar as configurações na aba de cadastros.
          </Text>

          <Button
            type="button"
            onClick={onCancel}
            colorScheme="brand-green"
            mt="10"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Finalizar
          </Button>
        </Flex>
      )}
    </Box>
  );
}
