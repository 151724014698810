import { useEffect } from 'react';
import {
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
  Text,
  Heading,
  Box,
  Flex,
  Divider,
} from '@chakra-ui/react';
import { alertColorBox } from '@/features/start/types';
import {
  TextLink,
  TextSub,
} from '@/features/historic/components/TextBase/TextBase';
import { formatDate } from '@/utils';
import { useUserStore } from '@/stores';
import { ConsultationInfo } from '..';

interface Props {
  activities: any;
  patient: any;
}

const FormatData = ({ label1, value1, label2, value2 }: any) => {
  return (
    <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
      <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
        <Heading as="h6" size="sm" color="#707070">
          {label1}
        </Heading>
        <Text>{value1}</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Heading as="h6" size="sm" color="#707070">
          {label2}
        </Heading>
        <Text>{value2}</Text>
      </GridItem>
    </Grid>
  );
};

export function ViewPatientDashInfo({ activities, patient }: Props) {
  const user = useUserStore(state => state.user);
  useEffect(() => {}, [activities]);

  return (
    <VStack spacing="4">
      {user?.type !== 'paciente' && (
        <>
          <Text
            color="brand-dark.500"
            fontSize="2xl"
            fontWeight="medium"
            w="100%"
            pt={5}
          >
            DADOS PACIENTE
          </Text>
          <ConsultationInfo patientSelect={patient} />
          <Divider mt="4" mb="8" />
        </>
      )}

      {activities.consultasFuturas && activities.consultasFuturas.length > 0 && (
        <Box w="100%">
          <Text
            color="brand-dark.500"
            fontSize="2xl"
            fontWeight="medium"
            w="100%"
            pt={5}
          >
            -- PRÓXIMA CONSULTA
          </Text>
          <Box>
            <Text
              mt="3"
              color="brand-dark.200"
              fontSize="2xl"
              fontWeight="medium"
            >
              {formatDate(activities.consultasFuturas[0].date)} -{' '}
              {activities.consultasFuturas[0].tipoConsulta === 0
                ? 'PRESENCIAL'
                : 'ONLINE'}
            </Text>

            <Text color="brand-dark.200" fontSize="xl" fontWeight="medium">
              Profissional de Saúde:{' '}
              {activities.consultasFuturas[0].profissional}
            </Text>
            <Text color="brand-dark.200" fontSize="xl" fontWeight="medium">
              Unidade de Saúde:{' '}
              {activities.consultasFuturas[0].unidadeSaude.descricao}
            </Text>
          </Box>
        </Box>
      )}

      <Divider mt="4" mb="8" />

      {activities.cidAtual && (
        <Box w="100%">
          <Text
            color="brand-dark.500"
            fontSize="2xl"
            fontWeight="medium"
            w="100%"
            pt={5}
          >
            -- CID ATUAL
          </Text>

          <Text color="brand-dark.400" fontSize="xl" fontWeight="medium">
            {activities.cidAtual}
          </Text>
        </Box>
      )}
      <Divider mt="4" mb="8" />

      {activities.monitoramentoAtual && (
        <>
          <Flex w="100%" alignItems="center">
            <Text
              color="brand-dark.500"
              fontSize="2xl"
              fontWeight="medium"
              w="100%"
              pt={5}
            >
              -- MONITORAMENTO ATUAL
            </Text>
            <Box
              bg={alertColorBox(activities.monitoramentoAtual.corAlerta) as any}
              w="25px"
              h="25px"
              p="5"
              borderRadius="20px"
            />
          </Flex>
          <Box>
            <Text color="brand-dark.400" fontSize="xl" fontWeight="medium">
              {activities.monitoramentoAtual.nomeQuestionario}
            </Text>
            <Text
              mb="8"
              color="brand-dark.200"
              fontSize="xl"
              fontWeight="medium"
              noOfLines={3}
            >
              {activities.monitoramentoAtual.feedBack}
            </Text>
          </Box>
        </>
      )}
      <Divider mt="4" mb="8" />

      {activities && activities.medicacoesAtuais.length > 0 && (
        <>
          <Text
            color="brand-dark.500"
            fontSize="2xl"
            fontWeight="medium"
            w="100%"
            mb={5}
          >
            -- MEDICAMENTOS
          </Text>
          {activities.medicacoesAtuais &&
            activities.medicacoesAtuais?.map((med: any) => (
              <Box
                border="1px solid #000"
                borderRadius="10"
                padding="3"
                mb="3"
                w="100%"
              >
                <TextSub>{`Nome: ${med.nome}`}</TextSub>
                <TextSub>{`Comentario: ${med.comentario}`}</TextSub>
                <TextSub>{`Concentração: ${med.concentracao}`}</TextSub>
                <TextSub>{`Controle especial: ${
                  med.controleEspecial ? 'Sim' : 'Não'
                }`}</TextSub>
                <TextSub>{`Posologia: ${med.posologia}`}</TextSub>
                <TextSub>{`Quantidade: ${med.quantidade}`}</TextSub>
                <TextSub>{`Tipo medicamento: ${med.tipoMedicamento}`}</TextSub>
                <TextSub>{`Uso continuo: ${
                  med.usoContinuo ? 'Sim' : 'Não'
                }`}</TextSub>
                <TextSub>{`Via administracao: ${med.viaAdministracao}`}</TextSub>
              </Box>
            ))}
        </>
      )}
    </VStack>
  );
}
