import { useMutation } from 'react-query';

import { api } from '@/lib';

interface ProfessionalSpecialties {
  especialidadeId: string;
}

interface Address {
  cep: string;
  numero: number;
  logradouro: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  latitude: number;
  longitude: number;
  regiao: string;
  codIBGE: string;
}

interface People {
  nome: string;
  cpf: string;
  rg: string;
  celular: string;
  dataNascimento: Date;
  enderecoFoto: string;
  telefoneResidencial: string;
  tokenDeviceId: string;
  sexoId: string;
  endereco: Address;
}

export interface HealthProfessionalUser {
  userName: string;
  passwordHash: string;
  email: string;
  pessoa: People;
  aprovado: boolean;
  ativo: boolean;
  urlFoto?: string;
  idade?: number;
}

export interface Professionals {
  usuarioProfissionalSaude: HealthProfessionalUser;
  nConselho: number;
  ufConselho: string;
  gestorId: string;
  profissionalSaudeEspecialidade: ProfessionalSpecialties[];
}

async function postProfessional(payload: Professionals): Promise<any> {
  const response = await api.post('/usuario/profissionalsaude', payload);
  return response;
}

export function useProfessionalMutation() {
  const mutation = useMutation((payload: Professionals) =>
    postProfessional(payload),
  );

  return mutation;
}
