import { Tr, Td, Flex, Heading, Stack, Icon } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { FiEdit, FiEye } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import {
  useInstanceQuery,
  useUnidadesIdQuery,
  useUnidadesMutation,
} from '@/hooks';
import { Tables } from '@/features/registers';
import { useInstanciaStore, useUserStore } from '@/stores';
import { PaginationButton } from '../components/Pagination/PaginationButton';
import { GenericModal, TopSection } from '../components';
import { ViewUnidadeInfo } from '../components/ViewInfo/ViewUnidadeInfo';
import { EditUnidadeForm } from '../components/EditForm/EditUnidadeInfo';
import { DeleteModal } from '../components/DeleteModals/DeleteModal';
import { useUpdadeContext } from '../hooks/useUpdateContext';

interface bInterface {
  value: string;
  label: string;
  instance: string;
}

export function UnidadesListPage() {
  const [unidade, setUnidade] = useState<any>();
  const { updateUnidades } = useUpdadeContext();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [selectPage, setSelectPage] = useState(0);
  const user = useUserStore(state => state.user);
  // const { data } = useManagerListQuery();
  const [text, setText] = useState('');
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState<any>([]);
  const [head, setHead] = useState<string[]>([]);

  const { data: instancia = [] } = useInstanceQuery();
  const instanceId = useInstanciaStore(state => state.instancia);
  const [instanciaId, setInstanciaId] = useState<any>(null);
  const { mutate, isLoading } = useUnidadesMutation();

  const onCloseModal = () => setIsEditOpen(false);
  const onCloseDeleteModal = () => setIsDeleteOpen(false);
  const onCloseViewModal = () => setIsViewOpen(false);

  const onOpenEditModal = (u: any) => {
    setUnidade(u);
    setIsEditOpen(true);
  };

  const onDeleteEditModal = (u: any) => {
    setUnidade(u);
    setIsDeleteOpen(true);
  };

  const onOpenViewModal = (u: any) => {
    setUnidade(u);
    setIsViewOpen(true);
  };

  const setDataContent = async (back = false, next = false) => {
    const unidadesUser = user?.unidadesId;
    const master = user?.isMaster;
    if (instanciaId) {
      await mutate(instanciaId, {
        onSuccess: (response: any) => {
          let unidades;
          if (!master) {
            unidades = response.data
              ?.filter((i: any) => {
                return unidadesUser?.filter(c => c === i.id).length;
              })
              .map((uni: any) => {
                return {
                  label: uni.descricao,
                  value: uni.id,
                  instance: uni.instanciaSaudeId,
                  cnpj: uni.cnpj,
                  endereco: uni.endereco,
                  enderecoId: uni.enderecoId,
                };
              });
          }
          unidades = response.data?.map((uni: any) => {
            return {
              label: uni.descricao,
              value: uni.id,
              instance: uni.instanciaSaudeId,
              cnpj: uni.cnpj,
              endereco: uni.endereco,
              enderecoId: uni.enderecoId,
            };
          });
          if (unidades.length > 0) {
            setData(unidades);
            setSearch(unidades);
            if (back) {
              setSelectPage(selectPage === 1 ? 1 : selectPage - 1);
            }
            if (next) {
              setSelectPage(selectPage + 1);
            }
          } else {
            setData([]);
            setSearch([]);
          }
        },
      });
    }
  };

  const getNameInstance = (id: any) => {
    const instance = instancia?.filter((item: any) => item.value === id);
    return instance;
  };

  useEffect(() => {
    if (user?.type === 'admin' || user?.type === 'master') {
      head.push('EDITAR');
    }
    if (user?.type === 'admin' || user?.type === 'master') {
      head.push('DELETAR');
    }
  }, []);

  useEffect(() => {
    if (instanceId?.id) {
      setInstanciaId(instanceId?.id);
    }
  }, [instanceId]);

  useEffect(() => {
    setDataContent(false, true);
  }, [instanciaId, instancia, isDeleteOpen, isEditOpen, updateUnidades]);

  useEffect(() => {
    if (data) {
      const lowerSearch = text.toLowerCase();

      const result = data.filter((d: any) => {
        return d.label.toLowerCase().includes(lowerSearch);
      });

      setSearch(result);
    }
  }, [text]);

  const showEditBtn = (b: any) => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'paciente' &&
      user?.type !== 'medico' &&
      user?.type !== 'assistente'
    ) {
      return (
        <Icon
          as={FiEdit}
          onClick={() => onOpenEditModal(b)}
          cursor="pointer"
          color="brand-dark.200"
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  const showDeleteBtn = (b: any) => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'paciente' &&
      user?.type !== 'medico' &&
      user?.type !== 'assistente'
    ) {
      return (
        <Icon
          as={MdDeleteOutline}
          onClick={() => onDeleteEditModal(b)}
          cursor="pointer"
          color="brand-dark.200"
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  return (
    <>
      <TopSection
        title="Unidades de Saúde"
        search={text}
        onChange={(str: any) => setText(str)}
      />

      <Tables
        head={['UNIDADE DE SAÚDE', 'INSTÂNCIA DE SAÚDE', 'VISUALIZAR', ...head]}
      >
        {search.map((b: bInterface) => {
          const nameI = getNameInstance(b.instance);

          return (
            <Tr key={b.value}>
              <Td>{b.label}</Td>

              <Td>{nameI[0]?.label}</Td>
              <Td>
                {' '}
                <Icon
                  as={FiEye}
                  onClick={() => onOpenViewModal(b)}
                  cursor="pointer"
                  color="brand-dark.200"
                  fontSize="20px"
                  fontWeight="400"
                />
              </Td>
              <Td>{showEditBtn(b)}</Td>
              <Td>{showDeleteBtn(b)}</Td>
            </Tr>
          );
        })}
      </Tables>

      <Flex
        direction={['column', 'column', 'row']}
        w="100%"
        align="center"
        justify="space-between"
      >
        <Heading
          size="xs"
          w="100%"
          mb={['4', '4', '0']}
          color="#707070"
          fontWeight="medium"
        >
          {/* Página {selectPage} de {dataPatients?.data.length} registros */}
          Página {selectPage}
        </Heading>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          w="100%"
          justify="flex-end"
        >
          <PaginationButton
            text="Voltar"
            onClick={() => setDataContent(true, false)}
          />
          {/* <PaginationButton text="Ultimo" onClick={() => setSelectPage(1)} /> */}
          {/* <NumbersPage
            onClick={value => setSelectPage(value)}
            page={selectPage}
            totalPage={dataPatients?.totalPages || 0}
          /> */}
          <PaginationButton
            text="Proximo"
            onClick={() => setDataContent(false, true)}
          />
          {/* <PaginationButton
            text="Ultimo"
            onClick={() => setSelectPage(dataPatients?.totalPages || 0)}
          /> */}
        </Stack>
      </Flex>

      <GenericModal
        open={isEditOpen}
        textAlign="center"
        title="Editar Unidade"
        onClose={onCloseModal}
      >
        <EditUnidadeForm unidade={unidade} onCancel={onCloseModal} />
      </GenericModal>
      <GenericModal
        open={isViewOpen}
        title={`Unidade: ${unidade && unidade.label}`}
        onClose={onCloseViewModal}
      >
        <ViewUnidadeInfo unidade={unidade} />
      </GenericModal>
      <GenericModal
        open={isDeleteOpen}
        textAlign="center"
        title="Deletar Unidade"
        onClose={onCloseDeleteModal}
      >
        <DeleteModal
          item={unidade}
          type="unidade"
          onCancel={onCloseDeleteModal}
        />
      </GenericModal>
    </>
  );
}
