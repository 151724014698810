import {
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  Text,
  Badge,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import { IconType } from 'react-icons';
import { useLocation } from 'react-router-dom';
import { useNotificationsTableQuery } from '@/features/notifications';

type NavItemProps = {
  id?: string;
  icon: IconType;
  label: string;
  link?: string;
  onClick?: () => void;
  subLinks?: {
    label: string;
    icon: IconType;
    link?: string;
    onClick?: () => void;
  }[];
};

export function NavItem({
  id,
  icon,
  label,
  link,
  onClick,
  subLinks,
}: NavItemProps) {
  const location = useLocation();

  const isActive = location.pathname === link;

  if (subLinks) {
    return (
      <Accordion allowToggle my="2" border="none">
        <AccordionItem border="none">
          <Flex
            as={AccordionButton}
            p="3"
            borderRadius="8"
            columnGap="3"
            alignItems="center"
            overflowX="hidden"
            _hover={{
              background: 'brand-dark.100',
            }}
          >
            <Icon as={icon} fontSize="24px" color="brand-dark.500" />
            <Text
              color="brand-dark.500"
              fontFamily="Nunito Sans"
              fontSize="md"
              isTruncated
            >
              {label}
            </Text>
            <AccordionIcon ml="auto" color="brand-dark.500" />
          </Flex>

          <AccordionPanel px="0">
            {subLinks.map(subLink => (
              <Grid
                key={subLink.label}
                as={Button}
                onClick={subLink.onClick}
                p="3"
                w="100%"
                h="auto"
                borderRadius="8"
                background={isActive ? 'brand-dark.100' : 'transparent'}
                templateColumns="repeat(2, max-content)"
                columnGap="3"
                alignItems="center"
                justifyContent="start"
                overflow="hidden"
                pointerEvents={isActive ? 'none' : 'all'}
                _hover={{
                  background: 'brand-dark.100',
                }}
              >
                <GridItem as={Flex} align="center" justify="center">
                  <Icon
                    as={subLink.icon}
                    fontSize="24px"
                    color="brand-dark.500"
                  />
                </GridItem>
                <GridItem>
                  <Text
                    color="brand-dark.500"
                    fontFamily="Nunito Sans"
                    fontSize="md"
                    isTruncated
                  >
                    {subLink.label}
                  </Text>
                </GridItem>
              </Grid>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    );
  }

  const { data: notifications } = useNotificationsTableQuery();

  const hasRecentNotification = useMemo(
    () =>
      notifications
        ?.map(notification => notification.recente)
        .some(value => value),
    [notifications],
  );

  return (
    <Grid
      as={Button}
      onClick={onClick}
      my="2"
      p="3"
      w="100%"
      h="auto"
      borderRadius="8"
      background={isActive ? 'brand-dark.100' : 'transparent'}
      templateColumns="repeat(2, max-content)"
      columnGap="3"
      alignItems="center"
      justifyContent="start"
      overflow="hidden"
      pointerEvents={isActive ? 'none' : 'all'}
      _hover={{
        background: 'brand-dark.100',
      }}
    >
      <GridItem as={Flex} align="center" justify="center">
        <Icon as={icon} fontSize="24px" color="brand-dark.500" />
        {hasRecentNotification && id === 'notification' && (
          <Badge
            bg="brand-orange.400"
            w="3"
            h="3"
            borderRadius="full"
            marginBottom="3"
            marginLeft="-3"
          />
        )}
      </GridItem>
      <GridItem>
        <Text
          color="brand-dark.500"
          fontFamily="Nunito Sans"
          fontSize="md"
          isTruncated
        >
          {label}
        </Text>
      </GridItem>
    </Grid>
  );
}
