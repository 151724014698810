import { Button, Stack } from '@chakra-ui/react';
import { useState } from 'react';

interface PaginationProps {
  currentPage: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  onPageChange: (page: number) => void;
}

export function Pagination({
  currentPage,
  canPreviousPage,
  canNextPage,
  onPageChange,
}: PaginationProps) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <Stack
      spacing="4"
      direction={['column', 'column', 'row']}
      mt={['4', '4', '0']}
    >
      <Button
        onClick={() => onPageChange(currentPage - 1)}
        disabled={!canPreviousPage}
        colorScheme="white"
        borderRadius="14px"
        color="brand-dark.300"
        fontSize="xs"
        textTransform="uppercase"
        boxShadow="1px 1px 8px -3px rgba(5, 6, 27, 0.25)"
      >
        Voltar
      </Button>

      <Button
        onClick={() => onPageChange(currentPage + 1)}
        disabled={!canNextPage}
        colorScheme="white"
        borderRadius="14px"
        color="brand-dark.300"
        fontSize="xs"
        textTransform="uppercase"
        boxShadow="1px 1px 8px -3px rgba(5, 6, 27, 0.25)"
      >
        Próxima
      </Button>
    </Stack>
  );
}
