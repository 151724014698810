import { useQuery } from 'react-query';

import { api } from '@/lib';

type SpecialtiesAPIResponse = {
  result: {
    id: string;
    descricao: string;
    rqe: number;
  }[];
};

async function specialtiesRequest(): Promise<SpecialtiesAPIResponse> {
  const { data } = await api.get('/usuario/especialidade');

  return data.data;
}

export function useSpecialtiesQuery() {
  const query = useQuery('specialties', specialtiesRequest, {
    refetchInterval: false,
    select: originalResponse => {
      return originalResponse.result
        .map(specialty => {
          // const [d, descricao] = specialty.descricao.split('.');
          return {
            label: specialty.descricao,
            value: specialty.id,
            rqe: specialty.rqe,
          };
        })
        .sort((a, b) => {
          if (a.label > b.label) {
            return 1;
          }
          if (a.label < b.label) {
            return -1;
          }
          return 0;
        });
    },
  });

  return query;
}
