import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  SimpleGrid,
  Text,
  useToast,
  VStack,
  List,
} from '@chakra-ui/react';
import { Link, useNavigate } from 'react-router-dom';
import registerConfirmationImg from '@/assets/register_confirmed.svg';
import { useEffect, useState } from 'react';
import { SelectInput } from '@/components';
import { useProfessionalStore, useUserStore } from '@/stores';
import { useInstanceQuery, useUnidadesIdQuery } from '@/hooks';

import { api } from '@/lib';
import { SearchIcon } from '@chakra-ui/icons';
import { usePatientTableQuery } from '@/features/historic/hooks';

type CurrentPatient = {
  cpf: string;
  id: string;
  nomePaciente: string;
  associado?: boolean;
};

interface RegisterInstanceFormProps {
  onCancel: () => void;
}

export function NewMonitoringForm({ onCancel }: RegisterInstanceFormProps) {
  const navigate = useNavigate();
  const [inputFocus, setInputFocus] = useState(false);
  const [namePatient, setNamePatient] = useState('');
  const [patientsInput, setPatientsInput] = useState<any[] | undefined>([]);
  const [patientsInputBackup, setPatientsInputBackup] = useState<
    any[] | undefined
  >([]);
  const [patientSelected, setSelected] = useState<any | undefined>(null);
  const [hasValueDigitized, setHasValueDigitized] = useState(false);
  const { data: instances = [], refetch: refreshInstance } = useInstanceQuery();
  const [instanciaId, setInstanciaId] = useState(instances[0]?.value);
  const { data: unidades = [] } = useUnidadesIdQuery(instanciaId);
  const [unidadeId, setUnidadeId] = useState('');
  const { professional } = useProfessionalStore();
  const [idMedico, setIdMedico] = useState('');
  const [idPaciente, setIdPaciente] = useState('');

  const { mutate: getpatient, isLoading } = usePatientTableQuery();
  const user = useUserStore(state => state.user);

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const setDataContent = async () => {
    const params = {
      idUsuarioProfissional:
        user?.type === 'medico' ? user?.id : professional?.idMedico,
      idUnidade: unidadeId,
    };

    const id = user?.type === 'medico' ? user?.id : professional?.idMedico;
    const res = getpatient(id, {
      onSuccess: response => {
        const tablePac = response.sort((a, b) => {
          if (a.nomePaciente > b.nomePaciente) {
            return 1;
          }
          if (a.nomePaciente < b.nomePaciente) {
            return -1;
          }
          return 0;
        });
        setPatientsInput(tablePac);
        setPatientsInputBackup(tablePac);
      },
      onError: e => {
        setPatientsInput([]);
        setPatientsInputBackup([]);
      },
    });
  };

  useEffect(() => {
    setDataContent();
  }, []);

  const changeNamePatient = (event: any) => {
    setNamePatient(event.target.value);
    setHasValueDigitized(true);

    if (!event.target.value) {
      setPatientsInput(patientsInputBackup);
      setHasValueDigitized(false);
      return;
    }

    const patientsFiltered = patientsInput?.filter(p => {
      return p.nomePaciente
        .toLowerCase()
        .includes(event.target.value.toLowerCase());
    });
    setPatientsInput(patientsFiltered);
  };

  const selectPatient = (p: any) => () => {
    if (!p.cpf) {
      toast({
        title:
          'Paciente não autorizado. Acesse o app e conclua cadastro para prescrever.',
        status: 'error',
      });
      return;
    }

    setSelected(p);
    setNamePatient(p.nomePaciente);
    setInputFocus(false);
  };

  async function onSubmit() {
    if (!patientSelected) {
      toast({
        title: 'Selecione o Paciente.',
        status: 'error',
      });
      return;
    }

    navigate('/app/historic', {
      state: { paciente: patientSelected, consulta: null, monitoring: true },
    });

    onCancel();
  }

  return (
    <Box as="form">
      <VStack spacing="4">
        <Text
          textAlign="left"
          width="100%"
          fontWeight={500}
          color="brand-dark.500"
          fontSize={16}
        >
          Paciente
        </Text>
        <InputGroup mb="8px">
          <Input
            type="text"
            placeholder="Nome do Paciente"
            onChange={changeNamePatient}
            value={namePatient}
            onFocus={() => setInputFocus(true)}
            onBlur={() =>
              setTimeout(() => {
                setInputFocus(false);
              }, 500)
            }
          />
          <InputRightElement
            pointerEvents="none"
            children={<SearchIcon color="brand-dark.300" />}
          />
        </InputGroup>
        {patientsInput && patientsInput.length ? (
          inputFocus ? (
            <List
              mb="6"
              style={{
                maxHeight: '10rem',
                overflowY: 'scroll',
                width: '100%',
              }}
            >
              {patientsInput.map(p => (
                <Flex
                  style={{ cursor: 'pointer' }}
                  justifyContent="space-between"
                  key={p.id}
                  onClick={selectPatient(p)}
                >
                  <Flex p="2" flexDirection="column">
                    <Text fontSize="sm" color="brand-dark.200">
                      {p.nomePaciente.split('@', 1)[0]}
                    </Text>
                  </Flex>
                </Flex>
              ))}
            </List>
          ) : (
            patientSelected?.nomePaciente && (
              <Flex justifyContent="space-between" width="80%">
                <Flex p="2" flexDirection="column">
                  <Text fontSize="md" color="brand-dark.300">
                    Nome
                  </Text>
                  <Text fontSize="sm" color="brand-dark.200">
                    {patientSelected?.nomePaciente}
                  </Text>
                </Flex>
                <Flex p="2" flexDirection="column">
                  <Text fontSize="md" color="brand-dark.300">
                    CPF
                  </Text>
                  <Text fontSize="sm" color="brand-dark.200">
                    {patientSelected?.cpf}
                  </Text>
                </Flex>
              </Flex>
            )
          )
        ) : (
          hasValueDigitized && (
            <List mb="6" style={{ maxHeight: '10rem' }}>
              <Flex flexDirection="column">
                <Text
                  style={{ fontWeight: '500' }}
                  fontSize="sm"
                  color="brand-red.600"
                >
                  Nome inválido ou não cadastrado*
                </Text>
              </Flex>
            </List>
          )
        )}
        <SimpleGrid minChildWidth="120px" spacing="4" w="100%">
          <Button
            type="button"
            variant="outline"
            colorScheme="brand-orange"
            h="45px"
            borderRadius="28px"
            color="brand-orange.300"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
            onClick={() => onCancel()}
          >
            Cancelar
          </Button>
          <Button
            isLoading={false}
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
            onClick={onSubmit}
          >
            Monitorar
          </Button>
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
