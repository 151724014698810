import { useNotificationsTableQuery } from '@/features/notifications';
import { useScheduleDateStore } from '@/features/schedule';
import { api } from '@/lib';
import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import confirmationImg from '@/assets/register_confirmed.svg';
import { useState } from 'react';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';
import { useScheduleQuery } from '@/features/schedule/hooks/useScheduleQuery';

interface Props {
  onCancel: () => void;
  event?: any;
  aceitar: boolean;
}

export function NotificatonEvent({ onCancel, event, aceitar }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const { refetch } = useScheduleIntervalQuery();
  const { refetch: refresh } = useScheduleQuery();
  const { refetch: refreshNotification } = useNotificationsTableQuery();

  const [wasFormSubmittedWithSuccess, setWasFormSubmittedWithSuccess] =
    useState(false);

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const ActionsNotification = () => {
    if (aceitar) {
      api
        .put(
          '/consulta',
          {},
          {
            params: {
              idAlertaWeb: event.idAlertaWeb,
              statusHoraAgenda: 0,
              idHorasAgenda: event.idHorasAgenda,
              // unidadeId: event.idUnidade
            },
          },
        )
        .then(res => {
          toast({ title: 'Notificação aprovada', status: 'success' });
          refetch();
          refresh();
          refreshNotification();
          setWasFormSubmittedWithSuccess(true);
        })
        .catch(err => {
          toast({
            title: 'Ocorreu um erro',
            status: 'error',
          });
          setWasFormSubmittedWithSuccess(false);
        })
        .finally(() => {
          refetch();
          refresh();
          refreshNotification();
        });
    } else {
      api
        .put(
          '/consulta',
          {},
          {
            params: {
              idAlertaWeb: event.idAlertaWeb,
              statusHoraAgenda: 5,
              idHorasAgenda: event.idHorasAgenda,
            },
          },
        )
        .then(res => {
          toast({ title: 'Notificação reprovada', status: 'success' });
          refetch();
          refresh();
          refreshNotification();
          setWasFormSubmittedWithSuccess(true);
        })
        .catch(err => {
          toast({
            title: 'Ocorreu um erro',
            status: 'error',
          });
          setWasFormSubmittedWithSuccess(false);
        })
        .finally(() => {
          refetch();
          refresh();
          refreshNotification();
        });
    }
  };

  return wasFormSubmittedWithSuccess ? (
    <Flex w="100%" direction="column" align="center">
      <Heading
        mt="6"
        color="brand-dark.300"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
      >
        A consulta foi confirmada!
      </Heading>

      <Image
        mt="8"
        mb="4"
        src={confirmationImg}
        alt="Mulher confirmando o sucesso de uma operação em um celular grande"
      />

      <Text
        color="brand-dark.200"
        fontSize="sm"
        fontWeight="normal"
        textAlign="center"
      >
        Você pode editar as configurações na aba de escalas.
      </Text>

      <Button
        type="button"
        onClick={onCancel}
        colorScheme="brand-green"
        mt="10"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Ir para escalas
      </Button>
    </Flex>
  ) : (
    <Box as="form" w="100%" textAlign="center">
      <Heading
        mt="6"
        mb="4"
        color={aceitar ? '#039c00' : 'red'}
        fontSize="xl"
        fontWeight="medium"
      >
        {aceitar ? 'Confirmar consulta' : 'Rejeitar Consulta'}
      </Heading>
      <Box>
        <Text color="brand-dark.300" fontSize="xl" fontWeight="medium">
          Deseja {aceitar ? 'aceitar' : 'rejeitar'} a consulta com o paciente?
        </Text>
      </Box>
      <VStack spacing="4" w="100%">
        <SimpleGrid minChildWidth="180px" spacing="4" w="100%" marginTop="50px">
          <Button
            disabled={isLoading}
            type="button"
            onClick={onCancel}
            variant="outline"
            colorScheme="red"
            h="45px"
            borderRadius="28px"
            color="red"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Voltar
          </Button>

          <Button
            disabled={isLoading}
            type="button"
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
            onClick={ActionsNotification}
          >
            {aceitar ? 'Confirmar' : 'Rejeitar'}
          </Button>
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
