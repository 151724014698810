import moment from 'moment';

export function initialTimeSuggestion() {
  const minutosAtual = moment().minute();
  const minutosRestantes =
    minutosAtual < 30 ? 30 - minutosAtual : 60 - minutosAtual;
  return moment().add(minutosRestantes, 'minutes').format('HH:mm');
}

export function finalTimeSuggestion() {
  const minutosAtual = moment().minute();

  const minutosRestantes =
    minutosAtual < 30 ? 90 - minutosAtual : 120 - minutosAtual;
  return moment().add(minutosRestantes, 'minutes').format('HH:mm');
}
