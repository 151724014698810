import { Tr, Td } from '@chakra-ui/react';
import { TablesReport, useScalesTableQuery } from '@/features/reports';

export function ScalesTable() {
  const { data } = useScalesTableQuery();
  return (
    <TablesReport head={data ? data.tableHead : []}>
      {data?.tableBody.map(b => (
        <Tr>
          <Td>{b.nomeProfissional}</Td>
          <Td>{b.consultaAgendada}</Td>
          <Td>{b.consultaAtendida}</Td>
          <Td>{b.consultaRemarcada}</Td>
          <Td>{b.consultaCancelada}</Td>
          <Td>{b.consultaNaoRealizada}</Td>
        </Tr>
      ))}
    </TablesReport>
  );
}
