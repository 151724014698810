import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useAddMesssageModalStore, useUserStore } from '@/stores';
import { NewMessagesForm } from '../components/NewMessagesForm';
import { NewMessagesPatientForm } from '../components/NewMessagesPatientForm';

export function RegisterNewMessagesPage() {
  const user = useUserStore(state => state.user);

  const {
    isOpen,
    onClose,
    content: contentShown,
    changeContent: setContentShown,
  } = useAddMesssageModalStore();

  function renderModalHeaderTitle() {
    return 'Nova Mensagem';
  }

  function renderModalContent() {
    return user?.type === 'paciente' ? (
      <NewMessagesPatientForm onCancel={onClose} />
    ) : (
      <NewMessagesForm onCancel={onClose} />
    );
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        setContentShown('buttons');
      }}
      size="xl"
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color="brand-dark.300"
          fontSize="xl"
          fontWeight="medium"
          textAlign="center"
        >
          {renderModalHeaderTitle()}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="12" pb="8">
          {renderModalContent()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
