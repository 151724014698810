import { Icon, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { MdNotes } from 'react-icons/md';

import { useMobileSidebarStore } from '@/stores';

export function HamburgerButton() {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });

  const onOpen = useMobileSidebarStore(state => state.onOpen);

  return isMobile ? (
    <IconButton
      aria-label="Abrir menu lateral"
      icon={<Icon as={MdNotes} fontSize="26px" color="brand-dark.400" />}
      w="52px"
      h="52px"
      borderRadius="14px"
      background="white"
      onClick={onOpen}
    />
  ) : null;
}
