import create, { StateCreator } from 'zustand';
import { devtools, persist, PersistOptions } from 'zustand/middleware';

type Professional = {
  id: string;
  name: string;
  idMedico: string;
  idUsuario: string;
};

type ProfessionalStore = {
  professional: Professional | null;
  setProfessional: (professional: Professional | null) => void;
};

type ProfessionalPersist = (
  config: StateCreator<ProfessionalStore>,
  options: PersistOptions<ProfessionalStore>,
) => StateCreator<ProfessionalStore>;

export const useProfessionalStore = create<ProfessionalStore>(
  devtools(
    (persist as ProfessionalPersist)(
      set => ({
        professional: null,
        setProfessional: professional => set(state => ({ professional })),
      }),
      {
        name: 'professional-store',
        getStorage: () => localStorage,
      },
    ),
    {
      name: 'ProfessionalStore',
    },
  ),
);
