import { api } from '@/lib';
import { useQuery } from 'react-query';

interface useGetPatientQuestionsProps {
  patientId?: string;
  quizId?: string;
}

const useGetPatientQuestions = ({
  patientId,
  quizId,
}: useGetPatientQuestionsProps) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['patient-questions', patientId, quizId],
    queryFn: async () => {
      const res = await api.get(`quizz/by-paciente`, {
        params: {
          id: patientId,
          idQuestionario: quizId,
        },
      });

      return res.data.data;
    },
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetPatientQuestions;
