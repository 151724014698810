import { Select } from '@chakra-ui/react';
import { useContext, useEffect } from 'react';
import { useViewContext } from '../../hooks/useViewContext';

export function DateRangeSelect() {
  const { view, setView } = useViewContext();

  const selectTypeView = (event: any) => {
    setView(event.target.value);
  };

  return (
    <Select
      size="lg"
      variant="filled"
      placeholder=""
      maxW={['100%', '150px']}
      w="100%"
      h="52px"
      borderRadius="14px"
      bg="white"
      color="brand-dark.500"
      _placeholder={{ color: '#bfbfbf' }}
      _hover={{ bg: 'brand-dark.100' }}
      onChange={selectTypeView}
      value={view}
    >
      {/* <option value="lastSeven">Últimos 7 dias</option>
      <option value="lastFifteen">Últimos 15 dias</option>
      <option value="lastThirty">Últimos 30 dias</option>
      <option value="nextSeven">Próximos 7 dias</option>
      <option value="nextFifteen">Próximos 15 dias</option>
      <option value="nextThirty">Próximos 30 dias</option> */}
      <option value="day">Dia</option>
      <option value="week">Semana</option>
    </Select>
  );
}
