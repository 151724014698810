import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Image,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { IoChevronBack, IoPerson } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { RecoveryPassSchema } from '@/features/auth/utils';

import { AppVersion } from '@/components';
import { TextInput } from '@/features/auth/components/FormItems';

import logoImg from '@/assets/logo.png';
import { useState } from 'react';
import { useSendEmailMutation } from '../../hooks';

type RecoveryFormFields = {
  email: string;
  password: string;
  code: string;
  confirmPassword: string;
};

export function RecoveryPasswordForm() {
  const [formStep, setFormStep] = useState(0);
  const [email, setEmail] = useState('');

  const navigate = useNavigate();

  const { isLoading, mutate } = useSendEmailMutation();

  const {
    handleSubmit,
    formState: { errors },
    register,
    trigger,
    getValues,
  } = useForm<RecoveryFormFields>({
    defaultValues: {
      email: '',
    },
    resolver: yupResolver(RecoveryPassSchema),
    shouldFocusError: false,
  });

  function handleStepBack() {
    setFormStep(currStep => currStep - 1);
  }

  function onSubmit() {
    mutate(email, {
      onSuccess: response => {
        setFormStep(currStep => currStep + 1);
      },
    });
  }

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      py={['12', '12', '8']}
      px={['8', '14', '14']}
      w="100%"
      height="100%"
      bg="white"
      borderRadius="0 22px 22px 0"
      direction="column"
      align="center"
      justify="center"
    >
      <Flex w="100%" justify="space-between">
        <Tooltip
          label="Voltar para a tela de Login"
          bg="gray.800"
          color="white"
          placement="right"
          hasArrow
        >
          <IconButton
            aria-label="Voltar para a tela anterior"
            icon={<Icon as={IoChevronBack} fontSize="24px" color="gray.600" />}
            onClick={() => navigate('/auth/login')}
          />
        </Tooltip>

        <Box visibility="hidden" />
      </Flex>

      <Image
        src={logoImg}
        alt="Logo da Dynamic Medic Health"
        mt="auto"
        mb="8"
        w="166px"
        h="77px"
      />

      {formStep === 0 && (
        <VStack spacing="4" w="100%">
          <Heading size="md" mb="6" color="gray.700" textAlign="center">
            Digite seu username para receber o código de verificação para
            recuperação de senha
          </Heading>
          <TextInput
            icon={IoPerson}
            error={errors.email}
            type="text"
            placeholder="Username"
            {...register('email')}
            onChange={e => setEmail(e.target.value)}
          />
        </VStack>
      )}

      {formStep === 1 && (
        <VStack spacing="4" w="100%">
          <Heading size="md" mb="6" color="gray.700" textAlign="center">
            Um link com instruções para recuperação de senha foi enviado para o
            email cadastrado na plataforma
            {/* <Text color="gray.400">{email}</Text> */}
          </Heading>
        </VStack>
      )}

      {formStep === 0 ? (
        <Button
          type="submit"
          disabled={isLoading}
          mt="6"
          mb="10"
          w="100%"
          h="38px"
          borderRadius="6"
          bg="brand-orange.400"
          color="white"
          fontFamily="Inter"
          fontSize="md"
          fontWeight="normal"
          boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
          _hover={{
            background: 'brand-orange.300',
          }}
        >
          Continuar
        </Button>
      ) : null}

      <AppVersion mt={['8', '8', 'auto']} />
    </Flex>
  );
}
