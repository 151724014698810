import { Button } from '@chakra-ui/react';

interface MonthButtonProps {
  month: {
    name: string;
    numeric: number;
  };
  isActive: boolean;
  onMonthSelect: (month: number) => void;
}

export function MonthButton({
  month,
  isActive,
  onMonthSelect,
}: MonthButtonProps) {
  return (
    <Button
      onClick={() => onMonthSelect(month.numeric)}
      w="100%"
      bg={isActive ? 'brand-orange.300' : 'transparent'}
      color={isActive ? 'white' : 'brand-dark.500'}
      pointerEvents={isActive ? 'none' : 'all'}
    >
      {month.name}
    </Button>
  );
}
