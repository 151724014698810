import { api } from '@/lib';
import { useProfessionalStore, useUserStore } from '@/stores';
import { useToast } from '@chakra-ui/react';
import axios from 'axios';
import { format, parseISO } from 'date-fns';
import moment from 'moment';
import { useMutation } from 'react-query';

type ReplicateScale = {
  tipo: string;
  dataOrigem: any;
  dataDestino: any;
  observacao: string;
};

async function postReplicateScale(
  payload: ReplicateScale,
  userId?: string,
): Promise<any> {
  let origemReplica: any = payload.dataOrigem;
  let destinoReplica: any = payload.dataDestino;

  if (payload.tipo === '2') {
    origemReplica = origemReplica.day(0);
    destinoReplica = destinoReplica.day(0);
  }

  origemReplica = origemReplica.format('YYYY-MM-DD');
  destinoReplica = destinoReplica.format('YYYY-MM-DD');

  const mutation = await api.put(
    '/agenda/replicar',
    {},
    {
      params: {
        observacao: payload.observacao,
        idUsuario: userId,
        dataOrigem: origemReplica,
        dataDestino: destinoReplica,
        tipo: parseInt(payload.tipo, 10),
        // unidadeId: mock add unidade
      },
    },
  );

  return mutation;
}

export function useReplicateScaleMutation() {
  const user = useUserStore(state => state.user);
  const pro = useProfessionalStore(state => state.professional);
  let id = user?.id;

  if (user && user.type !== 'medico') {
    id = pro?.idMedico;
  }

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const mutation = useMutation(
    (payload: ReplicateScale) => postReplicateScale(payload, id),
    {
      onSuccess: response => {
        // const { data } = response.data.data;
      },
      onError: error => {
        if (axios.isAxiosError(error)) {
          const { response } = error;

          if (response?.data && response.data.errors[0]) {
            toast({
              title: response.data.errors[0],
              status: 'error',
            });
          }
        }
      },
    },
  );

  return mutation;
}
