import React, { useEffect, useState } from 'react';
import { VStack, SimpleGrid, useToast, Button, Box } from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { MaskedInput, SelectInput, TextInput } from '@/components';
import { format, parseISO } from 'date-fns';
import moment from 'moment';
import { editPatientSchema, ButtonsForm } from '@/features/registers';
import { api, getCep } from '@/lib';
import { useUserStore } from '@/stores';
import { useGendersQuery } from '@/hooks';
import { useBrazilStatesQuery, useBrazilCitiesQuery } from '../../hooks';
import { EditInstances } from '../EditInstances';

interface Props {
  patient: any;
  onCancel: () => void;
}

interface EditFieldsModal {
  nome: string;
  email: string;
  celular: string;
  cpf: string;
  genero: string;
  sexoId: string;
  dataNascimento: string;
  cep: string;
  complemento: string;
  cidade: string;
  endereco: string;
  numero: string;
  bairro: string;
  estado: string;
  username: string;
}

export function EditPatientForm({ patient, onCancel }: Props) {
  const user = useUserStore(state => state.user);
  const { data: genders = [] } = useGendersQuery();
  const [formStep, setFormStep] = useState(0);
  const gender = genders?.filter(item => item.label === patient.sexo);
  const { data: states = [] } = useBrazilStatesQuery();

  const [startDay, startMonth, startYear] = patient.dataNascimento.split('-');
  const dateNasc = `${startDay}${startMonth}${startYear}`;

  const {
    control,
    formState,
    register,
    reset,
    handleSubmit,
    trigger,
    setValue,
    watch,
    setError,
    getValues,
  } = useForm<EditFieldsModal>({
    defaultValues: {
      nome: patient.nome || '',
      email: patient.email || '',
      cpf: patient.cpf || '',
      celular: patient.celular || '',
      genero: gender[0]?.value || '',
      dataNascimento: dateNasc || '',
      cep: patient.cep || '',
      complemento: patient.complemento || '',
      estado: patient.estado || '',
      cidade: patient.cidade || '',
      endereco: patient.endereco || '',
      bairro: patient.bairro || '',
      numero: patient.numero || '',
      sexoId: gender[0]?.value || '',
      username: patient.userName || '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(editPatientSchema),
  });

  const { errors } = formState;

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  useEffect(() => {
    if (gender[0]) {
      setValue('genero', gender[0]?.value);
      setValue('sexoId', gender[0]?.value);
    }
  }, [gender]);

  async function onSubmit(values: any) {
    const date = `${values.dataNascimento.slice(
      2,
      4,
    )}/${values.dataNascimento.slice(0, 2)}/${values.dataNascimento.slice(
      4,
      8,
    )}`;

    // const genero = genders?.filter(item => item.value === values.sexoId);

    const newPatient = {
      nome: values.nome,
      email: values.email,
      cpf: values.cpf,
      dataNascimento: new Date(date),
      celular: values.celular,
      // telefone: values.telefone,
      cep: values.cep,
      numero: values.numero,
      endereco: values.endereco,
      complemento: values.complemento,
      bairro: values.bairro,
      cidade: values.cidade,
      estado: values.estado,
      generoId: values.sexoId,
      userName: values.username,
    };

    try {
      const response: any = await api.put(
        `/paciente/alterar-novo?id=${patient.id}`,
        newPatient,
      );

      if (response?.statusText === 'OK') {
        toast({
          title: 'Paciente editado com sucesso!',
          status: 'success',
        });

        if (user?.type === 'admin' || user?.type === 'master') {
          setFormStep(2);
        } else {
          onCancel();
        }
      }
    } catch (error: any) {
      toast({
        title: 'Ops algo deu errado!',
        status: 'error',
      });
    }
  }

  const { data: cities = [] } = useBrazilCitiesQuery(watch('estado'));

  // useEffect(() => {
  //   reset({
  //     ...patient,
  //     numero: String(patient?.numero),
  //     sexoId: patient.genero,
  //     email: patient.email,
  //     nomeCompleto: patient.nome,
  //   });
  // }, [patient]);

  function handleStepBack() {
    if (formStep === 0) {
      onCancel();
    } else {
      setFormStep(currStep => currStep - 1);
    }
  }

  async function stepCompletion() {
    let isFormValid = false;

    if (formStep === 0) {
      isFormValid = await trigger([
        'nome',
        'email',
        'cpf',
        'dataNascimento',
        'sexoId',
        'celular',
      ]);

      const [username] = getValues(['username']);
      if (!username || username.length < 8 || username.length > 15) {
        setError('username', {
          type: 'manual',
          message: 'Username deve possuir de 8 a 15 caracteres',
        });
        return;
      }

      const regexSpecialChar = /[$#@!]/;
      const hasSpecialChar = regexSpecialChar.test(username);
      if (hasSpecialChar) {
        setError('username', {
          type: 'manual',
          message: 'Username inválido',
        });
        return;
      }

      const userExists = await api.get(
        `/usuario/Usuario-Por-Username?username=${username}`,
      );

      if (username !== patient.userName && userExists?.data.data) {
        setError('username', {
          type: 'manual',
          message: 'Username não está disponível',
        });
        return;
      }

      isFormValid = true;
    }

    if (isFormValid) {
      setFormStep(formStep + 1);
    }
  }

  async function checkCEP(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.value) return;

    const cep = e.target.value.replace(/\D/g, '');
    if (cep.length < 8) return;

    const address = await getCep(cep);

    setValue('endereco', address?.data.logradouro);
    setValue('bairro', address?.data.bairro);
    setValue('complemento', address?.data.complemento);
    setValue('estado', address?.data.uf);

    setTimeout(() => {
      setValue('cidade', address?.data.localidade);
    }, 1000);
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      <VStack spacing="4">
        {formStep === 0 && (
          <>
            <TextInput
              placeholder="Ex: Mariana Helzberg"
              label="Nome Completo"
              error={errors.nome}
              {...register('nome')}
            />

            <TextInput
              type="email"
              placeholder="Ex: mariana@exemplo.com"
              label="E-mail"
              error={errors.email}
              {...register('email')}
            />

            <SimpleGrid minChildWidth="150px" spacing="4" w="100%">
              <MaskedInput
                name="cpf"
                label="CPF"
                control={control}
                mask="cpf"
                error={errors.cpf}
                placeholder="Ex: 123.456.789-46"
              />
              <MaskedInput
                name="celular"
                label="Celular"
                control={control}
                mask="phone"
                error={errors.celular}
                placeholder="Ex: (12) 91234-5678"
              />
            </SimpleGrid>

            <SimpleGrid minChildWidth="150px" spacing="4" w="100%">
              <SelectInput
                options={genders}
                placeholder="Escolher gênero"
                label="Gênero"
                error={errors.sexoId}
                {...register('sexoId')}
              />

              <MaskedInput
                name="dataNascimento"
                label="Data de Nascimento"
                control={control}
                mask="date"
                error={errors.dataNascimento}
                placeholder="Ex: 01/01/1970"
              />
            </SimpleGrid>

            <TextInput
              placeholder="Ex: mariana.helzberg"
              label="Usuário"
              error={errors.username}
              {...register('username')}
            />

            <ButtonsForm onCancel={onCancel} onSubmit={stepCompletion} />
          </>
        )}
        {formStep === 1 && (
          <>
            <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
              <MaskedInput
                name="cep"
                label="CEP"
                control={control}
                mask="zipCode"
                error={errors.cep}
                placeholder="Ex: 12345-678"
                onBlur={checkCEP}
                onChange={checkCEP}
              />

              <SelectInput
                options={states}
                placeholder="Escolher estado"
                label="Estado"
                error={errors.estado}
                {...register('estado')}
              />
            </SimpleGrid>

            <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
              <SelectInput
                options={cities}
                placeholder="Escolher cidade"
                label="Cidade"
                error={errors.cidade}
                {...register('cidade')}
              />

              <TextInput
                placeholder="Ex: Bairro Nova Era"
                label="Bairro"
                error={errors.bairro}
                {...register('bairro')}
              />
            </SimpleGrid>

            <TextInput
              placeholder="Ex: Rua José Tavares"
              label="Endereço"
              error={errors.endereco}
              {...register('endereco')}
            />

            <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
              <TextInput
                placeholder="Ex: 123"
                label="Número"
                error={errors.numero}
                {...register('numero')}
              />

              <TextInput
                placeholder="Ex: Próximo ao mercado"
                label="Complemento (opcional)"
                error={errors.complemento}
                {...register('complemento')}
              />
            </SimpleGrid>

            <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
              <Button
                type="button"
                onClick={handleStepBack}
                variant="outline"
                colorScheme="brand-orange"
                h="45px"
                borderRadius="28px"
                color="brand-orange.300"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
              >
                Voltar
              </Button>

              <Button
                type="submit"
                colorScheme="brand-green"
                h="45px"
                borderRadius="28px"
                color="white"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
              >
                Salvar
              </Button>
            </SimpleGrid>
          </>
        )}
      </VStack>

      {formStep === 2 && (
        <EditInstances type={2} id={patient.id} onCancel={onCancel} />
      )}
    </Box>
  );
}
