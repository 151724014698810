import useFetchPatients from '@/hooks/queries/useFetchPatients';
import { getYearsOldByDate } from '@/utils/getYearsOldByDate';
import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  List,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { GuidanceModal } from './GuidanceModal';

export const msgSchema = yup.object().shape({
  orientation: yup.string().min(1),
});

interface SelectPatientToGuidanceProps {
  message: string;
  isOpen: boolean;
  onClose: () => void;
}
export function SelectPatientToGuidance({
  message,
  onClose,
  isOpen,
}: SelectPatientToGuidanceProps) {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const [searchValue, setSearchValue] = useState<string>();
  const [filteredPatients, setFilteredPatients] = useState<any[]>([]);
  const [inputFocus, setInputFocus] = useState(false);
  const [patientSelected, setPatientSelected] = useState<any | undefined>(null);
  const [guidanceModalIsOpen, setGuidanceModalIsOpen] = useState(false);

  const { data: patients } = useFetchPatients();

  const handleClose = () => {
    setSearchValue(undefined);
    setPatientSelected(undefined);
    onClose();
  };

  const handleOpenGuidanceModal = () => {
    setGuidanceModalIsOpen(true);
  };

  const handleCloseGuidanceModal = () => {
    setGuidanceModalIsOpen(false);
    handleClose();
  };

  useEffect(() => {
    if (patients && searchValue) {
      const filteredOptions = patients.filter(item =>
        item.nome.toLowerCase().includes(searchValue.toLowerCase()),
      );

      setFilteredPatients(filteredOptions);
    } else {
      setFilteredPatients(patients || []);
    }
  }, [patients, searchValue]);

  const selectPatient = (p: any) => () => {
    if (!p.cpf) {
      toast({
        title:
          'Paciente não autorizado. Acesse o app e conclua cadastro para prescrever.',
        status: 'error',
      });
      return;
    }

    setPatientSelected(p);
    setSearchValue(p.nomePaciente);
    setInputFocus(false);
  };

  return (
    <Modal isCentered isOpen={isOpen} size="xl" onClose={handleClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader margin="0 auto">Encaminhar orientação</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" gap={4} flexDir="column">
          <Box as="form">
            <VStack spacing="4">
              <Text
                textAlign="left"
                width="100%"
                fontWeight={500}
                fontSize={16}
              >
                Selecione um paciente:
              </Text>
              <InputGroup mb="8px">
                <Input
                  type="text"
                  placeholder="Nome do Paciente"
                  onChange={e => setSearchValue(e.target.value)}
                  value={searchValue}
                  onFocus={() => setInputFocus(true)}
                  onBlur={() =>
                    setTimeout(() => {
                      setInputFocus(false);
                    }, 500)
                  }
                />
                <InputRightElement
                  pointerEvents="none"
                  children={<SearchIcon color="brand-dark.300" />}
                />
              </InputGroup>
              {patients &&
                patients.length &&
                (inputFocus ? (
                  <List
                    mb="6"
                    style={{
                      maxHeight: '10rem',
                      overflowY: 'scroll',
                      width: '100%',
                    }}
                  >
                    {filteredPatients.map(p => (
                      <Flex
                        style={{ cursor: 'pointer' }}
                        justifyContent="space-between"
                        key={p.id}
                        onClick={selectPatient(p)}
                      >
                        <Flex p="2" flexDirection="column">
                          <Text fontSize="sm" fontWeight="medium">
                            {p.nome.split('@', 1)[0]}
                          </Text>
                        </Flex>
                      </Flex>
                    ))}
                  </List>
                ) : (
                  patientSelected?.nome && (
                    <Flex justifyContent="space-between" width="80%">
                      <Flex p="2" flexDirection="column">
                        <Text fontSize="md" color="brand-dark.300">
                          Nome
                        </Text>
                        <Text
                          fontSize="sm"
                          color="brand-dark.800"
                          fontWeight="medium"
                        >
                          {patientSelected?.nome}
                        </Text>
                      </Flex>
                      <Flex p="2" flexDirection="column">
                        <Text fontSize="md" color="brand-dark.300">
                          CPF
                        </Text>
                        <Text
                          fontSize="sm"
                          color="brand-dark.800"
                          fontWeight="medium"
                        >
                          {patientSelected?.cpf}
                        </Text>
                      </Flex>
                    </Flex>
                  )
                ))}
            </VStack>
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={4} onClick={handleClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="green"
            onClick={handleOpenGuidanceModal}
            disabled={!patientSelected}
          >
            Continuar
          </Button>
        </ModalFooter>
      </ModalContent>

      <GuidanceModal
        isOpen={guidanceModalIsOpen}
        onClose={handleCloseGuidanceModal}
        patient={{
          idade: getYearsOldByDate(patientSelected?.dataNascimento),
          idPaciente: patientSelected?.id,
          nomePaciente: patientSelected?.nome,
        }}
        guidance={message}
      />
    </Modal>
  );
}
