import { Tr, Td, Icon, Flex, Heading, Stack } from '@chakra-ui/react';
import { FiEye, FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import { useEffect, useState } from 'react';
import { useInstanceQuery, useTypeInstanceQuery } from '@/hooks';
import { useInstanciaStore } from '@/stores/instancias';
import { useUserStore } from '@/stores';
import { TopSection } from '@/features/registers';
import { GenericModal, Tables } from '../components';
import { PaginationButton } from '../components/Pagination/PaginationButton';
import { ViewInstanceInfo } from '../components/ViewInfo/ViewInstanceInfo';
import { EditInstanceForm } from '../components/EditForm/EditInstanceForm';
import { DeleteModal } from '../components/DeleteModals/DeleteModal';

interface bInterface {
  value: string;
  label: string;
  tipo: string;
}

export function InstanciasListPage() {
  const [instancia, setInstancia] = useState<any>();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const [selectPage, setSelectPage] = useState(0);
  const user = useUserStore(state => state.user);
  const [text, setText] = useState('');
  const [data, setData] = useState<any>([]);
  const [search, setSearch] = useState<any>([]);
  const [head, setHead] = useState<string[]>([]);

  const { data: tipoInstancia = [] } = useTypeInstanceQuery();
  const { data: instancias = [], refetch: refInstancia } = useInstanceQuery();
  const setInstancias = useInstanciaStore(state => state.setInstancia);

  const onCloseModal = () => setIsEditOpen(false);
  const onCloseDeleteModal = () => setIsDeleteOpen(false);
  const onCloseViewModal = () => setIsViewOpen(false);

  const onOpenEditModal = (i: any) => {
    setInstancia(i);
    setIsEditOpen(true);
  };

  const onDeleteEditModal = (i: any) => {
    setInstancia(i);
    setIsDeleteOpen(true);
  };

  const onOpenViewModal = (i: any) => {
    setInstancia(i);
    setIsViewOpen(true);
  };

  const setDataContent = async (back = false, next = false) => {
    await refInstancia();
    await instancias;
    if (instancias.length > 0) {
      setData(instancias);
      setSearch(instancias);
      if (back) {
        setSelectPage(selectPage === 1 ? 1 : selectPage - 1);
      }
      if (next) {
        setSelectPage(selectPage + 1);
      }
    }
  };

  const getNameInstance = (id: any) => {
    const instance = tipoInstancia?.filter(item => item.value === id);
    return instance;
  };

  useEffect(() => {
    setDataContent(false, true);
  }, [instancias, isEditOpen, isDeleteOpen]);

  useEffect(() => {
    if (data) {
      const lowerSearch = text.toLowerCase();

      const result = data.filter((d: any) => {
        return d.label.toLowerCase().includes(lowerSearch);
      });

      setSearch(result);
    }
  }, [text]);

  useEffect(() => {
    if (user?.type === 'admin' || user?.type === 'master') {
      head.push('EDITAR');
    }
    if (user?.type === 'admin' || user?.type === 'master') {
      head.push('DELETAR');
    }
  }, []);

  const showEditBtn = (b: any) => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'paciente' &&
      user?.type !== 'medico' &&
      user?.type !== 'assistente'
    ) {
      return (
        <Icon
          as={FiEdit}
          onClick={() => onOpenEditModal(b)}
          cursor="pointer"
          color="brand-dark.200"
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  const showDeleteBtn = (b: any) => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'paciente' &&
      user?.type !== 'medico' &&
      user?.type !== 'assistente'
    ) {
      return (
        <Icon
          as={MdDeleteOutline}
          onClick={() => onDeleteEditModal(b)}
          cursor="pointer"
          color="brand-dark.200"
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  return (
    <>
      <TopSection
        title="Instâncias de Saúde"
        search={text}
        onChange={(str: any) => setText(str)}
      />

      <Tables
        head={[
          'INSTÂNCIA DE SAÚDE',
          'TIPO DA INSTÂNCIA',
          'VISUALIZAR',
          ...head,
        ]}
      >
        {search.map((b: bInterface) => {
          const nameI = getNameInstance(b.tipo);
          return (
            <Tr key={b.value}>
              <Td>{b.label}</Td>

              <Td>{nameI[0]?.label}</Td>
              <Td>
                <Icon
                  as={FiEye}
                  onClick={() => onOpenViewModal(b)}
                  cursor="pointer"
                  color="brand-dark.200"
                  fontSize="20px"
                  fontWeight="400"
                  marginRight="15"
                />
              </Td>
              <Td>{showEditBtn(b)}</Td>
              <Td>{showDeleteBtn(b)}</Td>
            </Tr>
          );
        })}
      </Tables>
      {/* <Pagination
        page={data?.page || 0}
        totalPage={data?.totalPages || 0}
        totalRegisters={data ? data?.tableBody?.length : 0}
      /> */}

      <Flex
        direction={['column', 'column', 'row']}
        w="100%"
        align="center"
        justify="space-between"
      >
        <Heading
          size="xs"
          w="100%"
          mb={['4', '4', '0']}
          color="#707070"
          fontWeight="medium"
        >
          {/* Página {selectPage} de {dataPatients?.data.length} registros */}
          Página {selectPage}
        </Heading>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          w="100%"
          justify="flex-end"
        >
          <PaginationButton
            text="Voltar"
            onClick={() => setDataContent(true, false)}
          />
          {/* <PaginationButton text="Ultimo" onClick={() => setSelectPage(1)} /> */}
          {/* <NumbersPage
            onClick={value => setSelectPage(value)}
            page={selectPage}
            totalPage={dataPatients?.totalPages || 0}
          /> */}
          <PaginationButton
            text="Proximo"
            onClick={() => setDataContent(false, true)}
          />
          {/* <PaginationButton
            text="Ultimo"
            onClick={() => setSelectPage(dataPatients?.totalPages || 0)}
          /> */}
        </Stack>
      </Flex>

      <GenericModal
        open={isEditOpen}
        textAlign="center"
        title="Editar Instância"
        onClose={onCloseModal}
      >
        <EditInstanceForm instance={instancia} onCancel={onCloseModal} />
      </GenericModal>
      <GenericModal
        open={isViewOpen}
        title={`Instância: ${instancia && instancia.label}`}
        onClose={onCloseViewModal}
      >
        <ViewInstanceInfo instance={instancia} />
      </GenericModal>
      <GenericModal
        open={isDeleteOpen}
        textAlign="center"
        title="Deletar Instância"
        onClose={onCloseDeleteModal}
      >
        <DeleteModal
          item={instancia}
          type="instância"
          onCancel={onCloseDeleteModal}
        />
      </GenericModal>
    </>
  );
}
