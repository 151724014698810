import {
  Box,
  Button,
  Flex,
  Heading,
  Image,
  Link,
  SimpleGrid,
  Text,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  registerSecretarySchema,
  useBrazilCitiesQuery,
  useBrazilStatesQuery,
  useGetSecretaryByIdQuery,
  useUpdateSecretaryMutation,
} from '@/features/registers';
import { useGendersQuery } from '@/hooks';

import { MaskedInput, SelectInput, TextInput } from '@/components';

import registerConfirmationImg from '@/assets/register_confirmed.svg';
import { getCep } from '@/lib';

type UpdateSecretaryFormFields = {
  nome: string;
  email: string;
  cpf: string;
  telefone: string;
  generoId: string;
  dataNascimento: string;
  cep: string;
  numero: string;
  endereco: string;
  estado: string;
  cidade: string;
  bairro: string;
  complemento: string;
  senha: string;
};

interface UpdateSecretaryFormProps {
  onCancel: () => void;
}

export function UpdateSecretaryForm({ onCancel }: UpdateSecretaryFormProps) {
  const [formStep, setFormStep] = useState(0);

  const { data: secretary } = useGetSecretaryByIdQuery();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
    trigger,
    watch,
    setValue,
  } = useForm<UpdateSecretaryFormFields>({
    defaultValues: {
      nome: '',
      email: '',
      cpf: '',
      telefone: '',
      generoId: '',
      dataNascimento: '',
      cep: '',
      numero: '',
      endereco: '',
      estado: '',
      cidade: '',
      bairro: '',
      complemento: '',
      senha: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(registerSecretarySchema),
  });

  const { data: genders = [] } = useGendersQuery();
  const { data: states = [] } = useBrazilStatesQuery();
  const { data: cities = [] } = useBrazilCitiesQuery(watch('estado'));

  const {
    mutate: updateSecretaryMutate,
    isLoading: isSecretaryMutationLoading,
  } = useUpdateSecretaryMutation();

  function handleStepBack() {
    if (formStep === 0) {
      onCancel();
    } else {
      setFormStep(currStep => currStep - 1);
    }
  }

  async function handleStepCompletion() {
    let isFormValid = false;

    if (formStep === 0) {
      isFormValid = await trigger([
        'nome',
        'email',
        'cpf',
        'telefone',
        'generoId',
        'dataNascimento',
      ]);
    } else if (formStep === 1) {
      isFormValid = await trigger([
        'cep',
        'numero',
        'endereco',
        'estado',
        'cidade',
        'bairro',
        'complemento',
      ]);
    }

    if (isFormValid) {
      setFormStep(currStep => currStep + 1);
    }
  }

  function onSubmit(values: UpdateSecretaryFormFields) {
    // Formatando a data de nascimento para o formato internacional
    const intlBirthDate = `${values.dataNascimento.slice(
      2,
      4,
    )}/${values.dataNascimento.slice(0, 2)}/${values.dataNascimento.slice(
      4,
      8,
    )}`;

    const payload = {
      ...values,
      dataNascimento: new Date(intlBirthDate),
      numero: Number(values.numero),
    };

    return updateSecretaryMutate(payload, {
      onSuccess: () => {
        setFormStep(currStep => currStep + 1);
      },
    });
  }

  useEffect(() => {
    reset({
      ...secretary,
      numero: String(secretary?.numero),
    });
  }, [secretary]);

  async function checkCEP(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.value) return;

    const cep = e.target.value.replace(/\D/g, '');

    if (cep.length < 8) return;

    const address = await getCep(cep);

    setValue('endereco', address?.data.logradouro);
    setValue('bairro', address?.data.bairro);
    setValue('complemento', address?.data.complemento);
    setValue('estado', address?.data.uf);
    setValue('cidade', address?.data.localidade);
  }

  return (
    <Box as="form" onSubmit={handleSubmit(onSubmit)}>
      {formStep === 0 && (
        <VStack spacing="4">
          <TextInput
            placeholder="Ex: Mariana Helzberg"
            label="Nome Completo"
            error={errors.nome}
            {...register('nome')}
          />

          <TextInput
            type="email"
            placeholder="Ex: mariana@exemplo.com"
            label="E-mail"
            error={errors.email}
            {...register('email')}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <MaskedInput
              name="cpf"
              label="CPF"
              control={control}
              mask="cpf"
              error={errors.cpf}
              placeholder="Ex: 123.456.789-46"
            />

            <MaskedInput
              name="telefone"
              label="Telefone"
              control={control}
              mask="phone"
              error={errors.telefone}
              placeholder="Ex: (12) 91234-5678"
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <SelectInput
              options={genders}
              placeholder="Escolher gênero"
              label="Gênero"
              error={errors.generoId}
              {...register('generoId')}
            />

            <MaskedInput
              name="dataNascimento"
              label="Data de Nascimento"
              control={control}
              mask="date"
              error={errors.dataNascimento}
              placeholder="Ex: 01/01/1970"
            />
          </SimpleGrid>

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <Button
              type="button"
              onClick={handleStepBack}
              variant="outline"
              colorScheme="brand-orange"
              h="45px"
              borderRadius="28px"
              color="brand-orange.300"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Voltar
            </Button>

            <Button
              type="button"
              onClick={handleStepCompletion}
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Continuar
            </Button>
          </SimpleGrid>
        </VStack>
      )}

      {formStep === 1 && (
        <VStack spacing="4">
          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <MaskedInput
              name="cep"
              label="CEP"
              control={control}
              mask="zipCode"
              error={errors.cep}
              placeholder="Ex: 12345-678"
              onBlur={checkCEP}
              onChange={checkCEP}
            />

            <TextInput
              placeholder="Ex: 123"
              label="Número"
              error={errors.numero}
              {...register('numero')}
            />
          </SimpleGrid>

          <TextInput
            placeholder="Ex: Rua José Tavares"
            label="Endereço"
            error={errors.endereco}
            {...register('endereco')}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <SelectInput
              options={states}
              placeholder="Escolher estado"
              label="Estado"
              error={errors.estado}
              {...register('estado')}
            />

            <SelectInput
              options={cities}
              placeholder="Escolher cidade"
              label="Cidade"
              error={errors.cidade}
              {...register('cidade')}
            />
          </SimpleGrid>

          <TextInput
            placeholder="Ex: Bairro Nova Era"
            label="Bairro"
            error={errors.bairro}
            {...register('bairro')}
          />

          <TextInput
            placeholder="Ex: Perto do mercado"
            label="Complemento (opcional)"
            error={errors.complemento}
            {...register('complemento')}
          />

          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            <Button
              type="button"
              onClick={handleStepBack}
              variant="outline"
              colorScheme="brand-orange"
              h="45px"
              borderRadius="28px"
              color="brand-orange.300"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Voltar
            </Button>

            <Button
              type="button"
              onClick={handleStepCompletion}
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Continuar
            </Button>
          </SimpleGrid>
        </VStack>
      )}

      {formStep === 2 && (
        <>
          <VStack spacing="4" align="flex-start">
            <TextInput
              type="password"
              placeholder="*******"
              label="Senha"
              error={errors.senha}
              {...register('senha')}
            />

            <Text color="brand-dark.300" fontWeight="medium">
              Link - Termos de Uso e Política de Privacidade
              <br />
              <Link
                color="#bfbfbf"
                fontSize="sm"
                fontWeight="normal"
                href="https://youtube.com"
                isExternal
              >
                https://termos.com.br
              </Link>
            </Text>
          </VStack>

          <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
            <Button
              isDisabled={isSecretaryMutationLoading}
              type="button"
              onClick={handleStepBack}
              variant="outline"
              colorScheme="brand-orange"
              h="45px"
              borderRadius="28px"
              color="brand-orange.300"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Voltar
            </Button>

            <Button
              isLoading={isSecretaryMutationLoading}
              type="submit"
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Cadastrar
            </Button>
          </SimpleGrid>
        </>
      )}

      {formStep === 3 && (
        <Flex w="100%" direction="column" align="center">
          <Heading
            color="brand-dark.300"
            fontSize="md"
            fontWeight="medium"
            textAlign="center"
          >
            Cadastro Realizado com Sucesso!
          </Heading>

          <Image
            mt="8"
            mb="4"
            src={registerConfirmationImg}
            alt="Mulher confirmando o sucesso de uma operação em um celular grande"
          />

          <Text
            color="brand-dark.200"
            fontSize="sm"
            fontWeight="normal"
            textAlign="center"
          >
            Você pode editar as configurações na aba de cadastros.
          </Text>

          <Button
            type="button"
            onClick={onCancel}
            colorScheme="brand-green"
            mt="10"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Ir para cadastros
          </Button>
        </Flex>
      )}
    </Box>
  );
}
