import { FormControl, Select, SelectProps } from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { FieldError } from 'react-hook-form';
import { MdOutlineArrowDropDown } from 'react-icons/md';

interface SelectInputProps extends SelectProps {
  options: {
    label: string;
    value: string;
  }[];
  error?: FieldError;
}

const SelectInputBase: ForwardRefRenderFunction<
  HTMLSelectElement,
  SelectInputProps
> = ({ name, options, error, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      <Select
        {...rest}
        id={name}
        name={name}
        ref={ref}
        icon={<MdOutlineArrowDropDown />}
        variant="filled"
        h="42px"
        borderColor="gray.500"
        borderWidth={error ? '2px' : '1px'}
        background="white"
        color="gray.500"
        _hover={{
          borderColor: 'brand-orange.400',
        }}
        sx={{
          option: {
            background: 'white',
          },
        }}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export const SelectInput = forwardRef(SelectInputBase);
