import { isMobile } from '@/utils';
import { Flex, Heading, Stack } from '@chakra-ui/react';
import { useUserStore } from '@/stores';
import { DrawerIaChat } from '@/components/IaChat/DrawerIaChat';
import { useState } from 'react';
import { SelectPatientToGuidance } from '@/components/IaChat/components/SelectPatientToGuidance';
import { ScaleActions } from '../SchedulerSection/ScaleActions';
import { DateRangeSelect } from './DateRangeSelect';

export function TopSection() {
  const user = useUserStore(state => state.user);
  const [chatMessageSelected, setChatMessageSelected] = useState<string>();

  return (
    <Flex direction={['column']} gap={4}>
      {user?.type !== 'paciente' && (
        <DrawerIaChat
          isFluctuant
          minW="10"
          ml="auto"
          w={['100%', '2xl']}
          onMessageCallback={setChatMessageSelected}
        />
      )}
      <Flex
        direction={['column', 'column', 'row']}
        w="100%"
        align="center"
        justify="space-between"
      >
        <Heading
          size="lg"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
        >
          Agenda
        </Heading>
        {user?.type !== 'paciente' && (
          <Stack
            direction={['column', 'row']}
            spacing="4"
            w="100%"
            justify="flex-end"
          >
            <ScaleActions />

            {!isMobile() && <DateRangeSelect />}
          </Stack>
        )}
      </Flex>

      <SelectPatientToGuidance
        isOpen={!!chatMessageSelected}
        message={chatMessageSelected || ''}
        onClose={() => setChatMessageSelected(undefined)}
      />
    </Flex>
  );
}
