import { Flex, useDisclosure, Text, Icon, Button } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MdError } from 'react-icons/md';
import { usePrescriptionQuery } from '../../hooks/usePrescriptionQuery';
import { PrescriptionModal } from '../Modals/PrescriptionModal';
import { PostPrescription } from '../../hooks/PostPrescription';
// import { PrescriptionModal } from './Modals/PrescriptionModal';

export function Prescription() {
  const navigate = useNavigate();
  const loc = useLocation();
  const [location, setLocation] = useState<any>(loc.state);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [url, setUrl] = useState('');

  const {
    data: infos,
    refetch,
    isError,
    isLoading,
  } = usePrescriptionQuery(location.idPaciente, location.idMedico);

  useEffect(() => {
    setUrl(infos?.modalURL);
  }, [infos]);

  useEffect(() => {
    if (loc.state) {
      setLocation(loc.state);
    }
  }, [loc]);

  useEffect(() => {
    window.addEventListener(
      'message',
      event => {
        PostPrescription(event.data, location.idPaciente, location.idMedico);
        const validEvents = ['cancel', 'excluded', 'prescricao'];
        if (validEvents.includes(event.data.type)) {
          if (event.data.type === 'prescricao') {
            onOpen();
          }
        }
      },
      false,
    );
  }, []);

  return (
    <Flex p="20px" direction="column" flex="1">
      {!isError ? (
        <Flex
          w="100%"
          bg="gray.200"
          align="center"
          justify="center"
          flex="1"
          display="block"
          position="relative"
        >
          <iframe
            // height="100%"
            // width="100%"
            id="idframe"
            height="800px"
            src={url}
            width="100%"
            title="prescriçao"
          />

          {isOpen ? (
            <PrescriptionModal
              isOpen={isOpen}
              onClose={onClose}
              refetch={refetch}
              isLoading={isLoading}
            />
          ) : null}
        </Flex>
      ) : (
        <Flex
          w="100%"
          align="center"
          justifyContent="center"
          flexDirection="column"
          border="1px solid #999999"
          padding={10}
        >
          <Icon
            as={MdError}
            cursor="pointer"
            color="brand-dark.200"
            fontSize={200}
            fontWeight="400"
            marginRight="15"
          />
          <Text
            fontSize="2xl"
            w="100%"
            color="brand-dark.300"
            fontFamily="Rubik"
            paddingBottom="19"
            align="center"
          >
            ERRO NA CONEXÃO COM A MEVO. TENTE NOVAMENTE MAIS TARDE.
          </Text>
          <Button
            type="button"
            bg="#999999"
            color="#fff"
            mt="12px"
            py="12px"
            px="20px"
            w={['100%', 'auto']}
            borderRadius="20px"
            fontSize="xs"
            fontWeight="600"
            textTransform="uppercase"
            onClick={() => navigate('/')}
          >
            Sair da Página
          </Button>
        </Flex>
      )}
    </Flex>
  );
}
