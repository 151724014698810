import {
  Box,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  Flex,
  Skeleton,
  Button,
  useToast,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useEffect, useMemo, useState } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useExpanded,
  useGlobalFilter,
} from 'react-table';
import { TableColumnsDetails } from '@/features/historic';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { registerQuestionarySchema } from '@/features/historic/utils/schemas';
import { format, parseISO } from 'date-fns';
import { Pagination } from '@/features/notifications/components/TableSection/Pagination';
import { GenericModal } from '@/features/registers';
import useGetPatientExams from '@/features/historic/hooks/useGetPatientExams';
import { SearchInput } from './SearchInput';
import { ModalViewExams } from './ModalViewExams';
import { ModalAddExams } from './ModalAddExams';

type SchemaProps = {
  questionarioId: string;
  descricao: string;
  periodicidade: number;
  startDate: Date;
  finalDate: Date;
  horarios: Date[];
  horariosWithWeeks: any[];
  horariosWithMonths: any[];
};

export function TableSectionExams({
  patientId,
  patient,
}: {
  patientId: string;
  patient: any;
}) {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const [response, setResponse] = useState<any>();
  const [openModalAddExam, setOpenModalAddExam] = useState(false);
  const [viewFile, setViewFile] = useState(false);
  const [loading, setLoading] = useState(false);

  function onCloseCancel() {
    setResponse(undefined);
    setViewFile(false);
  }

  const {
    data: patientExams,
    refetch,
    isLoading: ispatientExamsLoading,
  } = useGetPatientExams(patientId);

  const {
    control,
    handleSubmit,
    reset,
    register,
    watch,
    setValue,
    formState: { errors },
  } = useForm<SchemaProps>({
    resolver: yupResolver(registerQuestionarySchema),
    defaultValues: {
      horarios: [],
      horariosWithWeeks: [],
      horariosWithMonths: [],
    },
  });

  // const data = useMemo<TableColumnsDetails[]>(
  //   () =>
  //     [
  //       {
  //         id: '6a24f885-ffb8-4742-9876-35bd007c301c',
  //         name: 'EXAME TESTE 1',
  //         type: 'TYPE EXAME',
  //         data: '2024-05-20T12:53:45.018',
  //         url: 'https://eppg.fgv.br/sites/default/files/teste.pdf',
  //       },
  //       {
  //         id: '6a24f885-ffb8-4742-9876-35bd007c3000',
  //         name: 'EXAME TESTE 2',
  //         type: 'TYPE EXAME 2',
  //         data: '2024-03-20T12:53:45.018',
  //         url: 'https://eppg.fgv.br/sites/default/files/teste.pdf',
  //       },
  //       {
  //         id: '6a24f885-ffb8-4742-9876-35bd007c3111',
  //         name: 'EXAME TESTE 3',
  //         type: 'TYPE EXAME 3',
  //         data: '2023-12-20T12:53:45.018',
  //         url: 'https://eppg.fgv.br/sites/default/files/teste.pdf',
  //       },
  //     ] || [],
  //   [patientExams],
  // );

  const data = useMemo<TableColumnsDetails[]>(
    () => patientExams || [],
    [patientExams],
  );

  const columns = useMemo(
    () => [
      {
        header: 'Exame',
        accessor: 'titulo',
        Cell: ({ cell }: any) => {
          return cell.row.original.titulo || '';
        },
      },
      {
        header: 'Descrição',
        accessor: 'descricao',
        Cell: ({ cell }: any) => {
          return cell.row.original.descricao || '';
        },
      },
      {
        header: 'Diagnóstico (CID)',
        accessor: 'cid',
        Cell: ({ cell }: any) => {
          return cell.row.original.cid || 'Não informado';
        },
      },
      {
        header: 'Data do exame',
        accessor: 'dataExame',
        Cell: ({ cell }: any) => {
          return (
            format(parseISO(cell.row.original.dataExame), 'dd/MM/yyyy') || ''
          );
        },
      },
    ],
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    pageOptions,
    gotoPage,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: 5,
      },
    },
    hooks => {
      hooks.visibleColumns.push(originalColumns => [
        ...originalColumns,
        {
          id: 'expander',
          header: 'Ações',
          Cell: ({ row }) => (
            <Flex alignItems="center" justifyContent="flex-start" paddingY="2">
              <Button
                type="button"
                bg="transparent"
                onClick={() => setResponse(row?.original as any)}
              >
                Visualizar
              </Button>
            </Flex>
          ),
        },
      ]);
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const { pageIndex, globalFilter } = state;

  useEffect(() => {
    refetch();
  }, [openModalAddExam, response]);

  return (
    <Box>
      <Box>
        <SearchInput filter={globalFilter} setFilter={setGlobalFilter} />

        <Button
          onClick={() => setOpenModalAddExam(true)}
          py={6}
          fontSize="large"
          fontWeight="medium"
          textTransform="uppercase"
        >
          ADICIONAR EXAMES
        </Button>
      </Box>

      <Box>
        {ispatientExamsLoading ? (
          <Skeleton mt="8" w="100%" h="600px" border="20px" />
        ) : patientExams?.length > 0 ? (
          <Box mt="8" w="100%">
            <TableContainer
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': { width: '8px', height: '8px' },
                '&::-webkit-scrollbar-thumb': {
                  borderRadius: 20,
                  background: 'brand-dark.200',
                },
              }}
            >
              <Table
                size="lg"
                bg="#fff"
                borderRadius="15px"
                {...getTableProps()}
              >
                <Thead h="50px">
                  {headerGroups.map(headerGroup => {
                    return (
                      <Tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                          <Th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps(),
                            )}
                            color="#000000"
                            fontFamily="Rubik"
                            fontSize="lg"
                            fontWeight="normal"
                            textAlign="left"
                            textTransform="initial"
                            border="1px solid #0005"
                          >
                            <>
                              {column.render('header')}

                              <Text as="span" color="#DEDEDE">
                                {column.isSorted
                                  ? column.isSortedDesc
                                    ? '▼'
                                    : '▲'
                                  : ''}
                              </Text>
                            </>
                          </Th>
                        ))}
                      </Tr>
                    );
                  })}
                </Thead>

                <Tbody {...getTableBodyProps()}>
                  {page.map((row, id) => {
                    prepareRow(row);
                    return (
                      <Tr bg={id % 2 === 0 ? '#d9d9d9' : 'transparent'}>
                        {row.cells.map((cell, index) => {
                          return (
                            <Td
                              {...cell.getCellProps()}
                              color="brand-dark.300"
                              fontSize="md"
                              fontWeight="medium"
                              whiteSpace="pre-wrap"
                              cursor="pointer"
                              position="relative"
                              border="1px solid #0005"
                              // onClick={() => {
                              //   if (index !== 5) {
                              //     navigate('/app/historic', {
                              //       state: { paciente: cell.row.original },
                              //     });
                              //   }
                              // }}
                            >
                              <span
                                style={{
                                  display: 'flex',
                                  justifyContent: `${
                                    index === 5 ? 'flex-end' : 'flex-start'
                                  }`,
                                }}
                              >
                                {row.original.recente &&
                                  cell.column.id === 'paciente'}
                                {cell.render('Cell')}
                              </span>
                            </Td>
                          );
                        })}
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </TableContainer>

            <Flex
              w="100%"
              direction={['column', 'column', 'row']}
              align="center"
              justify="space-between"
            >
              <Text color="brand-dark.300" fontSize="sm">
                Página {pageIndex + 1} de {pageOptions.length}
              </Text>

              <Pagination
                currentPage={pageIndex}
                totalCountOfRegisters={data.length}
                canPreviousPage={canPreviousPage}
                canNextPage
                onPageChange={gotoPage}
              />
            </Flex>
          </Box>
        ) : (
          <Box
            width="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            padding={10}
          >
            <Text
              fontSize="lg"
              w="100%"
              color="brand-dark.300"
              fontFamily="Rubik"
              paddingBottom="19"
              margin="auto"
              textAlign="center"
            >
              NÃO HÁ EXAMES SALVOS DESTE PACIENTE
            </Text>
          </Box>
        )}
      </Box>

      <Modal isOpen={!!response} onClose={onCloseCancel} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader color="brand-dark.300" fontSize="xl" fontWeight="medium">
            {`Exame: ${patient?.nomePaciente}` || 'Visualizar Exame'}
          </ModalHeader>
          <ModalBody px="10" pb="8">
            <ModalViewExams
              data={response!}
              viewFile={viewFile}
              setViewFile={setViewFile}
            />
          </ModalBody>
        </ModalContent>
      </Modal>

      <GenericModal
        open={openModalAddExam}
        title="Adicionar Exame"
        onClose={() => setOpenModalAddExam(false)}
        closeOnOverlayClick={!loading}
      >
        <ModalAddExams
          patientId={patientId}
          onClose={() => setOpenModalAddExam(false)}
          loading={loading}
          setLoading={setLoading}
        />
      </GenericModal>
    </Box>
  );
}
