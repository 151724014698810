import { Flex, Grid, GridItem } from '@chakra-ui/react';
import {
  SendEmailForm,
  LoginForm,
  LoginImageSection,
  FirstAccessEmailForm,
  FirstAccessCodeForm,
} from '@/features/auth';
import { useState } from 'react';

export function LoginPage() {
  const [formStep, setFormStep] = useState(0);
  const [email, setEmail] = useState('');
  const [dataUser, setDataUser] = useState({
    instancia: null,
    unidade: [],
    profissional: null,
  });

  const SetStep = (step: number) => {
    setFormStep(step);
  };

  return (
    <Flex
      as="main"
      w="100%"
      minH="100vh"
      bg="brand-green.400"
      align="center"
      justify="center"
    >
      <Grid
        as="section"
        templateColumns={
          formStep === 2 || formStep === 3
            ? ['1fr', '1fr', '70% 30%']
            : [('1fr', '1fr', '45% 55%')]
        }
        m="8"
        maxW="1020px"
        w="100%"
        h={['auto', 'auto', 'max(70vh, 685px)']}
        bg="white"
        borderRadius="10px"
        overflow="hidden"
      >
        {formStep === 0 ? (
          <GridItem w="100%">
            <SendEmailForm
              SetStep={SetStep}
              setEmail={setEmail}
              setDataUser={setDataUser}
            />
          </GridItem>
        ) : formStep === 1 || formStep === 4 ? (
          <GridItem w="100%">
            <LoginForm SetStep={SetStep} email={email} />
          </GridItem>
        ) : (
          <GridItem w="100%">
            <FirstAccessEmailForm
              formStep={formStep}
              SetStep={SetStep}
              email={email}
              dataUser={dataUser}
              setDataUser={setDataUser}
            />
          </GridItem>
        )}
        <GridItem w="100%">
          <LoginImageSection />
        </GridItem>
      </Grid>
    </Flex>
  );
}
