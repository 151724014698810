import { Navigate, Route, Routes } from 'react-router-dom';

import { NotificationsPage } from '@/features/notifications';

export function NotificationsRoutes() {
  return (
    <Routes>
      <Route path="" element={<NotificationsPage />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
}
