import { api } from '@/lib';
import { useUserStore } from '@/stores';
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

export const msgSchema = yup.object().shape({
  orientation: yup.string().min(1),
});

interface GuidanceModalProps {
  patient: {
    idPaciente: string;
    nomePaciente: string;
    idade: number;
  };
  guidance?: string;
  onClose: () => void;
  isOpen: boolean;
}

export function GuidanceModal({
  isOpen,
  onClose,
  patient,
  guidance,
}: GuidanceModalProps) {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const user = useUserStore(state => state.user);

  const {
    handleSubmit,
    register,
    setValue,
    formState: { isSubmitting },
  } = useForm<{ orientation: string }>({
    resolver: yupResolver(msgSchema),
  });

  useEffect(() => {
    setValue('orientation', guidance || '');
  }, [guidance]);

  const handleSubmitGuidance = async ({
    orientation,
  }: {
    orientation: string;
  }) => {
    const data = {
      tipoLocalOrientacao: 2,
      pacienteId: patient.idPaciente,
      profissionalId: user!.id,
      descricao: orientation,
    };

    try {
      await api.post(`/CondutaOrientacao`, data);

      toast({
        title: 'Orientação enviada!',
        status: 'success',
      });

      onClose();
    } catch (error: any) {
      toast({
        title: error.response.data.errors[0] || 'Ocorreu um erro!',
        status: 'error',
      });
    }
  };

  return (
    <Modal isCentered isOpen={isOpen} size="3xl" onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader margin="0 auto">Encaminhar orientação</ModalHeader>
        <ModalCloseButton />
        <ModalBody display="flex" gap={4} flexDir="column">
          <Text fontSize="lg" fontWeight="medium">
            Para:
          </Text>
          <Flex>
            <Box>
              <Text fontSize="md" fontWeight="medium">
                {patient.nomePaciente}
              </Text>
              <Text fontSize="md" fontWeight="medium">
                {patient.idade} anos
              </Text>
            </Box>
          </Flex>

          <Text fontSize="lg" fontWeight="medium">
            Orientação:
          </Text>
          <Textarea
            h="2xs"
            fontSize="md"
            fontWeight="medium"
            letterSpacing="tight"
            {...register('orientation')}
            resize="vertical"
          />
        </ModalBody>

        <ModalFooter>
          <Button variant="ghost" mr={4} onClick={onClose}>
            Cancelar
          </Button>
          <Button
            colorScheme="green"
            onClick={handleSubmit(handleSubmitGuidance)}
            isLoading={isSubmitting}
          >
            Enviar
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}
