import { useCallback, useEffect, useState } from 'react';
import {
  VStack,
  SimpleGrid,
  Button,
  Box,
  HStack,
  IconButton,
  useToast,
} from '@chakra-ui/react';
import { useUserStore } from '@/stores';
import { SelectInput, TextInput } from '@/components';
import { useUnidadesMutation } from '@/hooks';
import { api } from '@/lib';
import { Icon } from '@chakra-ui/icons';
import { IoAdd } from 'react-icons/io5';
import { UnidadeList } from '../RegisterUser/UnidadesList';
import { Unidade } from '../../types';

interface Props {
  type: number;
  id: string;
  onCancel: () => void;
  // onSubmit: (data: any) => void;
}

/* type:
  1- Profissional
  2- Paciente
  3- Gestor
  4- Assistente 
*/

export function EditInstances({ type, id, onCancel }: Props) {
  const { mutate } = useUnidadesMutation();
  const user = useUserStore(state => state.user);
  const [instance, setInstances] = useState<Unidade>();
  const [unidade, setUnidade] = useState<Unidade[]>([]);
  const [unidades, setUnidades] = useState<any[]>([]);
  const [unidadesId, setUnidadesId] = useState('');
  const [unidadeId, setUnidadeId] = useState('');
  const [professionalId, setProfessionalId] = useState<string>('');
  const [doctors, setDoctors] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const [update, setUpdade] = useState<boolean>(true);
  const changeUpdate = () => {
    setUpdade(!update);
  };

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  async function professionalsRequest(idUnidade: string): Promise<any> {
    const { data } = await api.get<any>(
      `/instancia/unidade-listar-profssionais?id=${idUnidade}`,
    );

    setDoctors(
      data.data.map((value: any) => ({
        label: value.usuarioProfissionalSaude.pessoa.nome,
        value: value.codigoVinculo,
        idMedico: value.usuarioProfissionalSaudeId,
      })),
    );
  }

  const getInstances = async () => {
    if (type === 1) {
      const { data } = await api.get(
        `/instancia/instancia-listar-id-profissional?id=${id}`,
      );
      const idIns = Object.keys(data.data)[0];
      const teste = {
        id: idIns,
        nome: data.data[idIns],
      };
      setInstances(teste);

      if (idIns) {
        await mutate(idIns, {
          onSuccess: (response: any) => {
            setUnidades(
              response.data.map((r: any) => {
                return { value: r.id, label: r.descricao };
              }),
            );
          },
        });
      }
    }
    if (type === 2) {
      const { data } = await api.get(
        `/instancia/instancia-listar-id-paciente?id=${id}`,
      );
      const idIns = Object.keys(data.data)[0];
      const teste = {
        id: idIns,
        nome: data.data[idIns],
      };
      setInstances(teste);

      if (idIns) {
        await mutate(idIns, {
          onSuccess: (response: any) => {
            setUnidades(
              response.data.map((r: any) => {
                return { value: r.id, label: r.descricao };
              }),
            );
          },
        });
      }
    }
    if (type === 3) {
      const { data } = await api.get(
        `/instancia/instancia-listar-id-gestor-master?id=${id}`,
      );
      const idIns = Object.keys(data.data)[0];
      const teste = {
        id: idIns,
        nome: data.data[idIns],
      };
      setInstances(teste);

      if (idIns) {
        await mutate(idIns, {
          onSuccess: (response: any) => {
            setUnidades(
              response.data.map((r: any) => {
                return { value: r.id, label: r.descricao };
              }),
            );
          },
        });
      }
    }
    if (type === 4) {
      const { data } = await api.get(
        `/instancia/instancia-listar-id-assistente?id=${id}`,
      );
      const idIns = Object.keys(data.data)[0];
      const teste = {
        id: idIns,
        nome: data.data[idIns],
      };
      setInstances(teste);

      if (idIns) {
        await mutate(idIns, {
          onSuccess: (response: any) => {
            setUnidades(
              response.data.map((r: any) => {
                return { value: r.id, label: r.descricao };
              }),
            );
          },
        });
      }
    }
  };

  const getUnidades = async () => {
    if (type === 1) {
      const { data: uni } = await api.get(
        `/instancia/unidade-listar-id-profissional?id=${id}`,
      );

      const array = Object.keys(uni.data).map(chave => {
        return {
          id: chave,
          nome: uni.data[chave],
        };
      });

      setUnidade(array);
    }

    if (type === 2) {
      const { data: uni } = await api.get(
        `/instancia/unidade-listar-id-paciente?id=${id}`,
      );

      const array = Object.keys(uni.data).map(chave => {
        return {
          id: chave,
          nome: uni.data[chave],
        };
      });

      setUnidade(array);
    }

    if (type === 3) {
      const { data: uni } = await api.get(
        `/instancia/unidade-listar-id-gestor?id=${id}`,
      );

      const array = Object.keys(uni.data).map(chave => {
        return {
          id: chave,
          nome: uni.data[chave],
        };
      });

      setUnidade(array);
    }

    if (type === 4) {
      const { data: uni } = await api.get(
        `/instancia/unidade-listar-id-assistente?id=${id}`,
      );

      const array = Object.keys(uni.data).map(chave => {
        return {
          id: chave,
          nome: uni.data[chave],
        };
      });

      setUnidade(array);
    }
  };

  const vincularUser = async (uniId: string) => {
    setIsLoading(true);
    if (type === 1) {
      const res = await api.post(
        `/instancia/unidade-associar-profissional?instanciaId=${uniId}&profissionalSaudeId=${id}`,
      );
      getUnidades();
      setIsLoading(false);
      return res.data.success;
    }

    if (type === 2) {
      if (!professionalId) {
        return toast({
          title: 'Selecione um profissional',
          status: 'error',
        });
      }
      await api
        .post(
          '/paciente/vincular-paciente-new',
          {},
          {
            params: {
              idPaciente: id,
              codigo: professionalId,
              idUnidade: uniId,
            },
          },
        )
        .then(result => {
          toast({
            title: 'Paciente vinculado!',
            status: 'success',
          });
          getUnidades();

          setProfessionalId('');
          return result.data.success;
        })
        .catch(e => {
          toast({
            title: 'Erro ao vincular com esse Profissional, tente novamente!',
            status: 'error',
          });
        });
      setIsLoading(false);
    }

    if (type === 3) {
      const res = await api.post(
        `/instancia/unidade-associar-gestor?instanciaId=${uniId}&gestorId=${id}`,
      );
      getUnidades();
      setIsLoading(false);
      return res.data.success;
    }

    if (type === 4) {
      const res = await api.post(
        `/instancia/unidade-associar-assistente?instanciaId=${uniId}&secretariaId=${id}`,
      );
      getUnidades();
      setIsLoading(false);
      return res.data.success;
    }

    return false;
  };

  const desvincularUser = async (uniId: string) => {
    setIsLoading(true);
    if (type === 1) {
      await api.delete(
        `/instancia/unidade-remover-profissional?instanciaId=${uniId}&profissionalSaudeId=${id}`,
      );
      getUnidades();
    }

    if (type === 2) {
      await api.delete(
        `/instancia/unidade-remover-paciente?instanciaId=${uniId}&pacienteId=${id}`,
      );
      getUnidades();
    }

    if (type === 3) {
      await api.delete(
        `/instancia/unidade-remover-gestor?instanciaId=${uniId}&gestorId=${id}`,
      );
      getUnidades();
    }

    if (type === 4) {
      await api.delete(
        `/instancia/unidade-remover-assistente?instanciaId=${uniId}&secretariaId=${id}`,
      );
      getUnidades();
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getInstances();
    getUnidades();
  }, []);

  async function handleAddUnidades() {
    if (!unidadesId) {
      toast({
        title: 'Selecione uma unidade',
        status: 'error',
      });

      return;
    }

    const found = unidade?.find(item => item.id === unidadesId);

    if (found && type !== 2) {
      toast({
        title: 'Unidade já adicionada!',
        status: 'error',
      });
      return;
    }

    setUnidadeId(unidadesId);
    professionalsRequest(unidadesId);
    const vinc = await vincularUser(unidadesId);
    if (vinc) {
      getUnidades();
      changeUpdate();
    }
  }

  const handleChangeProfessional = useCallback((target: any) => {
    setProfessionalId(target.value);
  }, []);

  useEffect(() => {
    professionalsRequest(unidadesId);
  }, [unidadesId]);

  return (
    <Box as="form">
      <VStack spacing="4">
        <TextInput
          value={instance?.nome}
          label="Instância de Saúde"
          isDisabled
        />

        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          <HStack spacing="2" align="flex-end">
            <SelectInput
              label="Unidades de Saúde"
              options={unidades}
              placeholder="Selecione unidade de saúde"
              onChange={e => setUnidadesId(e.target.value)}
            />
            <IconButton
              aria-label="Adicionar unidade"
              icon={<Icon as={IoAdd} color="white" fontSize="20px" />}
              onClick={handleAddUnidades}
              colorScheme="brand-green"
              w="52px"
              h="52px"
              borderRadius="14px"
            />
          </HStack>
        </SimpleGrid>

        {type === 2 && (
          <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
            {user?.type === 'medico' ? (
              <TextInput
                value={user?.name}
                label="Profissional de Saúde"
                isDisabled
              />
            ) : (
              <SelectInput
                options={doctors.filter(
                  (d: { primeiroAcesso: boolean }) => !d.primeiroAcesso,
                )}
                placeholder="Associar um Profissional de Saúde"
                label="Profissional de Saúde"
                onChange={({ target }) => handleChangeProfessional(target)}
              />
            )}
          </SimpleGrid>
        )}

        <UnidadeList
          unidades={unidade}
          onDelete={(uni: any) => {
            desvincularUser(uni);
          }}
        />

        <SimpleGrid minChildWidth="180px" spacing="4" mt="246px" w="100%">
          <Button
            type="button"
            onClick={onCancel}
            variant="outline"
            colorScheme="brand-orange"
            h="45px"
            borderRadius="28px"
            color="brand-orange.300"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
            disabled={isLoading}
          >
            Finalizar
          </Button>

          {/* <Button
            type="submit"
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Salvar
          </Button> */}
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
