import { Tr, Td } from '@chakra-ui/react';
import { TablesReport, useCidTableQuery } from '@/features/reports';

export function CidTable() {
  const { data } = useCidTableQuery();
  return (
    <TablesReport head={data ? data.tableHead : []}>
      {data?.tableBody.map((b: any, index: any) => {
        return (
          <Tr>
            <Td>{b.descricaoCid}</Td>
            <Td>{b.totalCid}</Td>
            <Td>{b.cidAtivos}</Td>
            <Td>{b.cidInativos}</Td>
          </Tr>
        );
      })}
    </TablesReport>
  );
}
