import { ReactNode } from 'react';
import Carousel from 'react-multi-carousel';

interface ActivitiesCarouselProps {
  children: ReactNode;
}

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
    partialVisibilityGutter: 60,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    partialVisibilityGutter: 50,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    partialVisibilityGutter: 30,
  },
};

export function ActivitiesCarousel({ children }: ActivitiesCarouselProps) {
  return (
    <Carousel
      responsive={responsive}
      autoPlay={false}
      shouldResetAutoplay={false}
      removeArrowOnDeviceType={['desktop']}
      partialVisible
      itemClass="carousel-item"
    >
      {children}
    </Carousel>
  );
}
