import create from 'zustand';

type EditUserModalStore = {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
};

export const useEditUserModalStore = create<EditUserModalStore>(set => ({
  isOpen: false,
  onOpen: () => set(state => ({ isOpen: true })),
  onClose: () => set(state => ({ isOpen: false })),
}));
