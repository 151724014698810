import * as yup from 'yup';

import {
  isValidDate,
  isValidDateAndNotPast,
  isValidMonth,
  isValidNumber,
  isValidTime,
} from '@/utils';

export const addScaleSchema = yup.object().shape({
  // data: yup
  //   .string()
  //   .test('isValidDate', 'Data inválida ou anterior ao dia de hoje', value =>
  //     isValidDateAndNotPast(value, { isFormatted: true }),
  //   )
  //   .required('Campo obrigatório'),
  // intervalo: yup
  //   .string()
  //   .test('isValidTime', 'Horário inválido', value => isValidTime(value))
  //   .required('Campo obrigatório'),
  // horarioInicio: yup
  //   .string()
  //   .test('isValidTime', 'Horário inválido', value => isValidTime(value))
  //   .required('Campo obrigatório'),
  // horarioFim: yup
  //   .string()
  //   .test('isValidTime', 'Horário inválido', value => isValidTime(value))
  //   .required('Campo obrigatório'),
});

export const replicateScaleSchema = yup.object().shape({
  tipoReplica: yup.string(),
  origemReplica: yup
    .string()
    .when('tipoReplica', {
      is: 'dia',
      then: yup
        .string()
        .test('isValidDate', 'Data inválida', value => isValidDate(value)),
      otherwise: yup
        .string()
        .test('isValidMonth', 'Data inválida', value => isValidMonth(value)),
    })
    .required('Campo obrigatório'),
  destinoReplica: yup
    .string()
    .when('tipoReplica', {
      is: 'dia',
      then: yup
        .string()
        .test('isValidDate', 'Data inválida', value => isValidDate(value)),
      otherwise: yup
        .string()
        .test('isValidMonth', 'Data inválida', value => isValidMonth(value)),
    })
    .required('Campo obrigatório'),
  observacao: yup.string().required('Campo obrigatório'),
});

export const addBatchSchema = yup.object().shape({
  // dataInicio: yup
  //   .string()
  //   .test('isValidDate', 'Data inválida', value => isValidDate(value))
  //   .required('Campo obrigatório'),
  quantidade: yup
    .string()
    .test('isValidCouncilNumber', 'Número inválido', value =>
      isValidNumber(value),
    )
    .required('Campo obrigatório'),
  // horarioInicio: yup
  //   .string()
  //   .test('isValidTime', 'Horário inválido', value => isValidTime(value))
  //   .required('Campo obrigatório'),
  // horarioFim: yup
  //   .string()
  //   .test('isValidTime', 'Horário inválido', value => isValidTime(value))
  //   .required('Campo obrigatório'),
  // observacao: yup.string().required('Campo obrigatório'),
});

export const lockScaleSchema = yup.object().shape({
  // dataInicio: yup
  //   .string()
  //   .test('isValidDate', 'Data inválida', value => isValidDate(value))
  //   .required('Campo obrigatório'),
  // dataFim: yup
  //   .string()
  //   .test('isValidDate', 'Data inválida', value => isValidDate(value))
  //   .required('Campo obrigatório'),
  // horarioInicio: yup
  //   .string()
  //   .test('isValidTime', 'Horário inválido', value => isValidTime(value))
  //   .required('Campo obrigatório'),
  // horarioFim: yup
  //   .string()
  //   .test('isValidTime', 'Horário inválido', value => isValidTime(value))
  //   .required('Campo obrigatório'),
  motivo: yup.string().required('Campo obrigatório'),
});

export const lockScaleCard = yup.object().shape({
  motivo: yup.string().required('Campo obrigatório'),
});

export const reScheduleSchema = yup.object().shape({
  data: yup
    .string()
    .test('isValidDate', 'Data inválida ou anterior ao dia de hoje', value =>
      isValidDateAndNotPast(value, { isFormatted: true }),
    )
    .required('Campo obrigatório'),
  // intervalo: yup
  //   .string()
  //   .test('isValidTime', 'Horário inválido', value => isValidTime(value))
  //   .required('Campo obrigatório'),
  // horarioInicio: yup
  //   .string()
  //   .test('isValidTime', 'Horário inválido', value => isValidTime(value))
  //   .required('Campo obrigatório'),
  // horarioFim: yup
  //   .string()
  //   .test('isValidTime', 'Horário inválido', value => isValidTime(value))
  //   .required('Campo obrigatório'),
});
