import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  VStack,
  Grid,
  GridItem,
  Text,
  Heading,
  Link,
  Divider,
} from '@chakra-ui/react';
import { format, parseISO } from 'date-fns';
import {
  ReactElement,
  JSXElementConstructor,
  ReactNode,
  ReactPortal,
} from 'react';

interface Props {
  secretary: any;
}

const FormatData = ({ label1, value1, label2, value2 }: any) => {
  return (
    <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
      <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
        <Heading as="h6" size="sm" color="#707070">
          {label1}
        </Heading>
        <Text>{value1}</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Heading as="h6" size="sm" color="#707070">
          {label2}
        </Heading>
        <Text>{value2}</Text>
      </GridItem>
    </Grid>
  );
};

export function ModalViewExams({ data: values, viewFile, setViewFile }: any) {
  return (
    <VStack spacing="4">
      <FormatData
        label1="Exame"
        value1={values && values.titulo}
        label2="Diagnóstico (CID)"
        value2={(values && values.cid) || 'Não informado'}
      />
      <FormatData
        label1="Descrição"
        value1={values && values.descricao}
        label2="Data do Exame"
        value2={
          (values && format(parseISO(values.dataExame), 'dd/MM/yyyy')) || ''
        }
      />
      {values.files && (
        <>
          <Divider />
          <Heading as="h6" size="sm" color="#707070">
            EXAMES
          </Heading>
          {values.files.length &&
            values.files.map(
              (file: { uri: string | undefined; fileName: string }) => {
                return (
                  <Link
                    href={file.uri}
                    target="_blank"
                    color="teal.500"
                    textAlign="center"
                  >
                    {file.fileName} <ExternalLinkIcon mx="2px" />
                  </Link>
                );
              },
            )}
        </>
      )}

      <Divider />

      <Heading as="h6" size="sm" color="#707070">
        OPEN HEALTH RELATÓRIO
      </Heading>
      {values.openHealthRelatorio &&
      values.openHealthRelatorio.length > 0 &&
      values.openHealthRelatorio[0].url ? (
        <Link
          href={values.openHealthRelatorio[0].url}
          target="_blank"
          color="teal.500"
          textAlign="center"
        >
          {values.openHealthRelatorio[0].url} <ExternalLinkIcon mx="2px" />
        </Link>
      ) : (
        <Text>NÃO INFORMADO</Text>
      )}
    </VStack>
  );
}
