import Toolbar from '@/components/CalendarWeek.tsx/Toolbar';
import { SchedulerSection, TopSection } from '@/features/schedule';
import { useState } from 'react';
import { useUserStore } from '@/stores';
import { SchedulerSectionWeek } from '../components/SchedulerSectionWeek';
import { useScheduleIntervalQuery } from '../hooks/useScheduleIntervalQuery';
import { useViewContext } from '../hooks/useViewContext';

import { SchedulerSectionPatient } from '../components/SchedulerSectionPatient';

export function SchedulePage() {
  const user = useUserStore(state => state.user);
  const { view } = useViewContext();

  return (
    <>
      <TopSection />

      {user?.type !== 'paciente' ? (
        view === 'week' ? (
          <SchedulerSectionWeek />
        ) : (
          <SchedulerSection />
        )
      ) : (
        <SchedulerSectionPatient />
      )}
    </>
  );
}
