import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { BsArrowUpSquare } from 'react-icons/bs';
import { FiArrowUp, FiCopy } from 'react-icons/fi';
import iaSvg from '@/assets/ia_icon.svg';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { api } from '@/lib';
import { useUserStore } from '@/stores';
import CopyToClipboard from 'react-copy-to-clipboard';
import useGetAssistantId from './hooks/useGetAssistantId';

export const msgSchema = yup.object().shape({
  message: yup.string().min(1),
});

interface IaAssistantChatProps {
  onMessageCallback?: (message: string) => void;
  assistenteId: string;
}

export function IaAssistantChat({
  onMessageCallback,
  assistenteId,
}: IaAssistantChatProps) {
  const focusRef = useRef(null);
  const { data: thread, isLoading, refetch } = useGetAssistantId(assistenteId);
  const [threadId, setThreadId] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleSendMessage = (message: string) => {
    if (onMessageCallback) {
      onMessageCallback(message);
    }
  };

  const [history, setHistory] = useState<
    { assistente: boolean; mensagem: string }[]
  >([]);

  useEffect(() => {
    if (thread) {
      setThreadId(thread.id);
      setHistory(thread.mensagens);
    }
  }, [thread]);

  const handleTextareaChange = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '52px'; // Redefine a altura
      textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta a altura com base no conteúdo
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<{ message: string }>({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(msgSchema),
  });

  const handleSubmitMessage = async ({ message }: { message: string }) => {
    setLoading(true);
    const { data } = await api.post('ai/assistant/thread/message', {
      threadId,
      mensagem: message,
    });

    setHistory(data.data.mensagens);
    // focusRef.current?.focus();

    reset();
    setLoading(false);
    textareaRef.current!.style.height = '52px';
    focusRef.current!.focus();
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      gap="2"
      bg="gray.100"
      px="4"
      py="4"
      maxH="60vh"
    >
      {isLoading ? (
        <Box
          w="100%"
          display="flex"
          background="yellow.300"
          borderRadius="md"
          justifyContent="center"
          alignItems="center"
          color="gray.700"
          fontWeight="extrabold"
        >
          conectando...
        </Box>
      ) : (
        <>
          <Box
            display="flex"
            flexDir="column-reverse"
            overflow="auto"
            gap="4"
            flex="1"
          >
            <Flex flexDir="column" key="pending" gap={2}>
              {/* {loading && (
                <Box
                  p="4"
                  rounded="xl"
                  alignSelf="flex-start"
                  bg="white"
                  fontWeight="medium"
                  maxW="90%"
                  position="relative"
                >
                  Digitando...
                </Box>
              )} */}
              <Box ref={focusRef} w="5" h="5" />
            </Flex>

            {history.map(item => (
              <Flex flexDir="column" key={item.mensagem} gap={2}>
                {item.assistente ? (
                  <Box
                    p="4"
                    rounded="xl"
                    alignSelf="flex-start"
                    bg="white"
                    fontWeight="medium"
                    maxW="90%"
                    position="relative"
                  >
                    {item.mensagem}
                  </Box>
                ) : (
                  <Box
                    p="4"
                    rounded="xl"
                    alignSelf="flex-end"
                    bg="green.200"
                    fontWeight="medium"
                    maxW="90%"
                    position="relative"
                  >
                    {item.mensagem}
                  </Box>
                )}
              </Flex>
            ))}
          </Box>
          <Flex>
            <Image src={iaSvg} mt="14px" pr={1} />

            <Flex flex={1} flexDir="row" mt="4" borderRadius="14px" bg="white">
              <Controller
                name="message"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <Textarea
                    ref={textareaRef}
                    disabled={isSubmitting}
                    size="lg"
                    padding="12px 12px 0"
                    placeholder="Escrever mensagem"
                    color="brand-dark.500"
                    bg="white"
                    border="0px"
                    minH="auto"
                    maxH="96"
                    _focus={{ boxShadow: 'none' }}
                    resize="none"
                    defaultValue={value}
                    value={value}
                    onChange={e => {
                      handleTextareaChange();
                      onChange(e.target.value);
                    }}
                  />
                )}
              />
              <Button
                borderRadius="14px"
                h="52px"
                marginTop="auto"
                bg="#fff"
                _hover={{ backgroundColor: 'green.300' }}
                onClick={handleSubmit(handleSubmitMessage)}
                isLoading={isSubmitting}
              >
                <Icon as={FiArrowUp} fontSize="27px" />
              </Button>
            </Flex>
          </Flex>
        </>
      )}
    </Box>
  );
}
