export function formatterApplicationType(type: number) {
  switch (type) {
    case 0:
      return 'Único';
    case 1:
      return 'Diário';
    case 2:
      return 'Semanal';
    case 3:
      return 'Mensal';
    default:
      return '';
  }
}
