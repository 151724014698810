import {
  Box,
  Button,
  Divider,
  Flex,
  Heading,
  Icon,
  Skeleton,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { CustomEventPatient } from '@/components/CalendarPatient/CustomEventPatient';
import { useMemo, useState, useEffect } from 'react';
import { formatDate } from '@/utils';
import { useScheduleDayPatientQuery } from '@/features/schedule/hooks/useSchedulePatientQuery';
import { IoAddOutline } from 'react-icons/io5';
import { RequestConsultationModal } from './RequestConsultationModal';

export function SchedulePatientSection() {
  const [min, setMin] = useState<number>(0);
  const [max, setMax] = useState<number>(23);
  const [times, setTimes] = useState<any>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    data: appointments,
    isLoading,
    refetch,
  } = useScheduleDayPatientQuery();

  useEffect(() => {
    setTimeout(() => {
      refetch();
    }, 2000);
  }, []);

  const today = new Date();
  const formattedToday = useMemo(
    () => formatDate(today, "dd 'de' MMMM 'de' yyyy"),
    [today],
  );

  useEffect(() => {
    if (appointments && appointments?.length > 0) {
      const orderTimes = appointments.sort(
        (a: any, b: any) => a.start - b.start,
      );
      const minTime = orderTimes[0].start;
      const maxTime = orderTimes[orderTimes.length - 1].end;

      setTimes(orderTimes);
      setMin(minTime.getHours());

      if (maxTime.toString() !== 'Invalid Date') {
        setMax(maxTime.getHours() + 2 > 13 ? 23 : maxTime.getHours() + 2);
      }
    } else {
      setTimes([]);
    }
  }, [appointments]);

  return isLoading ? (
    <Skeleton mt="10" w="100%" h="560px" borderRadius="20px" />
  ) : (
    <Box
      mt="10"
      p="10"
      w="100%"
      borderRadius="20px"
      justifyContent="center"
      border="1px solid rgba(0,0,0,0.1)"
    >
      <Flex w="100%" align="center" justify="space-between">
        <Heading color="brand-dark.300" size="md" fontWeight="semibold">
          Agenda do Dia - Paciente
        </Heading>
        <Text color="brand-dark.300" fontSize="xl" fontWeight="semibold">
          {formattedToday}
        </Text>
      </Flex>

      <Divider mt="4" mb="8" />

      <Box h="auto" maxW="100%" w="100%" overflowY="auto">
        <Flex w="100%" flexDirection="column" alignItems="center">
          {/* <CalendarPatient
          defaultView="day"
          events={appointments}
          toolbar={false}
          scrollToTime={
            min > 0
              ? new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDay(),
                  min,
                )
              : new Date(today.getFullYear(), today.getMonth(), today.getDay())
          }
          min={new Date(today.getFullYear(), today.getMonth(), today.getDay())}
          max={
            new Date(today.getFullYear(), today.getMonth(), today.getDay(), max)
          }
        /> 
        <CustomEventPatient /> */}

          <Flex w="100%" alignItems="center" justifyContent="space-between">
            <br />
            <Button
              fontSize="sm"
              borderRadius="30"
              bg="#60C2B0"
              color="white"
              onClick={onOpen}
            >
              <Icon
                as={IoAddOutline}
                fontSize="20"
                color="#fff"
                right="0"
                justifyContent="flex-end"
              />
              Agendar
            </Button>
          </Flex>
          {appointments && appointments.length > 0 ? (
            appointments.map((event: any) => (
              <CustomEventPatient event={event} title="" />
            ))
          ) : (
            <Text
              mt="5"
              mb="8"
              color="brand-dark.200"
              fontSize="2xl"
              fontWeight="medium"
            >
              Nenhuma consulta
            </Text>
          )}
        </Flex>
      </Box>

      <RequestConsultationModal
        isOpen={isOpen}
        onClose={onClose}
        refetch={refetch}
      />
    </Box>
  );
}
