import create from 'zustand';

type AddPrescriptionModal = {
  isOpen: boolean;
  content: string;
  changeContent: (value: string) => void;
  onOpen: () => void;
  onClose: () => void;
};

export const useAddPrescriptionModalStore = create<AddPrescriptionModal>(
  set => ({
    isOpen: false,
    content: 'buttons',
    changeContent: (value: string) => set(state => ({ content: value })),
    onOpen: () => set(state => ({ isOpen: true })),
    onClose: () => set(state => ({ isOpen: false })),
  }),
);
