import { formatDate } from '@/utils';
import {
  Box,
  Text,
  GridItem,
  Grid,
  Flex,
  Textarea,
  SimpleGrid,
} from '@chakra-ui/react';

import { Card } from 'antd';
import { addDays } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CardItem } from './components/CardItem';

const data = [
  {
    question: 'Não ser capaz de impedir ou de controlar as preocupações',
    score: 2,
  },
  {
    question: 'Sentir-se nervoso, ansioso ou muito tenso',
    score: 1,
  },
  {
    question: 'Se sentiu cansado(a) ou com pouca energia',
    score: 3,
  },
  {
    question:
      'Teve dificuldade para pegar no sono ou permanecer dormindo ou dormiu mais do que de costume',
    score: 2,
  },
  {
    question:
      'Teve dificuldade para se concentrar nas coisas (como ler o jornal ou ver televisão)',
    response: 'Vários dias',
    score: 1,
  },
  {
    question:
      'Pensar em se ferir de alguma maneira ou que seria melhor estar morto/a?',
    score: 0,
  },
];

interface disparos {
  questionarioId: number;
  horario: string;
  lastModificationTime: string | null;
  lastModifierId: string | null;
  creationTime: string;
  creatorId: string;
  id: number;
}

export interface dataProps {
  titulo: string;
  ativo: boolean;
  creationTime: string;
  taxonomiaId: number;
  periodicidade: number;
  protocoloId: number;
  taxonomia: string;
  protocolo: string;
  questionarioDisparo: disparos[];
  enviado: boolean;
  respondido: boolean;
}

interface ModalResultsProps {
  data: dataProps;
  patientId: string;
}

export default function ModalResults({
  data: values,
  patientId,
}: ModalResultsProps) {
  const [callsGrouped, setCallsGrouped] = useState<{
    [key: string]: disparos[];
  }>({});
  const [option, setOption] = useState(values.questionarioDisparo[0].id);
  const qaList = [];

  const groupByDate = (objetos: disparos[]) => {
    const grupos = objetos.reduce(
      (acc: { [key: string]: disparos[] }, objeto) => {
        const newData = moment(objeto.creationTime).format('YYYY-MM-DD');
        if (!acc[newData]) {
          acc[newData] = [];
        }
        acc[newData].push(objeto);
        return acc;
      },
      {},
    );
    return grupos;
  };

  useEffect(() => {
    if (values.questionarioDisparo.length > 0) {
      const groups = groupByDate(values.questionarioDisparo);
      setCallsGrouped(groups);
    }
  }, [values]);

  const DadosConsulta = () => {
    return (
      <Grid
        width="100%"
        templateColumns="repeat(12, 1fr)"
        paddingBottom="10"
        borderBottom="1px solid #0005"
      >
        <GridItem display="flex" alignItems="center" colSpan={12}>
          <Text
            color="brand-dark.200"
            fontSize="lg"
            fontWeight="normal"
            fontFamily="Rubik"
            ml="5"
          >
            Questionário
          </Text>
          <Box
            h="30px"
            borderRadius="10px"
            color="white"
            fontSize="lg"
            fontWeight="medium"
            textTransform="uppercase"
            marginX="5"
            bg="#d4d4d4"
            display="flex"
            alignItems="center"
            border="1px solid #0005"
            px="5"
          >
            {values.titulo}
          </Box>

          <Text
            color="brand-dark.200"
            fontSize="lg"
            fontWeight="normal"
            fontFamily="Rubik"
            ml="10"
          >
            Data de início:
          </Text>
          <Text
            color="brand-dark.500"
            fontSize="lg"
            fontWeight="large"
            fontFamily="Rubik"
            ml="5"
          >
            todo: api
          </Text>

          <Text
            color="brand-dark.200"
            fontSize="lg"
            fontWeight="normal"
            fontFamily="Rubik"
            ml="10"
          >
            Data de Fim:
          </Text>
          <Text
            color="brand-dark.500"
            fontSize="lg"
            fontWeight="large"
            fontFamily="Rubik"
            ml="5"
          >
            todo: api
          </Text>
        </GridItem>
      </Grid>
    );
  };

  const QuestionsDetails = () => {
    return (
      <>
        <Grid width="100%" templateColumns="repeat(16, 1fr)" paddingBottom="10">
          <GridItem display="flex" alignItems="flex-start" colSpan={8}>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="normal"
              fontFamily="Rubik"
              ml="5"
            >
              Perguntas
            </Text>
          </GridItem>

          {/* <GridItem display="flex" alignItems="flex-start" colSpan={2}>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="normal"
              fontFamily="Rubik"
              ml="10"
            >
              Nenhuma vez
            </Text>
          </GridItem>
          <GridItem display="flex" alignItems="flex-start" colSpan={2}>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="normal"
              fontFamily="Rubik"
              ml="10"
            >
              Vários dias
            </Text>
          </GridItem>
          <GridItem display="flex" alignItems="flex-start" colSpan={2}>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="normal"
              fontFamily="Rubik"
              ml="10"
            >
              Mais da metade dos dias
            </Text>
          </GridItem>
          <GridItem display="flex" alignItems="flex-start" colSpan={2}>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="normal"
              fontFamily="Rubik"
              ml="10"
            >
              Quase todos os dias
            </Text>
          </GridItem> */}
        </Grid>

        {data.map(d => (
          <Grid
            width="100%"
            templateColumns="repeat(16, 1fr)"
            paddingBottom="10"
          >
            <GridItem display="flex" alignItems="center" colSpan={8}>
              <Text
                color="brand-dark.200"
                fontSize="lg"
                fontWeight="normal"
                fontFamily="Rubik"
                ml="5"
              >
                {d.question}
              </Text>
            </GridItem>

            <GridItem display="flex" alignItems="center" colSpan={2}>
              <Text
                color="#000"
                fontSize="lg"
                fontWeight="normal"
                fontFamily="Rubik"
                ml="10"
                border={d.score === 0 ? '2px solid #000' : 'none'}
                paddingX="15"
                bgColor={d.score === 0 ? 'yellow' : 'transparent'}
              >
                0
              </Text>
            </GridItem>

            <GridItem display="flex" alignItems="center" colSpan={2}>
              <Text
                color="#000"
                fontSize="lg"
                fontWeight="normal"
                fontFamily="Rubik"
                ml="10"
                border={d.score === 1 ? '2px solid #000' : 'none'}
                paddingX="15"
                bgColor={d.score === 1 ? 'yellow' : 'transparent'}
              >
                1
              </Text>
            </GridItem>

            <GridItem display="flex" alignItems="center" colSpan={2}>
              <Text
                color="#000"
                fontSize="lg"
                fontWeight="normal"
                fontFamily="Rubik"
                ml="10"
                border={d.score === 2 ? '2px solid #000' : 'none'}
                paddingX="15"
                bgColor={d.score === 2 ? 'yellow' : 'transparent'}
              >
                2
              </Text>
            </GridItem>

            <GridItem display="flex" alignItems="center" colSpan={2}>
              <Text
                color="#000"
                fontSize="lg"
                fontWeight="normal"
                fontFamily="Rubik"
                ml="10"
                border={d.score === 3 ? '2px solid #000' : 'none'}
                paddingX="15"
                bgColor={d.score === 3 ? 'yellow' : 'transparent'}
              >
                3
              </Text>
            </GridItem>
          </Grid>
        ))}

        <Grid width="100%" templateColumns="repeat(12, 1fr)" paddingBottom="10">
          <GridItem display="flex" alignItems="center" colSpan={9}>
            <Text
              color="brand-dark.500"
              fontSize="lg"
              fontWeight="normal"
              fontFamily="Rubik"
              ml="5"
            >
              Perguntas
            </Text>
          </GridItem>

          <GridItem
            display="flex"
            alignItems="center"
            justifyContent="flex-end"
            colSpan={3}
          >
            <Box
              h="45px"
              borderRadius="10px"
              color="#000"
              fontSize="lg"
              fontWeight="medium"
              textTransform="uppercase"
              w="auto"
              marginY="5"
              paddingX="5"
              bg="yellow"
              display="flex"
              alignItems="center"
              justifyContent="center"
              border="2px solid #000"
            >
              9 DEPRESSÃO
            </Box>
          </GridItem>
        </Grid>

        <SimpleGrid columns={2} spacingX={10}>
          <Text
            color="brand-dark.500"
            fontSize="lg"
            fontWeight="normal"
            fontFamily="Rubik"
            ml="5"
            mb="5"
          >
            Indicador (Profissional de Saúde)
          </Text>
          <Text
            color="brand-dark.500"
            fontSize="lg"
            fontWeight="normal"
            fontFamily="Rubik"
            ml="5"
            mb="5"
          >
            Orientação (Paciente)
          </Text>
          <Textarea
            ml="5"
            placeholder="Here is a sample placeholder"
            resize="none"
            value="Sintomas depressivos/leve"
          />

          <Textarea
            ml="5"
            placeholder="Here is a sample placeholder"
            resize="none"
            size="lg"
            value="Você falou de estar com alguns sintomas que podem corresponder à depressão, achamos que vale a pena procurar um profissional para buscar uma avaliação"
          />
        </SimpleGrid>
      </>
    );
  };

  return (
    <Box
      height="50%"
      maxHeight="50%"
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
    >
      <DadosConsulta />
      <Grid width="100%" templateColumns="repeat(12, 1fr)">
        <GridItem colSpan={3}>
          {Object.keys(callsGrouped).map(groupValue => (
            <div>
              <Box
                cursor="pointer"
                fontSize="lg"
                fontWeight="500"
                bg=""
                p="2"
                fontFamily="Rubik"
                mt={10}
              >
                {formatDate(addDays(new Date(groupValue), 1), 'dd/MM/yyyy')}
              </Box>
              <ul>
                {callsGrouped[groupValue].map(obj => (
                  <CardItem
                    key={obj.id}
                    defaultValue="PRESCRIÇÃO"
                    active={obj.id === option}
                    onClick={() => setOption(obj.id)}
                  >
                    {formatDate(new Date(obj.creationTime), 'HH:mm')}
                  </CardItem>
                ))}
              </ul>
            </div>
          ))}
        </GridItem>
        <GridItem
          display="flex"
          colSpan={9}
          backgroundColor="red"
          marginTop="5"
        >
          <Box backgroundColor="#fff" width="100vw" height="100%">
            {false ? (
              <Card
                style={{
                  maxHeight: '60rem',
                  height: '100%',
                  borderRadius: '10px',
                  backgroundColor: '#fff',
                  overflowY: 'auto',
                  paddingRight: '20px',
                }}
              >
                <Flex justifyContent="center">
                  <Text
                    color="brand-dark.200"
                    fontSize="3xl"
                    fontWeight="normal"
                    fontFamily="Rubik"
                    paddingLeft="2"
                  >
                    Resultados
                  </Text>
                </Flex>
              </Card>
            ) : (
              <Card
                style={{
                  maxHeight: '60rem',
                  height: '100%',
                  borderRadius: '10px',
                  backgroundColor: '#fff',
                  overflowY: 'auto',
                  paddingRight: '20px',
                }}
              >
                <QuestionsDetails />
              </Card>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
