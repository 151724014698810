import {
  Box,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  InputProps,
  Tooltip,
} from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { FieldError } from 'react-hook-form';
import { IconType } from 'react-icons';
import { RiErrorWarningFill } from 'react-icons/ri';

interface TextInputProps extends InputProps {
  label: string;
  error?: any;
  iconView?: IconType;
  setView?: () => void;
}

const TextInputBase: ForwardRefRenderFunction<
  HTMLInputElement,
  TextInputProps
> = ({ name, iconView, setView, label, error, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <InputGroup>
        <Input
          {...rest}
          id={name}
          name={name}
          ref={ref}
          pl="4"
          pr="8"
          h="50px"
          borderColor="rgba(0, 0, 0, 0.12)"
          background="white"
          color="brand-dark.500"
          _placeholder={{ color: '#bfbfbf' }}
          _hover={{
            borderColor: 'brand-green.400',
          }}
        />

        <InputRightElement pr="2">
          {error ? (
            <Tooltip
              label={error.message}
              aria-label="Warning tooltip"
              bg="gray.800"
              color="white"
            >
              <Box as="span" pt="4">
                <Icon as={RiErrorWarningFill} fontSize="20px" color="red" />
              </Box>
            </Tooltip>
          ) : (
            iconView && (
              <Box as="span" pt="4">
                <Icon
                  as={iconView}
                  onClick={setView}
                  cursor="pointer"
                  fontSize="20px"
                  color="gray.400"
                />
              </Box>
            )
          )}
        </InputRightElement>
      </InputGroup>
    </FormControl>
  );
};

export const TextInput = forwardRef(TextInputBase);
