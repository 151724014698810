import axios from 'axios';

export const api = axios.create({
  baseURL: 'https://api-hlg.dmhealth.com.br/api',
});

export const apiQuestions = axios.create({
  baseURL: 'https://dm-quizzes.azurewebsites.net/api/app',
});

export async function getCep(cep: string) {
  return axios.get(`https://viacep.com.br/ws/${cep}/json/`);
}
