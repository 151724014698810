import { api } from '@/lib';

export const useFetchAssociatedProfessionalsByUnit = async (
  patientId: string,
  unitId: String,
) => {
  try {
    const { data } = await api.get(
      `/usuario/todos-profissionalsaude-paciente?pacienteId=${patientId}&idUnidade=${unitId}`,
    );

    return data.data;
  } catch (err) {
    return [];
  }
};
