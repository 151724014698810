import { useEffect, useMemo, useState } from 'react';
import { Tr, Td, Icon, useToast, Flex, Heading, Stack } from '@chakra-ui/react';
import { FiEye, FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import { PatientsProps } from '@/features/registers/types';
import {
  Pagination,
  TopSection,
  Tables,
  GenericModal,
  EditPatientForm,
  ViewPatientInfo,
  usePatientListQuery,
  usePatientMutation,
  usePatientEdit,
} from '@/features/registers';
import { api } from '@/lib';
import { useUserStore, useProfessionalStore, useUnidadeStore } from '@/stores';
import { useUserTermQuery } from '@/hooks';
import { EditUserPatientForm } from '../components/EditUserPatientForm';
import { PaginationButton } from '../components/Pagination/PaginationButton';
import { NumberSelectPagination } from '../components/Pagination/NumberSelectPagination';
import { NumberPagination } from '../components/Pagination/NumberPagination';
import { PatientSearchInput } from '../components/TopSection/PatientSearchInput';
import { DeleteUserModal } from '../components/DeleteModals/DeleteUserModal';

export function PatientsListPage() {
  const [patient, setPatient] = useState<any>();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  // const [pageNumber, setPageNumber] = useState(0);
  const [dataPatients, setDataPatients] = useState<any>();
  const [text, setText] = useState('');
  const [search, setSearch] = useState<any>([]);
  const [head, setHead] = useState<string[]>([]);
  const user = useUserStore(state => state.user);
  const { data: userTerm } = useUserTermQuery();
  // const { data } = usePatientListQuery(pageNumber);
  const { mutate: patientMutate, isLoading: isPatientMutationLoading } =
    usePatientMutation();
  const [selectPage, setSelectPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const { mutate: patientEdit } = usePatientEdit();

  const { professional } = useProfessionalStore();
  const unidadeId = useUnidadeStore(state => state.unidade?.id);

  const onCloseModal = () => setIsEditOpen(false);
  const onCloseDeleteModal = () => setIsDeleteOpen(false);
  const onCloseViewModal = () => setIsViewOpen(false);

  const onOpenEditModal = (p: any) => {
    setPatient(p);
    setIsEditOpen(true);
  };

  const onDeleteEditModal = (p: any) => {
    setPatient(p);
    setIsDeleteOpen(true);
  };

  const onOpenViewModal = (p: any) => {
    setPatient(p);
    setIsViewOpen(true);
  };

  const setDataContent = async (back = false, next = false) => {
    const params = {
      idUsuarioProfissional:
        user?.type === 'medico' ? user?.id : professional?.idMedico,
      idUnidade: unidadeId,
      pageSize: 5,
      pageNumber:
        !back && !next
          ? 1
          : back && selectPage > 1
          ? selectPage - 1
          : next
          ? selectPage + 1
          : selectPage,
    };

    const { data: total } = await api.get(
      `/paciente/obter-paciente-profissional-paginado`,
      {
        params: {
          idUsuarioProfissional: params.idUsuarioProfissional,
          idUnidade: params.idUnidade,
        },
      },
    );
    setTotalPage(Math.ceil(total.data.length / 5)); // mock solicitar total page no endpoint

    // const response = await api.get(
    //   '/paciente/obter-paciente-gestormaster-paginado',
    //   { params },
    // );

    await api
      .get('/paciente/obter-paciente-profissional-paginado', { params })
      .then(response => {
        if (response.data.data.length > 0) {
          setDataPatients(response.data.data);
          setSearch(response.data.data);
          if (back) {
            setSelectPage(selectPage === 1 ? 1 : selectPage - 1);
          }
          if (next) {
            setSelectPage(selectPage + 1);
          }
        } else if (!back && !next) {
          setDataPatients([]);
          setSearch([]);
        }
      })
      .catch(e => {
        setDataPatients([]);
        setSearch([]);
      });
  };

  useEffect(() => {
    setSelectPage(1);
    setDataContent(false, false);
  }, [professional, unidadeId]);

  useEffect(() => {
    setDataContent();
  }, [isEditOpen, isDeleteOpen]);

  useEffect(() => {
    if (dataPatients) {
      const lowerSearch = text.toLowerCase();
      const result = dataPatients.filter((d: any) => {
        return d.nome.toLowerCase().includes(lowerSearch);
      });

      setSearch(result);
    }
  }, [text]);

  useEffect(() => {
    if (
      user?.type === 'secretario' ||
      user?.type === 'assistente' ||
      user?.type === 'medico' ||
      user?.type !== 'paciente'
    ) {
      head.push('EDITAR');
    }
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'paciente' &&
      user?.type !== 'medico' &&
      user?.type !== 'assistente'
    ) {
      head.push('DELETAR');
    }
  }, []);

  const showEditBtn = (patientData: PatientsProps) => {
    if (
      user?.type === 'secretario' ||
      user?.type === 'assistente' ||
      user?.type === 'medico' ||
      user?.type !== 'paciente'
    ) {
      return (
        <Icon
          as={FiEdit}
          onClick={() => {
            if (patientData.primeiroAcesso) return;
            onOpenEditModal(patientData);
          }}
          cursor="pointer"
          color={
            patientData.primeiroAcesso ? 'brand-dark.100' : 'brand-dark.200'
          }
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  const showDeleteBtn = (patientData: PatientsProps) => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'paciente' &&
      user?.type !== 'medico' &&
      user?.type !== 'assistente'
    ) {
      return (
        <Icon
          as={MdDeleteOutline}
          onClick={() => {
            if (patientData.primeiroAcesso && user?.type !== 'gestor') return;
            onDeleteEditModal(patientData);
          }}
          cursor="pointer"
          color={
            patientData.primeiroAcesso && user?.type !== 'gestor'
              ? 'brand-dark.100'
              : 'brand-dark.200'
          }
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const editPatient = (values: any) => {
    const date = `${values.dataNascimento.slice(
      2,
      4,
    )}/${values.dataNascimento.slice(0, 2)}/${values.dataNascimento.slice(
      4,
      8,
    )}`;
    const idMedico = user?.type === 'gestor' ? '' : user?.gestorMasterId;

    const newPatient = {
      nome: values.nome,
      cpf: values.cpf,
      rg: values.rg,
      celular: values.celular,
      telefone: values.celular,
      dataNascimento: new Date(date),
      enderecoFoto: '',
      telefoneResidencial: '',
      tokenDeviceId: '',
      sexoId: values.genero,
      endereco: {
        cep: values.cep,
        numero: +values.numero,
        logradouro: values.endereco,
        complemento: values.complemento,
        bairro: values.bairro,
        cidade: values.cidade,
        estado: values.estado,
        latitude: 0,
        longitude: 0,
        regiao: '',
        codIBGE: '',
      },
    };

    return patientEdit(newPatient, {
      onSuccess: () => {
        alert('Paciente cadastrado');
      },
      onError(error, variables, context) {
        toast({
          title: 'Erro ao cadastrar, tente novamente!',
          status: 'error',
        });
      },
    });
  };

  // interface NumbersPageProps {
  //   page: number;
  //   totalPage: number;
  //   onClick: (page: number) => void;
  // }

  // const NumbersPage = ({ page, totalPage, onClick }: NumbersPageProps) => {
  //   const pages = useMemo(() => {
  //     const elements: any = [];

  //     if (totalPage === 0 || totalPage === 1) {
  //       return <NumberSelectPagination page={1} />;
  //     }
  //     for (let i = 1; i <= totalPage; i += 1) {
  //       if (i === page) {
  //         elements.push(<NumberSelectPagination key={i} page={i} />);
  //       } else {
  //         elements.push(
  //           <NumberPagination key={i} onClick={() => onClick(i)} page={i} />,
  //         );
  //       }
  //     }
  //     return elements;
  //   }, [page, totalPage, onClick]);
  //   return pages;
  // };

  return (
    <>
      <TopSection
        title="Pacientes Cadastrados"
        search={text}
        onChange={(str: any) => setText(str)}
      />

      <Tables
        head={[
          'Nome do Paciente',
          'Celular',
          'Email',
          'Status',
          'Visualizar',
          ...head,
        ]}
      >
        {search?.map((patientData: PatientsProps) => {
          const birthDate = patientData.dataNascimento.split('-');
          return (
            <Tr key={patientData.id}>
              <Td>{patientData.nome}</Td>
              <Td>{patientData.celular}</Td>
              <Td>{patientData.email}</Td>
              <Td style={{ color: patientData.primeiroAcesso && 'red' }}>
                {patientData.primeiroAcesso ? 'PENDENTE' : 'VALIDADO'}
              </Td>
              <Td>
                <Icon
                  as={FiEye}
                  onClick={() => onOpenViewModal(patientData)}
                  cursor="pointer"
                  color="brand-dark.200"
                  fontSize="20px"
                  fontWeight="400"
                  marginRight="15"
                />
              </Td>
              <Td>{showEditBtn(patientData)}</Td>
              <Td>{showDeleteBtn(patientData)}</Td>
            </Tr>
          );
        })}
      </Tables>
      {/* <Pagination
        page={pageNumber}
        totalPage={dataPatients?.totalPages || 0}
        totalRegisters={dataPatients?.data.length}
      /> */}
      <Flex
        direction={['column', 'column', 'row']}
        w="100%"
        align="center"
        justify="space-between"
      >
        <Heading
          size="xs"
          w="100%"
          mb={['4', '4', '0']}
          color="#707070"
          fontWeight="medium"
        >
          {/* Página {selectPage} de {dataPatients?.data.length} registros */}
          Página {selectPage}/{totalPage}
        </Heading>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          w="100%"
          justify="flex-end"
        >
          <PaginationButton
            text="Voltar"
            onClick={() => setDataContent(true, false)}
          />
          {/* <PaginationButton text="Ultimo" onClick={() => setSelectPage(1)} /> */}
          {/* <NumbersPage
            onClick={value => setSelectPage(value)}
            page={selectPage}
            totalPage={dataPatients?.totalPages || 0}
          /> */}
          <PaginationButton
            text="Proximo"
            onClick={() => setDataContent(false, true)}
          />
          {/* <PaginationButton
            text="Ultimo"
            onClick={() => setSelectPage(dataPatients?.totalPages || 0)}
          /> */}
        </Stack>
      </Flex>
      <GenericModal
        open={isEditOpen}
        textAlign="center"
        title="Editar Paciente"
        onClose={onCloseModal}
        closeOnOverlayClick={false}
      >
        <EditPatientForm patient={patient} onCancel={onCloseModal} />
        {/* <EditUserPatientForm patient={patient} onCancel={onCloseViewModal} /> */}
      </GenericModal>
      <GenericModal
        open={isViewOpen}
        title={`Paciente: ${patient && patient.nome}`}
        onClose={onCloseViewModal}
      >
        <ViewPatientInfo patient={patient} />
      </GenericModal>
      <GenericModal
        open={isDeleteOpen}
        textAlign="center"
        title={`Deletar Paciente: ${patient && patient.nome}`}
        onClose={onCloseDeleteModal}
      >
        <DeleteUserModal
          user={patient}
          type="patient"
          onCancel={onCloseDeleteModal}
        />
      </GenericModal>
    </>
  );
}
