import { api } from '@/lib';
import { formatCPF, formatDate } from '@/utils';
import {
  VStack,
  SimpleGrid,
  Text,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';

interface Props {
  type: any;
  onCancel: () => void;
  item: any;
}

export function DeleteModal({ item, onCancel, type }: Props) {
  const [isLoading, setIsLoading] = useState(false);

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  async function onSubmit() {
    if (type === 'instância') {
      try {
        setIsLoading(true);
        const result: any = await api.delete(
          `/instancia/Deletar-Instancia?id=${item.value}`,
        );
        if (result?.statusText === 'OK') {
          toast({
            title: 'Instância deletado(a) com sucesso!',
            status: 'success',
          });
        }
      } catch (error) {
        toast({
          title: 'Ops algo deu errado!',
          status: 'error',
        });
      } finally {
        onCancel();
        setIsLoading(false);
      }
    }
    if (type === 'unidade') {
      try {
        setIsLoading(true);
        const result: any = await api.delete(
          `/instancia/Deletar-Unidade?id=${item.value}`,
        );
        if (result?.statusText === 'OK') {
          toast({
            title: 'Instância deletado(a) com sucesso!',
            status: 'success',
          });
        }
      } catch (error) {
        toast({
          title: 'Ops algo deu errado!',
          status: 'error',
        });
      } finally {
        onCancel();
        setIsLoading(false);
      }
    }
  }

  return (
    <VStack spacing="4">
      <Box>
        <Text color="brand-dark.300" fontSize="xl" align="center">
          Deseja realmente excluir esta {type}?
        </Text>
      </Box>

      <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
        <Button
          disabled={isLoading}
          type="button"
          onClick={onCancel}
          variant="outline"
          colorScheme="brand-orange"
          h="45px"
          borderRadius="28px"
          color="brand-orange.300"
          fontSize="xs"
          fontWeight="medium"
          textTransform="uppercase"
        >
          Voltar
        </Button>

        <Button
          isLoading={isLoading}
          type="submit"
          colorScheme="red"
          h="45px"
          borderRadius="28px"
          color="white"
          fontSize="xs"
          fontWeight="medium"
          textTransform="uppercase"
          onClick={onSubmit}
        >
          Deletar
        </Button>
      </SimpleGrid>
    </VStack>
  );
}
