import { api } from '@/lib';
import { useUserStore } from '@/stores';
import { useQuery } from 'react-query';

const tableHead = [
  'Profissional',
  'Pacientes Associados',
  'Pacientes Desassociados',
  'Consulta Agendada',
  'Consulta Atendida',
  'Consulta Remarcada',
];

async function getHealthProfessionals(gestorMasterId: string | undefined) {
  const params = {
    idGestor: gestorMasterId,
  };

  const response = await api.get('/relatorio/profissionalsaude', { params });
  const oldTableBody: any[] = response.data.data;

  const tableBody = oldTableBody
    .sort((a, b) => {
      if (a.nomeProfissional > b.nomeProfissional) {
        return 1;
      }
      if (a.nomeProfissional < b.nomeProfissional) {
        return -1;
      }
      return 0;
    })
    .map(row => ({
      nomeProfissional: row.nomeProfissional ?? '-',
      pacienteAssociado: row.pacienteAssociado ?? '-',
      pacienteDesassociado: row.pacienteDesassociado ?? '-',
      consultaAgendada: row.consultaAgendada ?? '-',
      consultaAtendida: row.consultaAtendida ?? '-',
      consultaRemarcada: row.consultaRemarcada ?? '-',
      consultaCancelada: row.consultaCancelada ?? '-',
      consultaNaoRealizada: row.consultaNaoRealizada ?? '-',
    }));

  return { tableHead, tableBody };
}

export function useHealthProfessionalsTableQuery() {
  const user = useUserStore(state => state.user);

  const query = useQuery(
    ['healthProfessionalsList'],
    () => getHealthProfessionals(user?.gestorMasterId),
    {
      refetchOnWindowFocus: false,
    },
  );

  return query;
}
