/* eslint-disable import/no-duplicates */
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

export function formatDate(date: Date, formatAs?: string) {
  if (typeof formatAs !== 'undefined') {
    return format(date, formatAs, { locale: ptBR });
  }

  return format(date, 'P', { locale: ptBR });
}

export const formatCnpj = (valor: string) => {
  if (valor)
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');

  return '';
};

export const formatCpf = (valor: string) => {
  if (valor)
    return valor.replace(
      /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
      '$1.$2.$3/$4-$5',
    );

  return '';
};
