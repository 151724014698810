import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  Image,
  Modal,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useScheduleDateStore } from '@/features/schedule';
import confirmationImg from '@/assets/register_confirmed.svg';
import { api } from '@/lib';
import { format, parseISO } from 'date-fns';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';
import { useProfessionalStore } from '@/stores';

export function AssociarPaciente({ onCancel, event, refetch }: any) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const [wasFormSubmittedWithSuccess, setWasFormSubmittedWithSuccess] =
    useState(false);
  const { handleSubmit } = useForm();
  const pro = useProfessionalStore(state => state.professional);

  async function onSubmit() {
    api
      .put(
        '/paciente/vincular-profissional',
        {},
        {
          params: {
            idPaciente: event.id,
            idProfissional: pro?.idMedico,
            // idUnidade=20506a0f-b63b-466b-5128-08db57049b26 mock
            status: 0,
          },
        },
      )
      .then(res => {
        toast({ title: 'Paciente associado', status: 'success' });
        refetch();
      })
      .catch(err => {
        toast({ title: err.response.data.errors[0], status: 'error' });
      })
      .finally(() => {
        onCancel();
      });
  }

  return wasFormSubmittedWithSuccess ? (
    <Flex w="100%" direction="column" align="center">
      <Heading
        mt="6"
        color="brand-dark.300"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
      >
        O Paciente foi associado ao Profissional de saúde!
      </Heading>

      <Image
        mt="8"
        mb="4"
        src={confirmationImg}
        alt="Mulher confirmando o sucesso de uma operação em um celular grande"
      />

      <Text
        color="brand-dark.200"
        fontSize="sm"
        fontWeight="normal"
        textAlign="center"
      >
        Você pode editar as configurações na aba de escalas.
      </Text>

      <Button
        type="button"
        onClick={onCancel}
        colorScheme="brand-green"
        mt="10"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Ir para escalas
      </Button>
    </Flex>
  ) : (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      textAlign="center"
    >
      <Heading mt="6" mb="4" color="]#039c00" fontSize="xl" fontWeight="medium">
        O paciente {event.nome} não está associado ao Profissional de saúde{' '}
        {pro?.name}
      </Heading>
      <SimpleGrid minChildWidth="150px" spacing="4" w="100%" padding=" 20px 0">
        <Text color="brand-dark.300" fontSize="xl" fontWeight="medium">
          Deseja associar esse paciente?
        </Text>
      </SimpleGrid>

      <VStack spacing="4" w="100%">
        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          <Button
            disabled={isLoading}
            type="button"
            onClick={onCancel}
            variant="outline"
            colorScheme="red"
            h="45px"
            borderRadius="28px"
            color="red"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Cancelar
          </Button>

          <Button
            isLoading={isLoading}
            type="submit"
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Associar
          </Button>
        </SimpleGrid>
      </VStack>
    </Box>
  );
}
