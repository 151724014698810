/* eslint-disable react/no-array-index-key */
import { useState, useRef, useEffect } from 'react';
import { Button, InputRef } from 'antd';
import { CodeInputBox, CodeInputContainer } from './styles';

interface CodeInputProps {
  onChange: (value: string) => void;
  amount?: number;
  number?: boolean;
  // eslint-disable-next-line react/no-unused-prop-types
  clear?: boolean;
  status?: string;
}

export function CodeInput({
  onChange,
  amount = 5,
  number,
  status,
}: CodeInputProps) {
  const [code, setCode] = useState<string[]>(
    Array.from({ length: amount }, () => ''),
  );
  const [error, setError] = useState(false);
  const inputRefs = useRef<Array<InputRef | any>>([]);

  const handleChange = (index: number, value: any) => {
    const newCode = [...code];
    newCode[index] = value.toUpperCase();
    setCode(newCode);
    onChange(newCode.join(''));

    // Move focus to the next input
    if (value && index < amount - 1) {
      inputRefs.current[index + 1]?.focus();
    }

    if (!value) {
      inputRefs.current[index - 1]?.focus();
    }
    if (index === amount - 1) {
      inputRefs.current[index]?.blur();
    }
  };

  const clearCode = () => {
    onChange('');
    setCode([]);
  };

  useEffect(() => {
    if (status === 'error') {
      setError(true);
    }
  }, [status]);

  return (
    <>
      <CodeInputContainer>
        {Array.from({ length: amount }).map((_, index) => (
          <CodeInputBox
            id="input"
            key={index}
            // type={number && isMobile ? "number" : "text"}
            inputMode={number ? 'numeric' : 'text'}
            // eslint-disable-next-line no-return-assign
            ref={el => (inputRefs.current[index] = el)}
            value={code[index] || ''}
            maxLength={1}
            max={1}
            onChange={e => handleChange(index, e.target.value)}
            $borderColor={error ? '1px solid red' : '1px solid #515151'}
          />
        ))}
      </CodeInputContainer>
      {code.length > 0 && (
        <Button
          type="link"
          style={{ float: 'right', margin: '.1rem' }}
          onClick={clearCode}
        >
          Limpar código
        </Button>
      )}
    </>
  );
}

export default CodeInput;
