import { Tabs, TabList, TabPanels, TabPanel } from '@chakra-ui/react';

import {
  TopSection,
  AttendanceTable,
  CidTable,
  HealthProfessionalsTable,
  ScalesTable,
  NavButton,
} from '@/features/reports';

export function ReportsPage() {
  return (
    <>
      <TopSection />
      <Tabs variant="unstyled" align="start">
        <TabList marginBottom="10" marginTop="10">
          <NavButton text="Atendimentos" />
          <NavButton text="Escalas" />
          <NavButton text="CID-10" />
          <NavButton text="Profissionais da Saúde" />
        </TabList>
        <TabPanels>
          <TabPanel>
            <AttendanceTable />
          </TabPanel>
          <TabPanel>
            <ScalesTable />
          </TabPanel>
          <TabPanel>
            <CidTable />
          </TabPanel>
          <TabPanel>
            <HealthProfessionalsTable />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
}
