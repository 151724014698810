import { useQuery } from 'react-query';

import { useUserStore } from '@/stores';
import { api } from '@/lib';

type Secretary = {
  data: {
    nome: string;
    email: string;
    cpf: string;
    telefone: string;
    generoId: string;
    dataNascimento: string;
    cep: string;
    numero: number;
    endereco: string;
    estado: string;
    cidade: string;
    bairro: string;
    complemento: string;
    senha: string;
  };
};

async function getSecretaryByIdRequest(id?: string) {
  const response = await api.get<Secretary>(
    `secretaria/obterbyid?secretariaId=${id}`,
  );

  const originalBirthDate = response.data.data.dataNascimento;
  const data = {
    ...response.data.data,
    dataNascimento: `${originalBirthDate.slice(
      8,
      10,
    )}/${originalBirthDate.slice(5, 7)}/${originalBirthDate.slice(0, 4)}`,
  };

  return data;
}

export function useGetSecretaryByIdQuery() {
  const user = useUserStore(state => state.user);

  const query = useQuery(
    [user?.id, user?.type],
    () => getSecretaryByIdRequest(user?.id),
    {
      refetchInterval: false,
    },
  );

  return query;
}
