import { Button, Text } from '@chakra-ui/react';

interface Props {
  text: string;
  onClick: () => void;
}

export function PaginationButton({ text, onClick }: Props) {
  return (
    <Button
      maxW={['100%', '120px']}
      w="100%"
      h="40px"
      borderRadius="14px"
      bg="white"
      boxShadow="0.870327px 0.870327px 7.83294px -2.61098px rgba(5, 6, 27, 0.25)"
      onClick={onClick}
      _hover={{ bg: 'brand-dark.100' }}
    >
      <Text color="#707070" fontSize="14px" fontWeight="400">
        {text}
      </Text>
    </Button>
  );
}
