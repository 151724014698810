import { apiQuestions } from '@/lib';
import { useQuery } from 'react-query';
import { format, parseISO } from 'date-fns';

interface IQuiz {
  id: number;
  titulo: string;
  ativo: boolean;
  taxonomiaId: number;
  periodicidade: number;
  protocoloId: number;
  taxonomia: string;
  protocolo: string;
  isDeleted: boolean;
  deleterId?: string;
  questionarioDisparo: any[];
  deletionTime?: string;
  lastModificationTime?: string;
  lastModifierId?: string;
  creationTime: string;
  creatorId: string;
}

interface dataResponseProps {
  totalCount: number;
  items: IQuiz[];
}

interface useGetQuestionsProps {
  filter?: string;
}

const useGetQuestions = ({ filter }: useGetQuestionsProps) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['questions', filter],
    queryFn: async () => {
      const res = await apiQuestions.get(`questionario`, {
        params: {
          MaxResultCount: 999,
        },
      });
      const dataRes = res.data as dataResponseProps;
      const formattedData = dataRes?.items?.map(quiz => ({
        key: quiz.id,
        name: quiz.titulo,
        periodicidade: quiz.periodicidade,
        disparos: quiz.questionarioDisparo,
        aplicacao: '',
        status: quiz.ativo,
        periodo: format(parseISO(quiz.creationTime), 'dd/MM/yyyy'),
      }));

      return formattedData;
    },
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetQuestions;
