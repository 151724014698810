import { api } from '@/lib';
import {
  HStack,
  Icon,
  IconButton,
  useToast,
  Button,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useUserStore } from '@/stores';
import { IoCheckmark, IoClose, IoMailUnreadOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useNotificationsTableQuery } from '../../hooks';
import { ResponseMessageModal } from '../Modal/ResponseMessageModal';

interface Props {
  row: any;
  refetch: () => void;
}

export function ActionButtons({ row, refetch }: Props) {
  const navigate = useNavigate();
  const user = useUserStore(state => state.user);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const { refetch: refresh } = useNotificationsTableQuery();
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  function onCloseCancel() {
    setModalOpen(false);
    refetch();
    refresh();
  }

  const Visualizar = () => {
    setLoading(true);
    api
      .put(`/alerta/web?status=true&id=${row.original.id}`)
      .then(res => {
        toast({ title: 'Notificação visualizada', status: 'success' });
        refetch();
        refresh();
      })
      .catch(err => {
        toast({ title: err.response.data.errors[0], status: 'error' });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const VisualizarPac = () => {
    setLoading(true);
    api
      .put(`/alerta/app?status=true&id=${row.original.id}`)
      .then(res => {
        toast({ title: 'Notificação visualizada', status: 'success' });
        refetch();
        refresh();
      })
      .catch(err => {
        toast({
          title: err.response.data.errors[0] || 'Ocorreu um erro!',
          status: 'error',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const RejeitarNotificacao = () => {
    if (row.original.vincular === 0) {
      api
        .put(
          '/paciente/vincular-profissional',
          {},
          {
            params: {
              idPaciente: row.original.idPaciente,
              idProfissional: row.original.idProfissional,
              idUnidade: row.original.idUnidade,
              status: 5,
            },
          },
        )
        .then(res => {
          toast({ title: 'Notificação reprovada', status: 'success' });
          refetch();
          refresh();
          Visualizar();
        })
        .catch(err => {
          toast({
            title: err.response.data.errors[0] || 'Ocorreu um erro!',
            status: 'error',
          });
        });
    } else if (row.original.vincular === 1) {
      api
        .put(
          '/consulta',
          {},
          {
            params: {
              idAlertaWeb: row.original.idAlertaWeb,
              statusHoraAgenda: 5,
              idHorasAgenda: row.original.idHorasAgenda,
              idUnidade: row.original.idUnidade,
            },
          },
        )
        .then(res => {
          toast({ title: 'Notificação reprovada', status: 'success' });
          refetch();
          refresh();
          Visualizar();
        })
        .catch(err => {
          toast({
            title: err.response.data.errors[0] || 'Ocorreu um erro!',
            status: 'error',
          });
        });
    }
  };

  const AprovarNotificacao = () => {
    if (row.original.vincular === 0) {
      api
        .put(
          '/paciente/vincular-profissional',
          {},
          {
            params: {
              idPaciente: row.original.idPaciente,
              idProfissional: row.original.idProfissional,
              idUnidade: row.original.idUnidade,
              status: 0,
            },
          },
        )
        .then(res => {
          toast({ title: 'Notificação aprovada', status: 'success' });
          refetch();
          refresh();
          Visualizar();
        })
        .catch(err => {
          toast({
            title: err.response.data.errors[0] || 'Ocorreu um erro!',
            status: 'error',
          });
        });
    } else if (row.original.vincular === 1) {
      api
        .put(
          '/consulta',
          {},
          {
            params: {
              idAlertaWeb: row.original.idAlertaWeb,
              statusHoraAgenda: 0,
              idHorasAgenda: row.original.idHorasAgenda,
              idUnidade: row.original.idUnidade,
            },
          },
        )
        .then(res => {
          toast({ title: 'Notificação aprovada', status: 'success' });
          refetch();
          refresh();
          Visualizar();
        })
        .catch(err => {
          toast({
            title: err.response.data.errors[0] || 'Ocorreu um erro!',
            status: 'error',
          });
        });
    }
  };

  const Prescrever = () => {
    Visualizar();
    navigate('/app/prescriptions', {
      state: {
        idPaciente: row.original.idPaciente,
        idMedico: row.original.idProfissional || '',
      },
    });
  };

  return (
    <>
      <HStack spacing="4" align="center" justify="center">
        {user?.type === 'paciente' ? (
          <IconButton
            isLoading={isLoading}
            disabled={isLoading}
            onClick={VisualizarPac}
            aria-label="Aceitar notificação"
            icon={<Icon as={IoCheckmark} color="#039c00" fontSize="27px" />}
            w="54px"
            h="54px"
            borderRadius="full"
            bg="white"
            boxShadow="1px 1px 9px -3px rgba(5, 6, 27, 0.25)"
          />
        ) : row.original.categoria === 3 ? (
          <Button
            onClick={Prescrever}
            isLoading={isLoading}
            disabled={isLoading}
            w={['100%', '100%', '80%']}
            h="38px"
            borderRadius="full"
            bg="white"
            boxShadow="1px 1px 9px -3px rgba(5, 6, 27, 0.25)"
          >
            Prescrever
          </Button>
        ) : row.original.categoria === 2 ? (
          <IconButton
            isLoading={isLoading}
            disabled={isLoading}
            onClick={Visualizar}
            aria-label="Aceitar notificação"
            icon={<Icon as={IoCheckmark} color="#039c00" fontSize="27px" />}
            w="54px"
            h="54px"
            borderRadius="full"
            bg="white"
            boxShadow="1px 1px 9px -3px rgba(5, 6, 27, 0.25)"
          />
        ) : row.original.categoria === 4 ? (
          <IconButton
            isLoading={isLoading}
            disabled={isLoading}
            onClick={() => setModalOpen(true)}
            aria-label="Aceitar notificação"
            icon={
              <Icon as={IoMailUnreadOutline} color="#039c00" fontSize="27px" />
            }
            w="54px"
            h="54px"
            borderRadius="full"
            bg="white"
            boxShadow="1px 1px 9px -3px rgba(5, 6, 27, 0.25)"
          />
        ) : (
          <>
            <IconButton
              isLoading={isLoading}
              disabled={isLoading}
              onClick={RejeitarNotificacao}
              aria-label="Rejeitar notificação"
              icon={<Icon as={IoClose} color="#db0404" fontSize="27px" />}
              w="54px"
              h="54px"
              borderRadius="full"
              bg="white"
              boxShadow="1px 1px 9px -3px rgba(5, 6, 27, 0.25)"
            />
            <IconButton
              isLoading={isLoading}
              disabled={isLoading}
              onClick={AprovarNotificacao}
              aria-label="Aceitar notificação"
              icon={<Icon as={IoCheckmark} color="#039c00" fontSize="27px" />}
              w="54px"
              h="54px"
              borderRadius="full"
              bg="white"
              boxShadow="1px 1px 9px -3px rgba(5, 6, 27, 0.25)"
            />
          </>
        )}
      </HStack>
      <Flex w="auto">
        <Modal isOpen={modalOpen} onClose={onCloseCancel} size="xl" isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody px="12" pb="8">
              <ResponseMessageModal
                onCancel={onCloseCancel}
                notification={row.original}
              />
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>
    </>
  );
}
