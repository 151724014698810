import useGetQuestionDisparoResponses from '@/features/historic/hooks/useGetQuestionDisparoResponses';
import useGetUsuarioDisparosQuiz from '@/features/historic/hooks/useGetUsuarioDisparosQuiz';
import { formatDate } from '@/utils';
import { getOptionOnListById } from '@/utils/getOptionOnListById';
import {
  concernedIcon,
  faceRaisedIcon,
  poutingIcon,
  smilingIcon,
} from '@/assets/icons';
import {
  Box,
  Text,
  GridItem,
  Grid,
  Spinner,
  Center,
  Icon,
  Tooltip,
  Image,
  SimpleGrid,
} from '@chakra-ui/react';

import { Card } from 'antd';
import { addDays, format, parseISO } from 'date-fns';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { CardItem } from './components/CardItem';

interface disparos {
  questionarioId: number;
  horario: string;
  lastModificationTime: string | null;
  lastModifierId: string | null;
  creationTime: string;
  creatorId: string;
  id: number;
}

interface questionario {
  titulo: string;
  ativo: boolean;
  periodicidade: number;
  taxonomia: string;
  protocolo: string;
  questionarioDisparo: disparos[];
}

export interface dataProps {
  id: string;
  pacienteId: string;
  periodicidade: number;
  questionarioId: number;
  unidadeSaudeId: string;
  questionario: questionario;
  questionarioUsuarioDisparoId: number;
  dataInicial: string;
  dataFinal: string;
  descricao: string;
}

interface ModalResultsProps {
  data: dataProps;
}

export default function ModalResults({ data: values }: ModalResultsProps) {
  const { data: quizWithCalls, isLoading: isLoadingCalls } =
    useGetUsuarioDisparosQuiz({
      disparoId: values?.questionarioUsuarioDisparoId,
    });

  const [callsGrouped, setCallsGrouped] = useState<{
    [key: string]: any[];
  }>({});

  const [option, setOption] = useState<{
    questionarioUsuarioId: number;
    grupoId: string;
    resultado?: any;
  }>();

  const { data: qaList, isLoading: isLoadingQaList } =
    useGetQuestionDisparoResponses({
      questionarioUsuarioId: option?.questionarioUsuarioId,
      grupoId: option?.grupoId,
    });

  const groupByDate = (objetos: any[]) => {
    const allResponses = objetos.reduce((acc, obj) => {
      return acc.concat(
        obj.respostas.map((resposta: any[]) => {
          return { ...resposta, questionarioUsuarioId: obj.id };
        }),
      );
    }, []);

    const grupos = allResponses.reduce(
      (acc: { [key: string]: disparos[] }, objeto: any) => {
        const newData = moment(objeto.creationTime).format('YYYY-MM-DD');
        if (!acc[newData]) {
          acc[newData] = [];
        }
        acc[newData].push(objeto);
        return acc;
      },
      {},
    );
    return grupos;
  };

  const alertColorBox = () => {
    switch (option!.resultado?.corAlerta ?? 99) {
      case 0:
        return 'green.400';
      case 1:
        return 'yellow.400';
      case 2:
        return 'red.400';
      default:
        return 'white.400';
    }
  };

  useEffect(() => {
    if (quizWithCalls && quizWithCalls.length > 0) {
      const groups = groupByDate(quizWithCalls);
      setCallsGrouped(groups);
    }
  }, [quizWithCalls]);

  const getEmoji = (id: number) => {
    switch (id) {
      case 0:
        return <Image width="10" height="auto" src={smilingIcon} />;
      case 1:
        return <Image width="10" height="auto" src={faceRaisedIcon} />;
      case 2:
        return <Image width="10" height="auto" src={concernedIcon} />;
      case 3:
        return <Image width="10" height="auto" src={poutingIcon} />;
      default:
        return '';
    }
  };

  const DadosConsulta = () => {
    return (
      <Grid
        width="100%"
        templateColumns="repeat(12, 1fr)"
        paddingBottom="10"
        borderBottom="1px solid #0005"
      >
        <GridItem display="flex" alignItems="center" colSpan={12}>
          <Text
            color="brand-dark.200"
            fontSize="lg"
            fontWeight="normal"
            fontFamily="Rubik"
            ml="5"
          >
            Questionários
          </Text>
          <Box
            minH="30px"
            borderRadius="10px"
            color="white"
            fontSize="lg"
            fontWeight="medium"
            textTransform="uppercase"
            marginX="5"
            bg="#d4d4d4"
            display="flex"
            alignItems="center"
            border="1px solid #0005"
            px="5"
          >
            {values.questionario.titulo}
          </Box>

          <Text
            color="brand-dark.200"
            fontSize="lg"
            fontWeight="normal"
            fontFamily="Rubik"
            ml="10"
          >
            Data de início:
          </Text>
          <Text
            color="brand-dark.500"
            fontSize="lg"
            fontWeight="large"
            fontFamily="Rubik"
            ml="5"
          >
            {format(parseISO(values.dataInicial), 'dd/MM/yyyy')}
          </Text>

          <Text
            color="brand-dark.200"
            fontSize="lg"
            fontWeight="normal"
            fontFamily="Rubik"
            ml="10"
          >
            Data de Fim:
          </Text>
          <Text
            color="brand-dark.500"
            fontSize="lg"
            fontWeight="large"
            fontFamily="Rubik"
            ml="5"
          >
            {format(parseISO(values.dataFinal), 'dd/MM/yyyy')}
          </Text>
        </GridItem>
      </Grid>
    );
  };

  return (
    <Box
      flex={1}
      minH="container.sm"
      sx={{ '&::-webkit-scrollbar': { display: 'none' } }}
    >
      <DadosConsulta />
      <Grid flex={1} templateColumns="repeat(12, 1fr)">
        <GridItem colSpan={3} maxH="xl" overflowY="auto">
          {isLoadingCalls ? (
            <Center w="100%" h="xl">
              <Spinner color="green.500" size="xl" />
            </Center>
          ) : (
            <>
              {Object.keys(callsGrouped).map(groupValue => (
                <Box key={groupValue.toString()}>
                  <Box
                    cursor="pointer"
                    fontSize="lg"
                    fontWeight="500"
                    bg=""
                    p="2"
                    fontFamily="Rubik"
                    mt={10}
                  >
                    {formatDate(addDays(new Date(groupValue), 1), 'dd/MM/yyyy')}
                  </Box>
                  <ul>
                    {callsGrouped[groupValue].map(obj => (
                      <CardItem
                        key={obj.grupoId}
                        colorIndicator={obj.resultado?.corAlerta}
                        active={obj.grupoId === option?.grupoId}
                        onClick={() => setOption(obj)}
                      >
                        {formatDate(new Date(obj.creationTime), 'HH:mm')}
                      </CardItem>
                    ))}
                  </ul>
                </Box>
              ))}

              {Object.keys(callsGrouped).length < 1 && (
                <Box
                  m="auto"
                  padding={4}
                  bg="green.200"
                  borderRadius="xl"
                  fontWeight="semibold"
                  mt={6}
                >
                  Este questionário ainda não possui nenhuma resposta.
                </Box>
              )}
            </>
          )}
        </GridItem>
        <GridItem
          display="flex"
          colSpan={9}
          backgroundColor="red"
          marginTop="5"
        >
          <Box backgroundColor="#fff" width="100vw" height="100%">
            {isLoadingQaList ? (
              <Card
                style={{
                  maxHeight: '60rem',
                  height: '100%',
                  borderRadius: '10px',
                  backgroundColor: '#fff',
                  overflowY: 'auto',
                  paddingRight: '20px',
                }}
              >
                <Center w="100%" h="xl">
                  <Spinner color="green.500" size="xl" />
                </Center>
              </Card>
            ) : (
              <Card
                style={{
                  maxHeight: '30rem',
                  height: '100%',
                  borderRadius: '10px',
                  backgroundColor: '#fff',
                  overflowY: 'auto',
                  paddingRight: '20px',
                }}
              >
                {isLoadingQaList && (
                  <Center w="100%" h="xl">
                    <Spinner color="green.500" size="xl" />
                  </Center>
                )}
                {!isLoadingQaList && qaList.length === 0 && option && (
                  <Center w="100%" h="xl">
                    <Text fontSize="lg">Sem respostas</Text>
                  </Center>
                )}
                {qaList.length > 0 && (
                  <>
                    <Grid
                      width="100%"
                      templateColumns="repeat(16, 1fr)"
                      paddingBottom="10"
                      gap={10}
                    >
                      <GridItem
                        display="flex"
                        alignItems="flex-start"
                        colSpan={8}
                      >
                        <Text
                          color="brand-dark.500"
                          fontSize="lg"
                          fontWeight="bold"
                          fontFamily="Rubik"
                          ml="5"
                        >
                          Perguntas
                        </Text>
                      </GridItem>

                      <GridItem
                        display="flex"
                        alignItems="flex-start"
                        colSpan={1}
                      >
                        <Text
                          color="brand-dark.500"
                          fontSize="lg"
                          fontWeight="bold"
                          fontFamily="Rubik"
                        >
                          Opções
                        </Text>
                      </GridItem>

                      <GridItem
                        display="flex"
                        alignItems="flex-start"
                        colSpan={7}
                      >
                        <Text
                          color="brand-dark.500"
                          fontSize="lg"
                          fontWeight="bold"
                          fontFamily="Rubik"
                        >
                          Respostas
                        </Text>
                      </GridItem>
                    </Grid>

                    {qaList?.map((d: any) => (
                      <Grid
                        width="100%"
                        templateColumns="repeat(16, 1fr)"
                        paddingBottom="10"
                        key={d.id}
                        gap={10}
                      >
                        <GridItem
                          display="flex"
                          alignItems="center"
                          colSpan={8}
                        >
                          <Text
                            color="brand-dark.200"
                            fontSize="lg"
                            fontWeight="normal"
                            fontFamily="Rubik"
                            ml="5"
                          >
                            {d.pergunta.enunciado}
                          </Text>
                        </GridItem>

                        {[1, 2].includes(d.pergunta.tipoPergunta) && (
                          <>
                            <GridItem
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              colSpan={1}
                            >
                              <Tooltip
                                hasArrow
                                placement="right"
                                label={
                                  <>
                                    {d.pergunta.opcaoResposta.map((i: any) => (
                                      <>
                                        {i.descricao}
                                        <br />
                                      </>
                                    ))}
                                  </>
                                }
                                bg="green.300"
                              >
                                <Icon
                                  color="brand-dark.600"
                                  fontSize="20px"
                                  fontWeight="400"
                                  ml="2"
                                >
                                  <BsFillInfoCircleFill />
                                </Icon>
                              </Tooltip>
                            </GridItem>
                            <GridItem
                              display="flex"
                              alignItems="center"
                              colSpan={7}
                            >
                              <Text
                                color="#000"
                                fontSize="lg"
                                fontWeight="normal"
                                fontFamily="Rubik"
                              >
                                {
                                  getOptionOnListById(
                                    d.pergunta.opcaoResposta as any,
                                    d.opcaoRespostaId as number,
                                  )?.descricao
                                }
                              </Text>
                            </GridItem>
                          </>
                        )}
                        {[3].includes(d.pergunta.tipoPergunta) && (
                          <>
                            <GridItem
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              colSpan={1}
                            >
                              <Tooltip
                                hasArrow
                                placement="right"
                                label="Resposta de texto enviada pelo paciente."
                                bg="green.300"
                              >
                                <Icon
                                  color="brand-dark.600"
                                  fontSize="20px"
                                  fontWeight="400"
                                  ml="2"
                                >
                                  <BsFillInfoCircleFill />
                                </Icon>
                              </Tooltip>
                            </GridItem>
                            <GridItem
                              display="flex"
                              alignItems="center"
                              colSpan={7}
                            >
                              <Text
                                color="#000"
                                fontSize="lg"
                                fontWeight="normal"
                                fontFamily="Rubik"
                              >
                                {d.descricao}
                              </Text>
                            </GridItem>
                          </>
                        )}
                        {[4].includes(d.pergunta.tipoPergunta) && (
                          <>
                            <GridItem
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              colSpan={1}
                            >
                              <Tooltip
                                hasArrow
                                placement="right"
                                label={d.pergunta.taxonomia}
                                bg="green.300"
                              >
                                <Icon
                                  color="brand-dark.600"
                                  fontSize="20px"
                                  fontWeight="400"
                                  ml="2"
                                >
                                  <BsFillInfoCircleFill />
                                </Icon>
                              </Tooltip>
                            </GridItem>
                            <GridItem
                              display="flex"
                              alignItems="center"
                              colSpan={7}
                            >
                              <Text
                                color="#000"
                                fontSize="lg"
                                fontWeight="normal"
                                fontFamily="Rubik"
                              >
                                {d.descricao}
                              </Text>
                            </GridItem>
                          </>
                        )}
                        {[5].includes(d.pergunta.tipoPergunta) && (
                          <>
                            <GridItem
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              colSpan={1}
                            >
                              <Tooltip
                                hasArrow
                                placement="right"
                                label={d.pergunta.taxonomia}
                                bg="green.300"
                              >
                                <Icon
                                  color="brand-dark.600"
                                  fontSize="20px"
                                  fontWeight="400"
                                  ml="2"
                                >
                                  <BsFillInfoCircleFill />
                                </Icon>
                              </Tooltip>
                            </GridItem>
                            <GridItem
                              display="flex"
                              alignItems="center"
                              colSpan={7}
                            >
                              <Text
                                color="#000"
                                fontSize="lg"
                                fontWeight="normal"
                                fontFamily="Rubik"
                              >
                                {formatDate(
                                  new Date(d.descricao),
                                  'dd/MM/yyyy HH:mm',
                                )}
                              </Text>
                            </GridItem>
                          </>
                        )}
                        {[6].includes(d.pergunta.tipoPergunta) && (
                          <>
                            <GridItem
                              display="flex"
                              alignItems="center"
                              justifyContent="center"
                              colSpan={1}
                            >
                              <Tooltip
                                hasArrow
                                placement="right"
                                label={
                                  <>
                                    {d.pergunta.opcaoResposta.map((i: any) => (
                                      <>
                                        {getEmoji(i.linkertEmoji)}
                                        <br />
                                      </>
                                    ))}
                                  </>
                                }
                                bg="green.300"
                              >
                                <Icon
                                  color="brand-dark.600"
                                  fontSize="20px"
                                  fontWeight="400"
                                  ml="2"
                                >
                                  <BsFillInfoCircleFill />
                                </Icon>
                              </Tooltip>
                            </GridItem>
                            <GridItem
                              display="flex"
                              alignItems="center"
                              colSpan={7}
                            >
                              <Text
                                color="#000"
                                fontSize="lg"
                                fontWeight="normal"
                                fontFamily="Rubik"
                              >
                                {getEmoji(
                                  getOptionOnListById(
                                    d.pergunta.opcaoResposta,
                                    d.opcaoRespostaId,
                                  )?.linkertEmoji || 5,
                                )}
                              </Text>
                            </GridItem>
                          </>
                        )}
                      </Grid>
                    ))}
                  </>
                )}

                {option && (
                  <>
                    <Grid
                      width="100%"
                      templateColumns="repeat(12, 1fr)"
                      paddingBottom="10"
                    >
                      <GridItem display="flex" alignItems="center" colSpan={9}>
                        <Text
                          color="brand-dark.500"
                          fontSize="lg"
                          fontWeight="bold"
                          fontFamily="Rubik"
                          ml="5"
                        >
                          Perguntas
                        </Text>
                      </GridItem>

                      <GridItem
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        colSpan={3}
                      >
                        <Box
                          h="45px"
                          borderRadius="10px"
                          color="#000"
                          fontSize="lg"
                          fontWeight="medium"
                          textTransform="uppercase"
                          w="auto"
                          marginY="5"
                          paddingX="5"
                          bg={alertColorBox as any}
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                          border="2px solid #000"
                        >
                          {option.resultado?.nome}
                        </Box>
                      </GridItem>
                    </Grid>

                    <SimpleGrid marginTop="auto" columns={2} spacingX={10}>
                      <Text
                        color="brand-dark.500"
                        fontSize="lg"
                        fontWeight="normal"
                        fontFamily="Rubik"
                        ml="5"
                        mb="5"
                      >
                        Indicador (Profissional de Saúde)
                      </Text>
                      <Text
                        color="brand-dark.500"
                        fontSize="lg"
                        fontWeight="normal"
                        fontFamily="Rubik"
                        ml="5"
                        mb="5"
                      >
                        Orientação (Paciente)
                      </Text>
                      <Card>
                        <Text fontSize="md">
                          {option?.resultado?.indicador}
                        </Text>
                      </Card>

                      <Card>
                        <Text fontSize="md">{option?.resultado?.feedBack}</Text>
                      </Card>
                    </SimpleGrid>
                  </>
                )}
              </Card>
            )}
          </Box>
        </GridItem>
      </Grid>
    </Box>
  );
}
