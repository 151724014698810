import { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { TextSub, TextLink } from '../../TextBase/TextBase';

interface UserInfoModalProps {
  prescription: any;
}

export function HistoricModal({ prescription }: UserInfoModalProps) {
  const atestado = prescription?.result?.data[0]?.atestado;
  const exames = prescription?.result?.data[0]?.exames;
  const medicamentos = prescription?.result?.data[0]?.medicamentos;
  const encaminhamento = prescription?.result?.data[0]?.encaminhamento;
  const relatorio = prescription?.result?.data[0]?.relatorio;
  const pdfs = prescription?.result?.data[0]?.pdfs;
  const [urls, setURLS] = useState<any[]>([]);
  const [option, setOption] = useState('RECEITA MÉDICA');
  const options = [
    'RECEITA MÉDICA',
    'SOLICITAR EXAMES',
    'ATESTADO',
    'ENCAMINHAMENTO',
    'RELATÓRIO',
    'DOCUMENTOS',
  ];

  const getPDFS = () => {
    if (prescription?.result?.data[0]?.pdfs) {
      const c = prescription?.result?.data[0]?.pdfs?.reduce(
        (acc: any, item: any, i: any) => {
          return {
            ...acc,
            [item.tipo]: [...(acc[item.tipo] ?? []), item.url],
          };
        },
        {},
      );
      setURLS(c);
    }
  };

  useEffect(() => {
    getPDFS();
  }, [prescription]);

  return (
    <Box>
      <Tabs variant="soft-rounded" colorScheme="green">
        <TabList defaultValue="RECEITA MÉDICA">
          {options.map(op => (
            <Tab
              key={op}
              color="#545454"
              fontSize="12px"
              fontWeight="600"
              textTransform="uppercase"
              margin="2"
              _selected={{
                bg: 'transparent',
                color: '#60C2B0',
              }}
            >
              {op}
            </Tab>
          ))}
        </TabList>
        {prescription.status !== 'error' && (
          <TabPanels>
            <TabPanel paddingTop="5">
              {medicamentos && medicamentos.length > 0 ? (
                <>
                  {medicamentos &&
                    medicamentos?.map((med: any) => (
                      <Box
                        border="1px solid #000"
                        borderRadius="10"
                        padding="3"
                        mb="3"
                      >
                        <TextSub>{`Nome: ${med.nome}`}</TextSub>
                        <TextSub>{`Comentario: ${med.comentario}`}</TextSub>
                        <TextSub>{`Concentração: ${med.concentracao}`}</TextSub>
                        <TextSub>{`Controle especial: ${
                          med.controleEspecial ? 'Sim' : 'Não'
                        }`}</TextSub>
                        {/* <TextSub>{`Data criação: ${moment(
                          med.dataCriacao,
                        ).format('DD/MM/YYYY')}`}</TextSub> */}
                        <TextSub>{`Posologia: ${med.posologia}`}</TextSub>
                        <TextSub>{`Quantidade: ${med.quantidade}`}</TextSub>
                        <TextSub>{`Tipo medicamento: ${med.tipoMedicamento}`}</TextSub>
                        <TextSub>{`Uso continuo: ${
                          med.usoContinuo ? 'Sim' : 'Não'
                        }`}</TextSub>
                        <TextSub>{`Via administracao: ${med.viaAdministracao}`}</TextSub>
                      </Box>
                    ))}
                  <Box marginTop="5">
                    <TextSub>{`PDF Medicamentos:  `} </TextSub>
                    {urls[5] &&
                      urls[5].map((url: any) => (
                        <TextSub>
                          <TextLink>{`https://api-hlg.dmhealth.com.br/pdfs${
                            url.split('pdfs')[1]
                          }`}</TextLink>
                        </TextSub>
                      ))}
                  </Box>
                </>
              ) : (
                <Box
                  border="1px solid #000"
                  borderRadius="10"
                  padding="3"
                  mb="3"
                  color="#000"
                >
                  Não há dados de medicamentos
                </Box>
              )}
            </TabPanel>
            <TabPanel paddingTop="5">
              {exames && exames.length > 0 ? (
                <>
                  {exames &&
                    exames?.map((exa: any) => (
                      <Box
                        border="1px solid #000"
                        borderRadius="10"
                        padding="3"
                        mb="3"
                        color="#000"
                      >
                        <TextSub>{`Etiqueta: ${exa.label}`}</TextSub>
                        <TextSub>{`Nome: ${exa.nome}`}</TextSub>
                        <TextSub>{`Anestesia requerida: ${
                          exa.anestesiaRequerida ? 'Sim' : 'Não'
                        }`}</TextSub>
                        <TextSub>{`Aplicar anestesia: ${
                          exa.aplicarAnestesia ? 'Sim' : 'Não'
                        }`}</TextSub>
                        <TextSub>{`Categoria: ${exa.categoria}`}</TextSub>
                        <TextSub>{`Codigo TUSS: ${exa.codigoTUSS}`}</TextSub>
                        {/* <TextSub>{`Data criação: ${moment(
                          exa.dataCriacao,
                        ).format('DD/MM/YYYY')}`}</TextSub> */}
                        <TextSub>{`Instruções: ${exa.instrucoes}`}</TextSub>
                        <TextSub>{`Laudo requerido: ${exa.ladoRequerido}`}</TextSub>
                      </Box>
                    ))}

                  <Box marginTop="5">
                    <TextSub>{`PDF Exames:  `} </TextSub>
                    {urls[1] &&
                      urls[1].map((url: any) => (
                        <TextSub>
                          <TextLink>{`https://api-hlg.dmhealth.com.br/pdfs${
                            url.split('pdfs')[1]
                          }`}</TextLink>
                        </TextSub>
                      ))}
                  </Box>
                  {/* <Divider style={{ marginTop: 30 }} />  */}
                </>
              ) : (
                <Box
                  border="1px solid #000"
                  borderRadius="10"
                  padding="3"
                  mb="3"
                  color="#000"
                >
                  Não há dados de exames
                </Box>
              )}
            </TabPanel>

            <TabPanel paddingTop="5">
              {atestado && atestado.length > 0 ? (
                <>
                  {atestado &&
                    atestado?.map((ates: any) => (
                      <Box
                        border="1px solid #000"
                        borderRadius="10"
                        padding="3"
                        mb="3"
                      >
                        <TextSub>{`Consentimento CID: ${
                          ates.consentimentoCID ? 'Sim' : 'Não'
                        }`}</TextSub>
                        <TextSub>{`Observação: ${ates.observacao}`}</TextSub>
                        {/* <TextSub>{`Data atestado: ${moment(
                          ates.dataAtestado,
                        ).format('DD/MM/YYYY')}`}</TextSub>
                        <TextSub>{`Data inicio: ${moment(
                          ates.dataInicio,
                        ).format('DD/MM/YYYY')}`}</TextSub>
                        <TextSub>{`Data término: ${moment(
                          ates.dataTermino,
                        ).format('DD/MM/YYYY')}`}</TextSub> */}
                        <TextSub>{`Texto: ${ates.texto}`}</TextSub>
                        <TextSub>{`Texto completo: ${ates.textoCompleto}`}</TextSub>
                        <TextSub>{`Tipo periodo: ${ates.tipoPeriodo}`}</TextSub>
                      </Box>
                    ))}

                  <Box marginTop="5">
                    <TextSub>{`PDF Atestados:  `} </TextSub>
                    {urls[4] &&
                      urls[4].map((url: any) => (
                        <TextSub>
                          <TextLink>{`https://api-hlg.dmhealth.com.br/pdfs${
                            url.split('pdfs')[1]
                          }`}</TextLink>
                        </TextSub>
                      ))}
                  </Box>
                </>
              ) : (
                <Box
                  border="1px solid #000"
                  borderRadius="10"
                  padding="3"
                  mb="3"
                  color="#000"
                >
                  Não há dados de atestados
                </Box>
              )}
            </TabPanel>

            <TabPanel paddingTop="5">
              {encaminhamento && encaminhamento.length > 0 ? (
                <>
                  {encaminhamento &&
                    encaminhamento?.map((enc: any) => (
                      <Box
                        border="1px solid #000"
                        borderRadius="10"
                        padding="3"
                        mb="3"
                      >
                        <TextSub>{`CID: ${`${enc.ciD10.codigo} - ${enc.ciD10.descricao}`}`}</TextSub>
                        <TextSub>{`Motivo: ${enc.motivo}`}</TextSub>
                        <TextSub>{`Data encaminhamento: ${new Date(
                          enc.dataEncaminhamento ?? '',
                        ).toLocaleDateString('pt-BR')}`}</TextSub>
                        <TextSub>{`História clínica: ${enc.historiaClinica}`}</TextSub>
                        <TextSub>{`Conduta: ${enc.conduta}`}</TextSub>
                      </Box>
                    ))}

                  <Box marginTop="5">
                    <TextSub>{`PDF Encaminhamentos:  `} </TextSub>
                    {urls[2] &&
                      urls[2].map((url: any) => (
                        <TextSub>
                          <TextLink>{`https://api-hlg.dmhealth.com.br/pdfs${
                            url.split('pdfs')[1]
                          }`}</TextLink>
                        </TextSub>
                      ))}
                  </Box>
                </>
              ) : (
                <Box
                  border="1px solid #000"
                  borderRadius="10"
                  padding="3"
                  mb="3"
                  color="#000"
                >
                  Não há dados de encaminhamentos
                </Box>
              )}
            </TabPanel>

            <TabPanel paddingTop="5">
              {relatorio && relatorio.length > 0 ? (
                <>
                  {relatorio &&
                    relatorio?.map((rel: any) => (
                      <Box
                        border="1px solid #000"
                        borderRadius="10"
                        padding="3"
                        mb="3"
                      >
                        <TextSub>{`Título: ${`${rel.titulo}`}`}</TextSub>
                        <TextSub>{`Texto: ${rel.texto}`}</TextSub>
                        <TextSub>{`Data laudo: ${new Date(
                          rel.dataLaudoMedico ?? '',
                        ).toLocaleDateString('pt-BR')}`}</TextSub>
                        <TextSub>{`Data inclusão: ${new Date(
                          rel.dataInclusao ?? '',
                        ).toLocaleDateString('pt-BR')}`}</TextSub>
                      </Box>
                    ))}

                  <Box marginTop="5">
                    <TextSub>{`PDF Relatórios:  `} </TextSub>
                    {urls[3] &&
                      urls[3].map((url: any) => (
                        <TextSub>
                          <TextLink>{`https://api-hlg.dmhealth.com.br/pdfs${
                            url.split('pdfs')[1]
                          }`}</TextLink>
                        </TextSub>
                      ))}
                  </Box>
                </>
              ) : (
                <Box
                  border="1px solid #000"
                  borderRadius="10"
                  padding="3"
                  mb="3"
                  color="#000"
                >
                  Não há dados de relatórios
                </Box>
              )}
            </TabPanel>

            <TabPanel paddingTop="5">
              {pdfs && pdfs.length > 0 ? (
                <Box
                  border="1px solid #000"
                  borderRadius="10"
                  padding="3"
                  mb="3"
                >
                  <Box marginTop="5">
                    <TextSub>{`PDF Medicamentos:  `} </TextSub>
                    {urls[5] &&
                      urls[5].map((url: any) => (
                        <TextSub>
                          <TextLink>{`https://api-hlg.dmhealth.com.br/pdfs${
                            url.split('pdfs')[1]
                          }`}</TextLink>
                        </TextSub>
                      ))}
                  </Box>

                  <Box marginTop="5">
                    <TextSub>{`PDF Exames:  `} </TextSub>
                    {urls[1] &&
                      urls[1].map((url: any) => (
                        <TextSub>
                          <TextLink>{`https://api-hlg.dmhealth.com.br/pdfs${
                            url.split('pdfs')[1]
                          }`}</TextLink>
                        </TextSub>
                      ))}
                  </Box>

                  <Box marginTop="5">
                    <TextSub>{`PDF Atestados:  `} </TextSub>
                    {urls[4] &&
                      urls[4].map((url: any) => (
                        <TextSub>
                          <TextLink>{`https://api-hlg.dmhealth.com.br/pdfs${
                            url.split('pdfs')[1]
                          }`}</TextLink>
                        </TextSub>
                      ))}
                  </Box>

                  <Box marginTop="5">
                    <TextSub>{`PDF Encaminhamentos:  `} </TextSub>
                    {urls[2] &&
                      urls[2].map((url: any) => (
                        <TextSub>
                          <TextLink>{`https://api-hlg.dmhealth.com.br/pdfs${
                            url.split('pdfs')[1]
                          }`}</TextLink>
                        </TextSub>
                      ))}
                  </Box>

                  <Box marginTop="5">
                    <TextSub>{`PDF Relatórios:  `} </TextSub>
                    {urls[3] &&
                      urls[3].map((url: any) => (
                        <TextSub>
                          <TextLink>{`https://api-hlg.dmhealth.com.br/pdfs${
                            url.split('pdfs')[1]
                          }`}</TextLink>
                        </TextSub>
                      ))}
                  </Box>
                </Box>
              ) : (
                <Box
                  border="1px solid #000"
                  borderRadius="10"
                  padding="3"
                  mb="3"
                  color="#000"
                >
                  Não há dados de documentos
                </Box>
              )}
            </TabPanel>
          </TabPanels>
        )}
      </Tabs>

      {prescription.status === 'error' && (
        <TextSub>PRESCRIÇÃO NÃO ENCONTRADA.</TextSub>
      )}
    </Box>
  );
}
