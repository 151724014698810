import { Box, Flex, Heading, Link, Stack, Text } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

export function BodySection({ filter, setFilter }: any) {
  return (
    <Flex
      direction={['column', 'column', 'row']}
      align="center"
      justify="center"
    >
      <Box mt="4" width="90%">
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          paddingTop="10"
          align="justify"
        >
          Ao aceitar os presentes termos de uso do Software DIRECT MESSAGE
          HEALTH você concorda e aceita os termos desse contrato. Pelo presente
          instrumento particular de Contrato de Uso de Software DM como
          Prestação de Serviços - SaaS, e na melhor forma de direito:
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          <b>DM HEALTHCARE LTDA.</b> empresa de direito privado prestadora de
          serviços de tecnologia e inteligência artificial aplicada na área de
          saúde, com sede na Rua Piauí, n° 1246, sala 41- Higienópolis, CEP
          01241-000, São Paulo, SP, inscrita no CNPJ sob nº 32.276.063/0001-61,
          neste ato representada na forma de seu contrato social, doravante
          denominada simplesmente {`"CONTRATADA"`}.
        </Text>
        <Text>
          Ao instalar o app ou utilizar-se da plataforma, das funcionalidades, e
          do sistema (conjuntamente “Sistema ou Sistemas”) o usuário (“usuário
          ou CONTRATANTE”) concorda com os presentes Termos de Uso como Contrato
          de Prestação de Serviços.
        </Text>
        <Text>
          O usuário e a CONTRATADA conjuntamente serão referidos como “PARTES”,
          as quais têm entre si justo e contratado o quanto segue:{' '}
        </Text>
        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          1. DO OBJETO
        </Heading>

        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          1.1. O objeto do presente Contrato é a prestação de serviços de
          soluções de Tecnologia da Informação pelo uso do Software DIRECT
          MESSAGE HEALTH, registrado no INPI sob o n° BR512021000346-2,
          direcionado à coleta e tratamento de dados com inteligência
          artificial, para a criação de protocolos de diagnóstico, destinado à
          área de saúde, na modalidade SaaS - Software as a Service (Software
          como Serviço), com acesso via WEB no portal www.dmhealth.com.br o qual
          é, através da assinatura licenciado em caráter não exclusivo e
          intransferível, unicamente para o uso da CONTRATANTE.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          1.2. O presente serviço se destina a prover uma ferramenta para gestão
          de dados, rotinas de clínicas e consultórios, ofertando soluções de
          acompanhamento e atendimento médico sob responsabilidade da
          CONTRATANTE, sendo expressamente vedada sua utilização para outras
          finalidades que não as expressamente previstas, de forma direta ou
          indireta.
        </Text>

        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          2. DAS CONDIÇÕES DA PRESTAÇÃO DE SERVIÇOS E SUPORTE TÉCNICO
        </Heading>

        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          2.1. O Uso do Software DIRECT MESSAGE HEALTH e seus componentes são
          licenciados através por este instrumento, sem qualquer exclusividade,
          para apenas um CONTRATANTE, pessoa jurídica.
        </Text>

        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          2.2. O Software DIRECT MESSAGE HEALTH poderá ser utilizado por número
          ilimitado de usuários, desde que estejam credenciados pela assinatura
          da CONTRATANTE.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          2.3. A CONTRATANTE não poderá alterar, reproduzir, distribuir, os
          componentes que compõem o Software DIRECT MESSAGE HEALTH, sendo a
          mesma apenas licenciada para o uso do sistema.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          2.4. A CONTRATADA fornecerá suporte técnico à CONTRATANTE, através de
          abertura de chamados por telefone ou e-mail, ou através dos canais de
          atendimento direto.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          2.5. Falhas com o Software DIRECT MESSAGE HEALTH serão sanadas por
          suporte remoto sempre que possível. Caso não seja possível sanar
          remotamente a falha, por força do grau de complexidade técnica, o
          problema será encaminhado ao departamento técnico adequado da
          CONTRATADA, que investigará o problema.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          2.6. Problemas de ordem técnica reportados nos canais de atendimento
          serão analisados até o prazo de 3 dias úteis a contar da abertura do
          chamado, a fim de determinar se a falha guarda relação com o Software
          DIRECT MESSAGE HEALTH, com hardware (POS) ou se está relacionado com a
          conexão de dados (CHIP).
        </Text>

        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          3. DE PAGAMENTO, VALORES E REAJUSTES
        </Heading>

        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          3.1. Os preços e as condições de faturamento e pagamento serão as
          seguintes: assinatura mensal no valor de R$79,90 ( setenta e nove
          reais e noventa centavos).
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          3.2. Os valores dos serviços poderão ser atualizados monetariamente de
          acordo com a variação do IGPM ou, na sua suspensão, não divulgação ou
          extinção, por qualquer outro índice oficialmente estipulado para
          substituí-lo.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          3.3. Serviços de customização e novos desenvolvimentos não estão
          inclusos no escopo da prestação de serviços ora contratados. Tais
          serviços podem ser realizados e disponibilizados à CONTRATANTE
          mediante negociação e proposta específica, com termos e condições
          próprios.
        </Text>

        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          4. MULTA POR ATRASO
        </Heading>

        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          4.1. A CONTRATANTE está ciente de que, ocorrendo atraso no pagamento,
          incidirão sobre o valor devido multa de 2% (dois por cento), acrescida
          de juros moratórios no percentual de 1% (um por cento) ao mês.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          4.2. Se o atraso persistir, após 30 (trinta) dias contados da data do
          vencimento sem que seja constatado o pagamento do débito, a CONTRATADA
          poderá proceder ao bloqueio dos serviços, sem qualquer notificação ou
          comunicação prévia. A reativação dos serviços ocorrerá em até 48 horas
          após a baixa na pendência e quitação de todos os débitos e multas
          decorrentes.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          4.3. A partir de 60 dias de inadimplência, a CONTRATANTE estará
          sujeita à rescisão contratual, podendo a CONTRATADA incluí-la junto
          aos órgãos de proteção de crédito, estando também sujeita a protesto,
          assim como não serão aceitas novas contas, customizações ou
          parametrizações no sistema caso a CONTRATANTE esteja em débito.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          4.4. A CONTRATADA declara que nos valores dos serviços já estão
          inclusos todos os tributos e encargos incidentes a este tipo de
          transação. Será de responsabilidade do sujeito passivo da obrigação
          tributária, nos termos em lei definidos, o recolhimento dos tributos,
          impostos, taxas e contribuições de qualquer espécie, relativos ao
          presente Contrato.
        </Text>

        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          5. NÍVEIS DE SERVIÇO E GARANTIA DE DESEMPENHO (SLA)
        </Heading>

        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          5.1. Para os fins previstos no presente instrumento, o regular
          funcionamento do software e de suas funcionalidades será considerado
          como fator de excelência técnica SLA (Service Level Agreement), sendo
          certo que não existe - pela natureza do serviço e sua dependência de
          outros serviços, links, servidores e outros - garantia integral de
          100% de nível de serviço.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          5.2. Assim, desde que observadas as obrigações da CONTRATANTE
          estipuladas no presente instrumento e na medida do possível - no que
          depender da CONTRATADA – a mesma se compromete a manter, em cada mês
          civil, um SLA de 90%- especificamente no tocante à manutenção dos
          servidores próprios e links de acesso ao Software, de forma a
          mantê-los funcionantes e com acesso ininterrupto, excetuadas as
          hipóteses em que a falha ocorra sem culpa da CONTRATADA, conforme
          adiante previsto.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          5.3. Ficam excetuadas da garantia de desempenho, hipóteses de falhas
          alheias à atuação da CONTRATADA, incluindo, mas não se limitando a (i)
          Falha na conexão (“link”) fornecida pela empresa de telecomunicações
          responsável pela prestação de serviço; (ii) Mau uso da CONTRATANTE,
          incluindo mas não se limitando a sobrecarga do servidor por utilização
          não otimizada; (iii) Falhas no funcionamento do software DIRECT
          MESSAGE HEALTH comprovadamente causadas por mau funcionamento do
          servidor ou do sistema operacional; (iv) Interrupções para ajustes
          técnicos ou manutenção, que serão previamente informadas e
          preferencialmente realizadas em horários de baixo movimento; (v)
          Eventuais interrupções necessárias, no decorrer do dia, para
          manutenção e ajustes técnicos de curta duração, que serão informadas
          se o caso (vi) Medidas de segurança, prevenção e preservação em
          caráter de emergência, por força de invasão ou ameaça à segurança do
          servidor, das informações; (vii) Eventual suspensão da prestação dos
          serviços por descumprimento da CONTRATADA ou determinação de
          autoridades; (viii) Serviços não contratados ou alheios aos serviços
          detalhados.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          5.4. Eventuais suspensões pelas causas elencadas na cláusula anterior
          não poderão ser computadas para fins de verificação dos níveis de
          serviço garantidos.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          5.5. Excetuadas as hipóteses listadas no item 5.3., caso a CONTRATADA
          falhe em atingir os níveis de serviço acordados - e caso o tempo de
          disponibilidade do Software no mês civil fique entre 85% a 89,9%, a
          CONTRATANTE receberá um desconto de 10% sobre o valor de 1 (uma)
          mensalidade, em um tempo de até 2 meses subsequentes ao
          descumprimento- para que haja tempo de cômputo da falha e do desconto.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          5.6. Caso o tempo de disponibilidade do Software seja menor do que 90%
          do tempo no mês civil, fica facultado à CONTRATANTE a rescisão do
          presente Contrato, sem penalidades.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          5.7. A rescisão do presente instrumento por inadimplemento da
          CONTRATADA dará à CONTRATANTE, o direito de recebimento de perdas e
          danos pré-fixados em 10% (dez por cento) do valor das mensalidades
          efetivamente pagas pelo CONTRATANTE nos últimos 3 (três) meses, com
          expressa renúncia de parte da CONTRATANTE a indenização complementar a
          qualquer título, relacionada à falha dos serviços, ou sob outra
          justificativa, qualquer que seja. Serviços opcionais e/ou custos de
          utilizações excedentes não integrarão a base de cálculo para o
          referido desconto.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          5.8. Para fins de exigibilidade do desconto, a comunicação de
          descumprimento do SLA deverá necessariamente ser formalizada pela
          CONTRATANTE no prazo máximo de 15 (quinze) dias da comprovada
          constatação desse descumprimento, acompanhada de comprovação da falha.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          5.9. Os “backups” diários dos arquivos de mídia, logs e bases de dados
          do servidor, realizados a título de precaução contra eventuais
          contingências pela CONTRATADA serão utilizados para fins de
          restauração de arquivos em caso de eventual ocorrência, sempre na
          última versão colhida, em até 24 horas após a correção da falha.
        </Text>

        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          6. OBRIGAÇÕES DAS PARTES
        </Heading>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          6.1. A CONTRATADA deve sempre atuar de forma preventiva na manutenção
          e correção das falhas de serviço, reparando-os no menor tempo possível
          e sempre de forma a minimizar impactos ao usuário CONTRATANTE.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          6.2. Obriga-se a CONTRATADA a prestar serviços de suporte técnico,
          além de disponibilizar de forma automática as atualizações do Software
          DIRECT MESSAGE HEALTH.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          6.3. Responsabiliza-se o CONTRATANTE pela perfeita manutenção dos
          equipamentos necessários para o acesso do conteúdo desejado (internet
          em velocidade adequada, navegadores oficiais e atualizados, cabos,
          upgrade de hardware e sistema operacional - se necessário, de forma a
          viabilizar adequadamente a utilização dos serviços da CONTRATADA.
        </Text>

        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          7. VIGÊNCIA E RESCISÃO
        </Heading>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          7.1. O presente instrumento terá vigência de 12 meses, com renovação
          automática por mais 12 meses na ausência de denúncia por qualquer das
          partes. A rescisão antecipada do presente licenciamento pela
          CONTRATANTE acarretará no pagamento de uma multa no montante de 50% do
          valor residual do contrato.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          7.2. O presente contrato poderá ser rescindido por qualquer das
          partes, mediante aviso prévio por escrito, com 30 (trinta) dias de
          antecedência.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          7.3. O presente instrumento é passível de rescisão pela parte
          inocente, sem que a parte inadimplente tenha direito a indenização à
          ocorrência de qualquer das hipóteses a seguir: (i) infração ou
          descumprimento contratual pela outra parte; (ii) decretação de
          falência ou dissolução da outra parte; (iii) caso fortuito ou força
          maior.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          7.4. O presente contrato poderá ser rescindido de pleno direito após
          60 (sessenta) dias de atraso no pagamento.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          7.5. Nos termos do artigo 49 do Código de Defesa do Consumidor a
          CONTRATANTE poderá cancelar o contrato no prazo de 30 dias a contar de
          sua aceitação ou do ato de recebimento do serviço, com devolução
          integral do valor. Após este período, não serão devolvidos quaisquer
          valores pagos pelos serviços.
        </Text>

        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          8. DISPOSIÇÕES GERAIS
        </Heading>

        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          8.1. Confidencialidade - As PARTES se obrigam a manter,
          reciprocamente, sigilo de todas e quaisquer informações sigilosas a
          que tenham tido acesso em razão deste contrato.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          8.2. O presente Contrato e todos seus anexos, incluindo mas não se
          limitando a propostas comerciais e e-mails, representam o acordo
          completo entre as PARTES e substitui todos os contratos, propostas ou
          declarações, orais ou escritos, em relação ao seu objeto.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          8.3. As PARTES neste ato assumem que elas têm plenos poderes e
          autorização societários para celebrar este Contrato e cumprir suas
          obrigações segundo este instrumento.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          8.4. Os direitos e obrigações decorrentes do presente Contrato não
          poderão ser cedidos ou transferidos a terceiros, parcial ou
          totalmente, a qualquer título, por qualquer das partes, sem a prévia e
          expressa anuência por escrito da outra.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          8.5. O não exercício ou atraso no exercício, por qualquer das partes,
          dos direitos decorrentes deste Contrato será interpretada como mera
          liberalidade e não constituirá renúncia ou novação de tais direitos,
          tampouco prejudicará o seu eventual e futuro exercício. A eventual
          nulidade ou inexequibilidade, total ou parcial, de qualquer das
          disposições deste Contrato não implicará na nulidade ou perda da
          eficácia das demais.
        </Text>

        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          9. DA LEI GERAL DE PROTEÇÃO DE DADOS
        </Heading>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          9.1 Sem prejuízo das demais obrigações previstas no presente Contrato,
          a CONTRATADA obriga-se a cumprir rigorosamente o disposto na
          legislação aplicável em matéria de tratamento de dados pessoais,
          especialmente:
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
          paddingLeft="10"
        >
          a) realizar o tratamento de dados pessoais nos limites e para as
          finalidades permitidas por este Contrato;
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
          paddingLeft="10"
        >
          b) disponibilizar a CONTRATANTE as informações necessárias para
          demonstrar o cumprimento das obrigações decorrentes da LGPD, das
          normas aplicáveis à atividade e acordadas no presente Contrato;
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
          paddingLeft="10"
        >
          c) prestar assistência a CONTRATANTE e disponibilizar aos titulares
          informações sobre os seus dados pessoais, nos termos da LGPD e demais
          normas de proteção de dados;
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
          paddingLeft="10"
        >
          d) garantir que as pessoas autorizadas a tratar dados pessoais
          assumiram um compromisso de confidencialidade ou estão sujeitas a
          adequadas obrigações legais de confidencialidade;
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
          paddingLeft="10"
        >
          e) manter a CONTRATANTE informada em relação ao tratamento de dados
          pessoais, obrigando-se a comunicar de imediato qualquer situação que
          possa afetar o tratamento dos dados em causa ou que de algum modo
          possa dar origem ao incumprimento das disposições legais em matéria de
          proteção de dados pessoais;
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
          paddingLeft="10"
        >
          f) não compartilhar quaisquer dados pessoais tratados no âmbito deste
          Contrato com terceiros, sem ciência prévia e autorização por parte da
          CONTRATANTE, ressalvadas as hipóteses em que se fizerem necessários
          para o cumprimento do Contrato ou de obrigações legais e/o judiciais,
          mantendo-se, integralmente, responsável perante a CONTRATANTE pelas
          obrigações previstas neste Contrato, inclusive no contexto de eventual
          tratamento de dados pessoais realizados por terceiros em seu nome,
          devendo impor aos terceiros por ela selecionados condições de proteção
          de dados pessoais e segurança da informação que sejam no mínimo
          equivalentes às presentes neste Contrato;
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
          paddingLeft="10"
        >
          g) excluir, restituir ou eliminar os Dados Pessoais tratados no âmbito
          deste Contrato após serem cumpridas as finalidades de tratamento dos
          Dados Pessoais previstas sob este Contrato, de acordo com o que dispõe
          a LGPD.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          9.2. Caso exista modificação dos textos legais acima indicados ou de
          qualquer outro, de forma que exija modificações na estrutura do escopo
          deste Contrato ou na execução das atividades ligadas a este Contrato,
          a CONTRATADA se adequar às condições vigentes.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          9.3. Se houver alguma disposição que impeça a continuidade do Contrato
          conforme as disposições acordadas, a CONTRATANTE poderá resolvê-lo sem
          qualquer penalidade, apurando-se os serviços prestados até a data da
          rescisão e correspondentes valores devidos.
        </Text>

        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          10. DO FORO
        </Heading>

        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          10.1. As partes elegem o Foro da Comarca de São Paulo, como competente
          para dirimir eventuais litígios oriundos do presente instrumento,
          renunciando a qualquer outro, por mais privilegiado que seja.
        </Text>
      </Box>
    </Flex>
  );
}
