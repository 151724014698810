import { useMutation, useQuery } from 'react-query';

import { api } from '@/lib';
import { useUserStore } from '@/stores';
import { useInstanciaStore } from '@/stores/instancias';

type UnidadeAPIResponse = {
  data: {
    id: string;
    descricao: string;
    instanciaSaudeId: string;
    cnpj: string;
    endereco: object;
    enderecoId: string;
  }[];
};

async function unidadeIdRequest(id: any): Promise<UnidadeAPIResponse> {
  const response = await api.get(`/instancia/unidade-get-id?id=${id}`);
  return response.data;
}

async function unidadeRequest(id: any): Promise<any> {
  const response = await api.get(
    `/instancia/unidades-instancia?instanciaId=${id}`,
  );

  const uniFormat = response.data.data.sort(
    (a: { descricao: number }, b: { descricao: number }) => {
      if (a.descricao > b.descricao) {
        return 1;
      }
      if (a.descricao < b.descricao) {
        return -1;
      }
      return 0;
    },
  );

  return { ...response.data, data: uniFormat };
}

export function useUnidadesQuery() {
  const user = useUserStore(state => state.user);
  const id = useInstanciaStore(state => state.instancia?.id);
  const unidades = user?.unidadesId;
  const master = user?.isMaster;

  const query = useQuery(['unidade', id], () => unidadeRequest(id), {
    refetchInterval: false,
    select: originalResponse => {
      if (!master) {
        return originalResponse.data
          .filter((i: any) => {
            return unidades?.filter(c => c === i.id).length;
          })
          .map((uni: any) => {
            return {
              label: uni.descricao,
              value: uni.id,
              instance: uni.instanciaSaudeId,
              cnpj: uni.cnpj,
              endereco: uni.endereco,
              enderecoId: uni.enderecoId,
            };
          });
      }
      return originalResponse.data.map((uni: any) => {
        return {
          label: uni.descricao,
          value: uni.id,
          instance: uni.instanciaSaudeId,
          cnpj: uni.cnpj,
          endereco: uni.endereco,
          enderecoId: uni.enderecoId,
        };
      });
    },
  });

  return query;
}

export function useUnidadesIdQuery(id: string) {
  const user = useUserStore(state => state.user);
  const unidades = user?.unidadesId;
  const master = user?.isMaster;

  const query = useQuery(['unidade', id], () => unidadeRequest(id), {
    refetchInterval: false,
    select: originalResponse => {
      if (!master) {
        return originalResponse.data
          .filter((i: any) => {
            return unidades?.filter(c => c === i.id).length;
          })
          .map((uni: any) => {
            return {
              label: uni.descricao,
              value: uni.id,
              instance: uni.instanciaSaudeId,
              cnpj: uni.cnpj,
              endereco: uni.endereco,
              enderecoId: uni.enderecoId,
            };
          });
      }
      return originalResponse.data.map((uni: any) => {
        return {
          label: uni.descricao,
          value: uni.id,
          instance: uni.instanciaSaudeId,
          cnpj: uni.cnpj,
          endereco: uni.endereco,
          enderecoId: uni.enderecoId,
        };
      });
    },
  });

  return query;
}

export function useUnidadesMutation() {
  const mutation = useMutation((id: string) => unidadeRequest(id), {
    onSuccess: originalResponse => {},
    onError: error => {},
  });

  return mutation;
}
