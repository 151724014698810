import { useMutation, useQuery } from 'react-query';

import { api } from '@/lib';
import { useProfessionalStore, useUserStore } from '@/stores';
import { format, parseISO } from 'date-fns';
import { formatDate } from '@/utils';

type PatientList = {
  idPaciente: string;
  id: string;
  nomePaciente: string;
  nascimento: string;
  celular: string;
  email: string;
};

type Payload = {
  idMedico: string | undefined;
};

async function getPatientList(id: any): Promise<PatientList[]> {
  try {
    const response = await api.get('/anamnese/obter-paginado-novo', {
      params: { idUsuarioProfissional: id },
    });

    const newData = response.data.data
      // .filter((consulta: any) => consulta.anamnesias.length > 0)
      .map((paciente: any) => {
        const nascimento = paciente.dataNascimento.replace(/-/g, '/');
        const d = new Date();
        const yearNow = d.getFullYear();
        const monthNow = d.getMonth() + 1;
        const dayNow = d.getDate();

        const dayBirth = paciente.dataNascimento.slice(0, 2);
        const monthBirth = paciente.dataNascimento.slice(3, 5);
        const yearBirth = paciente.dataNascimento.slice(6, 11);

        let age = yearNow - yearBirth;
        if (
          monthNow < monthBirth ||
          (monthNow === monthBirth && dayNow < dayBirth)
        ) {
          age -= 1;
        }

        let anamneseFormat = [];
        let ultimaConsulta = 'Não realizada';

        if (paciente.anamnesias.length > 0) {
          ultimaConsulta = format(
            parseISO(paciente.anamnesias[0].dataAtendimento),
            'dd/MM/yyyy',
          );
          anamneseFormat = paciente.anamnesias.map((anaminese: any) => ({
            consultaId: anaminese.consultaId,
            data: format(parseISO(anaminese.dataAtendimento), 'dd/MM/yyyy'),
            hora: formatDate(parseISO(anaminese.dataAtendimento), 'HH:mm'),
            tipo: anaminese.tipoConsulta ? 'ON-LINE' : 'PRESENCIAL',
            duracao: anaminese.duracao,
            evolucao: anaminese.evolucao,
            alergica: anaminese.alergica || null,
            antecedentesPessoais: anaminese.antecedentesPessoais || null,
            antecedentesFamiliares: anaminese.antecedentesFamiliares || null,
            conduta: anaminese.conduta,
            CID: anaminese.cid || null,
            orientacoes: anaminese.orientacao,
          }));
        }

        return {
          key: paciente.id,
          idPaciente: paciente.id,
          nomePaciente: paciente.nome,
          idade: age,
          sexo: paciente.sexo,
          email: paciente.email,
          celular: paciente.celular,
          cpf: paciente.cpf,
          nascimento,
          ultimaConsulta,
          urlPhoto:
            paciente.urlFoto ||
            'https://cdn.pixabay.com/photo/2014/04/03/10/32/businessman-310819_960_720.png',
          anamineses: anamneseFormat,
        };
      });

    return newData;
  } catch {
    return [];
  }
}

export function usePatientTableQuery() {
  const mutation = useMutation((id: any) => getPatientList(id));

  return mutation;
}
