import { Flex, Tag, TagCloseButton, TagLabel } from '@chakra-ui/react';

import { Councils } from '@/features/registers';

interface CouncilProps {
  councils: Councils[];
  onDelete: (id: string) => void;
}

export function CouncilsList({ councils, onDelete }: CouncilProps) {
  return (
    <Flex w="100%" gap="2" wrap="wrap" align="flex-start" justify="flex-start">
      {councils.map(coun => (
        <Tag
          key={coun.conselhoId}
          size="sm"
          variant="solid"
          colorScheme="brand-green"
          borderRadius="full"
        >
          <TagLabel>{coun.conselho.nome}</TagLabel>
          <TagCloseButton onClick={() => onDelete(coun.conselhoId)} />
        </Tag>
      ))}
    </Flex>
  );
}
