import { Input, InputGroup } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useAttendanceTableQuery } from '../../hooks';

export function DateInput({ setDate, date }: any) {
  const { refetch } = useAttendanceTableQuery();

  const selectDate = (e: any) => {
    setDate(e.target.value);
  };

  useEffect(() => {
    refetch();
  }, [date]);

  return (
    <InputGroup maxW={['100%', '300px']} w="100%">
      <Input
        placeholder="Data Inicial"
        size="lg"
        variant="filled"
        backgroundColor="#ffffff"
        type="date"
        h="52px"
        borderRadius="14px"
        bg="white"
        color="brand-dark.500"
        _placeholder={{ color: '#bfbfbf' }}
        _hover={{ bg: 'brand-dark.100' }}
        onChange={e => selectDate(e)}
      />
    </InputGroup>
  );
}
