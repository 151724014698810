import { Tr, Td, Icon, Stack, Heading, Flex } from '@chakra-ui/react';
import { FiEye, FiEdit } from 'react-icons/fi';
import { MdDeleteOutline } from 'react-icons/md';
import { useEffect, useState } from 'react';

import { ProfessionalProps } from '@/features/registers/types';
import {
  TopSection,
  Tables,
  GenericModal,
  EditProfessionalForm,
  ViewProfessionalInfo,
} from '@/features/registers';
import { useGendersQuery, useProfessionalsQuery } from '@/hooks';
import { useUnidadeStore, useUserStore } from '@/stores';
import { api } from '@/lib';
import { PaginationButton } from '../components/Pagination/PaginationButton';
import { DeleteUserModal } from '../components/DeleteModals/DeleteUserModal';

export function ProfessionalsListPage() {
  const [professional, setProfessional] = useState<any>();
  const [isEditOpen, setIsEditOpen] = useState<boolean>(false);
  const [isDeleteOpen, setIsDeleteOpen] = useState<boolean>(false);
  const [isViewOpen, setIsViewOpen] = useState<boolean>(false);
  const user = useUserStore(state => state.user);
  const [selectPage, setSelectPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const unidadeId = useUnidadeStore(state => state.unidade?.id);

  const { data: genders = [] } = useGendersQuery();

  // const { data } = useProfessionalListQuery();
  const [text, setText] = useState('');
  const [data, setData] = useState<any>([]);
  const [head, setHead] = useState<string[]>([]);
  const [search, setSearch] = useState<any>([]);

  const onCloseModal = () => setIsEditOpen(false);
  const onCloseDeleteModal = () => setIsDeleteOpen(false);
  const onCloseViewModal = () => setIsViewOpen(false);

  const onOpenEditModal = (selectedProfessional: ProfessionalProps) => {
    setProfessional(selectedProfessional);
    setIsEditOpen(true);
  };

  const onDeleteEditModal = (selectedProfessional: ProfessionalProps) => {
    setProfessional(selectedProfessional);
    setIsDeleteOpen(true);
  };

  const onOpenViewModal = (selectedProfessional: ProfessionalProps) => {
    const gender = genders?.filter(
      item => item.value === selectedProfessional.generoId,
    );
    setProfessional({ ...selectedProfessional, gender });
    setIsViewOpen(true);
  };

  useEffect(() => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'medico' &&
      user?.type !== 'paciente' &&
      user?.type !== 'assistente'
    ) {
      setHead(['EDITAR', 'DELETAR']);
    }
  }, []);

  const showEditBtn = (professionalData: ProfessionalProps) => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'medico' &&
      user?.type !== 'paciente' &&
      user?.type !== 'assistente'
    ) {
      return (
        <Icon
          as={FiEdit}
          onClick={() => {
            if (professionalData.primeiroAcesso) return;
            onOpenEditModal(professionalData);
          }}
          cursor="pointer"
          color={
            professionalData.primeiroAcesso
              ? 'brand-dark.100'
              : 'brand-dark.200'
          }
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  const showDeleteBtn = (professionalData: ProfessionalProps) => {
    if (
      user?.type !== 'secretario' &&
      user?.type !== 'medico' &&
      user?.type !== 'paciente' &&
      user?.type !== 'assistente'
    ) {
      return (
        <Icon
          as={MdDeleteOutline}
          onClick={() => {
            if (professionalData.primeiroAcesso && user?.type !== 'gestor')
              return;
            onDeleteEditModal(professionalData);
          }}
          cursor="pointer"
          color={
            professionalData.primeiroAcesso && user?.type !== 'gestor'
              ? 'brand-dark.100'
              : 'brand-dark.200'
          }
          fontSize="20px"
          fontWeight="400"
          marginRight="15"
        />
      );
    }

    return null;
  };

  const setDataContent = async (back = false, next = false) => {
    const params = {
      id: unidadeId,
      pageSize: 5,
      pageNumber:
        !back && !next
          ? 1
          : back && selectPage > 1
          ? selectPage - 1
          : next
          ? selectPage + 1
          : selectPage,
    };

    const { data: total } = await api.get(
      `/instancia/unidade-listar-profssionais?id=${unidadeId}`,
    );
    setTotalPage(Math.ceil(total.data.length / 5)); // mock solicitar total page no endpoint

    const response = await api.get(`/instancia/unidade-listar-profssionais`, {
      params,
    });

    if (response.data.data.length > 0) {
      const resp = response.data.data.map((respo: any) => {
        return {
          id: respo.id,
          codigoVinculo: respo.codigoVinculo,
          codigoUsuario: respo.usuarioProfissionalSaude?.codigoUsuario,
          primeiroAcesso: respo.usuarioProfissionalSaude?.primeiroAcesso,
          bairro: respo.usuarioProfissionalSaude?.pessoa?.endereco?.bairro,
          celular: respo.usuarioProfissionalSaude?.pessoa?.celular,
          cep: respo.usuarioProfissionalSaude?.pessoa?.endereco?.cep,
          cidade: respo.usuarioProfissionalSaude?.pessoa?.endereco?.cidade,
          complemento:
            respo.usuarioProfissionalSaude?.pessoa?.endereco?.complemento,
          cpf: respo.usuarioProfissionalSaude?.pessoa?.cpf,
          dataNascimento:
            respo.usuarioProfissionalSaude?.pessoa?.dataNascimento,
          email: respo.usuarioProfissionalSaude?.email,
          dataCriacao: respo.usuarioProfissionalSaude?.dataCriacao,
          dataEdicao: respo.usuarioProfissionalSaude?.dataEdicao,
          endereco:
            respo.usuarioProfissionalSaude?.pessoa?.endereco?.logradouro,
          estado: respo.usuarioProfissionalSaude?.pessoa?.endereco?.estado,
          generoId: respo.usuarioProfissionalSaude?.pessoa?.sexoId,
          nome: respo.usuarioProfissionalSaude?.pessoa?.nome,
          numero: respo.usuarioProfissionalSaude?.pessoa?.endereco?.numero,
          userName: respo.usuarioProfissionalSaude?.userName,
          usuarioProfissionalSaudeId: respo.usuarioProfissionalSaudeId,
          especialidades: respo.profissionalSaudeEspecialidade,
          conselhos: respo.profissionalSaudeConselhos,
        };
      });

      setData(resp);
      setSearch(resp);
      if (back) {
        setSelectPage(selectPage === 1 ? 1 : selectPage - 1);
      } else if (next) {
        setSelectPage(selectPage + 1);
      } else {
        setSelectPage(1);
      }
    } else if (!back && !next) {
      setData([]);
      setSearch([]);
    }
  };

  useEffect(() => {
    setSelectPage(1);
    setDataContent(false, false);
  }, []);

  useEffect(() => {
    if (!isEditOpen && !isDeleteOpen) setDataContent();
  }, [isEditOpen, isDeleteOpen, unidadeId]);

  useEffect(() => {
    if (data) {
      const lowerSearch = text.toLowerCase();

      const result = data.filter((d: any) => {
        return d.nome.toLowerCase().includes(lowerSearch);
      });

      setSearch(result);
    }
  }, [text]);

  return (
    <>
      <TopSection
        title="Profissionais Cadastrados"
        search={text}
        onChange={(str: any) => setText(str)}
      />

      <Tables
        head={[
          'Nome do Profissional',
          'Celular',
          'E-mail',
          'Status',
          'Visualizar',
          ...head,
        ]}
      >
        {search?.map((professionalData: ProfessionalProps) => (
          <Tr key={professionalData.usuarioProfissionalSaudeId}>
            <Td>{professionalData.nome}</Td>
            <Td>{professionalData.celular}</Td>
            <Td>{professionalData.email}</Td>
            <Td style={{ color: professionalData.primeiroAcesso && 'red' }}>
              {professionalData.primeiroAcesso ? 'PENDENTE' : 'VALIDADO'}
            </Td>
            <Td>
              <Icon
                as={FiEye}
                onClick={() => onOpenViewModal(professionalData)}
                cursor="pointer"
                color="brand-dark.200"
                fontSize="20px"
                fontWeight="400"
                marginRight="15"
              />
            </Td>
            <Td>{showEditBtn(professionalData)}</Td>
            <Td>{showDeleteBtn(professionalData)}</Td>
          </Tr>
        ))}
      </Tables>
      {/* <Pagination
        page={data?.page || 0}
        totalPage={data?.totalPages || 0}
        // totalRegisters={0}
        totalRegisters={data?.data.length}
      /> */}

      <Flex
        direction={['column', 'column', 'row']}
        w="100%"
        align="center"
        justify="space-between"
      >
        <Heading
          size="xs"
          w="100%"
          mb={['4', '4', '0']}
          color="#707070"
          fontWeight="medium"
        >
          {/* Página {selectPage} de {dataPatients?.data.length} registros */}
          Página {selectPage}/{totalPage}
        </Heading>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          w="100%"
          justify="flex-end"
        >
          <PaginationButton
            text="Voltar"
            onClick={() => setDataContent(true, false)}
          />
          {/* <PaginationButton text="Ultimo" onClick={() => setSelectPage(1)} /> */}
          {/* <NumbersPage
            onClick={value => setSelectPage(value)}
            page={selectPage}
            totalPage={dataPatients?.totalPages || 0}
          /> */}
          <PaginationButton
            text="Proximo"
            onClick={() => setDataContent(false, true)}
          />
          {/* <PaginationButton
            text="Ultimo"
            onClick={() => setSelectPage(dataPatients?.totalPages || 0)}
          /> */}
        </Stack>
      </Flex>

      <GenericModal
        open={isEditOpen}
        textAlign="center"
        title="Editar Profissional"
        onClose={onCloseModal}
        closeOnOverlayClick={false}
      >
        <EditProfessionalForm
          professional={professional}
          onCancel={onCloseModal}
        />
      </GenericModal>
      <GenericModal
        open={isViewOpen}
        title={`Profissional: ${professional && professional.nome}`}
        onClose={onCloseViewModal}
      >
        <ViewProfessionalInfo professional={professional} />
      </GenericModal>
      <GenericModal
        open={isDeleteOpen}
        textAlign="center"
        title={`Deletar Profissional: ${professional && professional.nome}`}
        onClose={onCloseDeleteModal}
      >
        <DeleteUserModal
          user={professional}
          type="professional"
          onCancel={onCloseDeleteModal}
        />
      </GenericModal>
    </>
  );
}
