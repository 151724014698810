import { Box, Flex, Heading, Link, Stack, Text } from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';

export function BodySection({ filter, setFilter }: any) {
  return (
    <Flex
      direction={['column', 'column', 'row']}
      align="center"
      justify="center"
    >
      <Box mt="4" width="90%">
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          paddingTop="10"
          align="justify"
        >
          {'  '}Somos uma empresa de tecnologia em saúde. Sua privacidade é
          importante para nós, da DM HEALTH. Por favor, reserve um tempo para
          conhecer nossas práticas através dessa página ou dos nossos{' '}
          <Link as={ReactRouterLink} to="/terms" fontSize="lg" color="gray.500">
            Termos de Uso
          </Link>
          .
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          Com a finalidade de assegurar aos usuários o compromisso com a
          segurança e integridade dos dados, a <b>DM HEALTHCARE LTDA.</b>{' '}
          empresa de direito privado, com sede na Rua Piauí, n° 1246, sala 41-
          Higienópolis, CEP 01241-000, São Paulo, SP, inscrita no CNPJ sob nº
          32.276.063/0001-61, neste ato representada na forma de seu contrato
          social (“DM Health”) criou sua Política de Privacidade, garantindo a
          transparência no tratamento das informações disponibilizadas pelos
          usuários. Ao baixar o app em seu celular ou tablet, utilizando nossos
          serviços e navegando em nossa plataforma, seus dados são coletados e
          tratados, com o que você concorda expressamente, para as finalidades
          específicas a seguir.{' '}
        </Text>

        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          Coleta De Informação Pessoal
        </Heading>

        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          Para que a DM Health cumpra com a legislação vigente, as informações
          pessoais imputadas, como RG, CPF, foto, endereço eletrônico - e
          outras, são necessárias para fins de cadastro e sua identificação como
          usuário, além de serem imprescindíveis para a prestação de serviços e
          envio de informações diversas, atualizações de termos de uso e outras
          igualmente importantes, de forma segura e eficiente. Da mesma forma, a
          coleta de dados de localização nos auxilia a implementar as
          ferramentas de segurança, identificando eventual uso indevido por
          usuário não autorizado - em caso de perda, furto ou roubo de seu
          celular, permitindo à DM Health prestar a você os melhores serviços,
          de forma segura e garantindo a integridade dos dados. Você pode
          revogar seu consentimento a qualquer momento por meio dos canais
          diretos disponíveis no app ou plataforma da DM Health.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          A DM irá utilizar essas informações para processar e completar seu
          acesso ao app. Tais dados poderão ser armazenados, por tanto tempo
          quanto durarem os serviços prestados. Dessa maneira, de acordo com o
          art. 7º, inciso VIII da Lei 12.965/2014 (Marco Civil da Internet),
          poderemos enviar mensagens. Os dados pessoais são utilizados para
          identificá-lo e autenticá-lo como usuário a cada vez que você realizar
          o login. Os dados podem também ser utilizados para mantê-lo informado
          sobre novas políticas, atualizações na plataforma ou app, novos
          produtos, serviços e funcionalidades disponíveis, bem como
          instabilidades de sistema, problemas técnicos ou de segurança,
          eventuais falhas de identificação ou autenticação e ainda coleta de
          pesquisa de opinião visando a constante melhoria -dos serviços, de
          acordo com seu relato de experiência.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          Os dados pessoais são utilizados para identificá-lo e autenticá-lo
          como usuário a cada vez que você realizar o login. Os dados podem
          também ser utilizados para mantê-lo informado sobre novas políticas,
          atualizações na plataforma ou app, novos produtos, serviços e
          funcionalidades disponíveis, bem como instabilidades de sistema,
          problemas técnicos ou de segurança, eventuais falhas de identificação
          ou autenticação e ainda coleta de pesquisa de opinião visando a
          constante melhoria -dos serviços, de acordo com seu relato de
          experiência.
        </Text>

        <Heading
          size="md"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="10"
        >
          Tratamento de Dados Sensíveis e Prevenção de Fraude
        </Heading>

        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          Prestando um serviço confiável e seguro, a DM Health utiliza inúmeras
          medidas de controle de segurança, como criptografia de dados padrão da
          Internet, além de sistemas de proteção da integridade de seus dados,
          na coleta e armazenamento de suas informações. A DM pode fazer
          parcerias com outras prestadoras de serviços de saúde, como convênios,
          home-cares, consultórios, hospitais e outros, que poderão ter acesso
          aos dados para a específica finalidade da prestação de serviços
          contratada, em plena observância a todos os dispositivos legais de
          proteção e armazenamento de dados vigentes.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          Com o objetivo de colaborar para o sistema de controles de segurança
          de armazenamento e tratamento de dados da DM Health, você autoriza a
          DM Health a compartilhar seus dados pessoais, histórico médico,
          informações de diagnóstico e tratamento, dentre outros não
          especificamente inclusos aqui – com instituições de saúde pública ou
          privada, bem como autoridades policiais, com a finalidade de eventual
          apuração de fraude.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          O reconhecimento facial, dentre os dados coletados, será tratado como
          dado sensível, objetivando a prevenção de fraude, autenticidade do
          usuário e garantindo a segurança dos serviços prestados, além de
          cumprir com exigências da legislação vigente. A DM Health pode
          registrar e enviar a você, através de e-mails, pop ups,
          correspondência e outros meios de comunicação – por este termo
          autorizados e através dos dados por você fornecidos- informações
          financeiras, sobre mensalidades pagas, a vencer, em atraso, descontos
          e outras da mesma natureza, de acordo com seu perfil de serviços
          contratados. Você autoriza a DM Health a consultar suas informações de
          prontuários médicos junto a base de dados de saúde pública e privada,
          instituições de crédito nas ferramentas de proteção ao crédito
          disponíveis no mercado como SPC, Serasa e outras ligadas e autorizadas
          pelo Banco Central, para verificação de histórico de crédito, Cadastro
          Positivo e outras aqui relacionadas.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          Visando sua melhor experiência como usuário e a constante melhoria e
          eficiência dos serviços, você expressamente autoriza a DM Health a
          compartilhar parte das informações coletadas com terceiros, para a
          perfeita implementação e manutenção da qualidade dos serviços
          contratados, como autenticação e identificação de usuário, proteção
          das informações médicas, customização de perfis e implementação de
          novos serviços e produtos disponíveis, além de corroborar com a
          prevenção e combate a fraudes, problemas técnicos, novos protocolos de
          segurança nos processos de identificação, instabilidades de sistema,
          dentre outros. Uma vez coletados, os dados poderão ser anonimizados,
          mantidos e utilizados por tempo indeterminado pela DM Health, para
          fins de criação, monitoramento e controles estatísticos, sem qualquer
          identificação ou exposição do usuário.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          Com o intuito de garantir a segurança total garantia dos serviços
          prestados pela DM Health o armazenamento e compartilhamento seguro de
          informações poderá ocorrer, com a finalidade específica de observar a
          legislação vigente e cumprir com obrigações e determinações legais.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          A atualização, correção e revisão dos dados informados poderá ocorrer
          a qualquer tempo, através de uma das ferramentas de atendimento
          disponíveis no app ou plataforma.
        </Text>
        <Text
          fontSize="lg"
          w="100%"
          color="brand-dark.300"
          fontFamily="Rubik"
          paddingBottom="19"
          align="justify"
        >
          Terminada a prestação de serviços por qualquer motivo, a DM Health -
          com o fim de cumprir com obrigações legais a que está sujeita pela
          legislação vigente aplicável, armazenará, pelo período e nos termos
          exigidos, certos dados conforme condições determinadas por lei.
        </Text>
      </Box>
    </Flex>
  );
}
