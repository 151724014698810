import { differenceInMonths } from 'date-fns';

export function getMonthsDiff(startDate: Date, endDate: Date): number {
  if (!startDate || !endDate) {
    return 0;
  }

  const df = differenceInMonths(endDate, startDate);

  return df;
}
