import { useEffect, useState } from 'react';
import {
  Box,
  Circle,
  Flex,
  Icon,
  Link,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';
import {
  IoPersonAddOutline,
  IoPeopleOutline,
  IoPersonRemoveOutline,
  IoCalendarClearOutline,
  IoTimeOutline,
} from 'react-icons/io5';
import { TextSub } from '@/features/historic/components/TextBase/TextBase';
import { ActivityPatientTypes, alertColorBox } from '@/features/start';
import { formatDate } from '@/utils';
import { PatientInfoModal } from '../ScheduleSection/PatientInfoModal';

interface ActivityCardPatientProps {
  activity: {
    tipoAtividade: ActivityPatientTypes;
    infos: any;
    dataAtualizacao: Date;
    corAlerta?: string;
    setOpenModal: () => void;
  };
}

const icons: Record<ActivityPatientTypes, IconType> = {
  consultasFuturas: IoPersonRemoveOutline,
  numeroDeConsultas: IoPeopleOutline,
  cidAtual: IoPersonAddOutline,
  medicacoesAtuais: IoCalendarClearOutline,
  monitoramentoAtual: IoTimeOutline,
};

const titles: Record<ActivityPatientTypes, string> = {
  consultasFuturas: 'Próxima consulta ',
  numeroDeConsultas: 'Número de consultas',
  cidAtual: 'CID atual',
  medicacoesAtuais: 'Medicações atual',
  monitoramentoAtual: 'Monitoramento atual',
};

const baseUrlMaps = 'https://www.google.com/maps?q=';

export function ActivityCardPatient({ activity }: ActivityCardPatientProps) {
  const { dataAtualizacao, infos, tipoAtividade, corAlerta, setOpenModal } =
    activity;
  const [urlMap, setUrlMap] = useState<string>('');

  const formattedLastUpdateDate = formatDate(dataAtualizacao, "P 'às' HH:mm");

  useEffect(() => {
    if (infos.length > 0 && infos[0].endereco) {
      setUrlMap(
        `${infos[0].endereco.logradouro} ${infos[0].endereco.numero} ${infos[0].endereco.cidade} ${infos[0].endereco.estado} ${infos[0].endereco.cep} Brasil`.replace(
          /\s/g,
          '+',
        ),
      );
    }
  }, [activity]);

  return (
    <Box
      onClick={setOpenModal}
      height="100%"
      as="article"
      cursor="pointer"
      p="5"
      borderRadius="20px"
      bg={
        tipoAtividade === 'consultasFuturas'
          ? 'brand-orange.50'
          : 'brand-green.50'
      }
      position="relative"
    >
      <Circle
        size="40px"
        bg="brand-dark.100"
        position="absolute"
        top="15px"
        right="15px"
      >
        {/* <Icon
            as={icons[tipoAtividade]}
            fontSize="20px"
            color={
              tipoAtividade === 'numeroDeConsultas'
                ? 'brand-orange.300'
                : 'brand-green.300'
            }
          /> */}
      </Circle>

      <Text color="brand-dark.500" fontSize="2xl" fontWeight="medium">
        {titles[tipoAtividade]}
      </Text>

      {tipoAtividade === 'consultasFuturas' ? (
        infos.length > 0 ? (
          <Box>
            <Text
              mt="3"
              color="brand-dark.200"
              fontSize="xl"
              fontWeight="medium"
            >
              {formatDate(infos[0].date, "P 'às' HH:mm")}
            </Text>
            <Text
              color="brand-dark.200"
              fontSize="md"
              fontWeight="medium"
              mt={3}
              mb={3}
            >
              {infos[0].tipoConsulta === 0 ? 'PRESENCIAL' : 'ONLINE'}
            </Text>
            <Text color="brand-dark.200" fontSize="md" fontWeight="medium">
              Profissional: {infos[0].profissional}
            </Text>
            <Text color="brand-dark.200" fontSize="md" fontWeight="medium">
              Unidade de Saúde: {infos[0].unidadeSaude.descricao}
            </Text>
            {((infos[0].tipoConsulta === 0 && infos[0].endereco) ||
              infos[0].tipoConsulta === 1) && (
              <Link
                href={
                  infos[0].tipoConsulta === 0
                    ? baseUrlMaps + urlMap
                    : infos[0].urlVideo
                }
                target="_blank"
              >
                <Text
                  mb="8"
                  color="brand-dark.200"
                  fontSize="md"
                  fontWeight="medium"
                  textDecoration="underline"
                >
                  {infos[0].tipoConsulta === 0
                    ? `Local: ${infos[0].endereco.logradouro}, ${infos[0].endereco.numero}, ${infos[0].endereco.cidade} - ${infos[0].endereco.estado}, ${infos[0].endereco.cep}, Brasil`
                    : 'Entrar na consulta'}
                </Text>
              </Link>
            )}
          </Box>
        ) : (
          <Text
            mt="3"
            mb="8"
            color="brand-dark.200"
            fontSize="xl"
            fontWeight="medium"
          >
            Nenhuma consulta futura
          </Text>
        )
      ) : tipoAtividade === 'medicacoesAtuais' ? (
        infos.length > 0 ? (
          <UnorderedList
            styleType="'- '"
            mb="8"
            color="brand-dark.200"
            fontSize="xl"
            fontWeight="medium"
          >
            {infos?.slice(0, 3).map((med: any) => (
              <ListItem mt="2"> {med.nome}</ListItem>
            ))}
          </UnorderedList>
        ) : (
          <Text
            mt="3"
            mb="8"
            color="brand-dark.200"
            fontSize="xl"
            fontWeight="medium"
          >
            Nenhum medicamento
          </Text>
        )
      ) : tipoAtividade === 'monitoramentoAtual' && infos.nomeQuestionario ? (
        <Flex justifyContent="space-between" alignItems="center">
          <Box w="80%">
            <Text
              color="brand-dark.400"
              mt="3"
              fontSize="xl"
              fontWeight="medium"
            >
              {infos.nomeQuestionario}
            </Text>
            <Text
              mb="8"
              color="brand-dark.200"
              fontSize="xl"
              fontWeight="medium"
              noOfLines={3}
            >
              {infos.feedBack}
            </Text>
          </Box>
          <Box
            bg={alertColorBox(corAlerta) as any}
            w="25px"
            h="25px"
            p="5"
            borderRadius="20px"
          />
        </Flex>
      ) : (
        <Text
          mt="3"
          mb="8"
          color="brand-dark.200"
          fontSize="xl"
          fontWeight="medium"
        >
          {infos}
        </Text>
      )}
      <Text
        align="right"
        position="absolute"
        bottom="2"
        color="#bfbfbf"
        fontSize="xs"
        fontWeight="medium"
      >
        Última atualização {formattedLastUpdateDate}
      </Text>
    </Box>
  );
}
