import { api } from '@/lib';
import { useProfessionalStore, useUserStore } from '@/stores';
import { useQuery } from 'react-query';

type Notification = {
  id: string;
  paciente: string;
  descricao: string;
  dataCadastro: Date;
  data: any;
  hora: string;
  status: 'ativo' | 'inativo';
  recente: boolean;
  vincular: number;
  idUnidade: string;
};

async function getNotifications(
  id?: string,
  medico?: boolean,
): Promise<Notification[]> {
  try {
    const response = await api.get('/alerta/paginado-web', {
      params: {
        idUsuarioProfissional: id,
      },
    });
    if (!response.data.data) {
      throw Error;
    }

    const unidades = await api.get('/instancia/unidades');

    const newData = response.data.data
      .map((notification: any) => {
        const name = unidades?.data.data.filter(
          (item: any) => item.id === notification.idUnidade,
        );

        if (name.length) {
          return {
            id: notification.id,
            paciente: notification.nomePaciente,
            descricao: name[0].descricao
              ? `${notification.descricao} na unidade ${name[0].descricao}`
              : notification.categoria === 4
              ? `Mensagem: ${notification.descricao}`
              : notification.descricao,
            dataCadastro: new Date(notification.data),
            data: notification.data,
            hora: notification.hora,
            status: (() => {
              if (notification.statusVisualizacao === true) {
                return 'Lido';
              }
              if (notification.statusVisualizacao === false) {
                return 'Não lido';
              }
              return 'Aguardando';
            })(),
            recente: notification.recente,
            vincular: notification.vincular,
            idPaciente: notification.idPaciente,
            idUsuarioPaciente: notification.idUsuarioPaciente,
            idProfissional: notification.idUsuarioProfissional,
            idMedico: notification.idMedico,
            idUnidade: notification.idUnidade,
            idConsulta: notification.idConsulta,
            idAlertaWeb: notification.id,
            idHorasAgenda: notification.idHorasAgenda,
            categoria: notification.categoria,
          };
        }
        return {
          id: notification.id,
          paciente: notification.nomePaciente,
          descricao:
            notification.categoria === 4
              ? `Mensagem: ${notification.descricao}`
              : notification.descricao,
          dataCadastro: new Date(notification.data),
          data: notification.data,
          hora: notification.hora,
          status: (() => {
            if (notification.statusVisualizacao === true) {
              return 'Lido';
            }
            if (notification.statusVisualizacao === false) {
              return 'Não lido';
            }
            return 'Aguardando';
          })(),
          recente: notification.recente,
          vincular: notification.vincular,
          idPaciente: notification.idPaciente,
          idUsuarioPaciente: notification.idUsuarioPaciente,
          idProfissional: notification.idUsuarioProfissional,
          idMedico: notification.idMedico,
          idUnidade: notification.idUnidade,
          idConsulta: notification.idConsulta,
          idAlertaWeb: notification.id,
          idHorasAgenda: notification.idHorasAgenda,
          categoria: notification.categoria,
        };
      })
      .filter((noty: any) => {
        if (!medico) {
          return noty.categoria !== 3 && noty.categoria !== 4;
        }
        return noty;
      });
    return newData;
  } catch {
    return [];
  }
}

async function getNotificationsPacient(id?: string): Promise<Notification[]> {
  try {
    const response = await api.get('alerta/app-usuario', {
      params: {
        idPaciente: id,
      },
    });
    if (!response.data.data) {
      throw Error;
    }

    const unidades = await api.get('/instancia/unidades');

    const newData = response.data.data.map((notification: any) => {
      const name = unidades?.data.data.filter(
        (item: any) => item.id === notification.idUnidade,
      );

      if (name.length) {
        return {
          id: notification.id,
          paciente: notification.pacienteNome,
          descricao: name[0].descricao
            ? `${notification.descricao} na unidade ${name[0].descricao}`
            : notification.categoria === 4
            ? `Mensagem: ${notification.descricao}`
            : notification.descricao,
          dataCadastro: new Date(notification.data),
          data: notification.data,
          hora: notification.hora,
          status: (() => {
            if (notification.statusVisualizacao === true) {
              return 'Lido';
            }
            if (notification.statusVisualizacao === false) {
              return 'Não lido';
            }
            return 'Aguardando';
          })(),
          recente: notification.recente,
          vincular: notification.vincular,
          idPaciente: notification.idPaciente,
          idUsuarioPaciente: notification.idUsuarioPaciente,
          idProfissional: notification.idUsuarioProfissional,
          idMedico: notification.idMedico,
          idUnidade: notification.idUnidade,
          idConsulta: notification.idConsulta,
          idAlertaWeb: notification.id,
          idHorasAgenda: notification.idHorasAgenda,
          categoria: notification.categoria,
        };
      }
      return {
        id: notification.id,
        paciente: notification.pacienteNome,
        descricao:
          notification.categoria === 4
            ? `Mensagem: ${notification.descricao}`
            : notification.descricao,
        dataCadastro: new Date(notification.data),
        data: notification.data,
        hora: notification.hora,
        status: (() => {
          if (notification.statusVisualizacao === true) {
            return 'Lido';
          }
          if (notification.statusVisualizacao === false) {
            return 'Não lido';
          }
          return 'Aguardando';
        })(),
        recente: notification.recente,
        vincular: notification.vincular,
        idPaciente: notification.idPaciente,
        idUsuarioPaciente: notification.idUsuarioPaciente,
        idProfissional: notification.idUsuarioProfissional,
        idMedico: notification.idMedico,
        idUnidade: notification.idUnidade,
        idConsulta: notification.idConsulta,
        idAlertaWeb: notification.id,
        idHorasAgenda: notification.idHorasAgenda,
        categoria: notification.categoria,
      };
    });

    return newData;
  } catch {
    return [];
  }
}

export function useNotificationsTableQuery() {
  // pageSize: number,
  // pageNumber: number,
  const professional = useProfessionalStore(state => state.professional);
  const user = useUserStore(state => state.user);
  const id =
    user?.type === 'medico' ? user?.profissionalId : professional?.idUsuario;
  const medico = user?.type === 'medico';

  let query;

  if (user?.type !== 'paciente') {
    query = useQuery(['notificationsTable', id], () =>
      getNotifications(id, medico),
    );
  } else {
    query = useQuery(['notificationsTable', id], () =>
      getNotificationsPacient(user?.pacienteId),
    );
  }

  return query;
}
