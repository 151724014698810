import { useBreakpointValue } from '@chakra-ui/react';
import { useEffect, useMemo } from 'react';
import { MdPrivacyTip } from 'react-icons/md';
import { BsQuestionSquare } from 'react-icons/bs';
import { FiDatabase } from 'react-icons/fi';
import {
  IoAdd,
  IoBusinessOutline,
  IoCalendarClearOutline,
  IoHomeOutline,
  IoList,
  IoClipboardOutline,
  IoChatbubblesOutline,
  IoTriangleOutline,
  IoPersonAddOutline,
} from 'react-icons/io5';
import { RiPulseLine } from 'react-icons/ri';
import { useNavigate } from 'react-router-dom';
import {
  useAddInstanceModalStore,
  useAddPrescriptionModalStore,
  useAddUserModalStore,
  useUserStore,
} from '@/stores';

import { MobileSidebar } from './MobileSidebar';
import { WideSidebar } from './WideSidebar';

export function Sidebar() {
  const isMobile = useBreakpointValue({
    base: true,
    md: false,
  });
  const user = useUserStore(state => state.user);
  const { onOpen: onAddUserModalOpen } = useAddUserModalStore();
  const { onOpen: onAddInstanceOpen } = useAddInstanceModalStore();
  const { onOpen: onAddPrescriptionOpen } = useAddPrescriptionModalStore();

  const navigate = useNavigate();

  const gestoresValidation = () => {
    if (user?.type === 'admin') {
      return [
        {
          icon: IoList,
          label: 'Assistentes',
          link: '/app/secretariesList',
          onClick: () => {
            navigate('/app/secretariesList');
          },
        },
        {
          icon: IoList,
          label: 'Gestores',
          link: '/app/managersList',
          onClick: () => {
            navigate('/app/managersList');
          },
        },
        {
          icon: IoList,
          label: 'Profissionais',
          link: '/app/professionalsList',
          onClick: () => {
            navigate('/app/professionalsList');
          },
        },
        {
          icon: IoList,
          label: 'Pacientes',
          link: '/app/patientsList',
          onClick: () => {
            navigate('/app/patientsList');
          },
        },
        {
          icon: IoAdd,
          label: 'Novo',
          onClick: () => {
            onAddUserModalOpen();
          },
        },
      ];
    }
    if (user?.type === 'master') {
      return [
        {
          icon: IoList,
          label: 'Assistentes',
          link: '/app/secretariesList',
          onClick: () => {
            navigate('/app/secretariesList');
          },
        },
        {
          icon: IoList,
          label: 'Gestores',
          link: '/app/managersList',
          onClick: () => {
            navigate('/app/managersList');
          },
        },
        {
          icon: IoList,
          label: 'Profissionais',
          link: '/app/professionalsList',
          onClick: () => {
            navigate('/app/professionalsList');
          },
        },
        {
          icon: IoList,
          label: 'Pacientes',
          link: '/app/patientsList',
          onClick: () => {
            navigate('/app/patientsList');
          },
        },
        {
          icon: IoAdd,
          label: 'Novo',
          onClick: () => {
            onAddUserModalOpen();
          },
        },
      ];
    }
    if (user?.type === 'gestor') {
      return [
        {
          icon: IoList,
          label: 'Assistentes',
          link: '/app/secretariesList',
          onClick: () => {
            navigate('/app/secretariesList');
          },
        },
        // {
        //   icon: IoList,
        //   label: 'Gestores',
        //   link: '/app/managersList',
        //   onClick: () => {
        //     navigate('/app/managersList');
        //   },
        // },
        {
          icon: IoList,
          label: 'Profissionais',
          link: '/app/professionalsList',
          onClick: () => {
            navigate('/app/professionalsList');
          },
        },
        {
          icon: IoList,
          label: 'Pacientes',
          link: '/app/patientsList',
          onClick: () => {
            navigate('/app/patientsList');
          },
        },
        {
          icon: IoAdd,
          label: 'Novo',
          onClick: () => {
            onAddUserModalOpen();
          },
        },
      ];
    }
    if (user?.type === 'medico') {
      return [
        {
          icon: IoList,
          label: 'Assistentes',
          link: '/app/secretariesList',
          onClick: () => {
            navigate('/app/secretariesList');
          },
        },
        {
          icon: IoList,
          label: 'Gestores',
          link: '/app/managersList',
          onClick: () => {
            navigate('/app/managersList');
          },
        },
        {
          icon: IoList,
          label: 'Profissionais',
          link: '/app/professionalsList',
          onClick: () => {
            navigate('/app/professionalsList');
          },
        },
        {
          icon: IoList,
          label: 'Pacientes',
          link: '/app/patientsList',
          onClick: () => {
            navigate('/app/patientsList');
          },
        },
        {
          icon: IoAdd,
          label: 'Novo',
          onClick: () => {
            onAddUserModalOpen();
          },
        },
      ];
    }
    if (user?.type === 'assistente') {
      return [
        // {
        //   icon: IoList,
        //   label: 'Assistentes',
        //   link: '/app/secretariesList',
        //   onClick: () => {
        //     navigate('/app/secretariesList');
        //   },
        // },
        // {
        //   icon: IoList,
        //   label: 'Gestores',
        //   link: '/app/managersList',
        //   onClick: () => {
        //     navigate('/app/managersList');
        //   },
        // },
        {
          icon: IoList,
          label: 'Profissionais',
          link: '/app/professionalsList',
          onClick: () => {
            navigate('/app/professionalsList');
          },
        },
        {
          icon: IoList,
          label: 'Pacientes',
          link: '/app/patientsList',
          onClick: () => {
            navigate('/app/patientsList');
          },
        },
        {
          icon: IoAdd,
          label: 'Novo',
          onClick: () => {
            onAddUserModalOpen();
          },
        },
      ];
    }
    if (user?.type === 'paciente') {
      return [];
    }

    return [
      {
        icon: IoList,
        label: 'Assistentes',
        link: '/app/secretariesList',
        onClick: () => {
          navigate('/app/secretariesList');
        },
      },
      {
        icon: IoList,
        label: 'Profissionais',
        link: '/app/professionalsList',
        onClick: () => {
          navigate('/app/professionalsList');
        },
      },
      {
        icon: IoList,
        label: 'Pacientes',
        link: '/app/patientsList',
        onClick: () => {
          navigate('/app/patientsList');
        },
      },
      {
        icon: IoAdd,
        label: 'Novo',
        onClick: () => {
          onAddUserModalOpen();
        },
      },
    ];
  };

  const instancesValidation = () => {
    if (user?.type === 'admin' || user?.type === 'master') {
      return [
        {
          icon: IoList,
          label: 'Instâncias',
          link: '/app/instanciasList',
          onClick: () => {
            navigate('/app/instanciasList');
          },
        },
        {
          icon: IoList,
          label: 'Unidades',
          link: '/app/unidadesList',
          onClick: () => {
            navigate('/app/unidadesList');
          },
        },
        {
          icon: IoAdd,
          label: 'Novo',
          onClick: () => {
            onAddInstanceOpen();
          },
        },
      ];
    }

    return [
      {
        icon: IoList,
        label: 'Instâncias',
        link: '/app/instanciasList',
        onClick: () => {
          navigate('/app/instanciasList');
        },
      },
      {
        icon: IoList,
        label: 'Unidades',
        link: '/app/unidadesList',
        onClick: () => {
          navigate('/app/unidadesList');
        },
      },
    ];
  };

  const nav = () => {
    if (user?.type === 'paciente') {
      return [
        {
          icon: IoHomeOutline,
          label: 'Início',
          link: '/app/start',
          onClick: () => {
            navigate('/app/start');
          },
        },
        {
          icon: IoChatbubblesOutline,
          label: 'Chat',
          link: '/app/chat',
          onClick: () => {
            navigate('/app/chat');
          },
        },
        {
          icon: IoCalendarClearOutline,
          label: 'Agenda',
          link: '/app/schedule',
          onClick: () => {
            navigate('/app/schedule');
          },
        },
        {
          id: 'notification',
          icon: IoTriangleOutline,
          label: 'Agenda | Prescrição',
          link: '/app/notifications',
          onClick: () => {
            navigate('/app/notifications');
          },
        },
        // {
        //   // icon: IoReaderOutline,
        //   // label: 'Histórico',
        //   // link: '/app/patients',
        //   // onClick: () => {
        //   //   navigate('/app/patients');
        //   // },
        // },
        {
          icon: BsQuestionSquare,
          label: 'Termos de Uso',
          link: '/app/terms',
          onClick: () => {
            navigate('/app/terms');
          },
        },
        {
          icon: MdPrivacyTip,
          label: 'Privacidade',
          link: '/app/privacy',
          onClick: () => {
            navigate('/app/privacy');
          },
        },
      ];
    }

    if (user?.type === 'medico') {
      return [
        {
          icon: IoHomeOutline,
          label: 'Início',
          link: '/app/start',
          onClick: () => {
            navigate('/app/start');
          },
        },
        {
          icon: IoChatbubblesOutline,
          label: 'Chat',
          link: '/app/chat',
          onClick: () => {
            navigate('/app/chat');
          },
        },
        {
          icon: IoCalendarClearOutline,
          label: 'Agenda',
          link: '/app/schedule',
          onClick: () => {
            navigate('/app/schedule');
          },
        },
        {
          id: 'notification',
          icon: IoTriangleOutline,
          label: 'Agenda | Prescrição',
          link: '/app/notifications',
          onClick: () => {
            navigate('/app/notifications');
          },
        },
        // {
        //   icon: IoBusinessOutline,
        //   label: 'Gestão Clínica',
        //   link: '/app/patients',
        //   onClick: () => {
        //     navigate('/app/patients');
        //   },
        // },

        {
          icon: IoPersonAddOutline,
          label: 'Gestão Clínica',
          link: '/app/patients',
          onClick: () => {
            navigate('/app/patients');
          },
        },
        {
          icon: IoClipboardOutline,
          label: 'Prescrição',
          link: '/app/prescriptions',
          // onClick: () => {
          //   navigate('/app/prescriptions');
          // },
          subLinks: [
            {
              icon: IoAdd,
              label: 'Prescrição',
              onClick: () => {
                onAddPrescriptionOpen();
              },
            },
          ],
        },
        {
          icon: IoBusinessOutline,
          label: 'Instâncias',
          subLinks: instancesValidation(),
        },
        {
          icon: FiDatabase,
          label: 'Cadastros',
          subLinks: gestoresValidation(),
        },
        {
          icon: RiPulseLine,
          label: 'Relatórios',
          link: '/app/reports',
          onClick: () => {
            navigate('/app/reports');
          },
        },
        {
          icon: BsQuestionSquare,
          label: 'Termos de Uso',
          link: '/app/terms',
          onClick: () => {
            navigate('/app/terms');
          },
        },
        {
          icon: MdPrivacyTip,
          label: 'Privacidade',
          link: '/app/privacy',
          onClick: () => {
            navigate('/app/privacy');
          },
        },
      ];
    }

    return [
      {
        icon: IoHomeOutline,
        label: 'Início',
        link: '/app/start',
        onClick: () => {
          navigate('/app/start');
        },
      },
      {
        icon: IoChatbubblesOutline,
        label: 'Chat',
        link: '/app/chat',
        onClick: () => {
          navigate('/app/chat');
        },
      },
      {
        icon: IoCalendarClearOutline,
        label: 'Agenda',
        link: '/app/schedule',
        onClick: () => {
          navigate('/app/schedule');
        },
      },
      {
        id: 'notification',
        icon: IoTriangleOutline,
        label: 'Agenda | Prescrição',
        link: '/app/notifications',
        onClick: () => {
          navigate('/app/notifications');
        },
      },

      // {
      //   icon: IoReaderOutline,
      //   label: 'Gestão Clínica',
      //   link: '/app/patients',
      //   onClick: () => {
      //     navigate('/app/patients');
      //   },
      // },
      // {
      //   icon: IoClipboardOutline,
      //   label: 'Prescrição',
      //   link: '/app/prescriptions',
      //   // onClick: () => {
      //   //   navigate('/app/prescriptions');
      //   // },
      //   subLinks: [
      //     {
      //       icon: IoAdd,
      //       label: 'Prescrição',
      //       onClick: () => {
      //         onAddPrescriptionOpen();
      //       },
      //     },
      //   ],
      // },
      {
        icon: IoBusinessOutline,
        label: 'Instâncias',
        subLinks: instancesValidation(),
      },
      {
        icon: FiDatabase,
        label: 'Cadastros',
        subLinks: gestoresValidation(),
      },
      {
        icon: RiPulseLine,
        label: 'Relatórios',
        link: '/app/reports',
        onClick: () => {
          navigate('/app/reports');
        },
      },
      {
        icon: BsQuestionSquare,
        label: 'Termos de Uso',
        link: '/app/terms',
        onClick: () => {
          navigate('/app/terms');
        },
      },
      {
        icon: MdPrivacyTip,
        label: 'Privacidade',
        link: '/app/privacy',
        onClick: () => {
          navigate('/app/privacy');
        },
      },
    ];
  };

  const navItems = useMemo(() => nav(), [user]);

  return isMobile ? (
    <MobileSidebar navItems={navItems} />
  ) : (
    <WideSidebar navItems={navItems} />
  );
}
