import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { Box, Button, Flex, Heading, Icon, IconButton } from '@chakra-ui/react';
import { MdNavigateBefore, MdNavigateNext } from 'react-icons/md';
import { Tooltip } from 'antd';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';

// import { MonthButton } from '../../features/schedule/components/SchedulerSection/MonthButton';

function Toolbar(props: any) {
  const { label, date, view, views, onView, onNavigate } = props;
  const [month, setMonth] = useState('January');
  const mMonth = moment(date).format('MMMM');
  const months = moment.months();
  const { refetch } = useScheduleIntervalQuery();

  useEffect(() => {
    setMonth(mMonth);
  }, [mMonth]);

  const goToBack = () => {
    onNavigate('PREV');
    refetch();
  };
  const goToNext = () => {
    onNavigate('NEXT');
    refetch();
  };

  return (
    <div>
      <Flex w="100%" direction="column" align="center" paddingBottom="50px">
        {/* <Box>
          {view === 'month' ? (
            <>
              <select
                className="rbc-dropdown"
                value={month}
                onChange={onChange}
              >
                {months?.map(m => (
                  <option value={m} className="rbc-dropdown-option" key={m}>
                    {m}
                  </option>
                ))}
              </select>
              <span className="rbc-year"> {moment(date).format('YYYY')}</span>
            </>
          ) : (
            <Heading color="brand-dark.300" size="md" fontWeight="semibold">
              {label}
            </Heading>
          )}
        </Box> */}

        <Box>
          {/* <Button
            onClick={goToBack}
            bg="brand-orange.300"
            color="white"
            pointerEvents="all"
            margin="10px"
          >
            Back
          </Button> */}
          <Tooltip placement="bottom" title="Semana anterior">
            <IconButton
              aria-label=""
              icon={
                <Icon
                  as={MdNavigateBefore}
                  fontSize="40px"
                  color="brand-orange.300"
                  _hover={{
                    color: 'white',
                  }}
                />
              }
              borderRadius="14px"
              bg="none"
              pointerEvents="all"
              _hover={{
                background: 'brand-orange.300',
              }}
              onClick={goToBack}
            />
          </Tooltip>
          <Button
            // onClick={goToToday}
            bg="brand-orange.300"
            color="white"
            pointerEvents="all"
            margin="10px"
            cursor="default"
            _hover={{
              background: 'brand-orange.300',
            }}
          >
            <Heading color="white" size="md" fontWeight="semibold">
              {label}
            </Heading>
          </Button>
          {/* <Button
            onClick={goToNext}
            bg="brand-orange.300"
            color="white"
            pointerEvents="all"
            margin="10px"
          >
            Next
          </Button> */}
          <Tooltip placement="bottom" title="Próxima semana">
            <IconButton
              aria-label="Próxima semana"
              icon={
                <Icon
                  as={MdNavigateNext}
                  fontSize="40px"
                  color="brand-orange.300"
                  _hover={{
                    color: 'white',
                  }}
                />
              }
              borderRadius="14px"
              bg="none"
              _hover={{
                background: 'brand-orange.300',
              }}
              pointerEvents="all"
              onClick={goToNext}
            />
          </Tooltip>
        </Box>
      </Flex>
    </div>
  );
}

export default Toolbar;
