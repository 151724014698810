import { api } from '@/lib';
import { useUserStore } from '@/stores';
import { useQuery } from 'react-query';

const tableHead = [
  'Profissional',
  'Consulta Agendada',
  'Consulta Atendida',
  'Consulta Remarcada',
  'Consulta Cancelada',
  'Consultas Não Realizadas',
];

async function getScales(gestorMasterId: string | undefined) {
  const params = {
    idGestor: gestorMasterId,
  };

  // const response = await api.get('/relatorio/escala', { params });
  const response = await api.get('/relatorio/profissionalsaude', { params });
  const oldTableBody: any[] = response.data.data;

  const tableBody = oldTableBody
    .sort((a, b) => {
      if (a.nomeProfissional > b.nomeProfissional) {
        return 1;
      }
      if (a.nomeProfissional < b.nomeProfissional) {
        return -1;
      }
      return 0;
    })
    .map(row => ({
      nomeProfissional: row.nomeProfissional ?? '-',
      consultaAgendada: row.consultaAgendada ?? '-',
      consultaAtendida: row.consultaAtendida ?? '-',
      consultaRemarcada: row.consultaRemarcada ?? '-',
      consultaCancelada: row.consultaCancelada ?? '-',
      consultaNaoRealizada: row.consultaNaoRealizada ?? '-',
    }));

  return { tableHead, tableBody };
}

export function useScalesTableQuery() {
  const user = useUserStore(state => state.user);

  const query = useQuery(
    ['scalesList'],
    () => getScales(user?.gestorMasterId),
    {
      refetchOnWindowFocus: false,
    },
  );

  return query;
}
