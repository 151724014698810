import { Flex, Heading } from '@chakra-ui/react';

export function TopSection() {
  return (
    <Flex
      direction={['column', 'column', 'row']}
      align="center"
      justify="center"
    >
      <Heading
        w="80%"
        size="lg"
        mb={['4', '4', '0']}
        color="brand-dark.500"
        fontWeight="medium"
        textAlign="center"
      >
        TERMO DE USO DE SOFTWARE DIRECT MESSAGE HEALTH COMO PRESTAÇÃO DE
        SERVIÇOS – SAAS
      </Heading>
    </Flex>
  );
}
