import React, { useContext } from 'react';

export const IntervalDateContext = React.createContext({
  dateInit: '',
  setDateInit: (data: any) => {},
  dateFinal: '',
  setDateFinal: (data: any) => {},
});

export function useIntervalDateContext() {
  return useContext(IntervalDateContext);
}
