import { Navigate, Route, Routes } from 'react-router-dom';
import { TermsPages } from '../pages';

export function TermsRoutes() {
  return (
    <Routes>
      <Route path="" element={<TermsPages />} />
      <Route path="*" element={<Navigate to="." />} />
    </Routes>
  );
}
