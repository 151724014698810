import { ChatNotificationsProvider } from '@/context/chat-notifications';
import { useUserStore } from '@/stores';

import { PrivateRoutes } from './private';
import { PublicRoutes } from './public';

export default function AppRoutes() {
  const user = useUserStore(state => state.user);

  return user ? (
    <ChatNotificationsProvider>
      <PrivateRoutes />
    </ChatNotificationsProvider>
  ) : (
    <PublicRoutes />
  );
}
