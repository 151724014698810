import Provider from '@/providers';
import AppRoutes from '@/routes';
import 'antd/dist/reset.css';

export default function App() {
  return (
    <Provider>
      <AppRoutes />
    </Provider>
  );
}
