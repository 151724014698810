import { useGetConsultas } from '@/features/historic/hooks/getConsultas';
import { MONTHS } from '@/features/schedule';
import { useUserStore } from '@/stores';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Card } from 'antd';
import { format, parseISO } from 'date-fns';
import { SetStateAction, useEffect, useState } from 'react';
import {
  IoArrowForward,
  IoEllipseOutline,
  IoRemoveOutline,
} from 'react-icons/io5';

type HistoryProps = {
  paciente?: any;
  setSelectConsul: (data: any) => any;
};

export default function HistoryCard({
  paciente,
  setSelectConsul,
}: HistoryProps) {
  const [consultas, setConsultas] = useState<any[]>();
  const [consultasForm, setConsultasForm] = useState<any[]>();
  const [selectIndex, setIndex] = useState<any>(null);
  const { mutate: getConsults, isLoading } = useGetConsultas();

  const getConsultas = async () => {
    getConsults(paciente?.idPaciente, {
      onSuccess: response => {
        setConsultas(response);
      },
    });
  };

  useEffect(() => {
    getConsultas();
  }, [paciente]);

  const getDateMonth = (num: number) => {
    const name = MONTHS.filter(m => m.numeric === num)[0];
    return name.numeric;
  };

  const getCategories = () => {
    if (consultas) {
      const c = consultas?.reduce((acc, item, i) => {
        return {
          ...acc,
          [getDateMonth(new Date(item.horasAgenda.agenda.data).getMonth())]: [
            ...(acc[
              getDateMonth(new Date(item.horasAgenda.agenda.data).getMonth())
            ] ?? []),
            item,
          ],
        };
      }, {});

      setConsultasForm(c);
    }
  };

  useEffect(() => {
    getCategories();
  }, [consultas]);

  return (
    <Box width={{ base: '100%', sm: '100%', md: '30%' }} paddingTop="10">
      <Card
        style={{
          maxHeight: '90%',
          overflowY: 'auto',
          borderRadius: '10px',
          backgroundColor: '#fff',
        }}
      >
        <Flex style={{ backgroundColor: '#fff' }} w="100%" h="100%">
          <Skeleton width="100%" isLoaded={!isLoading}>
            <Grid width="100%" templateColumns="repeat(12, 1fr)">
              {MONTHS.map((m: any) => {
                const { name } = m;
                return (
                  <GridItem
                    key={name}
                    display="flex"
                    colSpan={12}
                    style={{ height: '100%' }}
                  >
                    <Box width="30%" borderRight="1px dotted #E4E4E4">
                      <Box borderBottom="1px solid #E4E4E4">
                        <Text
                          color="#D6D6D6"
                          fontSize="md"
                          fontWeight="medium"
                          paddingTop="10"
                          paddingLeft="15"
                          fontFamily="Rubik"
                        >
                          {name}
                        </Text>
                      </Box>
                      <Box width="max-content">
                        {consultasForm &&
                          consultasForm[m.numeric]?.map(
                            (c: any, index: any) => {
                              const date = format(
                                parseISO(c.horasAgenda.agenda.data),
                                'dd/MM/yyyy',
                              );
                              return (
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  justifyContent="center"
                                  paddingTop="10"
                                  key={c.horasAgenda.id}
                                >
                                  <Box>
                                    <Text
                                      color="#414141"
                                      fontSize="md"
                                      fontWeight="medium"
                                      paddingLeft="15"
                                      fontFamily="Rubik"
                                      alignItems="center"
                                    >
                                      {date}
                                    </Text>
                                    <Text
                                      color="#414141"
                                      fontSize="sm"
                                      fontWeight="medium"
                                      paddingLeft="15"
                                      fontFamily="Rubik"
                                      alignItems="center"
                                    >
                                      {c.horasAgenda.agenda.horarioInicio}h
                                    </Text>
                                  </Box>
                                  <Box
                                    position="absolute"
                                    left="29%"
                                    backgroundColor="#fff"
                                  >
                                    <Icon
                                      as={IoEllipseOutline}
                                      color="#D6D6D6"
                                      fontSize="30px"
                                    />
                                    <Icon
                                      as={IoRemoveOutline}
                                      color="#D6D6D6"
                                      fontSize="30px"
                                    />
                                  </Box>
                                  <Box
                                    position="absolute"
                                    left="35%"
                                    backgroundColor="#fff"
                                  >
                                    <Button
                                      fontSize="sm"
                                      borderRadius="6"
                                      bg={
                                        index === selectIndex?.i &&
                                        m.numeric === selectIndex?.num
                                          ? '#A4A4A4'
                                          : '#F1F1F1'
                                      }
                                      border="1px solid #C9C9C9"
                                      onClick={() => {
                                        setSelectConsul(c);
                                        setIndex({
                                          i: index,
                                          num: m.numeric,
                                        });
                                      }}
                                    >
                                      {c.especialidade.length > 0
                                        ? c.especialidade[0].descricao
                                        : c.conselho[0].descricao}

                                      <Icon
                                        as={IoArrowForward}
                                        fontSize="30px"
                                        color="#8A8A8A"
                                        right="0"
                                        justifyContent="flex-end"
                                      />
                                    </Button>
                                  </Box>
                                </Box>
                              );
                            },
                          )}
                      </Box>
                    </Box>
                  </GridItem>
                );
              })}
            </Grid>
          </Skeleton>
        </Flex>
      </Card>
    </Box>
  );
}
