import { Patient } from '@/features/schedule';
import { CancelScale } from '@/features/schedule/components/SchedulerSection/ScaleActions/CancelScale';
import { ChangeTypeScale } from '@/features/schedule/components/SchedulerSection/ScaleActions/ChangeTypeScale';
import { SchedulePatientModal } from '@/features/schedule/components/SchedulerSection/SchedulePatientModal';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';
import { PatientInfoModal } from '@/features/start/components/ScheduleSection/PatientInfoModal';
import {
  Box,
  Button,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Icon,
  Badge,
} from '@chakra-ui/react';
import { Menu, Dropdown } from 'antd';
import format from 'date-fns/format';
import { useLocation, useNavigate } from 'react-router-dom';
import { useGendersQuery } from '@/hooks';
import { useUserStore } from '@/stores';
import { useCallback, useEffect, useRef, useState } from 'react';
import { EventProps } from 'react-big-calendar';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { LockScaleCard } from './EventActions/LockScaleCard';
import { LockScaleRescheduleCard } from './EventActions/LockScaleRescheduleCard';
import { NotificatonEvent } from './EventActions/NotificationEvent';
import { ReSchedule } from './EventActions/ReSchedule';
import { UnlockScaleCard } from './EventActions/UnlockScaleCard';

export function CustomEvent({ title, event }: EventProps<any>) {
  const user = useUserStore(state => state.user);
  const { pathname } = useLocation();
  const { data: genders = [] } = useGendersQuery();
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
  const { data: appointments = [], refetch } = useScheduleIntervalQuery();
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [openMenu, setOpenMenu] = useState(false);
  const [options, setOptions] = useState<any>([]);

  const navigate = useNavigate();

  const [scaleOption, setScaleOption] = useState<string | null>(null);

  function handleScaleOptionSelection(option: any) {
    if (option === 'consulta' || option === 'agendar') {
      if (event.ativo) {
        setSelectedPatient(event);
        onOpen();
      }
      return;
    }
    if (option === 'monitorar') {
      if (event.resource) {
        const gender = genders?.filter(
          item => item?.value === event?.resource?.sexoId,
        );
        const patient = {
          key: event.idPatient,
          idPaciente: event.idPatient,
          nomePaciente: event.resource.nome,
          idade: event.idade,
          sexo: gender[0].label,
          email: '',
          celular: event.resource.celular,
          cpf: event.cpf,
          nascimento: event.resource.dataNascimento,
          ultimaConsulta: '',
          urlPhoto: event.urlPhoto,
          anamineses: event.anamneses,
        };

        navigate('/app/historic', {
          state: { paciente: patient, consulta: null, monitoring: true },
        });
      }
      return;
    }

    setScaleOption(option);

    setCancelModalOpen(true);
  }
  function onCloseCancel() {
    setCancelModalOpen(false);
  }

  const handleClickListItem = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function renderModalContent() {
    if (scaleOption === 'cancel') {
      return (
        <CancelScale onCancel={onCloseCancel} event={event} deletar={false} />
      );
    }

    if (scaleOption === 'delete') {
      return <CancelScale onCancel={onCloseCancel} event={event} deletar />;
    }

    if (scaleOption === 'changeType') {
      return <ChangeTypeScale onCancel={onCloseCancel} event={event} />;
    }

    if (scaleOption === 'reagendar') {
      return <ReSchedule onCancel={onCloseCancel} event={event} />;
    }

    if (scaleOption === 'block') {
      return <LockScaleRescheduleCard onCancel={onCloseCancel} event={event} />;
    }

    if (scaleOption === 'blockCard') {
      return <LockScaleCard onCancel={onCloseCancel} event={event} />;
    }
    if (scaleOption === 'unblockCard') {
      return <UnlockScaleCard onCancel={onCloseCancel} event={event} />;
    }

    if (scaleOption === 'aceitar') {
      return (
        <NotificatonEvent onCancel={onCloseCancel} event={event} aceitar />
      );
    }

    if (scaleOption === 'negar') {
      return (
        <NotificatonEvent
          onCancel={onCloseCancel}
          event={event}
          aceitar={false}
        />
      );
    }

    return null;
  }

  useEffect(() => {
    if (event && Boolean(event.cpf)) {
      if (event.statusConsulta !== 0 && event.statusConsulta !== 5) {
        setOptions([
          { key: 'aceitar', label: 'Aceitar' },
          { key: 'negar', label: 'Negar' },
        ]);
      } else {
        setOptions([
          {
            key: 'consulta',
            label: 'Consulta',
            disable: user?.type !== 'medico',
          },
          { key: 'reagendar', label: 'Remarcar' },
          { key: 'block', label: 'Bloquear' },
          { key: 'cancel', label: 'Cancelar' },
          { key: 'changeType', label: 'Alterar Tipo' },
          {
            key: 'monitorar',
            label: 'Monitorar',
            disable: user?.type !== 'medico',
          },
        ]);
      }
    } else if (!event.ativo) {
      setOptions([
        { key: 'unblockCard', label: 'Desbloquear' },
        { key: 'delete', label: 'Cancelar' },
      ]);
    } else {
      setOptions([
        { key: 'agendar', label: 'Agendar' },
        { key: 'blockCard', label: 'Bloquear' },
        { key: 'delete', label: 'Cancelar' },
      ]);
    }
  }, [event]);

  const alertColorBox = () => {
    switch (event!.resultado?.corAlerta ?? 99) {
      case 0:
        return 'green.400';
      case 1:
        return 'yellow.400';
      case 2:
        return 'red.400';
      default:
        return 'white.400';
    }
  };

  return (
    <Box height="200%">
      <Button
        variant="unstyled"
        style={{ cursor: event.ativo ? 'pointer' : 'default' }}
        w="100%"
        h="auto"
        textAlign="left"
        onClick={() => {
          if (event.ativo) {
            setSelectedPatient(event);
            if (!pathname.includes('start')) onOpen();
          }
        }}
      >
        <Box mr="4">
          <Text
            color="brand-dark.300"
            fontSize="md"
            fontWeight="medium"
            textAlign="left"
            isTruncated
          >
            {event && Boolean(event.cpf) // isso é para teste
              ? title
              : event.ativo
              ? ''
              : 'Agenda bloqueada'}
          </Text>

          {event.start && event.end ? (
            <>
              <Text
                mt="3"
                color="#959595"
                fontSize="sm"
                fontWeight="medium"
                textAlign="left"
              >
                {format(event.start, 'HH:mm')} - {format(event.end, 'HH:mm')}
              </Text>

              {event && Boolean(event.cpf) && event.ativo ? (
                <>
                  {event.resultado && (
                    <Box
                      top="0"
                      position="absolute"
                      right="30%"
                      bg={alertColorBox as any}
                      w="10"
                      h="10"
                      borderRadius="50%"
                    />
                  )}
                  <Text
                    mt="-3"
                    color="brand-dark.300"
                    fontSize="md"
                    fontWeight="medium"
                    textAlign="right"
                  >
                    {event.statusConsulta === 5
                      ? 'NEGADO'
                      : event.statusConsulta === 6
                      ? 'CONCLUÍDO'
                      : event.statusConsulta !== 0
                      ? 'PENDENTE'
                      : event.consultation
                      ? 'ON-LINE'
                      : 'PRESENCIAL'}
                  </Text>
                </>
              ) : (
                <Text
                  mt="3"
                  color="#959595"
                  fontSize="sm"
                  fontWeight="medium"
                  textAlign="left"
                  wordBreak="break-word"
                >
                  {event.obsBloqueio}
                </Text>
              )}
            </>
          ) : null}
        </Box>
      </Button>

      {event && event.cpf ? (
        <PatientInfoModal
          isOpen={isOpen}
          onClose={onClose}
          patient={selectedPatient}
        />
      ) : (
        selectedPatient && (
          <SchedulePatientModal
            isOpen={isOpen}
            onClose={onClose}
            currentPatient={selectedPatient}
            appointments={appointments}
          />
        )
      )}

      <Flex w="auto">
        <Modal
          isOpen={cancelModalOpen}
          onClose={onCloseCancel}
          size="xl"
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody px="12" pb="8">
              {renderModalContent()}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>

      {event.statusConsulta !== 6 && event.statusConsulta !== 5 ? (
        <Box
          top="5px"
          bg="transparent"
          position="absolute"
          right="4px"
          transition="all 0.2s"
          _hover={{ bg: 'gray.400' }}
          _expanded={{ bg: 'brand-dark.200' }}
          _focus={{ boxShadow: 'outline' }}
        >
          <Dropdown
            overlay={
              <Menu
                style={{
                  width: '200px',
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  boxShadow: '0.8px 0.8px 7px -2px rgba(5, 6, 27, 0.5)',
                  marginLeft: '3%',
                  alignItems: 'center',
                  color: 'brand-dark.300',
                  fontSize: 'md',
                  fontWeight: 'medium',
                }}
              >
                {options
                  .filter((op: any) => !op.disable)
                  .map((op: any, index: any) => (
                    <Menu.Item
                      key={op.key}
                      style={{
                        color: '#707070',
                        fontSize: '20px',
                        textAlign: 'left',
                        wordBreak: 'break-word',
                      }}
                      // disabled={op.key === 'monitorar'}
                      onClick={() => {
                        handleClose();
                        handleScaleOptionSelection(op.key);
                      }}
                    >
                      {op.label}
                    </Menu.Item>
                  ))}
              </Menu>
            }
            placement="bottomLeft"
          >
            <Icon
              as={HiOutlineDotsVertical}
              _hover={{
                background: 'transparent',
                border: 'none',
              }}
              fontSize="25px"
              color="brand-dark.500"
            />
          </Dropdown>
        </Box>
      ) : null}
    </Box>
  );
}
