import { useMutation } from 'react-query';
import { api } from '@/lib';

interface Address {
  cep: string;
  numero: number;
  logradouro: string;
  complemento: string;
  bairro: string;
  cidade: string;
  estado: string;
  latitude: number;
  longitude: number;
  regiao: string;
  codIBGE: string;
}

interface People {
  nome: string;
  cpf: string;
  rg: string;
  celular: string;
  dataNascimento: Date;
  enderecoFoto: string;
  telefoneResidencial: string;
  tokenDeviceId: string;
  sexoId: string;
  endereco: Address;
}
export interface User {
  userName: string | undefined;
  passwordHash: string;
  email: string;
  pessoa: People;
  aprovado: boolean;
  ativo: boolean;
  urlFoto: string;
  idade: number;
}

export interface Patient {
  id: string;
  termoPesquisaId: string;
  linkTermos: string;
  linkTermosPoliticaPrivacidade: string;
  idMedico: string | undefined;
  prontuario: string;
  depressaoFlag: number;
  ansiedadeFlag: number;
  atividade: number;
  sono: number;
  usuario: User;
}

async function postPatient(payload: Patient): Promise<any> {
  const response = await api.post('/paciente', payload);
  return response;
}

async function editPatient(payload: Patient): Promise<any> {
  const response = await api.put('/paciente', payload);
  return response;
}

export function usePatientMutation() {
  const mutation = useMutation((payload: Patient) => postPatient(payload));

  return mutation;
}

export function usePatientEdit() {
  const edit = useMutation((payload: any) => editPatient(payload));

  return edit;
}
