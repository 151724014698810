export type ActivityTypes =
  | 'consultasDia'
  | 'consultasSemana'
  | 'consultasAtendidas'
  | 'consultasCanceladas'
  | 'consultasNaoRealizadas'
  | 'consultasDisponibilizadas'
  | 'consultasAgendadas'
  | 'horariosVagos'
  | 'consultasRemarcadas'
  | 'mensagensNaoRespondidasDia'
  | 'mensagensNaoRespondidasSemana'
  | 'notificacoesNaoVistasDia'
  | 'notificacoesNaoVistasSemana';

export type ActivityPatientTypes =
  | 'consultasFuturas'
  | 'numeroDeConsultas'
  | 'cidAtual'
  | 'medicacoesAtuais'
  | 'monitoramentoAtual';

export type Person = {
  nome: string;
  avatar: string;
  idade: number;
  historico: string;
  fatorRisco: string;
  acao: string;
  atender: string;
  medicamentos: string;
  consulta: string;
  cpf: string;
};

export type Patient = {
  nome: string;
  avatar: string;
  idade: number;
  historico: string;
  fatorRisco: string;
  acao: string;
  atender: string;
  medicamentos: string;
  consulta: string;
  resource: Person;
};

export function alertColorBox(corAlerta: number) {
  switch (corAlerta ?? 99) {
    case 0:
      return 'green.400';
    case 1:
      return 'yellow.400';
    case 2:
      return 'red.400';
    default:
      return 'white.400';
  }
}
