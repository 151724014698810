import React from 'react';
import ReactDOM from 'react-dom';

import reportWebVitals from '@/reportWebVitals';

import App from '@/App';

import 'react-day-picker/dist/style.css';
import 'react-multi-carousel/lib/styles.css';
import './styles/global.css';

// Fonts
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/700.css';
import '@fontsource/montserrat/400.css';
import '@fontsource/montserrat/500.css';
import '@fontsource/montserrat/600.css';
import '@fontsource/montserrat/700.css';
import '@fontsource/nunito-sans/600.css';
import '@fontsource/rubik/300.css';
import '@fontsource/rubik/400.css';
import '@fontsource/rubik/500.css';
import '@fontsource/rubik/600.css';
import '@fontsource/rubik/700.css';

ReactDOM.render(<App />, document.getElementById('root'));

reportWebVitals();
