import { Button, Circle, Text } from '@chakra-ui/react';

interface DayButtonProps {
  day: {
    name: string;
    numeric: number;
  };
  isActive: boolean;
  onDaySelect: (month: number) => void;
}

export function DayButton({ day, isActive, onDaySelect }: DayButtonProps) {
  return (
    <Button
      key={day.numeric}
      onClick={() => onDaySelect(day.numeric)}
      variant="ghost"
      py="12"
      w="100%"
      flexDirection="column"
    >
      <Circle
        size="48px"
        bg={isActive ? 'brand-green.300' : '#f2f2f2'}
        color={isActive ? 'white' : 'brand-dark.500'}
      >
        {day.numeric}
      </Circle>
      <Text
        mt="2"
        color={isActive ? 'brand-dark.500' : '#bfbfbf'}
        fontSize="xs"
        fontWeight="semibold"
        textTransform="uppercase"
      >
        {day.name}
      </Text>
    </Button>
  );
}
