import { api } from '@/lib';
import { formatCPF, formatDate } from '@/utils';
import {
  VStack,
  SimpleGrid,
  Text,
  Box,
  Button,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';

interface Props {
  user: any;
  onCancel: () => void;
  type: any;
}

export function DeleteUserModal({ user, onCancel, type }: Props) {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  async function onSubmit() {
    if (type === 'secretary') {
      try {
        setIsLoading(true);
        const result: any = await api.delete(
          `/assistente?secretariaId=${user.id}`,
        );
        if (result?.statusText === 'OK') {
          toast({
            title: 'Assistente deletado(a) com sucesso!',
            status: 'success',
          });
        }
      } catch (error) {
        toast({
          title: 'Ops algo deu errado!',
          status: 'error',
        });
      } finally {
        onCancel();
        setIsLoading(false);
      }
    }
    if (type === 'professional') {
      try {
        setIsLoading(true);
        const result: any = await api.delete(
          `/usuario/profissionalsaude?idUsuarioProfissional=${user.usuarioProfissionalSaudeId}`,
        );
        if (result?.statusText === 'OK') {
          toast({
            title: 'Profissional de saúde deletado(a) com sucesso!',
            status: 'success',
          });
        }
      } catch (error) {
        toast({
          title: 'Ops algo deu errado!',
          status: 'error',
        });
      } finally {
        onCancel();
        setIsLoading(false);
      }
    }
    if (type === 'patient') {
      try {
        setIsLoading(true);
        const result: any = await api.delete(`/paciente?id=${user.id}`);
        if (result?.statusText === 'OK') {
          toast({
            title: 'Paciente deletado(a) com sucesso!',
            status: 'success',
          });
        }
      } catch (error) {
        toast({
          title: 'Ops algo deu errado!',
          status: 'error',
        });
      } finally {
        onCancel();
        setIsLoading(false);
      }
    }
    if (type === 'gestor') {
      // toast({
      //   title: 'Gestor não pode ser deletado!',
      //   status: 'error',
      // });
      try {
        setIsLoading(true);
        const result: any = await api.delete(
          `/usuario/deletargestorMaster?gestorId=${user.id}`,
        );
        if (result?.statusText === 'OK') {
          toast({
            title: 'Gestor deletado(a) com sucesso!',
            status: 'success',
          });
        }
      } catch (error) {
        toast({
          title: 'Ops algo deu errado!',
          status: 'error',
        });
      } finally {
        onCancel();
        setIsLoading(false);
      }
    }
  }

  return (
    <VStack spacing="4">
      {/* <SimpleGrid minChildWidth="150px" spacing="4" w="100%">
        <SimpleGrid>
          <Heading as="h6" size="sm" color="#707070">
            E-mail
          </Heading>
          <Text>{secretary && secretary.email}</Text>
        </SimpleGrid>
        <SimpleGrid>
          <Heading as="h6" size="sm" color="#707070">
            Celular
          </Heading>
          <Text>{secretary && secretary.celular}</Text>
        </SimpleGrid>
      </SimpleGrid>
      <SimpleGrid minChildWidth="150px" spacing="4" w="100%">
        <SimpleGrid>
          <Heading as="h6" size="sm" color="#707070">
            CPF
          </Heading>
          <Text>{secretary && formatCPF(secretary.cpf)}</Text>
        </SimpleGrid>
        <SimpleGrid>
          <Heading as="h6" size="sm" color="#707070">
            Sexo
          </Heading>
          <Text>{secretary && secretary.sexo}</Text>
        </SimpleGrid>
      </SimpleGrid> */}

      <Box>
        <Text color="brand-dark.300" fontSize="xl" align="center">
          Deseja realmente excluir este usuário?
        </Text>
        {/* <Text color="brand-dark.300" fontSize="xl" align="center">
          {secretary.nome}?
        </Text> */}
      </Box>

      <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
        <Button
          disabled={isLoading}
          type="button"
          onClick={onCancel}
          variant="outline"
          colorScheme="brand-orange"
          h="45px"
          borderRadius="28px"
          color="brand-orange.300"
          fontSize="xs"
          fontWeight="medium"
          textTransform="uppercase"
        >
          Voltar
        </Button>

        <Button
          isLoading={isLoading}
          type="submit"
          colorScheme="red"
          h="45px"
          borderRadius="28px"
          color="white"
          fontSize="xs"
          fontWeight="medium"
          textTransform="uppercase"
          onClick={onSubmit}
        >
          Deletar
        </Button>
      </SimpleGrid>
    </VStack>
  );
}
