import { Flex, Heading, Stack } from '@chakra-ui/react';

import { DrawerIaChat } from '@/components/IaChat/DrawerIaChat';
import { useState } from 'react';
import { SelectPatientToGuidance } from '@/components/IaChat/components/SelectPatientToGuidance';
import { ExportButton } from './ExportButton';
import { DateInput } from './DateInput';
import { useIntervalDateContext } from '../../hooks';

export function TopSection() {
  const { dateInit, setDateInit, dateFinal, setDateFinal } =
    useIntervalDateContext();
  const [chatMessageSelected, setChatMessageSelected] = useState<string>();

  return (
    <Flex flexDir="column" gap={3}>
      <DrawerIaChat
        ml="auto"
        isFluctuant
        onMessageCallback={setChatMessageSelected}
      />
      <Flex
        direction={['column', 'column', 'row']}
        w="100%"
        align="center"
        justify="space-between"
      >
        <Heading
          size="lg"
          mb={['4', '4', '0']}
          color="brand-dark.500"
          fontWeight="medium"
        >
          Relatórios
        </Heading>
        <Stack
          direction={['column', 'row']}
          spacing="4"
          w="100%"
          justify="flex-end"
        >
          <DateInput setDate={setDateInit} date={dateInit} />
          <DateInput setDate={setDateFinal} date={dateFinal} />
          <ExportButton />
        </Stack>
      </Flex>

      <SelectPatientToGuidance
        isOpen={!!chatMessageSelected}
        message={chatMessageSelected || ''}
        onClose={() => setChatMessageSelected(undefined)}
      />
    </Flex>
  );
}
