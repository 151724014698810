import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Image,
  Input,
  SimpleGrid,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  addScaleSchema,
  useAddScaleMutation,
  useScheduleDateStore,
} from '@/features/schedule';

import { TextAreaInput, SelectInput } from '@/components';

import confirmationImg from '@/assets/register_confirmed.svg';
import { DatePicker, Form } from 'antd';
import moment from 'moment';
import { useScheduleIntervalQuery } from '@/features/schedule/hooks/useScheduleIntervalQuery';
import { useScheduleQuery } from '@/features/schedule/hooks/useScheduleQuery';
import {
  finalTimeSuggestion,
  initialTimeSuggestion,
} from '@/features/schedule/utils/timeSugestion';
import {
  useAddUserModalStore,
  useInstanciaStore,
  useProfessionalStore,
  useUnidadeStore,
  useUserStore,
} from '@/stores';
import { useUnidadesIdQuery } from '@/hooks';
import type { Dayjs } from 'dayjs';

type AddScaleFormFields = {
  data: string;
  horarioInicio: string;
  horarioFim: string;
  observacao: string;
};

interface AddScaleFormProps {
  onCancel: () => void;
  pageStart?: any;
}

export function AddScaleForm({ onCancel, pageStart }: AddScaleFormProps) {
  const [form] = Form.useForm();
  const [wasFormSubmittedWithSuccess, setWasFormSubmittedWithSuccess] =
    useState(false);

  const referenceDate = useScheduleDateStore(state => state.referenceDate);
  const [date, setData] =
    moment() >= moment(referenceDate)
      ? useState(moment())
      : useState(moment(referenceDate));

  const [horaInicio, setHoraInicio] = useState(initialTimeSuggestion);
  const [horaFim, setHoraFim] = useState(finalTimeSuggestion);

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<AddScaleFormFields>({
    defaultValues: {
      data: '',
      horarioInicio: '',
      horarioFim: '',
      observacao: '',
    },
    resolver: yupResolver(addScaleSchema),
  });

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  const { mutate, isLoading } = useAddScaleMutation();
  const { refetch } = useScheduleIntervalQuery();
  const { refetch: refresh } = useScheduleQuery();
  const instances = useInstanciaStore(state => state.instancia);
  const [instanciaId, setInstanciaId] = useState(instances?.id);
  const { data: unidades = [] } = useUnidadesIdQuery(instanciaId || '');
  const unidadeSet = useUnidadeStore(state => state.unidade);
  const [unidadeId, setUnidadeId] = useState(unidadeSet?.id);

  function onSubmit(values: AddScaleFormFields) {
    const [scheduleHour, scheduleMinute] = horaInicio.split(':');
    const [fimHour, fimMinute] = horaFim.split(':');
    if (
      scheduleHour > fimHour ||
      (scheduleHour === fimHour && scheduleMinute > fimMinute)
    ) {
      toast({
        title: 'Horário final menor que horário incial',
        status: 'error',
      });

      return;
    }

    const data = date.toISOString();
    mutate(
      {
        ...values,
        data,
        horarioInicio: horaInicio,
        horarioFim: horaFim,
        idUnidadeSaude: unidadeId,
      },
      {
        onSuccess: () => {
          refetch();
          refresh();
          setWasFormSubmittedWithSuccess(true);
        },
      },
    );
  }

  return wasFormSubmittedWithSuccess ? (
    <Flex w="100%" direction="column" align="center">
      <Heading
        mt="6"
        color="brand-dark.300"
        fontSize="2xl"
        fontWeight="medium"
        textAlign="center"
      >
        A Escala foi inclusa!
      </Heading>

      <Image
        mt="8"
        mb="4"
        src={confirmationImg}
        alt="Mulher confirmando o sucesso de uma operação em um celular grande"
      />

      <Text
        color="brand-dark.200"
        fontSize="sm"
        fontWeight="normal"
        textAlign="center"
      >
        Você pode editar as configurações na aba de escalas.
      </Text>

      <Button
        type="button"
        onClick={() => {
          onCancel();
          // window.location.reload();
        }}
        colorScheme="brand-green"
        mt="10"
        h="45px"
        borderRadius="28px"
        color="white"
        fontSize="xs"
        fontWeight="medium"
        textTransform="uppercase"
      >
        Ir para escalas
      </Button>
    </Flex>
  ) : (
    <Box
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      w="100%"
      textAlign="center"
    >
      <Heading mt="6" mb="4" color="#039c00" fontSize="xl" fontWeight="medium">
        Adicionar consulta
      </Heading>

      <VStack spacing="4">
        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          {/* <PickerInput
            name="data"
            label="Data"
            control={control}
            mask="date"
            error={errors.data}
            placeholder="Ex: 01/01/1970"
            icon={IoCalendarClearOutline}
          /> */}
          <SelectInput
            label="Unidades de Saúde"
            options={unidades}
            placeholder="Selecione unidade de saúde"
            disabled
            value={unidadeId}
          />
          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Data
            </FormLabel>
            <DatePicker
              getPopupContainer={triggerNode => {
                return triggerNode?.parentNode as HTMLElement;
              }}
              onSelect={(selectedDay: any) => {
                if (selectedDay) {
                  setData(selectedDay);
                }
              }}
              disabledDate={(current: any) => {
                return moment().add(-1, 'days') >= current;
              }}
              size="large"
              style={{ width: '100%' }}
              format="DD/MM/YYYY"
              placeholder="Selecionar data"
            />
            {/* <TimePicker
              getPopupContainer={triggerNode => {
                return triggerNode?.parentNode as HTMLElement;
              }}
              onSelect={(selectedDay: any) => {
                if (selectedDay) {
                  setData(selectedDay.toISOString());
                }
              }}
              size="large"
              style={{ width: '100%' }}
              placeholder="Selecionar data"
              format="HH:mm"
              value={moment(horaInicio, 'HH:mm')}
              // suffixIcon={<DownOutlined />}
              // popupClassName={classes.timepicker}
            /> */}
          </FormControl>
        </SimpleGrid>

        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          {/* <MaskedInput
            name="horarioInicio"
            label="Horário - Início"
            control={control}
            mask="time"
            useMaskedValue
            error={errors.horarioInicio}
            placeholder="Ex: 14:50"
          /> */}

          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Horário - Início
            </FormLabel>
            <Input
              type="time"
              id="horarioInicio"
              name="horarioInicio"
              value={horaInicio}
              minBlockSize="09:00"
              maxBlockSize="18:00"
              style={{
                width: '100%',
              }}
              placeholder="Selecionar hora"
              onChange={(e: any) => {
                if (e) {
                  setHoraInicio(e.target.value);
                }
              }}
              required
            />
          </FormControl>

          <FormControl>
            <FormLabel htmlFor="data" width="100%">
              Horário - Fim
            </FormLabel>
            <Input
              type="time"
              id="horarioFim"
              name="horarioFim"
              value={horaFim}
              style={{ width: '100%' }}
              placeholder="Selecionar hora"
              onChange={(e: any) => {
                if (e) {
                  setHoraFim(e.target.value);
                }
              }}
              required
            />
          </FormControl>

          {/*
           <PickerInput
            name="data"
            label="Data"
            control={control}
            mask="date"
            useMaskedValue
            error={errors.data}
            placeholder="Ex: 01/01/1970"
            icon={FiClock}
          />  <MaskedInput
            name="horarioFim"
            label="Horário - Fim"
            control={control}
            mask="time"
            useMaskedValue
            error={errors.horarioFim}
            placeholder="Ex: 15:50"
          /> */}
        </SimpleGrid>

        <TextAreaInput
          placeholder="Ex: Retorno ao paciente"
          label="Observação (opcional)"
          {...register('observacao')}
        />

        <SimpleGrid minChildWidth="180px" spacing="4" w="100%">
          <Button
            disabled={isLoading}
            type="button"
            onClick={onCancel}
            variant="outline"
            colorScheme="red"
            h="45px"
            borderRadius="28px"
            color="red"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Cancelar
          </Button>

          <Button
            isLoading={isLoading}
            type="submit"
            colorScheme="brand-green"
            h="45px"
            borderRadius="28px"
            color="white"
            fontSize="xs"
            fontWeight="medium"
            textTransform="uppercase"
          >
            Incluir
          </Button>
        </SimpleGrid>
      </VStack>
    </Box>
  );
}

/* <Heading mt="6" mb="4" color="#039c00" fontSize="xl" fontWeight="medium">
        Adicionar escala
      </Heading>

      <VStack spacing="4">
        <Form form={form} layout="vertical" onFinish={onSubmit}>
          <Form.Item initialValue="dia" name="tipoReplica" label="Replicar">
            <Radio.Group size="large">
              <Radio value="dia">Dia</Radio>
              <Radio value="semana">Semana</Radio>
              <Radio value="semanaPadrao">Semana Padrão</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item name="horarioInicio" label="Horário Início">
            <DatePicker
              getPopupContainer={triggerNode => {
                return triggerNode?.parentNode as HTMLElement;
              }}
              size="large"
              style={{ width: '100%' }}
            />
          </Form.Item>

          {/* <Form.Item
            name="destinoReplica"
            label={tipoReplica !== 'dia' ? 'Para a Semana' : 'Para o Dia'}
          >
            {tipoReplica !== 'dia' ? (
              <DatePicker
                picker="week"
                size="large"
                style={{ width: '100%' }}
                getPopupContainer={triggerNode => {
                  return triggerNode?.parentNode as HTMLElement;
                }}
              />
            ) : (
              <DatePicker
                getPopupContainer={triggerNode => {
                  return triggerNode?.parentNode as HTMLElement;
                }}
                size="large"
                style={{ width: '100%' }}
              />
            )}
          </Form.Item>

          <Form.Item name="observacao" label="Observação">
            <Input size="large" />
          </Form.Item>

          <SimpleGrid minChildWidth="120px" spacing="4" w="100%">
            <ButtonUi
              disabled={isLoading}
              type="button"
              onClick={onCancel}
              variant="outline"
              colorScheme="red"
              h="45px"
              borderRadius="28px"
              color="red"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Cancelar
            </ButtonUi>

            <ButtonUi
              isLoading={isLoading}
              type="submit"
              colorScheme="brand-green"
              h="45px"
              borderRadius="28px"
              color="white"
              fontSize="xs"
              fontWeight="medium"
              textTransform="uppercase"
            >
              Incluir
            </ButtonUi>
          </SimpleGrid>
        </Form>
      </VStack> */
