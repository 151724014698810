import { Button, Text, Tab } from '@chakra-ui/react';

interface Props {
  text: string;
}

export function NavButton({ text }: Props) {
  return (
    <Tab
      _selected={{ outline: '0px', outlineOffset: '0px' }}
      _focus={{ outline: '0px', outlineOffset: '0px' }}
    >
      <Button
        maxW={['100%', '350px']}
        w="100%"
        h="55px"
        borderRadius="14px"
        bg="white"
        marginRight="15"
        onClick={() => {}}
        _hover={{ bg: 'brand-dark.100' }}
      >
        <Text
          marginLeft="3"
          color="brand-dark.200"
          fontSize="18px"
          fontWeight="400"
        >
          {text}
        </Text>
      </Button>
    </Tab>
  );
}
