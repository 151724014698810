import { Box, Flex, Stack, Skeleton, useDisclosure } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { parseISO } from 'date-fns';
import {
  MONTHS,
  Day,
  useScheduleDateStore,
  Patient,
} from '@/features/schedule';
import { UnlockScaleCard } from '@/components/Calendar/EventActions/UnlockScaleCard';
import { CalendarPatient } from '@/components/CalendarPatient';
import { ItemsCarousel } from '../SchedulerSection/ItemsCarousel';
import { MonthButton } from '../SchedulerSection/MonthButton';
import { DayButton } from '../SchedulerSection/DayButton';
import { useSchedulePatientQuery } from '../../hooks/useSchedulePatientQuery';

export function SchedulerSectionPatient() {
  const { referenceDate, setReferenceDate } = useScheduleDateStore();
  const [activeMonth, setActiveMonth] = useState(0);
  const [monthSlide, setMonthSlide] = useState<number>(0);
  const [daySlide, setDaySlide] = useState<number>(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(23);
  const [times, setTimes] = useState<any>([]);
  const [days, setDays] = useState<Day[]>([]);
  const [activeDay, setActiveDay] = useState(0);
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: appointments = [], isLoading } = useSchedulePatientQuery();

  const today = new Date();

  function generateDaysInMonth(month: number) {
    const dayInMonth = new Date(today.getFullYear(), month, 1);
    const allDaysInMonth = [];

    while (dayInMonth.getMonth() === month) {
      allDaysInMonth.push({
        numeric: dayInMonth.getDate(),
        name: new Intl.DateTimeFormat('pt-BR', { weekday: 'short' }).format(
          dayInMonth,
        ),
      });

      dayInMonth.setDate(dayInMonth.getDate() + 1);
    }

    setDays(allDaysInMonth);
  }

  function handleMonthSelection(month: number) {
    const parsedReferenceDate = parseISO(referenceDate);

    parsedReferenceDate.setMonth(month);
    parsedReferenceDate.setDate(1);

    setReferenceDate(parsedReferenceDate.toISOString());
  }

  function handleDaySelection(day: number) {
    const parsedReferenceDate = parseISO(referenceDate);

    parsedReferenceDate.setDate(day);

    setReferenceDate(parsedReferenceDate.toISOString());
  }

  useEffect(() => {
    const parsedReferenceDate = parseISO(referenceDate);

    setActiveMonth(parsedReferenceDate.getMonth());
    generateDaysInMonth(parsedReferenceDate.getMonth());
    setActiveDay(parsedReferenceDate.getDate());
  }, [referenceDate]);

  useEffect(() => {
    if (!isLoading && activeDay !== 0) {
      if (activeDay < 3) {
        setDaySlide(activeDay - 1);
      } else {
        setTimeout(() => {
          setDaySlide(activeDay - 3);
        }, 500);
      }

      if (activeMonth < 2) {
        setMonthSlide(activeMonth);
      } else {
        setTimeout(() => {
          setMonthSlide(activeMonth - 2);
        }, 500);
      }
    }
  }, [isLoading, activeDay]);

  useEffect(() => {
    setMin(0);
    if (appointments && appointments.length > 0) {
      const orderTimes = appointments.sort(
        (a: any, b: any) => a.start - b.start,
      );
      const minTime = orderTimes[0].start;
      const maxTime = orderTimes[orderTimes.length - 1].end;

      setTimes(orderTimes);
      setMin(minTime.getHours());

      if (maxTime.toString() !== 'Invalid Date') {
        setMax(maxTime.getHours() + 2 > 13 ? 23 : maxTime.getHours() + 2);
      }
    } else {
      setTimes([]);
    }
  }, [appointments]);

  return (
    <Stack
      spacing="4"
      mt="4"
      w="100%"
      direction={['column', null, null, null, 'row']}
      sx={{
        '.carousel-item': { '&:not(:last-child)': { paddingRight: '20px' } },
        '.carousel-container': { padding: '12px' },
        '.react-multiple-carousel__arrow': { zIndex: 1 },
      }}
    >
      <Flex w="100%" direction="column" align="center">
        <Box maxWidth="1000px" w="100%">
          <ItemsCarousel activeSlide={monthSlide}>
            {MONTHS.map(month => (
              <MonthButton
                key={month.numeric}
                month={month}
                isActive={activeMonth === month.numeric}
                onMonthSelect={handleMonthSelection}
              />
            ))}
          </ItemsCarousel>
        </Box>

        <Box mt="4" maxWidth="1000px" w="100%">
          <ItemsCarousel activeSlide={daySlide}>
            {days.map(day => (
              <DayButton
                key={day.numeric}
                day={day}
                isActive={activeDay === day.numeric}
                onDaySelect={handleDaySelection}
              />
            ))}
          </ItemsCarousel>
        </Box>

        {isLoading ? (
          <Skeleton mt="4" w="100%" h="800px" borderRadius="20px" />
        ) : (
          <Box
            mt="4"
            maxW="100%"
            w="100%"
            h="600px"
            overflowY="auto"
            // overflowX="inherit"
            justifyContent="space-between"
          >
            <CalendarPatient
              defaultView="day"
              events={times}
              toolbar={false}
              scrollToTime={
                min > 0
                  ? new Date(
                      today.getFullYear(),
                      today.getMonth(),
                      today.getDay(),
                      min,
                    )
                  : new Date(
                      today.getFullYear(),
                      today.getMonth(),
                      today.getDay(),
                    )
              }
              min={
                new Date(today.getFullYear(), today.getMonth(), today.getDay())
              }
              max={
                new Date(
                  today.getFullYear(),
                  today.getMonth(),
                  today.getDay(),
                  max,
                )
              }
            />
          </Box>
        )}
      </Flex>

      {isOpen && <UnlockScaleCard onCancel={onClose} event={selectedPatient} />}
    </Stack>
  );
}
