/* eslint-disable eqeqeq */
/* eslint-disable react/jsx-no-useless-fragment */
import { useState, useEffect, ChangeEvent } from 'react';
import {
  Button,
  Select,
  HStack,
  Input,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
  Text,
  List,
  InputGroup,
  InputRightElement,
  Heading,
  Box,
  useToast,
  Image,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import registerConfirmationImg from '@/assets/register_confirmed.svg';
import {
  useAddUserModalStore,
  useInstanciaStore,
  useProfessionalStore,
  useUnidadeStore,
  useUserStore,
} from '@/stores';
import { api } from '@/lib';
import { SelectInput } from '@/components';
import { useInstanceQuery, useUnidadesIdQuery } from '@/hooks';
import '@/styles/global.css';
import { DatePicker, Calendar, theme } from 'antd';
import type { CalendarProps } from 'antd';
import type { Dayjs } from 'dayjs';
import moment from 'moment';
import { useFetchAssociatedProfessionalsByUnit } from '../../hooks/useFetchUnitsCodePro';

interface RequestConsultationModalProps {
  isOpen: boolean;
  onClose: () => void;
  refetch: () => void;
}

type CurrentPatient = {
  cpf: string;
  id: string;
  nome: string;
  associado?: boolean;
};

export function RequestConsultationModal({
  isOpen,
  onClose,
  refetch,
}: RequestConsultationModalProps) {
  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });
  const [inputFocus, setInputFocus] = useState(false);
  const [namePatient, setNamePatient] = useState('');
  const [hasValueDigitized, setHasValueDigitized] = useState(false);
  const [openModalAssociate, setOpenModalAssociate] = useState(false);

  const [patientsInput, setPatientsInput] = useState<
    CurrentPatient[] | undefined
  >([]);
  const [patientsInputBackup, setPatientsInputBackup] = useState<
    CurrentPatient[] | undefined
  >([]);
  const user = useUserStore(state => state.user);

  const [profSelected, setSelectedProf] = useState<CurrentPatient | any>({
    cpf: '',
    id: '',
    nome: '',
    associado: false,
  });
  const [typeConsultation, setTypeConsultation] = useState<any>(null);
  const [validat, setValidat] = useState<boolean>(true);
  const instances = useInstanciaStore(state => state.instancia);
  const [instanciaId, setInstanciaId] = useState(instances?.id);
  const { data: unidades = [] } = useUnidadesIdQuery(instanciaId || '');
  const [unidadeId, setUnidadeId] = useState('');
  const unidadeSet = useUnidadeStore(state => state.unidade);
  const [unidadeStore, setUnidadeStore] = useState(unidadeSet?.id);
  const [professionals, setProfessionals] = useState<any>([]);
  const [freeAppointments, setFreeAppointments] = useState<any[]>([]);
  const [freeTimeAppointmentList, setFreeTimeAppointmentList] = useState<any[]>(
    [],
  );
  const [selectedTime, setSelectedTime] = useState(null);
  const [tipoConsulta, setTipoConsulta] = useState<any>(null);
  const [formStep, setFormStep] = useState(0);

  useEffect(() => {
    if (unidadeId) {
      setPatientsInput(professionals);
      setPatientsInputBackup(professionals);
    }
  }, [professionals]);

  const resetSelect = () => {
    setSelectedProf({
      cpf: '',
      id: '',
      nome: '',
    });
    setNamePatient('');
  };

  const changeNamePatient = (event: any) => {
    resetSelect();
    setNamePatient(event.target.value);
    setHasValueDigitized(true);

    if (!event.target.value) {
      setPatientsInput(patientsInputBackup);
      setHasValueDigitized(false);
      return;
    }

    const patientsFiltered = professionals?.filter(patient =>
      patient.nome.toLowerCase().includes(event.target.value.toLowerCase()),
    );
    setPatientsInput(patientsFiltered);
  };

  const closeModal = () => {
    onClose();
    setNamePatient('');
    setHasValueDigitized(false);
    setPatientsInput(patientsInputBackup);
    setTypeConsultation(null);
    setValidat(true);
    resetSelect();
  };
  const [loading, setLoading] = useState(false);

  const fetchData = async () => {
    if (user) {
      setLoading(true);
      try {
        const res = await useFetchAssociatedProfessionalsByUnit(
          user?.pacienteId,
          unidadeId,
        );
        setProfessionals(res);
      } finally {
        setLoading(false);
      }
    }
  };

  const verifyFreeTime = async (date: any, medicoId?: string) => {
    setLoading(true);

    if ((profSelected && profSelected?.medicoId) || medicoId) {
      try {
        const { data: response } = await api.get('/agenda/disponivel', {
          params: {
            data: date,
            idUsuarioProfissional: profSelected?.medicoId || medicoId,
            idUnidade: unidadeId,
          },
        });
        const horarios = response.data
          .filter((d: any) => {
            return d.horasAgendas.length !== 0 && d.status !== 4 ? d : null;
          })
          .sort(
            (a: any, b: any) =>
              a.horarioInicio.replace(/:/g, '') -
              b.horarioInicio.replace(/:/g, ''),
          );

        setFreeTimeAppointmentList(horarios);
      } catch (err) {
        toast({
          title: 'Erro ao buscar os horários livres',
          status: 'error',
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const selectPatient = (patient: CurrentPatient) => () => {
    setSelectedProf(patient);
    setNamePatient(patient.nome);
    setInputFocus(false);
    verifyFreeTime(new Date(), patient.medicoId);
  };

  useEffect(() => {
    fetchData();
  }, [unidadeId]);

  const confirm = async () => {
    if (!selectedTime) {
      toast({
        title: 'Selecione um horário.',
        status: 'error',
      });
      return;
    }
    if (!tipoConsulta) {
      toast({
        title: 'Selecione tipo de consulta.',
        status: 'error',
      });
      return;
    }
    setLoading(true);
    const selected = freeTimeAppointmentList.find(
      (item: any) => item.id === selectedTime,
    );
    try {
      await api.get('/paciente/vincular-consulta', {
        params: {
          idProfissional: profSelected?.medicoId,
          idPaciente: user.pacienteId,
          idHorasAgenda: selected.horasAgendas[0].id,
          idUnidade: unidadeId,
          tipoConsulta,
        },
      });
      refetch();
      setFormStep(1);
    } catch (err) {
      toast({
        title:
          'Agendamento não realizado. O horário selecionado pode não está mais disponível.',
        status: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const current = new Date();
    const arr = freeTimeAppointmentList
      .sort((a, b) => {
        const horarioInicioA = Date.parse(`2024-05-17T${a.horarioInicio}:00`);
        const horarioInicioB = Date.parse(`2024-05-17T${b.horarioInicio}:00`);
        if (horarioInicioA < horarioInicioB) {
          return -1;
        }
        if (horarioInicioA > horarioInicioB) {
          return 1;
        }
        return 0;
      })
      .map((time: any) => {
        const dataAppointment = new Date(time.data);
        const [horaInicio, minute] = time.horarioInicio.split(':');

        return {
          label: time.horarioInicio.slice(0, 5),
          value: time.id,
          disabled:
            dataAppointment.getDate() <= current.getDate() &&
            (current.getHours() > horaInicio ||
              (current.getHours() === horaInicio &&
                current.getMinutes() < minute)),
        };
      });
    setFreeAppointments(arr);
  }, [freeTimeAppointmentList]);

  useEffect(() => {
    setFreeAppointments([]);
  }, [profSelected]);

  useEffect(() => {
    resetSelect();
    setFormStep(0);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={closeModal} size="2xl" isCentered>
      <ModalOverlay />
      <ModalContent p="8" borderRadius="20px">
        {formStep === 0 && (
          <ModalHeader
            as={HStack}
            spacing="6"
            alignItems="flex-start"
            justifyContent="center"
          >
            <Text color="brand-dark.300" fontSize="2xl" fontWeight="medium">
              Agendar Consulta
            </Text>
          </ModalHeader>
        )}
        <ModalCloseButton />

        <ModalBody pb="8">
          {formStep === 0 && (
            <Box>
              <SelectInput
                label="Unidades de Saúde"
                options={unidades}
                placeholder="Selecione unidade de saúde"
                onChange={e => setUnidadeId(e.target.value)}
              />
              <Text
                textAlign="left"
                width="100%"
                fontWeight={500}
                color="brand-dark.500"
                fontSize={16}
                marginTop={5}
              >
                Profissional de Saúde
              </Text>
              <InputGroup mb="8px">
                <Input
                  type="text"
                  placeholder="Nome"
                  onChange={changeNamePatient}
                  value={namePatient}
                  onFocus={() => setInputFocus(true)}
                  onBlur={() =>
                    setTimeout(() => {
                      setInputFocus(false);
                    }, 500)
                  }
                />
                <InputRightElement
                  pointerEvents="none"
                  children={<SearchIcon color="brand-dark.300" />}
                />
              </InputGroup>
              {patientsInput && patientsInput.length ? (
                <>
                  {inputFocus && (
                    <List
                      mb="6"
                      style={{ maxHeight: '10rem', overflowY: 'scroll' }}
                    >
                      {patientsInput
                        .filter(
                          (d: { primeiroAcesso: boolean }) => !d.primeiroAcesso,
                        )
                        .map(patient => (
                          <Flex
                            style={{ cursor: 'pointer' }}
                            justifyContent="space-between"
                            key={patient.id}
                            onClick={selectPatient(patient)}
                          >
                            <Flex p="2" flexDirection="column">
                              <Text fontSize="sm" color="brand-dark.200">
                                {patient.nome.split('@', 1)[0]}
                              </Text>
                            </Flex>
                          </Flex>
                        ))}
                    </List>
                  )}
                </>
              ) : (
                <>
                  {hasValueDigitized && (
                    <List mb="6" style={{ maxHeight: '10rem' }}>
                      <Flex flexDirection="column">
                        <Text
                          style={{ fontWeight: '500' }}
                          fontSize="sm"
                          color="brand-red.600"
                        >
                          Profissional não encontrado*
                        </Text>
                      </Flex>
                    </List>
                  )}
                </>
              )}
              {profSelected?.id && (
                <>
                  <Text
                    textAlign="left"
                    width="100%"
                    fontWeight={500}
                    color="brand-dark.500"
                    fontSize={16}
                    marginTop={5}
                  >
                    Selecionar Data
                  </Text>
                  <Box border="1px solid #999999" borderRadius="10">
                    <Calendar
                      fullscreen={false}
                      onSelect={(newValue: Dayjs) => {
                        setSelectedTime(null);
                        verifyFreeTime(newValue.format('YYYY-MM-DD'));
                      }}
                      disabledDate={(current: any) => {
                        return moment().add(-1, 'days') >= current;
                      }}
                    />
                  </Box>
                  {freeAppointments && freeAppointments.length > 0 ? (
                    <>
                      <Text
                        textAlign="left"
                        width="100%"
                        fontWeight={500}
                        color="brand-dark.500"
                        fontSize={16}
                        marginTop={5}
                      >
                        Horários disponíveis
                      </Text>
                      {freeAppointments.map((free: any) => (
                        <Button
                          fontSize="md"
                          borderRadius="30"
                          bg={
                            selectedTime === free.value ? '#60C2B0' : '#F2F4F7'
                          }
                          color="#002251"
                          margin="2"
                          disabled={free.disabled}
                          onClick={() => {
                            setSelectedTime(
                              selectedTime === free.value ? null : free.value,
                            );
                          }}
                        >
                          {free.label}
                        </Button>
                      ))}
                    </>
                  ) : (
                    <Text
                      textAlign="center"
                      width="100%"
                      fontWeight={500}
                      color="brand-dark.500"
                      fontSize={16}
                      marginTop={5}
                    >
                      Não há horários disponíveis para esta data
                    </Text>
                  )}

                  {selectedTime && (
                    <>
                      <Text
                        textAlign="left"
                        width="100%"
                        fontWeight={500}
                        color="brand-dark.500"
                        fontSize={16}
                        marginTop={5}
                      >
                        Tipo de consulta
                      </Text>
                      <Select
                        size="lg"
                        variant="filled"
                        placeholder="Selecionar"
                        w="100%"
                        h="52px"
                        borderColor="brand-dark.100"
                        bg="white"
                        color="brand-dark.500"
                        _placeholder={{ color: '#bfbfbf' }}
                        _hover={{ bg: 'brand-dark.100' }}
                        onChange={event => {
                          return setTipoConsulta(event.target.value);
                        }}
                      >
                        <option value={1}>Online</option>
                        <option value={0}>Presencial</option>
                      </Select>
                    </>
                  )}
                </>
              )}

              <SimpleGrid minChildWidth="120px" spacing="4" w="100%" mt="6">
                <Button
                  type="button"
                  variant="outline"
                  colorScheme="brand-orange"
                  h="45px"
                  borderRadius="28px"
                  color="brand-orange.300"
                  fontSize="xs"
                  fontWeight="medium"
                  textTransform="uppercase"
                  onClick={onClose}
                >
                  Cancelar
                </Button>

                <Button
                  type="button"
                  colorScheme="brand-green"
                  h="45px"
                  w="100%"
                  borderRadius="28px"
                  color="white"
                  fontSize="xs"
                  fontWeight="medium"
                  textTransform="uppercase"
                  isLoading={loading}
                  onClick={confirm}
                >
                  Agendar
                </Button>
              </SimpleGrid>
            </Box>
          )}
          {formStep === 1 && (
            <Flex w="100%" direction="column" align="center">
              <Heading
                color="brand-dark.500"
                fontSize="xl"
                fontWeight="medium"
                textAlign="center"
                mb="2"
              >
                Agendamento Reservado com Sucesso!
              </Heading>
              <Heading
                color="brand-dark.300"
                fontSize="md"
                fontWeight="medium"
                textAlign="center"
              >
                Seu agendamento foi reservado com sucesso! {`\n`}Aguarde o
                médico confirmar.
              </Heading>

              <Image
                mt="8"
                mb="4"
                src={registerConfirmationImg}
                alt="Mulher confirmando o sucesso de uma operação em um celular grande"
              />

              <Text
                color="brand-dark.200"
                fontSize="sm"
                fontWeight="normal"
                textAlign="center"
              >
                Você pode visualizar seu horário em sua agenda.
              </Text>

              <Button
                type="button"
                onClick={onClose}
                colorScheme="brand-green"
                mt="10"
                h="45px"
                borderRadius="28px"
                color="white"
                fontSize="xs"
                fontWeight="medium"
                textTransform="uppercase"
              >
                Finalizar
              </Button>
            </Flex>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
