import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  SimpleGrid,
  Text,
  Icon,
  Heading,
  VStack,
} from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { BiCheck, BiCheckCircle, BiX } from 'react-icons/bi';
import { useNavigate } from 'react-router-dom';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refetch?: any;
  isLoading: any;
}

const URLPortal = 'https://staging-embedded.nexodata.com.br/v3/prescription/';

export function PrescriptionModal({
  isOpen,
  onClose,
  refetch,
  isLoading,
}: Props) {
  const navigate = useNavigate();
  const newPrescription = () => {
    refetch();
    onClose();
  };

  return (
    <Flex alignContent="center" alignItems="center">
      <Box
        background="#fff"
        borderRadius="25px"
        box-shadow="0 2px 8px #333"
        min-width="320px"
        minHeight="250px"
        position="absolute"
        left="50%"
        padding="24px"
        top="50%"
        transform="translate(-50%, -50%)"
        alignItems="center"
      >
        <VStack spacing="4" w="100%" alignItems="center">
          <Icon as={BiCheckCircle} fontSize="80px" color="#60C2B0" />
        </VStack>
        <Heading
          mt="6"
          mb="4"
          color="brand-dark.300"
          fontSize="xl"
          fontWeight="medium"
        >
          <Text color="text.lightened" fontSize="2xl" fontWeight="medium">
            Prescrição realizada com sucesso{' '}
          </Text>
        </Heading>
        <VStack spacing="4" w="100%" marginTop="40px">
          <SimpleGrid minChildWidth="120px" spacing="4" w="100%">
            <Button
              type="button"
              isLoading={isLoading}
              colorScheme="teal"
              py="12px"
              px="64px"
              w={['100%', 'auto']}
              borderRadius="20px"
              bg="#60C2B0"
              fontSize="xs"
              fontWeight="600"
              textTransform="uppercase"
              onClick={newPrescription}
            >
              Prescrição
            </Button>
            <Button
              type="button"
              isLoading={isLoading}
              colorScheme="teal"
              py="12px"
              px="64px"
              w={['100%', 'auto']}
              borderRadius="20px"
              bg="#0005"
              fontSize="xs"
              fontWeight="600"
              textTransform="uppercase"
              onClick={() => {
                navigate('/app/start');
              }}
            >
              Sair
            </Button>
          </SimpleGrid>
        </VStack>
      </Box>
    </Flex>
  );
}
