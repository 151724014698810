import { FormControl, FormLabel, Select, SelectProps } from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { FieldError } from 'react-hook-form';
import { MdOutlineArrowDropDown } from 'react-icons/md';

interface SelectInputProps extends SelectProps {
  label: string;
  options: {
    label: string;
    value: string;
  }[];
  error?: FieldError;
}

const SelectInputBase: ForwardRefRenderFunction<
  HTMLSelectElement,
  SelectInputProps
> = ({ name, label, options, error, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Select
        {...rest}
        id={name}
        name={name}
        ref={ref}
        icon={<MdOutlineArrowDropDown />}
        variant="filled"
        h="50px"
        borderColor="rgba(0, 0, 0, 0.12)"
        borderWidth={error ? '2px' : '1px'}
        background="white"
        color="brand-dark.500"
        _hover={{
          borderColor: 'brand-green.400',
        }}
        sx={{
          option: {
            background: 'white',
          },
        }}
      >
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
    </FormControl>
  );
};

export const SelectInput = forwardRef(SelectInputBase);
