import { useMemo, useState } from 'react';
import { Tooltip } from 'antd';
import {
  Box,
  Button,
  Flex,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  SimpleGrid,
  Text,
  useDisclosure,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { CgArrowsExchangeAlt, CgLoadbarSound } from 'react-icons/cg';
import { IoAddOutline, IoLockClosedOutline } from 'react-icons/io5';
import { useProfessionalStore, useUserStore } from '@/stores';
import { ScaleOptions } from '@/features/schedule';
import { AddScaleForm } from './AddScaleForm';
import { ReplicateScaleForm } from './ReplicateScaleForm';
import { AddBatchForm } from './AddBatchForm';
import { LockScaleForm } from './LockScaleForm';

export function ScaleActions({ pageStart }: any) {
  const [scaleOption, setScaleOption] = useState<ScaleOptions | null>(null);
  const user = useUserStore(state => state.user);
  const pro = useProfessionalStore(state => state.professional);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast({
    variant: 'solid',
    position: 'top-right',
    isClosable: true,
  });

  function handleScaleOptionSelection(option: ScaleOptions) {
    if (!pro && user?.type !== 'medico') {
      toast({
        title: 'Selecione um profissional de saúde.',
        status: 'error',
      });
    } else {
      setScaleOption(option);
      onOpen();
    }
  }

  function renderModalContent() {
    if (scaleOption === 'add') {
      return <AddScaleForm onCancel={onClose} pageStart={pageStart} />;
    }

    if (scaleOption === 'replicate') {
      return <ReplicateScaleForm onCancel={onClose} />;
    }

    if (scaleOption === 'batch') {
      return <AddBatchForm onCancel={onClose} pageStart={pageStart} />;
    }

    if (scaleOption === 'lock') {
      return <LockScaleForm onCancel={onClose} pageStart={pageStart} />;
    }

    return null;
  }

  return (
    <Box
      p="3"
      minW="200px"
      bg="white"
      alignItems="center"
      alignContent="center"
      textAlign="center"
      maxW={['100%', '200px']}
      w="100%"
      h="52px"
      borderRadius="14px"
      color="brand-dark.500"
    >
      {/* <Heading size="md" color="brand-dark.300" fontWeight="semibold">
        Ações nas Escalas
      </Heading> */}

      <Flex marginTop="0" justifyContent="space-between">
        <Tooltip placement="bottom" title="Adicionar consulta">
          <Text
            bg="#fff"
            color="#039c00"
            fontFamily="Rubik"
            fontWeight="normal"
            ml="3"
            fontSize="20px"
            _hover={{
              cursor: 'pointer',
            }}
            onClick={() => handleScaleOptionSelection('add')}
          >
            U
          </Text>
        </Tooltip>

        <Tooltip placement="bottom" title="Adicionar em lote">
          <Text
            bg="#fff"
            color="#ee7e44"
            fontFamily="Rubik"
            fontWeight="normal"
            ml="3"
            fontSize="20px"
            _hover={{
              cursor: 'pointer',
            }}
            onClick={() => handleScaleOptionSelection('batch')}
          >
            R
          </Text>
        </Tooltip>

        <Tooltip placement="bottom" title="Replicar escala">
          <Text
            bg="#fff"
            color="#60c0af"
            fontFamily="Rubik"
            fontWeight="normal"
            ml="3"
            fontSize="20px"
            _hover={{
              cursor: 'pointer',
            }}
            onClick={() => handleScaleOptionSelection('replicate')}
          >
            E
          </Text>
        </Tooltip>

        <Tooltip placement="bottom" title="Bloquear horário">
          <Icon
            bg="#fff"
            color="#ee7e44"
            alignSelf="center"
            fontSize="18px"
            _hover={{
              cursor: 'pointer',
            }}
            onClick={() => handleScaleOptionSelection('lock')}
            as={IoLockClosedOutline}
          />
        </Tooltip>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody px="12" pb="8">
            {renderModalContent()}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
}

/* <Button
          onClick={() => handleScaleOptionSelection('add')}
          leftIcon={<Icon as={IoAddOutline} mb="1" fontSize="24px" />}
          colorScheme="white"
          w="100%"
          h="45px"
          borderRadius="28px"
          color="#039c00"
          fontSize="xs"
          fontWeight="medium"
          boxShadow="1px 1px 8px -4px rgba(5, 6, 26, 0.36)"
          textTransform="uppercase"
        >
          Adicionar
        </Button> */
/* <Button
          onClick={() => handleScaleOptionSelection('replicate')}
          leftIcon={<Icon as={CgArrowsExchangeAlt} mb="1" fontSize="24px" />}
          colorScheme="white"
          w="100%"
          h="45px"
          borderRadius="28px"
          color="#60c0af"
          fontSize="xs"
          fontWeight="medium"
          boxShadow="1px 1px 8px -4px rgba(5, 6, 26, 0.36)"
          textTransform="uppercase"
        >
          Replicar
        </Button>  <Button
          onClick={() => handleScaleOptionSelection('batch')}
          leftIcon={<Icon as={CgLoadbarSound} mb="1" fontSize="24px" />}
          colorScheme="white"
          w="100%"
          h="45px"
          borderRadius="28px"
          color="#ee7e44"
          fontSize="xs"
          fontWeight="medium"
          boxShadow="1px 1px 8px -4px rgba(5, 6, 26, 0.36)"
          textTransform="uppercase"
        >
          Lote
        </Button>  <Button
          onClick={() => handleScaleOptionSelection('lock')}
          leftIcon={<Icon as={IoLockClosedOutline} mb="1" fontSize="24px" />}
          colorScheme="white"
          w="100%"
          h="45px"
          borderRadius="28px"
          color="red"
          fontSize="xs"
          fontWeight="medium"
          boxShadow="1px 1px 8px -4px rgba(5, 6, 26, 0.36)"
          textTransform="uppercase"
        >
          Bloquear
        </Button> */
