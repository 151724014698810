import {
  Box,
  Image,
  Text,
  Flex,
  Grid,
  GridItem,
  Heading,
} from '@chakra-ui/react';
import sidebarLogoImg from '@/assets/logo_sidebar.png';
import { PrivacyPages } from './Privacy';

export function PublicPrivacyPage() {
  return (
    <Flex mt="4" h="100%" justify="center" align="start">
      <Box
        marginLeft="-1"
        position="absolute"
        right="5%"
        zIndex="3"
        width="auto"
        mb="10"
      >
        <Image
          src={sidebarLogoImg}
          alt="Dynamic Medic Health Logo"
          w="100%"
          h="auto"
        />
      </Box>
      <Box mt="60px">
        <PrivacyPages />
        <Flex
          color="brand-dark.500"
          fontWeight="medium"
          paddingBottom="19"
          paddingTop="5"
          marginTop="5"
          justify="center"
          borderTop="2px"
          borderColor="brand-dark.100"
          textColor="brand-dark.300"
        >
          DM HEALTHCARE LTDA. - Todos os direitos reservados
        </Flex>
      </Box>
    </Flex>
  );
}
