import useGetQuestions from '@/features/historic/hooks/useGetQuestions';
import { Text, Box, Select, SimpleGrid } from '@chakra-ui/react';

interface SearchInputProps {
  onQuestionSelect: (id: string) => void;
}

export function SearchInput({ onQuestionSelect }: SearchInputProps) {
  const { data: quizzes } = useGetQuestions({});

  return (
    <SimpleGrid minChildWidth="180px" gap={2} w="100%" marginBottom="10">
      <Box maxW={['100%', '390px']} w="100%">
        <Select
          size="lg"
          variant="filled"
          placeholder="Selecionar"
          w="100%"
          h="52px"
          borderColor="brand-dark.100"
          bg="white"
          color="brand-dark.500"
          _placeholder={{ color: '#bfbfbf' }}
          _hover={{ bg: 'brand-dark.100' }}
          onChange={event => {
            return onQuestionSelect(event.target.value);
          }}
        >
          {quizzes?.map(quiz => (
            <option value={quiz.key}>{quiz.name}</option>
          ))}
        </Select>
      </Box>
    </SimpleGrid>
  );
}
