import { extendTheme } from '@chakra-ui/react';

const colors = {
  'brand-blue': {
    100: '#6d7998',
    200: '#5d6b90',
    300: '#4f5f89',
    400: '#415383',
    500: '#33487e',
    600: '#263e7c',
    700: '#19347a',
    800: '#1f3365',
    900: '#223055',
  },
  'brand-green': {
    50: '#eff9f7',
    100: '#c9e5d9',
    200: '#aad2c1',
    300: '#8cbfa9',
    400: '#7ab49b',
    500: '#539377',
    600: '#3e725d',
    700: '#2b5241',
    800: '#173227',
    900: '#001309',
  },
  'brand-orange': {
    50: '#ffeddf',
    100: '#fdceb5',
    200: '#f6ad88',
    300: '#f18e5a',
    400: '#ee7e44',
    500: '#d25413',
    600: '#a5410d',
    700: '#762e08',
    800: '#481a01',
    900: '#1e0600',
  },
  'brand-red': {
    100: '#efe4e4',
    200: '#e3c8c6',
    300: '#dbaaa7',
    400: '#da8b85',
    500: '#df6960',
    600: '#ec4236',
    700: '#d53b30',
    800: '#b43f37',
    900: '#96433d',
  },
  'brand-violet': {
    100: '#c6b4c9',
    200: '#b99cbe',
    300: '#af84b7',
    400: '#a96bb4',
    500: '#a64fb5',
    600: '#a13ab3',
    700: '#9c27b0',
    800: '#842f92',
    900: '#70347b',
  },
  'brand-dark': {
    100: 'rgba(36, 74, 94, 0.1)',
    200: '#959595',
    300: '#707070',
    400: '#454545',
    500: '#24292e',
  },
  bgColor: '#fafafb',
};

const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
};

const fonts = {
  body: 'Montserrat, sans-serif',
  heading: 'Montserrat, sans-serif',
};

const styles = {
  global: {
    'html, body, #root': {
      width: '100%',
      minHeight: '100vh',
    },
    body: {
      background: '#fafafb',
    },
  },
};

export const theme = extendTheme({
  colors,
  config,
  styles,
  fonts,
});
