export const MONTHS = [
  {
    name: 'Janeiro',
    numeric: 0,
  },
  {
    name: 'Fevereiro',
    numeric: 1,
  },
  {
    name: 'Março',
    numeric: 2,
  },
  {
    name: 'Abril',
    numeric: 3,
  },
  {
    name: 'Maio',
    numeric: 4,
  },
  {
    name: 'Junho',
    numeric: 5,
  },
  {
    name: 'Julho',
    numeric: 6,
  },
  {
    name: 'Agosto',
    numeric: 7,
  },
  {
    name: 'Setembro',
    numeric: 8,
  },
  {
    name: 'Outubro',
    numeric: 9,
  },
  {
    name: 'Novembro',
    numeric: 10,
  },
  {
    name: 'Dezembro',
    numeric: 11,
  },
];

export const REPLICATE_TYPE_OPTIONS = [
  {
    label: 'Dia',
    value: 'dia',
  },
  {
    label: 'Semana',
    value: 'semana',
  },
  // {
  //   label: 'Semana Padrão',
  //   value: 'semanaPadrao',
  // },
];
