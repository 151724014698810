import { Navigate, Routes, Route } from 'react-router-dom';

import { NotificationsRoutes } from '@/features/notifications';
import { StartRoutes } from '@/features/start';
import { ReportsRoutes } from '@/features/reports';

import { ScheduleRoutes } from '@/features/schedule';
import {
  PatientsListPage,
  ProfessionalsListPage,
  ManagersListPage,
  SecretariesListPage,
  UpdateUserOptionsPage,
  RegisterNewUserPage,
} from '@/features/registers';

import { AppLayout } from '@/components';
import { useUserStore } from '@/stores';
import { HistoricPages } from '@/features/historic';
import {
  InstanciasListPage,
  RegisterNewInstancePage,
  UnidadesListPage,
} from '@/features/instances';
import { PrescriptionPages } from '@/features/prescription';
import { RegisterNewPrescriptionPage } from '@/features/prescription/pages/RegisterNewPrescription';
import { HistoricPatientPages } from '@/features/historic/pages/HistoricPatient';
import { RegisterNewMessagesPage } from '@/features/messages/pages/RegisterNewMessages';
import { PrivacyRoutes } from '@/features/privacy/routes';
import { PublicPrivacyPage } from '@/features/privacy/pages';
import { TermsRoutes } from '@/features/terms/routes';
import { RegisterNewMonitoringPage } from '@/features/historic/components/Drawer/Monitoring/RegisterNewMonitoring';
import { ChatPage } from '@/features/chat/pages/Chat';

export function PrivateRoutes() {
  const user = useUserStore(state => state.user);

  if (user?.type === 'paciente') {
    return (
      <>
        <Routes>
          <Route path="/app" element={<AppLayout />}>
            <Route index element={<Navigate to="start" />} />
            <Route path="start/*" element={<StartRoutes />} />
            <Route path="schedule/*" element={<ScheduleRoutes />} />
            <Route path="patients/*" element={<HistoricPages />} />
            <Route path="historic" element={<HistoricPatientPages />} />
            <Route path="reports/*" element={<ReportsRoutes />} />
            <Route path="privacy/*" element={<PrivacyRoutes />} />{' '}
            <Route path="terms/*" element={<TermsRoutes />} />
            <Route path="notifications/*" element={<NotificationsRoutes />} />
            <Route path="chat/*" element={<ChatPage />} />
            <Route path="*" element={<Navigate to="start" />} />
          </Route>
          <Route path="/privacy" element={<PublicPrivacyPage />} />
          <Route path="*" element={<Navigate to="/app" />} />
        </Routes>

        {/* /* Modal de envio de uma nova mensagem  */}
        <RegisterNewMessagesPage />
      </>
    );
  }

  return (
    <>
      <Routes>
        <Route path="/app" element={<AppLayout />}>
          <Route index element={<Navigate to="start" />} />
          <Route path="start/*" element={<StartRoutes />} />
          <Route path="schedule/*" element={<ScheduleRoutes />} />
          <Route path="instanciasList/*" element={<InstanciasListPage />} />
          <Route path="chat/*" element={<ChatPage />} />
          {user?.type === 'medico' && (
            <>
              <Route path="patients/*" element={<HistoricPages />} />
              <Route path="historic" element={<HistoricPatientPages />} />
              <Route path="prescriptions/*" element={<PrescriptionPages />} />
            </>
          )}
          <Route path="patientsList/*" element={<PatientsListPage />} />
          <Route path="secretariesList/*" element={<SecretariesListPage />} />
          <Route path="unidadesList/*" element={<UnidadesListPage />} />
          <Route
            path="professionalsList/*"
            element={<ProfessionalsListPage />}
          />
          <Route path="managersList/*" element={<ManagersListPage />} />
          <Route path="privacy/*" element={<PrivacyRoutes />} />
          <Route path="terms/*" element={<TermsRoutes />} />
          <Route path="reports/*" element={<ReportsRoutes />} />
          <Route path="notifications/*" element={<NotificationsRoutes />} />
          <Route path="*" element={<Navigate to="start" />} />
        </Route>
        <Route path="*" element={<Navigate to="/app" />} />
      </Routes>

      {/* /* Modal criação de um novo usuário  */}
      {/* <NewUserOptionsPage /> */}
      <RegisterNewUserPage />

      {/* /* Modal edição de um novo usuário  */}
      <UpdateUserOptionsPage />

      {/* /* Modal criação de uma nova instancia/unidade  */}
      <RegisterNewInstancePage />

      {/* /* Modal criação de uma nova prescrição  */}
      <RegisterNewPrescriptionPage />

      {/* /* Modal de envio de uma nova mensagem  */}
      <RegisterNewMessagesPage />

      {/* /* Modal de monitoramento  */}
      <RegisterNewMonitoringPage />
    </>
  );
}
