import { apiQuestions } from '@/lib';
import { useQuery } from 'react-query';

interface useGetQuestionDisparoResponsesProps {
  questionarioUsuarioId?: number;
  grupoId?: string;
}

const useGetQuestionDisparoResponses = ({
  questionarioUsuarioId,
  grupoId,
}: useGetQuestionDisparoResponsesProps) => {
  const { data, isLoading, isError, refetch } = useQuery({
    queryKey: ['question-disparo-responses', questionarioUsuarioId, grupoId],
    queryFn: async () => {
      if (!questionarioUsuarioId || !grupoId) {
        return [];
      }

      const res = await apiQuestions.get(`questionario-cliente/respostas`, {
        params: {
          questionarioUsuarioId,
          grupoId,
        },
      });
      const dataResponse = res.data?.respostas?.sort(
        (
          a: { creationTime: string | number | Date },
          b: { creationTime: string | number | Date },
        ) => {
          // @ts-ignore
          return new Date(b.creationTime) - new Date(a.creationTime);
        },
      );
      return dataResponse;
    },
  });

  return {
    data,
    isLoading,
    isError,
    refetch,
  };
};

export default useGetQuestionDisparoResponses;
