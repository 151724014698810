import { Patient } from '@/features/schedule';
import { CancelScale } from '@/features/schedule/components/SchedulerSection/ScaleActions/CancelScale';
import { useSchedulePatientQuery } from '@/features/schedule/hooks/useSchedulePatientQuery';
import { PatientScreenInfoModal } from '@/features/start/components/SchedulePatientSection/PatientScreenInfoModal';
import {
  Box,
  Button,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Flex,
  Icon,
} from '@chakra-ui/react';
import { Dropdown, Menu } from 'antd';
import format from 'date-fns/format';
import { useEffect, useState } from 'react';
import { HiOutlineDotsVertical } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';

export function CustomEventPatient({ event }: any) {
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
  const { data: appointments = [], isLoading } = useSchedulePatientQuery();
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [options, setOptions] = useState<any>([]);

  const navigate = useNavigate();

  const [scaleOption, setScaleOption] = useState<string | null>(null);

  function handleScaleOptionSelection(option: any) {
    if (option === 'consulta' || option === 'agendar') {
      onOpen();
      return;
    }
    if (option === 'monitorar') {
      return;
    }

    setScaleOption(option);

    setCancelModalOpen(true);
  }

  function onCloseCancel() {
    setCancelModalOpen(false);
  }

  const handleClickListItem = (e: any) => {
    setAnchorEl(e.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function renderModalContent() {
    if (scaleOption === 'cancel') {
      return (
        <CancelScale onCancel={onCloseCancel} event={event} deletar={false} />
      );
    }
    return null;
  }

  useEffect(() => {
    if (event && Boolean(event.cpf)) {
      setOptions([
        // { key: 'consulta', label: 'Consulta' },
        // { key: 'reagendar', label: 'Remarcar' },
        { key: 'cancel', label: 'Cancelar' },
      ]);
    }
  }, [event]);

  return (
    <Box
      w="95%"
      border="1px solid rgba(0,0,0,0.1)"
      borderBottom="5px solid rgba(0,0,0,0.1)"
      borderRadius="8px"
      mt="8"
    >
      <Button
        variant="unstyled"
        style={{ cursor: event.ativo ? 'pointer' : 'default' }}
        w="100%"
        h="auto"
        m="2"
        textAlign="left"
        onClick={() => {
          if (event.ativo) {
            setSelectedPatient(event);
            onOpen();
          }
        }}
      >
        <Box mr="4">
          <Text
            color="brand-dark.300"
            fontSize="xl"
            fontWeight="medium"
            textAlign="left"
          >
            {event && Boolean(event.cpf) // isso é para teste
              ? event.nomeMedico
              : event.ativo
              ? ''
              : 'Agenda bloqueada'}
          </Text>
          <Text
            color="brand-dark.200"
            fontSize="md"
            fontWeight="medium"
            textAlign="left"
          >
            {event && Boolean(event.cpf) // isso é para teste
              ? event.nameUnidade
              : ''}
          </Text>

          {event.start && event.end ? (
            <>
              <Text
                mt="3"
                color="#959595"
                fontSize="sm"
                fontWeight="medium"
                textAlign="left"
              >
                {format(event.start, 'HH:mm')} - {format(event.end, 'HH:mm')}
              </Text>

              {event && Boolean(event.cpf) && event.ativo ? (
                <Text
                  mt="-3"
                  color="brand-dark.300"
                  fontSize="md"
                  fontWeight="medium"
                  textAlign="right"
                >
                  {event.statusConsulta === 5
                    ? 'NEGADO'
                    : event.statusConsulta === 6
                    ? 'CONCLUÍDO'
                    : event.statusConsulta !== 0
                    ? 'PENDENTE'
                    : event.consultation
                    ? 'ON-LINE'
                    : 'PRESENCIAL'}
                </Text>
              ) : (
                <Text
                  mt="3"
                  color="#959595"
                  fontSize="sm"
                  fontWeight="medium"
                  textAlign="left"
                  wordBreak="break-word"
                >
                  {event.obsBloqueio}
                </Text>
              )}
            </>
          ) : null}
        </Box>
      </Button>

      {selectedPatient !== null && (
        <PatientScreenInfoModal
          isOpen={isOpen}
          onClose={onClose}
          patient={selectedPatient}
        />
      )}

      <Flex w="auto">
        <Modal
          isOpen={cancelModalOpen}
          onClose={onCloseCancel}
          size="xl"
          isCentered
        >
          <ModalOverlay />
          <ModalContent>
            <ModalCloseButton />
            <ModalBody px="12" pb="8">
              {renderModalContent()}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Flex>

      {event.statusConsulta !== 6 &&
      event.statusConsulta !== 5 &&
      event.statusConsulta !== 0 ? (
        <Box
          top="0px"
          bg="transparent"
          position="absolute"
          right="14px"
          transition="all 0.2s"
          _hover={{ bg: 'gray.400' }}
          _expanded={{ bg: 'brand-dark.200' }}
          _focus={{ boxShadow: 'outline' }}
        >
          <Dropdown
            overlay={
              <Menu
                style={{
                  width: '200px',
                  backgroundColor: 'white',
                  borderRadius: '10px',
                  boxShadow: '0.8px 0.8px 7px -2px rgba(5, 6, 27, 0.5)',
                  marginLeft: '3%',
                  alignItems: 'center',
                  color: 'brand-dark.300',
                  fontSize: 'md',
                  fontWeight: 'medium',
                }}
              >
                {options
                  .filter((op: any) => !op.disable)
                  .map((op: any, index: any) => (
                    <Menu.Item
                      key={op.key}
                      style={{
                        color: '#707070',
                        fontSize: '20px',
                        textAlign: 'left',
                        wordBreak: 'break-word',
                      }}
                      disabled={op.key === 'monitorar'}
                      onClick={() => {
                        handleClose();
                        handleScaleOptionSelection(op.key);
                      }}
                    >
                      {op.label}
                    </Menu.Item>
                  ))}
              </Menu>
            }
            placement="bottomLeft"
          >
            <Icon
              as={HiOutlineDotsVertical}
              _hover={{
                background: 'transparent',
                border: 'none',
              }}
              fontSize="25px"
              color="brand-dark.500"
            />
          </Dropdown>
        </Box>
      ) : null}
    </Box>
  );
}
