/* eslint-disable import/no-duplicates */
import { useState, useEffect } from 'react';
import {
  Calendar as BigCalendar,
  CalendarProps,
  dateFnsLocalizer,
  ToolbarProps,
} from 'react-big-calendar';
import { Box, Button, Text } from '@chakra-ui/react';

import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import ptBR from 'date-fns/locale/pt-BR';
import '@/styles/calendar.style.css';
import { CustomEventWeek } from './CustomEventWeek';
import Toolbar from './Toolbar';
import Header from './Header';
import { CustomEvent } from '../Calendar/CustomEvent';

const locales = {
  'pt-BR': ptBR,
};

const loc = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// const localizer = momentLocalizer(moment);

export function CalendarWeek(props: Omit<CalendarProps, 'localizer'>) {
  const [newProps, setNewProps] = useState<any>(props);

  useEffect(() => {
    const { events } = props;
    if (events && events?.length > 0) {
      const newEvents = events.map((env, i) => {
        return {
          ...env,
          top: i * 10,
        };
      });
      setNewProps({
        ...props,
        events: newEvents,
      });
    }
  }, [props]);

  const handleNavigate = (date: any, view: any, action: any) => {};

  return (
    <>
      {/* @ts-ignore */}
      <BigCalendar
        {...newProps}
        culture="pt-BR"
        localizer={loc}
        startAccessor="start"
        endAccessor="end"
        defaultView="week"
        views={['week']}
        components={{
          event: CustomEvent,
          toolbar: Toolbar,
          header: Header,
        }}
        dayLayoutAlgorithm="overlap"
        // formats={{
        //   eventTimeRangeFormat: ({ start, end }, culture, local) =>
        //     `${loc.format(start, 'HH:mm')} - ${loc.format(end, 'HH:mm')}`,
        // }}
        formats={{ eventTimeRangeFormat: () => '' }}
        eventPropGetter={(e: any) => {
          return {
            style: {
              cursor: e.ativo ? 'pointer' : 'default',
              position: 'absolute',
              // padding: '10px 10px 50px',
              border: 'none',
              borderRadius: '8px',
              background: 'white',
              // minHeight: '50px',
              borderBottom: '5px solid rgba(0,0,0,0.1)',
              color: '#252525',
              backgroundColor: e.ativo ? 'default' : '#C5C5C5',
              boxShadow: '1px 1px 9px -3px rgba(5, 6, 27, 0.25)',
            },
          };
        }}
      />
    </>
  );
}
