import { useState } from 'react';

import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { useAddInstanceModalStore } from '@/stores';
import { RegisterInstanceForm } from '../components';

export function RegisterNewInstancePage() {
  // const [contentShown, setContentShown] = useState('buttons');

  const {
    isOpen,
    onClose,
    content: contentShown,
    changeContent: setContentShown,
  } = useAddInstanceModalStore();

  function renderModalHeaderTitle() {
    return 'Novo Cadastro';
  }

  function renderModalContent() {
    return (
      <RegisterInstanceForm
        // onCancel={() => {
        //   setContentShown('buttons');
        // }}
        onCancel={onClose}
      />
    );
    // return <RegisterUserForm onCancel={onClose} />;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      onCloseComplete={() => {
        setContentShown('buttons');
      }}
      size="xl"
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color="brand-dark.300"
          fontSize="xl"
          fontWeight="medium"
          textAlign="center"
        >
          {renderModalHeaderTitle()}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="12" pb="8">
          {renderModalContent()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
