import {
  Flex,
  Grid,
  GridItem,
  useBreakpointValue,
  Button,
  Image,
  Badge,
  Icon,
} from '@chakra-ui/react';
import { Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { NotificationsButton } from '@/features/notifications';
import {
  useAddPrescriptionModalStore,
  useUserStore,
  useAddMonitoringModalStore,
} from '@/stores';
import { PrecriptionIcon, TelegramIcon } from '@/assets/icons';
import { IoChatbubblesOutline } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useChatNotifications } from '@/context/chat-notifications';
import { ProfessionalSelect } from './ProfessionalSelect';
import { UnidadeSelect } from './UnidadeSelect';
import { InstanciaSelect } from './InstanciaSelect';
import { HamburgerButton } from './HamburgerButton';
import { UserInfo } from './UserInfo';

export function TopHeader() {
  const isMobile = useBreakpointValue({
    base: true,
    lg: false,
  });
  const navigate = useNavigate();
  const [isProfessionalSelect, setIsProfessionalSelect] = useState<boolean>();
  const user = useUserStore(state => state.user);
  const { onOpen: onAddPrescriptionOpen } = useAddPrescriptionModalStore();
  // const { onOpen: onAddMessageOpen } = useAddMesssageModalStore();
  const { onOpen: onAddMonitOpen } = useAddMonitoringModalStore();
  const { notifications } = useChatNotifications();

  useEffect(() => {
    if (user && user.type !== 'medico' && user.type !== 'paciente') {
      setIsProfessionalSelect(!!user.gestorMasterId);
    }
  }, [user]);

  return (
    <Flex
      as="header"
      px="4"
      h="90px"
      background="transparent"
      // boxShadow="1px 0 20px -3px rgba(0, 0, 0, 0.1)"
      align="center"
      justify={['space-between', 'space-between', 'flex-end']}
    >
      <HamburgerButton />
      <Grid width="100%" templateColumns="repeat(12, 1fr)">
        <GridItem display="flex" colSpan={6}>
          {/* {user?.type !== 'paciente' && ( */}
          <InstanciaSelect /> <UnidadeSelect /> <ProfessionalSelect />
          {/* )} */}
        </GridItem>
        <GridItem display="flex" colSpan={6} justifyContent="flex-end" mr="5">
          <Flex backgroundColor="#fff" borderRadius="50px" alignItems="center">
            {!isMobile && (
              <>
                <Button
                  fontSize="sm"
                  borderRadius="30"
                  bg="#fff"
                  color="#999999"
                  border="1px solid #999999"
                  ml="5"
                  fontFamily="Rubik"
                  fontWeight="normal"
                  _hover={{ backgroundColor: 'transparent' }}
                  onClick={() => {
                    navigate('/app/chat');
                  }}
                >
                  {notifications.length > 0 && (
                    <Badge
                      bg="red"
                      position="absolute"
                      top="-3"
                      right="-1.5"
                      fontFamily="Rubik"
                      color="#fff"
                      px="2"
                      py="1.5"
                      fontSize="12"
                      borderRadius={50}
                    >
                      {notifications.length}
                    </Badge>
                  )}
                  <Icon
                    as={IoChatbubblesOutline}
                    fontSize="20px"
                    marginRight="5"
                    color="brand-dark.300"
                  />
                  CHAT
                </Button>
                {user?.type === 'medico' && (
                  <>
                    <Button
                      fontSize="sm"
                      borderRadius="30"
                      bg="#fff"
                      color="#999999"
                      border="1px solid #999999"
                      ml="5"
                      mr="5"
                      fontFamily="Rubik"
                      fontWeight="normal"
                      _hover={{ backgroundColor: 'transparent' }}
                      onClick={() => onAddPrescriptionOpen()}
                    >
                      <Image
                        width="20px"
                        height="auto"
                        marginRight="5"
                        src={PrecriptionIcon}
                      />
                      PRESCRIÇÃO
                    </Button>
                    <Button
                      fontSize="sm"
                      borderRadius="30"
                      bg="#fff"
                      color="#999999"
                      border="1px solid #999999"
                      mr="5"
                      fontFamily="Rubik"
                      fontWeight="normal"
                      _hover={{ backgroundColor: 'transparent' }}
                      onClick={() => onAddMonitOpen()}
                    >
                      <Image
                        width="20px"
                        height="auto"
                        marginRight="5"
                        src={PrecriptionIcon}
                      />
                      MONITORAR
                    </Button>
                  </>
                )}
                <UserInfo />
              </>
            )}
            <Tooltip placement="bottom" title="Notificações">
              <NotificationsButton />
            </Tooltip>
          </Flex>
        </GridItem>
      </Grid>
    </Flex>
  );
}
