import { Button, Form, Input, Typography } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import styled from 'styled-components';
import { FormProps } from 'antd/lib';

const { Title } = Typography;

export const Content = styled.div`
  min-width: 400px;
  width: 400px;

  @media screen and (max-width: 768px) {
    min-width: 60%;
    width: 90%;
  }
  
  }
`;

export const TextWrapper = styled.div`
  p {
    margin-bottom: 0;
  }

  p > span {
    font-weight: 600;
  }
`;

export const TimeWrapper = styled.div`
  display: flex;
  margin: 1rem 0;
  justify-content: space-between;
  align-items: center;
`;

export const TimeContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;

  span {
    font-size: 1.5rem;
    color: #1677ff;
  }
`;

export const TTextWrapper = styled.div`
  p {
    font-weight: 600;
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
  }

  p > span {
    color: #999999;
    font-weight: 400;

    margin-bottom: 0;
  }
`;

export const Main = styled.main`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;

export const CForm = styled(Form)<FormProps>`
  min-width: 400px;
  width: 400px;

  @media screen and (max-width: 768px) {
    min-width: 60%;
    width: auto;
  }
`;

export const TopContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
`;

export const InputCode = styled(Input)`
  font-size: 24px;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
`;

export const TitleForm = styled(Title)`
  text-align: center;
  font-weight: 600;
  font-size: 24px;
`;

export const SubTitleForm = styled.p`
  color: grey;
  font-weight: 600;
  font-size: 14px;
  margin: 25px 35px 35px;
  text-align: center;
`;

export const Link = styled.a`
  float: right;
`;

export const RouterL = styled(RouterLink)`
  float: right;
`;

export const WrapperItems = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
`;

export const WrapperItemsLink = styled.div`
  display: flex;
  gap: 0.6rem;
  margin-bottom: 1rem;
`;

export const WrapperInfo = styled.div`
  padding: 1.6rem 1rem;
  background: #e3f9f9;
  margin: 1.1rem 0;
  color: #5c5959;
  line-height: 22px;

  span {
    color: #e80d0de0;
  }
`;

export const SButton = styled(Button)`
  width: 100%;
`;

export const SmButton = styled(Button)`
  height: 33px;
  font-size: medium;
  font-weight: 500;
`;

export const WrapperTerms = styled.div`
  margin-top: 0;
  color: #616161;
  font-size: 0.78rem;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const TText = styled.div`
  color: #000;
  margin-bottom: 1rem;
  font-size: 1rem;
`;

interface PassCheckProps {
  checked?: boolean;
}

export const PassCheck = styled.span<PassCheckProps>`
  color: ${({ checked }) => (checked ? '#7AB49B' : '')} !important;
`;

export const QRCode = styled('ant-image')`
  margin: 0 50%;
`;

export const DateBox = styled.div`
  width: 100%;

  border-radius: 10px;
  .ant-picker-large {
    padding: 7px 7px 7px 45px;
    border: 1px solid #718096;
  }

  :hover {
    border-color: #ee7e44;
  }
`;

export const StepIcon = styled.div`
  height: 100%;
  font-size: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 2px;
`;
