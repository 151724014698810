import create from 'zustand';

type AddMonitoringModal = {
  isOpen: boolean;
  content: string;
  changeContent: (value: string) => void;
  onOpen: () => void;
  onClose: () => void;
};

export const useAddMonitoringModalStore = create<AddMonitoringModal>(set => ({
  isOpen: false,
  content: 'buttons',
  changeContent: (value: string) => set(state => ({ content: value })),
  onOpen: () => set(state => ({ isOpen: true })),
  onClose: () => set(state => ({ isOpen: false })),
}));
