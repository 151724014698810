import React from 'react';
import { StepProps as AntdStepProps } from 'antd';
import { FaCheckCircle } from 'react-icons/fa';
import { CSteps, StepIcon } from './styles';

interface StepsProps {
  current: number | undefined;
  status?: 'wait' | 'process' | 'finish' | 'error' | undefined;
  items?: AntdStepProps[] | undefined;
  style?: React.CSSProperties | undefined;
}

export function Steps({ current, items, status, style }: StepsProps) {
  function mountIcon(position: any) {
    if (current && position < current) {
      return (
        <StepIcon color="finish">
          <FaCheckCircle />
        </StepIcon>
      );
    }

    if (position === current) {
      if (status === 'error') {
        return <StepIcon color="error">{position + 1}</StepIcon>;
      }
      return <StepIcon color="process">{position + 1}</StepIcon>;
    }

    return <StepIcon color="wait">{position + 1}</StepIcon>;
  }

  const customItems = items?.map((item, index: number) => {
    return {
      title: item.title,
      icon: mountIcon(index),
    };
  });

  return (
    <CSteps
      current={current}
      status={status}
      items={customItems}
      style={style}
    />
  );
}
