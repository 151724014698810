import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  Textarea,
} from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { BsArrowUpSquare } from 'react-icons/bs';
import { FiArrowUp, FiCopy } from 'react-icons/fi';
import iaSvg from '@/assets/ia_icon.svg';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { api } from '@/lib';
import { useUserStore } from '@/stores';
import CopyToClipboard from 'react-copy-to-clipboard';
import useGetChatId from './hooks/useGetChatId';

export const msgSchema = yup.object().shape({
  message: yup.string().min(1),
});

interface IaChatProps {
  onMessageCallback?: (message: string) => void;
}

export function IaChat({ onMessageCallback }: IaChatProps) {
  const { data: chatId } = useGetChatId();
  const user = useUserStore(state => state.user);
  const textareaRef = useRef<HTMLTextAreaElement | null>(null);

  const handleSendMessage = (message: string) => {
    if (onMessageCallback) {
      onMessageCallback(message);
    }
  };

  const [history, setHistory] = useState<
    { question: string; response: string }[]
  >([]);

  const handleTextareaChange = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = '52px'; // Redefine a altura
      textarea.style.height = `${textarea.scrollHeight}px`; // Ajusta a altura com base no conteúdo
    }
  };

  const {
    handleSubmit,
    control,
    reset,
    formState: { isSubmitting },
  } = useForm<{ message: string }>({
    defaultValues: {
      message: '',
    },
    resolver: yupResolver(msgSchema),
  });

  const handleSubmitMessage = async ({ message }: { message: string }) => {
    const { data } = await api.post('ai/mensagem', {
      chatId,
      usuarioId: user!.id,
      mensagem: message,
    });

    setHistory([{ question: message, response: data.data }, ...history]);

    reset();
    textareaRef.current!.style.height = '52px';
  };

  return (
    <Box
      display="flex"
      flexDir="column"
      gap="2"
      bg="gray.100"
      px="4"
      py="4"
      maxH="60vh"
    >
      <Box
        display="flex"
        flexDir="column-reverse"
        overflow="auto"
        gap="4"
        flex="1"
      >
        {history.map(item => (
          <Flex flexDir="column" key={item.question} gap={2}>
            <Box
              p="4"
              rounded="xl"
              alignSelf="flex-end"
              bg="green.200"
              fontWeight="medium"
              maxW="90%"
              position="relative"
            >
              {item.question}
            </Box>

            <Box
              p="4"
              rounded="xl"
              alignSelf="flex-start"
              bg="white"
              fontWeight="medium"
              maxW="90%"
              w="60vw"
              position="relative"
            >
              <Text size="md" whiteSpace="pre-line">
                {item.response}
              </Text>

              <Flex justifyContent="flex-end" mt="2" gap={2}>
                <CopyToClipboard text={item.response}>
                  <Button size="xs" gap={1} variant="outline">
                    <Icon as={FiCopy} size={20} />
                    Copiar
                  </Button>
                </CopyToClipboard>
                {onMessageCallback && (
                  <Button
                    size="xs"
                    gap={1}
                    variant="outline"
                    onClick={() => handleSendMessage(item.response)}
                  >
                    <Icon as={BsArrowUpSquare} size={20} />
                    P/Orientação
                  </Button>
                )}
              </Flex>
            </Box>
          </Flex>
        ))}
      </Box>
      <Flex>
        <Image src={iaSvg} mt="14px" pr={1} />

        <Flex flex={1} flexDir="row" mt="4" borderRadius="14px" bg="white">
          <Controller
            name="message"
            control={control}
            render={({ field: { value, onChange } }) => (
              <Textarea
                ref={textareaRef}
                disabled={isSubmitting}
                size="lg"
                padding="12px 12px 0"
                placeholder="Faça uma pergunta"
                color="brand-dark.500"
                bg="white"
                border="0px"
                minH="auto"
                maxH="96"
                _focus={{ boxShadow: 'none' }}
                resize="none"
                defaultValue={value}
                value={value}
                onChange={e => {
                  handleTextareaChange();
                  onChange(e.target.value);
                }}
              />
            )}
          />
          <Button
            borderRadius="14px"
            h="52px"
            marginTop="auto"
            bg="#fff"
            _hover={{ backgroundColor: 'green.300' }}
            onClick={handleSubmit(handleSubmitMessage)}
            isLoading={isSubmitting}
          >
            <Icon as={FiArrowUp} fontSize="27px" />
          </Button>
        </Flex>
      </Flex>
    </Box>
  );
}
