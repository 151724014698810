import {
  Box,
  Flex,
  Stack,
  Skeleton,
  useDisclosure,
  Center,
  Text,
  Button,
  VStack,
} from '@chakra-ui/react';
import { useCallback, useContext, useEffect, useState } from 'react';
/* eslint-disable */
import { parseISO } from 'date-fns';

import {
  MONTHS,
  Day,
  useScheduleDateStore,
  Patient,
} from '@/features/schedule';

import { CalendarWeek } from '@/components/CalendarWeek.tsx';
import { ItemsCarousel } from '../SchedulerSection/ItemsCarousel';
import { MonthButton } from '../SchedulerSection/MonthButton';
import { DayButton } from '../SchedulerSection/DayButton';

import { SchedulePatientModal } from '../SchedulerSection/SchedulePatientModal';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import format from 'date-fns/format';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import getDay from 'date-fns/getDay';
import ptBR from 'date-fns/locale/pt-BR';
import moment from 'moment';
import {
  useScheduleIntervalQuery,
  useScheduleWeekQuery,
} from '../../hooks/useScheduleIntervalQuery';
import { PatientInfoModal } from '@/features/start/components/ScheduleSection/PatientInfoModal';
import { useViewContext } from '../../hooks/useViewContext';

const locales = {
  'pt-BR': ptBR,
};

const localizer = momentLocalizer(moment);

export function SchedulerSectionWeek() {
  const { referenceDate, setReferenceDate } = useScheduleDateStore();

  const [activeMonth, setActiveMonth] = useState(0);
  const [monthSlide, setMonthSlide] = useState<number>(0);
  const [daySlide, setDaySlide] = useState<number>(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(23);
  const [times, setTimes] = useState<any>([]);
  const [days, setDays] = useState<Day[]>([]);
  const [activeDay, setActiveDay] = useState(0);
  const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);

  const today = new Date(referenceDate);
  var date = today.getDate();
  var dayOfTheWeek = today.getDay();
  var newDate = today.setDate(date - dayOfTheWeek);

  var dataInicial = new Date(newDate);
  const [day, setDay] = useState<any>(dataInicial);
  const [data, setData] = useState<any>(referenceDate);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: appointments = [], isLoading } = useScheduleWeekQuery();

  function generateDaysInMonth(month: number) {
    const dayInMonth = new Date(today.getFullYear(), month, 1);
    const allDaysInMonth = [];

    while (dayInMonth.getMonth() === month) {
      allDaysInMonth.push({
        numeric: dayInMonth.getDate(),
        name: new Intl.DateTimeFormat('pt-BR', { weekday: 'short' }).format(
          dayInMonth,
        ),
      });

      dayInMonth.setDate(dayInMonth.getDate() + 1);
    }

    setDays(allDaysInMonth);
  }

  function handleDaySelection(d: any) {
    setData(d.toLocaleString());
    setDay(new Date(dataInicial.setDate(d.toLocaleString().slice(0, 2))));
    setDay(new Date(dataInicial.setMonth(d.toLocaleString().slice(3, 5) - 1)));
    // setReferenceDate(dataInicial.toISOString());
    setReferenceDate(dataInicial.toISOString());
  }

  useEffect(() => {
    setReferenceDate(dataInicial.toISOString());

    // setInterval(dataInicial);
  }, []);

  useEffect(() => {
    if (!isLoading && activeDay !== 0) {
      if (activeDay < 3) {
        setDaySlide(activeDay - 1);
      } else {
        setTimeout(() => {
          setDaySlide(activeDay - 3);
        }, 500);
      }

      if (activeMonth < 2) {
        setMonthSlide(activeMonth);
      } else {
        setTimeout(() => {
          setMonthSlide(activeMonth - 2);
        }, 500);
      }
    }
  }, [isLoading, activeDay]);

  useEffect(() => {
    setMin(0);
    if (appointments && appointments.length > 0) {
      const orderTimes = appointments.sort(
        (a: any, b: any) => a.start.getHours() - b.start.getHours(),
      );
      const minTime = orderTimes[0].start;
      const maxTime = orderTimes[orderTimes.length - 1].end;

      setTimes(orderTimes);
      setMin(minTime.getHours());

      if (maxTime.toString() !== 'Invalid Date') {
        setMax(maxTime.getHours() + 2 > 13 ? 23 : maxTime.getHours() + 2);
      }
    } else {
      setTimes([]);
    }
  }, [appointments]);

  useEffect(() => {}, [isLoading]);

  return (
    <VStack>
      {isLoading ? (
        <Skeleton mt="4" w="100%" h="800px" borderRadius="20px" />
      ) : (
        <Box
          mt="4"
          maxW="100%"
          minW="1000px"
          w="100%"
          h="1000px"
          justifyContent="space-between"
        >
          <CalendarWeek
            defaultView="week"
            events={times}
            defaultDate={referenceDate}
            onRangeChange={(d: any, v: any) => {
              if (v === 'day') {
                // setView(v);
                // handleDaySelection(d);
                return;
              }
              handleDaySelection(d);
            }}
            onKeyPressEvent={e => {}}
            onSelectEvent={(slot: any, e: any) => {
              // if (slot.cpf) {
              //   setSelectedPatient(slot);
              //   onOpen();
              // }
            }}
            scrollToTime={
              min > 0
                ? new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDay(),
                    min,
                  )
                : new Date(
                    today.getFullYear(),
                    today.getMonth(),
                    today.getDay(),
                  )
            }
            min={
              new Date(today.getFullYear(), today.getMonth(), today.getDay())
            }
            max={
              new Date(
                today.getFullYear(),
                today.getMonth(),
                today.getDay(),
                max,
              )
            }
          />

          {/* <Calendar
            culture="pt-BR"
            localizer={localizer}
            startAccessor="start"
            endAccessor="end"
            dayLayoutAlgorithm="overlap"
            events={times}
            popup
            step={30}
            timeslots={2}
            toolbar
          /> */}
        </Box>
      )}

      {/* <ScaleActions /> */}

      {selectedPatient && selectedPatient.cpf ? (
        <PatientInfoModal
          isOpen={isOpen}
          onClose={onClose}
          patient={selectedPatient}
        />
      ) : (
        selectedPatient && (
          <SchedulePatientModal
            isOpen={isOpen}
            onClose={onClose}
            currentPatient={selectedPatient}
            appointments={appointments}
          />
        )
      )}
    </VStack>
  );
}
