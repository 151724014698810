import { useMutation } from 'react-query';

import { api } from '@/lib';
import { useUserStore } from '@/stores';

type Secretary = {
  nome: string;
  email: string;
  cpf: string;
  telefone: string;
  generoId: string;
  dataNascimento: Date;
  cep: string;
  numero: number;
  endereco: string;
  bairro: string;
  cidade: string;
  complemento: string;
  senha: string;
};

async function updateSecretary(payload: Secretary, id?: string): Promise<void> {
  await api.put(`/assistente?secretariaId=${id}`, payload);
}

export function useUpdateSecretaryMutation() {
  const user = useUserStore(state => state.user);

  const mutation = useMutation((payload: Secretary) =>
    updateSecretary(payload, user?.id),
  );

  return mutation;
}
