import { Box, useRadio, useRadioGroup, Text, Flex } from '@chakra-ui/react';
import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { getPatientPrescription } from '../../../hooks';
import { TableSectionQuestion } from './Questions/TableSectionQuestion';
import { TableSectionResponse } from './Response/TableSectionResponse';

function CardItem(props: any) {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as="label">
      <input {...input} />

      <Box
        {...checkbox}
        cursor="pointer"
        fontSize="md"
        fontWeight="500"
        _checked={{
          bg: 'transparent',
          color: '#60C2B0',
        }}
        fontFamily="Rubik"
        px={5}
        py={3}
        borderBottom="1px solid #E4E4E4"
        whiteSpace="nowrap"
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function MonitoringCard({ patientId }: any) {
  const [textCard, setTextCard] = useState<any[]>([]);
  const [option, setOption] = useState('QUESTIONÁRIOS');
  const [prescription, setPrescription] = useState<any>([]);
  const options = ['QUESTIONÁRIOS'];

  const SetOptionValue = (v: any) => {
    setOption(v);
  };

  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'framework',
    defaultValue: 'QUESTIONÁRIOS',
    onChange: SetOptionValue,
  });

  return (
    <Flex>
      <Box>
        {options.map(value => {
          const radio = getRadioProps({ value });
          return (
            <CardItem key={value} {...radio} defaultValue="QUESTIONÁRIOS">
              {value}
            </CardItem>
          );
        })}
      </Box>
      <Flex flex="1">
        <Box backgroundColor="#fff" flex="1">
          {option === 'QUESTIONÁRIOS' ? (
            <Card
              style={{
                maxHeight: '60rem',
                height: '100%',
                borderRadius: '10px',
                backgroundColor: '#fff',
                marginBottom: '30px',
              }}
            >
              <TableSectionQuestion patientId={patientId} />
            </Card>
          ) : option === 'RESPOSTAS' ? (
            <Card
              style={{
                maxHeight: '60rem',
                height: '60rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
                overflowY: 'auto',
              }}
            >
              <TableSectionResponse patientId={patientId} />
            </Card>
          ) : (
            <Card
              style={{
                maxHeight: '60rem',
                height: '60rem',
                borderRadius: '10px',
                backgroundColor: '#fff',
                overflowY: 'auto',
              }}
            >
              {textCard.length
                ? textCard?.map((p: any) => <Text>{`- ${p}`}</Text>)
                : 'NÃO HÁ DADOS'}
            </Card>
          )}
        </Box>
      </Flex>
    </Flex>
  );
}
