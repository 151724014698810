import { Tr, Td } from '@chakra-ui/react';
import { TablesReport, useAttendanceTableQuery } from '@/features/reports';

export function AttendanceTable() {
  const { data } = useAttendanceTableQuery();
  return (
    <TablesReport head={data ? data.tableHead : []}>
      {data?.tableBody.map(b => (
        <Tr key={b.nomePaciente}>
          <Td>{b.nomePaciente}</Td>
          <Td>{b.data}</Td>
          <Td>{b.horasInicial}</Td>
          <Td>{b.horasFinal}</Td>
          <Td>{b.status}</Td>
          <Td>{b.dataUltimaConsulta}</Td>
          <Td>{b.cidUltimaConsulta}</Td>
        </Tr>
      ))}
    </TablesReport>
  );
}
