import { useUserStore } from '@/stores';
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
  useMemo,
} from 'react';
import useWebSocket from 'react-use-websocket';

interface ChatNotificationsProviderProps {
  children: ReactNode;
}

interface IChatNotificationsContextData {
  notifications: any[];
}

const ChatNotificationsContext = createContext(
  {} as IChatNotificationsContextData,
);

function ChatNotificationsProvider({
  children,
}: ChatNotificationsProviderProps) {
  const user = useUserStore(state => state.user);
  const [notifications, setNotifications] = useState<any[]>([]);

  const { lastJsonMessage } = useWebSocket(
    `wss://api-hlg.dmhealth.com.br/api/websocket/chat/wsChatList?id=${
      user!.id
    }`,
    {
      retryOnError: true,
      reconnectInterval: 300,
      shouldReconnect: () => true,
    },
  );

  useEffect(() => {
    if (lastJsonMessage && typeof lastJsonMessage === 'object') {
      setNotifications(lastJsonMessage as any[]);
    }
  }, [lastJsonMessage]);

  const contextValue = useMemo(() => ({ notifications }), [notifications]);

  return (
    <ChatNotificationsContext.Provider value={contextValue}>
      {children}
    </ChatNotificationsContext.Provider>
  );
}

function useChatNotifications() {
  const context = useContext(ChatNotificationsContext);

  return context;
}

export { ChatNotificationsProvider, useChatNotifications };
