import { useQuery } from 'react-query';

import { api } from '@/lib';

type GendersAPIResponse = {
  data: {
    id: string;
    nome: string;
  }[];
};

async function gendersRequest(): Promise<GendersAPIResponse> {
  const response = await api.get('/sexo');

  return response.data;
}

export function useGendersQuery() {
  const query = useQuery('genders', gendersRequest, {
    refetchInterval: false,
    select: originalResponse => {
      return originalResponse.data
        .filter(item => item.nome !== 'Outro')
        .map(gender => ({
          label: gender.nome,
          value: gender.id,
        }));
    },
  });

  return query;
}
