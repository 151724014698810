import { formatCnpj } from '@/utils';
import {
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
  Text,
  Heading,
} from '@chakra-ui/react';

interface Props {
  instance: any;
}

export function ViewInstanceInfo({ instance }: Props) {
  return (
    <VStack spacing="4">
      <SimpleGrid minChildWidth="150px" spacing="4" w="100%">
        <SimpleGrid>
          <Heading as="h6" size="sm" color="#707070">
            Descrição
          </Heading>
          <Text>{instance && instance.label}</Text>
        </SimpleGrid>
        <SimpleGrid>
          <Heading as="h6" size="sm" color="#707070">
            CNPJ
          </Heading>
          <Text>{instance && formatCnpj(instance.cnpj)}</Text>
        </SimpleGrid>
      </SimpleGrid>

      <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
          <Heading as="h6" size="sm" color="#707070">
            Endereço
          </Heading>
          <Text>{instance.endereco && instance.endereco.logradouro}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Heading as="h6" size="sm" color="#707070">
            Numero
          </Heading>
          <Text>{instance.endereco && instance.endereco.numero}</Text>
        </GridItem>
      </Grid>
      <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
          <Heading as="h6" size="sm" color="#707070">
            Bairro
          </Heading>
          <Text>{instance.endereco && instance.endereco.bairro}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Heading as="h6" size="sm" color="#707070">
            CEP
          </Heading>
          <Text>{instance.endereco && instance.endereco.cep}</Text>
        </GridItem>
      </Grid>
      <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
        <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
          <Heading as="h6" size="sm" color="#707070">
            Cidade
          </Heading>
          <Text>{instance.endereco && instance.endereco.cidade}</Text>
        </GridItem>
        <GridItem colSpan={2}>
          <Heading as="h6" size="sm" color="#707070">
            Estado
          </Heading>
          <Text>{instance.endereco && instance.endereco.estado}</Text>
        </GridItem>
      </Grid>
    </VStack>
  );
}
