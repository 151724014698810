import {
  Box,
  Button,
  Flex,
  Stack,
  Icon,
  IconButton,
  Image,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { IoChevronBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';

import { registerSchema, useRegisterMutation } from '@/features/auth';
import { useCouncilsQuery, useGendersQuery } from '@/hooks';
import { STATE_OPTIONS } from '@/utils';

import { AppVersion } from '@/components';
import {
  MaskedInput,
  SelectInput,
  TextInput,
} from '@/features/auth/components';

import logoImg from '@/assets/logo.png';

type RegisterFormFields = {
  name: string;
  email: string;
  birthDate: string;
  cpf: string;
  gender: string;
  password: string;
  confirmPassword: string;
  council: string;
  councilNumber: string;
  state: string;
  expertise: string;
  rqe: string;
};

export function RegisterForm() {
  const [formStep, setFormStep] = useState(0);

  const {
    control,
    handleSubmit,
    formState: { errors },
    register,
    trigger,
  } = useForm<RegisterFormFields>({
    defaultValues: {
      name: '',
      email: '',
      birthDate: '',
      cpf: '',
      gender: '',
      password: '',
      confirmPassword: '',
      council: '',
      councilNumber: '',
      state: '',
      expertise: '',
      rqe: '',
    },
    mode: 'onChange',
    reValidateMode: 'onChange',
    shouldFocusError: false,
    resolver: yupResolver(registerSchema),
  });

  const navigate = useNavigate();

  const { data: genders = [] } = useGendersQuery();
  const { data: councils = [] } = useCouncilsQuery();

  const { isLoading, mutate } = useRegisterMutation();

  function handleStepBack() {
    setFormStep(currStep => currStep - 1);
  }

  async function handleStepCompletion() {
    let isFormValid = false;

    isFormValid = await trigger([
      'name',
      'email',
      'birthDate',
      'cpf',
      'gender',
      'password',
      'confirmPassword',
    ]);

    if (isFormValid) {
      setFormStep(currStep => currStep + 1);
    }
  }

  function onSubmit(values: RegisterFormFields) {
    const { email, password, name, cpf, birthDate, state, gender, council } =
      values;

    // Formatando a data de nascimento para o formato internacional
    const intlBirthDate = `${birthDate.slice(2, 4)}/${birthDate.slice(
      0,
      2,
    )}/${birthDate.slice(4, 8)}`;

    const payload = {
      email,
      senha: password,
      nome: name,
      cpf,
      rg: '',
      celular: '',
      dataNascimento: new Date(intlBirthDate),
      endereco: {
        cep: '',
        numero: 0,
        logradouro: '',
        complemento: '',
        bairro: '',
        cidade: '',
        estado: state,
        latitude: 0,
        longitude: 0,
        regiao: '',
        codIBGE: '',
      },
      sexoId: gender,
      pronomeTratamentoId: council,
      enderecoFoto: '',
      telefoneResidencial: '',
      tokenDeviceId: '',
    };

    mutate(payload);
  }

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      py={['12', '12', '8']}
      px={['8', '14', '14']}
      w="100%"
      height="100%"
      bg="white"
      borderRadius="0 22px 22px 0"
      direction="column"
      align="center"
    >
      <Flex w="100%" justify="space-between">
        <Tooltip
          label="Voltar para a tela de Login"
          bg="gray.800"
          color="white"
          placement="right"
          hasArrow
        >
          <IconButton
            aria-label="Voltar para a tela anterior"
            icon={<Icon as={IoChevronBack} fontSize="24px" color="gray.600" />}
            onClick={() => navigate(-1)}
          />
        </Tooltip>

        <Image
          src={logoImg}
          alt="Logo da Dynamic Medic Health"
          mb="8"
          w="166px"
          h="77px"
        />

        <Box visibility="hidden" />
      </Flex>

      {formStep === 0 && (
        <VStack spacing="4" w="100%">
          <TextInput
            error={errors.name}
            placeholder="Nome completo"
            {...register('name')}
          />

          <TextInput
            error={errors.email}
            type="email"
            placeholder="Email"
            {...register('email')}
          />

          <MaskedInput
            name="birthDate"
            control={control}
            mask="date"
            error={errors.birthDate}
            placeholder="Data de nascimento"
          />

          <MaskedInput
            name="cpf"
            control={control}
            mask="cpf"
            error={errors.cpf}
            placeholder="CPF"
          />

          <SelectInput
            options={genders}
            error={errors.gender}
            placeholder="Gênero"
            {...register('gender')}
          />

          <TextInput
            error={errors.password}
            type="password"
            placeholder="Senha"
            {...register('password')}
          />

          <TextInput
            error={errors.confirmPassword}
            type="password"
            placeholder="Confirmar senha"
            {...register('confirmPassword')}
          />
        </VStack>
      )}

      {formStep === 1 && (
        <VStack spacing="4" w="100%">
          <SelectInput
            options={councils}
            error={errors.council}
            placeholder="Conselho"
            {...register('council')}
          />

          <TextInput
            error={errors.councilNumber}
            placeholder="Número do conselho"
            {...register('councilNumber')}
          />

          <SelectInput
            options={STATE_OPTIONS}
            error={errors.state}
            placeholder="Estado"
            {...register('state')}
          />

          <SelectInput
            options={[{ label: 'Ortopedista', value: 'Ortopedista' }]}
            error={errors.expertise}
            placeholder="Especialidade / Área de atuação"
            {...register('expertise')}
          />

          <TextInput
            error={errors.rqe}
            placeholder="RQE"
            {...register('rqe')}
          />
        </VStack>
      )}

      {formStep === 0 ? (
        <Button
          type="button"
          onClick={handleStepCompletion}
          mt="6"
          mb="10"
          w="100%"
          h="38px"
          borderRadius="6"
          bg="brand-orange.400"
          color="white"
          fontFamily="Inter"
          fontSize="md"
          fontWeight="normal"
          boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
          _hover={{
            background: 'brand-orange.300',
          }}
        >
          Continuar
        </Button>
      ) : (
        <Stack direction={['column', 'row']} spacing="4" mt="6" w="100%">
          <Button
            type="button"
            onClick={handleStepBack}
            disabled={isLoading}
            w="100%"
            h="38px"
            borderRadius="6"
            borderWidth="1px"
            borderColor="brand-orange.400"
            bg="white"
            color="brand-orange.400"
            fontFamily="Inter"
            fontSize="md"
            fontWeight="normal"
            boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
            _hover={{
              background: 'gray.50',
            }}
          >
            Voltar
          </Button>

          <Button
            type="submit"
            isLoading={isLoading}
            mb="10"
            w="100%"
            h="38px"
            borderRadius="6"
            bg="brand-orange.400"
            color="white"
            fontFamily="Inter"
            fontSize="md"
            fontWeight="normal"
            boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
            _hover={{
              background: 'brand-orange.300',
            }}
          >
            Finalizar
          </Button>
        </Stack>
      )}

      <AppVersion mt={['8', '8', 'auto']} />
    </Flex>
  );
}
