import { Button, Flex, Heading, Image, Link, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { IoLockClosed } from 'react-icons/io5';
import { Link as ReactRouterLink } from 'react-router-dom';

import { passwordSchema } from '@/features/auth/utils';
import { sendEmail, useLoginMutation } from '@/features/auth';

import { AppVersion } from '@/components';
import { TextInput } from '@/features/auth/components/FormItems';

import logoImg from '@/assets/logo.png';
import { useState } from 'react';
import { FiEye } from 'react-icons/fi';

type LoginFormFields = {
  password: string;
};

export function LoginForm({ SetStep, email }: any) {
  const {
    handleSubmit,
    formState: { errors },
    register,
  } = useForm<LoginFormFields>({
    defaultValues: {
      password: '',
    },
    resolver: yupResolver(passwordSchema),
    shouldFocusError: false,
  });
  const [viewSenha, setViewSenha] = useState(false);

  const { isLoading, mutate } = useLoginMutation();

  function onSubmit(values: LoginFormFields) {
    const payload = {
      login: email,
      senha: values.password,
    };
    mutate(payload);
  }

  return (
    <Flex
      as="form"
      onSubmit={handleSubmit(onSubmit)}
      py={['12', '12', '8']}
      px={['8', '14', '14']}
      w="100%"
      height="100%"
      bg="white"
      borderRadius="0 22px 22px 0"
      direction="column"
      align="center"
      justify="center"
    >
      <Image
        src={logoImg}
        alt="Logo da Dynamic Medic Health"
        mt="auto"
        mb="8"
        w="166px"
        h="77px"
      />

      <VStack spacing="4" w="100%">
        <Heading size="sm" mb="6" color="gray.700" textAlign="left">
          Digite a senha para acesso ao sistema DMHealth
        </Heading>
        {/* <TextInput
          icon={IoPerson}
          error={errors.userName}
          type="text"
          placeholder="Usuário"
          {...register('userName')}
        /> */}

        <TextInput
          icon={IoLockClosed}
          iconView={FiEye}
          setView={() => setViewSenha(!viewSenha)}
          error={errors.password}
          type={viewSenha ? 'text' : 'password'}
          placeholder="Senha"
          {...register('password')}
        />
      </VStack>

      <Flex mt="4" mb="8" w="100%" align="center" justify="flex-end">
        <Link
          as={ReactRouterLink}
          to="/auth/password"
          fontSize="xs"
          color="gray.500"
        >
          Esqueceu sua senha?
        </Link>
      </Flex>

      <Button
        type="submit"
        isLoading={isLoading}
        mb="4"
        w={['100%', '100%', '80%']}
        h="38px"
        borderRadius="6"
        bg="brand-orange.400"
        color="white"
        fontFamily="Inter"
        fontSize="md"
        fontWeight="normal"
        boxShadow="0 1px 3px rgba(0, 0, 0, 0.1)"
        _hover={{
          background: 'brand-orange.300',
        }}
      >
        Login
      </Button>
      <Button
        type="button"
        isLoading={isLoading}
        mb="10"
        w={['100%', '100%', '80%']}
        h="38px"
        borderRadius="6"
        bg="white"
        color="brand-orange.400"
        fontFamily="Inter"
        fontSize="md"
        fontWeight="normal"
        boxShadow="0 1px 3px #F18E5A"
        _hover={{
          background: 'brand-orange.100',
          color: 'white',
        }}
        onClick={() => {
          SetStep(0);
        }}
      >
        Voltar
      </Button>
      <AppVersion mt={['8', '8', 'auto']} />
    </Flex>
  );
}
