import { formatDate } from '@/utils';
import {
  VStack,
  SimpleGrid,
  Grid,
  GridItem,
  Text,
  Heading,
} from '@chakra-ui/react';
import { useEffect } from 'react';

interface Props {
  patient: any;
}

const FormatData = ({ label1, value1, label2, value2 }: any) => {
  return (
    <Grid width="100%" templateColumns="repeat(4, 1fr)" gap={4}>
      <GridItem colSpan={2} textAlign="left" justifyContent="flex-start">
        <Heading as="h6" size="sm" color="#707070">
          {label1}
        </Heading>
        <Text>{value1}</Text>
      </GridItem>
      <GridItem colSpan={2}>
        <Heading as="h6" size="sm" color="#707070">
          {label2}
        </Heading>
        <Text>{value2}</Text>
      </GridItem>
    </Grid>
  );
};

export function ViewPatientInfo({ patient }: Props) {
  useEffect(() => {}, [patient]);

  return (
    <VStack spacing="4">
      {patient && patient.primeiroAcesso ? (
        <>
          <FormatData
            label1="Email"
            value1={patient && patient.email}
            label2="Celular"
            value2={patient && patient.celular}
          />
          <FormatData
            label1="Código Validação"
            value1={patient && patient.codigoUsuario}
            label2="Criado em"
            value2={patient && formatDate(new Date(patient.dataCriacao))}
          />
        </>
      ) : (
        <>
          {' '}
          <FormatData
            label1="Email"
            value1={patient && patient.email}
            label2="Celular"
            value2={patient && patient.celular}
          />
          <FormatData
            label1="CPF"
            value1={patient && patient.cpf}
            label2="Sexo"
            value2={patient && patient.sexo}
          />
          <FormatData
            label1="Data de Nascimento"
            value1={patient && patient.dataNascimento.replace(/-/g, '/')}
            label2="Usuário"
            value2={patient && patient.userName}
          />
          <FormatData
            label1="Endereço"
            value1={patient && patient.endereco}
            label2="Número"
            value2={patient && patient.numero}
          />
          <FormatData
            label1="Bairro"
            value1={patient && patient.bairro}
            label2="CEP"
            value2={patient && patient.cep}
          />
          <div style={{ marginTop: 15 }} />
          {patient?.dataCriacao && (
            <FormatData
              label1="Criado em:"
              value1={patient && formatDate(new Date(patient.dataCriacao))}
              label2="Atualizado em:"
              value2={patient && formatDate(new Date(patient.dataEdicao))}
            />
          )}
        </>
      )}
    </VStack>
  );
}
