import {
  FormControl,
  FormLabel,
  TextareaProps,
  Textarea,
} from '@chakra-ui/react';
import { forwardRef, ForwardRefRenderFunction } from 'react';
import { FieldError } from 'react-hook-form';

interface TextAreaInputProps extends TextareaProps {
  label: string;
  error?: FieldError;
}

const TextAreaBase: ForwardRefRenderFunction<
  HTMLTextAreaElement,
  TextAreaInputProps
> = ({ name, label, error, ...rest }, ref) => {
  return (
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Textarea
        {...rest}
        resize="none"
        id={name}
        name={name}
        ref={ref}
        pl="4"
        pr="8"
        borderColor="rgba(0, 0, 0, 0.12)"
        background="white"
        color="brand-dark.500"
        _placeholder={{ color: '#bfbfbf' }}
        _hover={{
          borderColor: 'brand-green.400',
        }}
      />
    </FormControl>
  );
};

export const TextAreaInput = forwardRef(TextAreaBase);
