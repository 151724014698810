import { Box, Flex, Heading, Icon, Skeleton } from '@chakra-ui/react';
import { useActivitiesQuery } from '@/features/start/hooks';
import { IoArrowBack } from 'react-icons/io5';
import { ActivitiesCarousel } from './ActivitiesCarousel';
import { ActivityCard } from './ActivityCard';
import {
  ActivitiesSectionPatient,
  ConsultationInfo,
} from '../ActivitiesSectionPatient';

export function ActivitiesSection({ patientSelect, setPatientSelect }: any) {
  const { data: activities, isLoading } = useActivitiesQuery();

  return (
    <Box
      mt="4"
      w="100%"
      sx={{
        '.carousel-item': { '&:not(:last-child)': { paddingRight: '20px' } },
      }}
    >
      {patientSelect ? (
        <Box>
          <Flex alignItems="center" marginTop={10} marginBottom={10}>
            <Icon
              as={IoArrowBack}
              fontSize="30px"
              color="#8A8A8A"
              right="0"
              justifyContent="flex-end"
              marginBottom="2"
              cursor="pointer"
              onClick={() => setPatientSelect(null)}
            />
            <ConsultationInfo patientSelect={patientSelect} />
          </Flex>
          <ActivitiesSectionPatient patientSelect={patientSelect} />
        </Box>
      ) : (
        <Box>
          <Heading
            size="md"
            mb="2"
            color="brand-dark.200"
            fontWeight="medium"
            paddingLeft="2"
          >
            Atividades
          </Heading>
          {activities && !isLoading ? (
            <ActivitiesCarousel>
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.consultasDia.length || 0,
                  tipoAtividade: 'consultasDia',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.consultasSemana.length || 0,
                  tipoAtividade: 'consultasSemana',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.consultasAtendidas,
                  tipoAtividade: 'consultasAtendidas',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.consultasCanceladas,
                  tipoAtividade: 'consultasCanceladas',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.consultasNaoRealizadas,
                  tipoAtividade: 'consultasNaoRealizadas',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.consultasDisponibilizadas,
                  tipoAtividade: 'consultasDisponibilizadas',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.consultasAgendadas,
                  tipoAtividade: 'consultasAgendadas',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.horariosVagos,
                  tipoAtividade: 'horariosVagos',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.consultasRemarcadas,
                  tipoAtividade: 'consultasRemarcadas',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.mensagensNaoRespondidasDia,
                  tipoAtividade: 'mensagensNaoRespondidasDia',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.mensagensNaoRespondidasSemana,
                  tipoAtividade: 'mensagensNaoRespondidasSemana',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.notificacoesNaoVistasDia,
                  tipoAtividade: 'notificacoesNaoVistasDia',
                }}
              />
              <ActivityCard
                activity={{
                  dataAtualizacao: new Date(),
                  numeroPacientes: activities.notificacoesNaoVistasSemana,
                  tipoAtividade: 'notificacoesNaoVistasSemana',
                }}
              />
            </ActivitiesCarousel>
          ) : (
            <ActivitiesCarousel>
              {Array.from(Array(5).keys()).map(key => (
                <Skeleton key={key} w="100%" h="165px" borderRadius="20px" />
              ))}
            </ActivitiesCarousel>
          )}
        </Box>
      )}
    </Box>
  );
}
