import { Flex, Heading, Stack } from '@chakra-ui/react';
import { PatientSearchInput } from './PatientSearchInput';
import { InstanciaSelect } from './InstanciaSelect';

interface Props {
  title: string;
  search: string;
  onChange: any;
}

export function TopSection({ title, search, onChange }: Props) {
  return (
    <Flex
      direction={['column', 'column', 'row']}
      w="100%"
      align="center"
      justify="space-between"
    >
      <Heading
        size="lg"
        w="100%"
        mb={['4', '4', '0']}
        color="brand-dark.500"
        fontWeight="medium"
      >
        {title}
      </Heading>
      <Stack
        direction={['column', 'row']}
        spacing="4"
        w="100%"
        justify="flex-end"
      >
        <InstanciaSelect />
        <PatientSearchInput value={search} onChange={onChange} />
      </Stack>
    </Flex>
  );
}
