import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';

import { UpdateSecretaryForm } from '@/features/registers';
import { useEditUserModalStore, useUserStore } from '@/stores';

export function UpdateUserOptionsPage() {
  const user = useUserStore(state => state.user);
  const { isOpen, onClose } = useEditUserModalStore();

  const navigate = useNavigate();

  function renderModalContent() {
    if (user?.type === 'secretario') {
      return <UpdateSecretaryForm onCancel={() => navigate(-1)} />;
    }

    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      isCentered
      closeOnOverlayClick={false}
      closeOnEsc={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalHeader
          color="brand-dark.300"
          fontSize="xl"
          fontWeight="medium"
          textAlign="center"
        >
          Atualizar dados
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody px="12" pb="8">
          {renderModalContent()}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
